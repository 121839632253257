var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 263.22 189.81",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "card-back-too-small-linear-gradient",
                x1: "82.8",
                y1: "125.55",
                x2: "332.95",
                y2: "123.78",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#a6a6a6" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "card-back-too-small-cls-1" }, [
        _c("g", { attrs: { id: "Card-too-small" } }, [
          _c("rect", {
            staticClass: "card-back-too-small-cls-2",
            attrs: { width: "263.22", height: "189.81" },
          }),
          _vm._v(" "),
          _c("g", [
            _c("rect", {
              staticClass: "card-back-too-small-cls-3",
              attrs: {
                x: "76.91",
                y: "60.68",
                width: "103.71",
                height: "68.63",
                rx: "4.84",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              staticClass: "card-back-too-small-cls-4",
              attrs: {
                x: "112.3",
                y: "48.06",
                width: "2.7",
                height: "52.01",
                transform: "translate(39.39 187.59) rotate(-89.9)",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "card-back-too-small-cls-4",
              attrs: {
                points:
                  "120.58 108.64 87.71 108.55 87.71 105.85 120.54 106.17 120.58 108.64",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("rect", {
                staticClass: "card-back-too-small-cls-4",
                attrs: {
                  x: "105.52",
                  y: "66.28",
                  width: "2.7",
                  height: "38.5",
                  transform: "translate(21.16 192.26) rotate(-89.9)",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                staticClass: "card-back-too-small-cls-4",
                attrs: {
                  x: "105.52",
                  y: "76.52",
                  width: "2.7",
                  height: "38.5",
                  transform: "translate(10.93 202.48) rotate(-89.9)",
                },
              }),
            ]),
            _vm._v(" "),
            _c("path", {
              staticClass: "card-back-too-small-cls-5",
              attrs: {
                d:
                  "M180.67,121.16l0,5.44a2.73,2.73,0,0,1-2.73,2.71l-98-.16a3.3,3.3,0,0,1-3-2.41v-5.58Z",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              staticClass: "card-back-too-small-cls-6",
              attrs: {
                x: "76.91",
                y: "60.64",
                width: "103.71",
                height: "68.63",
                rx: "4.84",
              },
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }