// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.progress-bar[data-v-77431866] {
  background: var(--progress-bar-background);
  width: 100%;
  z-index: 2;
}
.progress-indicator[data-v-77431866] {
  background: var(--progress-bar-color);
  display: block;
  width: 0;
  height: var(--progress-bar-height);
  transition: width 0.35s ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/ProgressBar.vue"],"names":[],"mappings":";AA4BA;EACA,0CAAA;EACA,WAAA;EACA,UAAA;AACA;AAEA;EACA,qCAAA;EACA,cAAA;EACA,QAAA;EACA,kCAAA;EACA,mCAAA;AACA","sourcesContent":["<template>\n  <div class=\"progress-bar\">\n    <i class=\"progress-indicator\" :style=\"progressStatus\" />\n  </div>\n</template>\n\n<script>\nimport getters from '@src/store/getters'\nimport { mapGetters } from 'vuex'\n\nexport default {\n  computed: {\n    ...mapGetters([getters.allowedPages, getters.isIdentityCheckFailed]),\n\n    progressStatus() {\n      const { name } = this.$route\n      const { allowedPages } = this\n\n      const width = this.isIdentityCheckFailed\n        ? 100\n        : (100 / allowedPages.length) * (allowedPages.indexOf(name) + 1)\n      return { width: `${width || 0}%` }\n    }\n  }\n}\n</script>\n\n<style scoped>\n.progress-bar {\n  background: var(--progress-bar-background);\n  width: 100%;\n  z-index: 2;\n}\n\n.progress-indicator {\n  background: var(--progress-bar-color);\n  display: block;\n  width: 0;\n  height: var(--progress-bar-height);\n  transition: width 0.35s ease-in-out;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
