import VueI18n from 'vue-i18n'
import { deepMerge } from '@src/scripts/helpers'

export let i18n = null

const defaultLocale = 'en'
let defaultAppTranslations = null
let defaultZoomTranslations = null
let defaultCustomTranslations = null
let defaultResourceName = null

export default {
  install: (Vue) => {
    Vue.use(VueI18n)

    i18n = new VueI18n({
      locale: null,
      fallbackLocale: defaultLocale,
      silentTranslationWarn: true
    })

    Vue.prototype.resource = null
    Vue.prototype.live = false
    Vue.prototype.$language = () => i18n.locale

    Vue.prototype.$td = getValue
    Vue.prototype.$changeLang = loadTranslations
    Vue.prototype.$loadZoomTranslations = loadZoomTranslations
    Vue.prototype.$loadCustomTranslations = setCustomTranslations
  }
}

function getValue(key) {
  const valueKey = `${this.resource}.${key}`
  const defaultKey = `${valueKey}Default`
  const errorKey = `Error.${key}`

  if (this.live) {
    if (i18n.te(valueKey)) return i18n.t(valueKey)
  }
  if (i18n.te(defaultKey)) return i18n.t(defaultKey)
  if (i18n.te(errorKey)) return i18n.t(errorKey)
  return null
}

async function loadTranslations(lang) {
  if (i18n.locale === lang) return
  i18n.locale = lang

  if (!defaultAppTranslations) await initAppTranslations()
  let translations = deepCopy(defaultAppTranslations)

  try {
    if (defaultLocale !== lang) {
      const appTranslations = await getTranslates(lang)
      if (appTranslations) {
        appTranslations.Countries = await getCounriesTranslates(lang)
        translations = deepMerge(translations, appTranslations, true)
      }
    }
  } catch (error) {
    // empty
  }

  if (defaultZoomTranslations) loadZoomTranslations(lang)

  if (defaultResourceName) {
    const customTranslations = await loadCustomTranslations(
      defaultResourceName,
      lang
    )
    if (customTranslations) {
      translations = deepMerge(translations, customTranslations, true)
    }
  }

  i18n.setLocaleMessage(lang, translations)
}

async function loadZoomTranslations(lang) {
  if (!defaultZoomTranslations) await initZoomTranslations()
  let translations = deepCopy(defaultZoomTranslations)

  try {
    if (defaultLocale !== lang) {
      const zoomTranslations = await getZoomTranslates(lang)
      if (zoomTranslations) {
        translations = deepMerge(translations, zoomTranslations, true)
      }
    }
  } catch (error) {
    console.error(error)
    // empty
  }

  window.zoomTranslations = translations
}

async function loadCustomTranslations(resourceName, lang) {
  if (!defaultCustomTranslations) await initCustomTranslations(resourceName)
  let translations = deepCopy(defaultCustomTranslations)

  try {
    if (defaultLocale !== lang) {
      const customTranslations = await getCustomTranslates(resourceName, lang)
      if (customTranslations) {
        translations = deepMerge(translations, customTranslations, true)
      }
    }
  } catch (error) {
    // empty
  }

  return translations
}

async function setCustomTranslations(resourceName, lang) {
  let translations = i18n.getLocaleMessage(lang)
  if (!translations) return

  const customTranslations = await loadCustomTranslations(resourceName, lang)
  if (!customTranslations) return

  translations = deepMerge(translations, customTranslations, true)
  i18n.setLocaleMessage(lang, translations)
}

async function initAppTranslations() {
  try {
    defaultAppTranslations = await getTranslates(defaultLocale)
    if (defaultAppTranslations) {
      defaultAppTranslations.Countries = await getCounriesTranslates(
        defaultLocale
      )
    }
  } catch (error) {
    // empty
  }
}

async function initZoomTranslations() {
  try {
    defaultZoomTranslations = await getZoomTranslates(defaultLocale)
  } catch (error) {
    // empty
  }
}

async function initCustomTranslations(resourceName) {
  try {
    defaultCustomTranslations = await getCustomTranslates(
      resourceName,
      defaultLocale
    )
    defaultResourceName = resourceName
  } catch (error) {
    // empty
  }
}

async function getTranslates(lang) {
  try {
    return await new Promise((resolve) => {
      setTimeout(async () => {
        const response = await fetch(`/${Hash}/translates/${lang}.json`)
        resolve(response.json())
      })
    })
  } catch {
    return undefined
  }
}

async function getCounriesTranslates(lang) {
  try {
    return await new Promise((resolve) => {
      setTimeout(async () => {
        const response = await fetch(
          `/${Hash}/translates/countries/${lang}.json`
        )
        resolve(response.json())
      })
    })
  } catch {
    return undefined
  }
}

async function getCustomTranslates(resourcePath, lang) {
  if (!resourcePath) return undefined

  try {
    return await new Promise((resolve) => {
      setTimeout(async () => {
        const response = await fetch(`${resourcePath}/${lang}.json`)
        resolve(response.json())
      })
    })
  } catch {
    return undefined
  }
}

async function getZoomTranslates(lang) {
  try {
    return await new Promise((resolve) => {
      setTimeout(async () => {
        const response = await fetch(`/${Hash}/translates/zoom/${lang}.json`)
        resolve(response.json())
      })
    })
  } catch {
    return undefined
  }
}

function deepCopy(obj) {
  if (!obj) return
  return JSON.parse(JSON.stringify(obj))
}
