const FACETEC_MAIN_CONTAINER_ID = 'DOM_FT_PRIMARY_TOPLEVEL_mainContainer'
const FACETEC_RETRY_SCREEN_ID = 'DOM_FT_retryScreen'
const FACETEC_READY_SCREEN_ID = 'DOM_FT_getReadyScreen'
const FACETEC_READY_BUTTON_ID = 'DOM_FT_getReadyActionButton'
const FOOTER_CONTAINER_ID = 'footerContainer'

const RETRY_ACTION_CLASS = 'retry-action'
const EXTRA_ACTION_CLASS = 'extra-action'

// We need to manually override some facetec functionality to match our needs.
//
// # Extra action
// * In some cases we need extra action after liveness failure
//   - add (addExtraAction)
//   - update if added (addExtraActionListener -> extraActionHandler)

export class FaceTecOverride {
  state = {
    isReadyButtonVisible: false,
    isRetryScreenVisible: false
  }

  addBodyMutationListener() {
    const mutationObserver = new MutationObserver((nodes) =>
      this.bodyMutationHandler(nodes)
    )

    mutationObserver.observe(document.body, {
      childList: true
    })
  }

  addExtraAction({ label, onClick }) {
    const block = document.getElementById(FACETEC_RETRY_SCREEN_ID)
    if (!block) {
      return
    }

    const retryAction = block.querySelector('button')
    const extraAction = retryAction.cloneNode(true)

    retryAction.classList.add(RETRY_ACTION_CLASS)

    extraAction.classList.add(EXTRA_ACTION_CLASS)
    extraAction.disabled = false
    extraAction.innerHTML = label
    extraAction.onclick = onClick

    block.appendChild(extraAction)

    this.addExtraActionListener(extraAction)
  }

  // private
  didBecomeVisible = (nodes, statePropName) => {
    const isVisible = isElementVisible(nodes)

    if (isVisible === this.state[statePropName]) {
      return false
    }

    this.state[statePropName] = isVisible
    return isVisible
  }

  bodyMutationHandler = (nodes) => {
    const faceTecMainContainerAdded = nodes.some((node) =>
      Array.from(node.addedNodes).some(
        (element) => element.id === FACETEC_MAIN_CONTAINER_ID
      )
    )
    if (faceTecMainContainerAdded) {
      addStyleMutationListener(FACETEC_READY_SCREEN_ID, this.readyScreenHandler)
      addStyleMutationListener(FACETEC_READY_BUTTON_ID, this.readyButtonHandler)
      addStyleMutationListener(FACETEC_RETRY_SCREEN_ID, this.retryScreenHandler)
    }
  }

  readyScreenHandler = (nodes) => {
    if (!isElementVisible(nodes)) {
      this.state.isReadyButtonVisible = false
      return
    }

    // fallback solution if auto-click would not be fired correctly
    setTimeout(() => {
      if (!this.state.isReadyButtonVisible) {
        return
      }

      const readyButtonElement = document.getElementById(
        FACETEC_READY_BUTTON_ID
      )

      readyButtonElement?.dispatchEvent(new Event('mousedown'))
    }, 1200)
  }

  readyButtonHandler = (nodes) => {
    if (this.didBecomeVisible(nodes, 'isReadyButtonVisible')) {
      const readyButtonElement = document.getElementById(
        FACETEC_READY_BUTTON_ID
      )

      readyButtonElement?.dispatchEvent(new Event('mousedown'))
    }
  }

  retryScreenHandler = (nodes) => {
    if (this.didBecomeVisible(nodes, 'isRetryScreenVisible')) {
      const footerContainer = document.getElementById(FOOTER_CONTAINER_ID)

      if (footerContainer) {
        footerContainer.style.display = 'flex'
      }
    }
  }

  addExtraActionListener = (extraAction) => {
    const mutationObserver = new MutationObserver((nodes) =>
      this.extraActionHandler(nodes)
    )

    mutationObserver.observe(extraAction, {
      attributeFilter: ['disabled']
    })
  }

  extraActionHandler = (nodes) => {
    const extraActionMutation = nodes.find((node) => !!node.target.disabled)
    if (extraActionMutation) {
      extraActionMutation.target.disabled = false
    }
  }
}

const addStyleMutationListener = (id, callback) => {
  const element = document.getElementById(id)

  const mutationObserver = new MutationObserver((nodes) => callback(nodes))

  mutationObserver.observe(element, {
    attributeFilter: ['style']
  })
}

const isElementVisible = (nodes) => {
  return nodes.some(
    (element) =>
      element.target.style.opacity === '1' &&
      element.target.style.visibility !== 'hidden'
  )
}
