<template>
  <a class="card" :title="label" @click="$emit('click')">
    <i class="image">
      <slot />
    </i>
    <span class="label">{{ label }}</span>
  </a>
</template>

<script>
export default {
  props: {
    label: { type: String, default: undefined }
  }
}
</script>

<style scoped>
.card {
  transition: border-color var(--document-time) ease-in-out;
}

.image {
  display: block;
}

.label {
  font-family: var(--primary-font);
  color: var(--text-contrast-color);
  display: block;
  transition: color var(--document-time) ease-in-out;
}

@media screen and (min-width: 38.0625rem) {
  .card {
    display: flex;
    flex-direction: column;
    width: 29%;
    min-width: 16rem;
    border: 3px solid var(--document-color);
    border-radius: 0.5rem;
    margin: 1rem;
  }

  .card:hover {
    --document-color: var(--document-color-hover);
  }

  .image {
    width: 100%;
    padding: 15% 20% 0% 20%;
  }

  .label {
    font-size: 1.5rem;
    text-align: center;
    padding-bottom: 5%;
    margin: auto 1rem;
    overflow-wrap: anywhere;
  }

  .card:hover .label {
    color: var(--document-color);
  }
}

@media screen and (max-width: 38rem) {
  .card {
    display: flex;
    width: 100%;
    border: 1px solid var(--document-color);
    padding: 1.5rem 1rem;
    margin-bottom: -1px;
  }

  .card:hover {
    z-index: 1;
  }

  .image {
    min-width: 8rem;
    min-height: 7rem;
    padding: 0.5rem 1rem;
    width: 0;
  }

  .label {
    font-size: clamp(1rem, 5vw, 1.5rem);
    margin-top: auto;
    overflow-wrap: anywhere;
    margin-bottom: auto;
  }

  .document-card .image {
    min-width: 5rem;
    min-height: 5rem;
    padding: 0;
    margin-right: 1.5rem;
    width: 0;
  }
}
</style>
