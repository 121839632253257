var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "message-overlay", class: { show: _vm.show } },
    [
      _c("div", { staticClass: "message-content" }, [
        _vm._v("\n    " + _vm._s(_vm.$td("Header." + _vm.message)) + "\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }