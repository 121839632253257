<template>
  <button
    :type="type"
    class="ui-button"
    :class="{
      'button-additional': additional,
      'button-primary': !additional,
      'with-icon': !!photoIcon
    }"
    :disabled="disabled || loading"
    :title="title"
    @click="$emit('click')"
  >
    {{ title }}
    <loader v-if="loading" class="button-loader" />
    <i v-if="photoIcon" class="photo-icon" />
  </button>
</template>

<script>
import Loader from '@src/components/images/Loader'

export default {
  name: 'ui-button',
  components: {
    Loader
  },
  props: {
    type: { type: String, default: undefined },
    label: { type: String, default: undefined },
    timer: { type: Number, default: undefined, required: false },
    disabled: Boolean,
    additional: Boolean,
    photoIcon: Boolean,
    loading: Boolean
  },

  computed: {
    title() {
      const { label, timer } = this
      const buttonTitle =
        timer !== undefined ? this.$t(label, { n: timer }) : this.$t(label)

      return label && buttonTitle
    }
  }
}
</script>

<style scoped>
.ui-button {
  font-family: var(--button-font-family);
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weigth);
  line-height: var(--button-line-height);
  display: flex;
  justify-content: center;
  position: relative;
  min-width: 8rem;
  transition: all 0.15s ease-in-out;
}

.ui-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.15s ease-in-out;
}

.ui-button:focus {
  outline: none;
}

.ui-button:hover {
  transform: translateY(1px);
  cursor: pointer;
}

.ui-button.with-icon {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.photo-icon {
  --image: url('/public/images/photo-icon.png');

  margin-left: 0.625rem;
  display: block;
  width: 1.25rem;
  -webkit-mask-image: var(--image);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-image: var(--image);
  mask-repeat: no-repeat;
  mask-position: center;
  transition: all 0.15s ease-in-out;
}

.button-primary .photo-icon {
  background-color: var(--button-primary-color);
}

.button-additional .photo-icon {
  background-color: var(--button-additional-color);
}

.button-primary {
  background-color: var(--button-primary-background);
  color: var(--button-primary-color);
  border: var(--button-primary-border);
  border-radius: var(--button-primary-border-radius);
  text-transform: var(--button-primary-text-transform);
  padding: var(--button-primary-padding);
  opacity: var(--button-primary-opacity);
}

.button-primary::before {
  border-radius: var(--button-primary-border-radius);
  box-shadow: var(--button-primary-shadow-size)
    var(--button-primary-shadow-color);
  opacity: var(--button-primary-shadow-opacity);
}

.button-primary:hover {
  --button-primary-background: var(--button-primary-background-hover);
  --button-primary-color: var(--button-primary-color-hover);
  --button-primary-opacity: var(--button-primary-opacity-hover);
  --button-primary-border: var(--button-primary-border-hover);
}

.button-primary:hover::before {
  --button-primary-shadow-opacity: var(--button-primary-shadow-opacity-hover);
  --button-primary-shadow-color: var(--button-primary-shadow-color-hover);
}

.button-additional {
  background: var(--button-additional-background);
  color: var(--button-additional-color);
  border: var(--button-additional-border);
  border-radius: var(--button-additional-border-radius);
  text-transform: var(--button-additional-text-transform);
  padding: var(--button-additional-padding);
  opacity: var(--button-additional-opacity);
}

.button-additional::before {
  border-radius: var(--button-additional-border-radius);
  box-shadow: var(--button-additional-shadow-size)
    var(--button-additional-shadow-color);
  opacity: var(--button-additional-shadow-opacity);
}

.button-additional:hover {
  --button-additional-background: var(--button-additional-background-hover);
  --button-additional-color: var(--button-additional-color-hover);
  --button-additional-opacity: var(--button-additional-opacity-hover);
  --button-additional-border: var(--button-additional-border-hover);
}

.button-additional:hover::before {
  --button-additional-shadow-opacity: var(
    --button-additional-shadow-opacity-hover
  );
  --button-additional-shadow-color: var(--button-additional-shadow-color-hover);
}

.ui-button:disabled {
  opacity: 0.7;
  cursor: default;
  transform: none;
}

@keyframes spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button-loader {
  margin-left: 0.5rem;
  animation-name: spinning;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
</style>
