<template>
  <ui-frame>
    <ui-loader :size="3" center />
  </ui-frame>
</template>

<script>
import Api, { call } from '@src/scripts/api'
import {
  appPages,
  errorTypes,
  ruleList,
  ruleStatusList
} from '@src/scripts/enums'
import { MAX_API_DELAY } from '@src/scripts/constants'
import { delay } from '@src/scripts/helpers'
import getters from '@src/store/getters'
import mutations from '@src/store/mutations'
import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: mapGetters([
    getters.isAgeVerificationEnabled,
    getters.isFaceCaptureDisabled
  ]),

  created() {
    this.submit()
  },

  methods: {
    ...mapMutations([
      mutations.removeAgeVerification,
      mutations.removeFaceRequirement
    ]),

    async submit() {
      const { data, error: verifyError } = await this.callWithRetryInit(
        Api.identificationVerify
      )

      if (verifyError) {
        this.$popUp(errorTypes.failed)
        return
      }

      if (this.isAgeVerificationEnabled) {
        if (this.isFaceCaptureDisabled) {
          this.removeAgeVerification()
        } else if (
          data?.rules?.[ruleList.biometricAgeThresholdComparison] !==
          ruleStatusList.success
        ) {
          this.removeFaceRequirement()
          this.$router.pushNext({ name: appPages.documentSelect })

          return
        }
      }

      const { error: submitError } = await this.callWithRetryInit(
        Api.identificationSubmit
      )

      if (submitError) {
        this.$popUp(errorTypes.failed)
        return
      }

      const { error: completionError } = await this.callWithRetryInit(
        Api.completion
      )
      if (completionError) {
        this.$popUp(errorTypes.failed)
        return
      }

      this.$router.pushNext()
    },

    async callWithRetryInit(method) {
      return await this.callWithRetry(method, 5, 1000)
    },

    async callWithRetry(method, count, delayInMs) {
      const response = await call(method)
      const { data, error } = response

      if (data || error !== errorTypes.failed) {
        return response
      }

      if (count < 1) {
        return response
      }

      await delay(delayInMs)

      if (delayInMs < MAX_API_DELAY) {
        delayInMs *= 2
        delayInMs = (delayInMs < MAX_API_DELAY && delayInMs) || MAX_API_DELAY
      }

      return await this.callWithRetry(method, --count, delayInMs)
    }
  }
}
</script>
