<template>
  <ui-frame logo>
    <i class="icon">
      <mobile-id />
    </i>
    <h1 class="header">
      {{ $t('SignatureAuthentication.MobileId.Header') }}
    </h1>
    <p class="description">
      {{ $t('SignatureAuthentication.MobileId.Description') }}
    </p>
    <ui-form @submit="submit">
      <ui-input
        label="SignatureAuthentication.MobileId.PersonCode"
        :value="personCode"
        :error="personCodeErrorMessage"
        :max-length="14"
        :disabled="isLoading"
        @input="personeCodeChange"
      />
      <phone-input
        label="SignatureAuthentication.MobileId.PhoneNumber"
        :value="phoneNumber"
        :error="phoneNumberErrorMessage"
        :country="country"
        :disabled="isLoading"
        @change="phoneNumberChange"
      />
      <ui-button :label="$t('App.Continue')" :disabled="isLoading" />
    </ui-form>
  </ui-frame>
</template>

<script>
import MobileId from '@src/components/images/MobileId'
import PhoneInput from '@src/components/partials/PhoneInput'
import Validators from '@src/scripts/validators'
import Api, { call } from '@src/scripts/api'

export default {
  components: {
    MobileId,
    PhoneInput
  },

  props: {
    settings: { type: Object, default: null }
  },

  data() {
    return {
      isLoading: false,
      country: 'LT',
      personCode: null,
      phoneNumber: {
        code: '',
        number: ''
      },
      personCodeError: 'Required',
      phoneNumberError: 'Required'
    }
  },

  computed: {
    personCodeErrorMessage() {
      const { personCodeError } = this

      return (
        (personCodeError &&
          `SignatureAuthentication.Validators.${personCodeError}`) ||
        null
      )
    },

    phoneNumberErrorMessage() {
      const { phoneNumberError } = this

      return (
        (phoneNumberError &&
          `SignatureAuthentication.Validators.${phoneNumberError}`) ||
        null
      )
    }
  },

  methods: {
    personeCodeChange(value) {
      this.personCode = value
      this.personCodeError = Validators.requiredValidator(value)
    },

    phoneNumberChange(value) {
      this.phoneNumber = value
      this.phoneNumberError = Validators.phoneNumberValidator(value)
    },

    async submit() {
      const {
        personCode,
        phoneNumber,
        personCodeError,
        phoneNumberError
      } = this
      if (personCodeError || phoneNumberError) return

      this.isLoading = true

      try {
        const { error, data } = await call(
          Api.signatureAuthenticationMobileId,
          {
            language: this.$language(),
            nationalIdentityNumber: personCode,
            phoneNumber: `${phoneNumber.code}${phoneNumber.number}`
          }
        )

        if (error || !data) {
          this.$popUp('MobileIdSignFailed')
        } else {
          this.$emit('wait', data.verificationCode)
          return
        }
      } catch (error) {
        this.$popUp('MobileIdSignFailed')
      }

      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.icon {
  width: 6rem;
  display: block;
}

.header {
  margin-bottom: 1.25rem;
  text-align: center;
  color: var(--header-contrast-color);
}

.description {
  text-align: center;
  max-width: 30rem;
  color: var(--text-contrast-color);
}

.ui-form {
  max-width: 10rem;
}

.ui-button {
  margin: 1rem auto auto;
}

@media screen and (min-width: 38.0625rem) {
  .header,
  .description {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}
</style>
