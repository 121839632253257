var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 494.84 630.8",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-front-linear-gradient",
                x1: "247.89",
                y1: "274.76",
                x2: "247.89",
                y2: "223.28",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#3b3b3b" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#545454" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-front-linear-gradient-2",
                x1: "58.87",
                y1: "526.9",
                x2: "1086.69",
                y2: "519.63",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#a6a6a6" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-front-linear-gradient-3",
                x1: "239.53",
                y1: "591.95",
                x2: "261.49",
                y2: "637.34",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#3b3b3b" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#2b2829" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-front-linear-gradient-4",
                x1: "-1.29",
                y1: "480.51",
                x2: "750.6",
                y2: "504.91",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#6f7173" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-front-linear-gradient-5",
                x1: "124.43",
                y1: "417.28",
                x2: "124.43",
                y2: "402.5",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#e3ddd5" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#bdb3a9" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-front-linear-gradient-6",
                x1: "-516.75",
                y1: "1268.94",
                x2: "-548.03",
                y2: "1204.88",
                gradientTransform:
                  "matrix(1.15, 0.01, -0.01, 1.15, 731.68, -1009.77)",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#a3a3a3" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.29", "stop-color": "#b2b2b2" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.66", "stop-color": "#bfbfbf" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#c4c4c4" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "passport-front-linear-gradient-7",
              x1: "120.1",
              y1: "388.93",
              x2: "128.96",
              y2: "402.05",
              "xlink:href": "#passport-front-linear-gradient",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-front-linear-gradient-8",
                x1: "123.7",
                y1: "338.26",
                x2: "123.83",
                y2: "338.4",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#999" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#6e6e6e" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "passport-front-linear-gradient-9",
              x1: "108.11",
              y1: "363.05",
              x2: "239.78",
              y2: "422.8",
              "xlink:href": "#passport-front-linear-gradient-5",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "passport-front-linear-gradient-10",
              x1: "100.63",
              y1: "361.42",
              x2: "98.93",
              y2: "370.39",
              "xlink:href": "#passport-front-linear-gradient-5",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-front-linear-gradient-11",
                x1: "120.62",
                y1: "368.45",
                x2: "128.73",
                y2: "390.66",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#d1cdcb" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#9c9189" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-front-linear-gradient-12",
                x1: "123.64",
                y1: "338.23",
                x2: "123.69",
                y2: "338.22",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.13", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.28", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#1f1f1f" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.54", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.78", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "passport-front-linear-gradient-13",
              x1: "151.94",
              y1: "369.78",
              x2: "150.12",
              y2: "379.38",
              "xlink:href": "#passport-front-linear-gradient-5",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-front-linear-gradient-14",
                x1: "89.24",
                y1: "348.12",
                x2: "157.71",
                y2: "348.12",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.12", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.25", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#262626" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.58", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "0.8", "stop-color": "#050505" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "passport-front-cls-1" }, [
        _c("g", { attrs: { id: "Passport-front" } }, [
          _c("path", {
            staticClass: "passport-front-cls-2",
            attrs: {
              d:
                "M460.76,281.81l.1,208-.1,60.52a11.17,11.17,0,0,1-11.19,11.15L47,560.77c-6.17,0-11.16-5-12.41-9.88l.1-61.12L35,279.63Z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "passport-front-cls-3",
            attrs: {
              d:
                "M34.59,281.14l.33-209.47L35,11.15A11.17,11.17,0,0,1,46.21,0L448.78.67c6.17,0,11.16,5,12.41,9.88l-.1,61.12-.33,210.14Z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "passport-front-cls-4",
            attrs: {
              d:
                "M460.86,489.77l-.1,60.52a11.17,11.17,0,0,1-11.19,11.15L47,560.77c-6.17,0-11.16-5-12.41-9.88V489.77Z",
            },
          }),
          _vm._v(" "),
          _c("g", [
            _c("polygon", {
              staticClass: "passport-front-cls-5",
              attrs: {
                points:
                  "49.19 525.51 46.36 522.68 54.66 514.39 46.24 505.97 49.07 503.14 60.31 514.39 49.19 525.51",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "passport-front-cls-5",
              attrs: {
                points:
                  "68.38 525.51 65.56 522.68 73.85 514.39 65.43 505.97 68.26 503.14 79.5 514.39 68.38 525.51",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "passport-front-cls-5",
              attrs: {
                points:
                  "87.57 525.51 84.75 522.68 93.04 514.39 84.62 505.97 87.45 503.14 98.7 514.39 87.57 525.51",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "passport-front-cls-5",
              attrs: {
                points:
                  "106.77 525.51 103.94 522.68 112.23 514.39 103.81 505.97 106.64 503.14 117.89 514.39 106.77 525.51",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "passport-front-cls-5",
              attrs: {
                points:
                  "125.96 525.51 123.13 522.68 131.42 514.39 123.01 505.97 125.83 503.14 137.08 514.39 125.96 525.51",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "passport-front-cls-5",
              attrs: {
                points:
                  "436.55 548.38 425.31 537.14 436.43 526.02 439.25 528.85 430.96 537.14 439.38 545.55 436.55 548.38",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "passport-front-cls-5",
              attrs: {
                points:
                  "417.36 548.38 406.11 537.14 417.24 526.02 420.06 528.85 411.77 537.14 420.18 545.55 417.36 548.38",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "passport-front-cls-5",
              attrs: {
                points:
                  "398.17 548.38 386.92 537.14 398.04 526.02 400.87 528.85 392.58 537.14 400.99 545.55 398.17 548.38",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "passport-front-cls-5",
              attrs: {
                points:
                  "378.97 548.38 367.73 537.14 378.85 526.02 381.68 528.85 373.39 537.14 381.8 545.55 378.97 548.38",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "passport-front-cls-5",
              attrs: {
                points:
                  "359.78 548.38 348.54 537.14 359.66 526.02 362.49 528.85 354.19 537.14 362.61 545.55 359.78 548.38",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("path", {
                staticClass: "passport-front-cls-5",
                attrs: {
                  d:
                    "M145.65,505.38h4.29l6,14,6-14h4.27v18.84h-3.78V512l-5,12.26H154.5L149.42,512v12.26h-3.77Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "passport-front-cls-5",
                attrs: {
                  d:
                    "M177,505.38c4.54,0,6.8,2.62,6.8,5.77a5.44,5.44,0,0,1-4.29,5.43l4.51,7.64h-4.37l-4.16-7.34H173.7v7.34h-3.78V505.38Zm-.13,3.13H173.7V514h3.16c2.1,0,3-1.1,3-2.8S179,508.51,176.86,508.51Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "passport-front-cls-5",
                attrs: {
                  d:
                    "M199.56,508.29,191.08,521h8.48v3.19H186.71V521.3l8.42-12.74h-8.42v-3.18h12.85Z",
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("g", [
              _c("polygon", {
                staticClass: "passport-front-cls-5",
                attrs: {
                  points:
                    "14.55 315.13 0 315.13 0 241.22 73.91 241.22 73.91 255.77 14.55 255.77 14.55 315.13",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "passport-front-cls-5",
                attrs: {
                  points:
                    "494.84 599.68 420.92 599.68 420.92 585.13 480.29 585.13 480.29 525.77 494.84 525.77 494.84 599.68",
                },
              }),
            ]),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "passport-front-cls-5",
              attrs: {
                points:
                  "494.84 315.13 480.29 315.13 480.29 255.77 420.92 255.77 420.92 241.22 494.84 241.22 494.84 315.13",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "passport-front-cls-5",
              attrs: {
                points:
                  "73.91 599.68 0 599.68 0 525.77 14.55 525.77 14.55 585.13 73.91 585.13 73.91 599.68",
              },
            }),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("path", {
              staticClass: "passport-front-cls-5",
              attrs: {
                d:
                  "M275.78,590.9v35.47a4.43,4.43,0,0,1-4.44,4.43h-48a4.42,4.42,0,0,1-4.43-4.42V590.9a4.43,4.43,0,0,1,4.44-4.43h9.78a4.46,4.46,0,0,0,3.53-1.74l2.74-3.57a6.73,6.73,0,0,1,5.34-2.63H251a6.73,6.73,0,0,1,5.34,2.63l2.74,3.57a4.46,4.46,0,0,0,3.53,1.74h8.72A4.43,4.43,0,0,1,275.78,590.9Z",
              },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "passport-front-cls-6",
              attrs: { cx: "247.06", cy: "607.51", r: "15.62" },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "passport-front-cls-7",
              attrs: { cx: "247.06", cy: "607.51", r: "10.99" },
            }),
          ]),
          _vm._v(" "),
          _c("rect", {
            staticClass: "passport-front-cls-8",
            attrs: { x: "34.69", y: "487.42", width: "426.17", height: "2.35" },
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "passport-front-cls-9",
            attrs: {
              d:
                "M46.76,0,449.51.66c6.76,0,12.24,5.49,10.64,12.25l.54,268.9H35.14V11.61A11.61,11.61,0,0,1,46.76,0Z",
            },
          }),
          _vm._v(" "),
          _c("g", [
            _c("rect", {
              staticClass: "passport-front-cls-10",
              attrs: {
                x: "57.93",
                y: "309.23",
                width: "131.94",
                height: "133.91",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-front-cls-11",
              attrs: {
                d:
                  "M112.58,394.26,109.89,412a6.87,6.87,0,0,1-3.64,5.12l-23,11.5,82.34.65-17-9.56a12,12,0,0,1-5.82-8.23l-3.13-16.56Z",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("path", {
                staticClass: "passport-front-cls-12",
                attrs: {
                  d:
                    "M103.61,416.91s6.59,6.29,23.06,6.37,23.17-4.09,23.17-4.09L180.93,437l5.71,6.75-124.83-.64,5.06-6.7Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "passport-front-cls-13",
                attrs: {
                  d:
                    "M103.61,416.91s6.59,6.29,23.06,6.37,23.17-4.09,23.17-4.09L180.93,437l5.71,6.75-124.83-.64,5.06-6.7Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "passport-front-cls-13",
                attrs: {
                  d:
                    "M103.61,416.91s6.59,6.29,23.06,6.37,23.17-4.09,23.17-4.09L180.93,437l5.71,6.75-124.83-.64,5.06-6.7Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "passport-front-cls-14",
                attrs: {
                  d:
                    "M103.61,416.91s6.59,6.29,23.06,6.37,23.17-4.09,23.17-4.09L180.93,437l5.71,6.75-124.83-.64,5.06-6.7Z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-front-cls-15",
              attrs: {
                d:
                  "M111.56,398.42s3.37,7.59,14.3,8.19a16.35,16.35,0,0,0,15.36-8.7Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-front-cls-16",
              attrs: {
                d:
                  "M123.7,338.55a2.28,2.28,0,0,1-.08-.65A1.76,1.76,0,0,1,123.7,338.55Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-front-cls-17",
              attrs: {
                d:
                  "M149.57,374.6a39.51,39.51,0,0,1-1.1,11.07,27,27,0,0,1-7,12c0,.05-7.51,5.95-14.48,6.11a8.43,8.43,0,0,1-.87,0,29.34,29.34,0,0,1-3.21-.12c-.51-.05-1-.1-1.54-.19h0c-6.76-1.08-10.21-5.06-13.33-9.31-3.91-5.36-6.92-11.84-6.91-18.46l0-13.68c.1-4.79,0-5.72.1-11.45,0,0,10.62,2.2,25.27-5.67a12.1,12.1,0,0,0,7.71,3.23c7.89.29,9.59-3.25,9.62-4.05.48,2,.93,16.68,4.63,18.65,2.78,1.75,1.17.52,1.15.88Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-front-cls-18",
              attrs: {
                d: "M99.45,367.45s-4.9.19-4.79,4.18,6.75,7.56,6.75,7.56Z",
              },
            }),
            _vm._v(" "),
            _c("polyline", {
              staticClass: "passport-front-cls-19",
              attrs: { points: "125.09 368.55 122.15 384.77 124.68 386.79" },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "passport-front-cls-20",
              attrs: { cx: "113.4", cy: "370.79", r: "2.23" },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "passport-front-cls-20",
              attrs: { cx: "136.21", cy: "370.79", r: "2.23" },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-front-cls-2",
              attrs: {
                d:
                  "M122.56,393.22s6.48-.34,9.71-1.86a5.91,5.91,0,0,1-4.86,2.86C123.85,394.49,122.56,393.22,122.56,393.22Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-front-cls-21",
              attrs: {
                d:
                  "M123.7,338.55a2.28,2.28,0,0,1-.08-.65A1.76,1.76,0,0,1,123.7,338.55Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-front-cls-22",
              attrs: {
                d: "M149.65,366.08s4.9-.21,5.12,3.77-5.46,8.81-5.46,8.81Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-front-cls-23",
              attrs: {
                d:
                  "M101.27,378.09c.29-1.49.58-3,.88-4.47-.37-10.54,1.85-14.15,4.17-15.32,1.35-.68,3.12-.75,4.86-2.4a9.57,9.57,0,0,0,2.66-5.77,20,20,0,0,0,7.44,6.48c10.21,5,21.45-1.86,24.43,2.54,1.74,2.55-1.14,6.22,1.74,11.22a12.39,12.39,0,0,0,2.19,2.78c1.93-2.39,8.87-11.51,8-24a33.11,33.11,0,0,0-4.59-14.57,32.78,32.78,0,0,0-7.81-8.61c-1.66-1.29-14.33-10.86-25.55-6.83a33.57,33.57,0,0,0-10.89,6.49,20.69,20.69,0,0,1-3.48,2.85c-2.95,1.89-4.7,1.84-6.87,2.78-5.38,2.32-7.66,8.19-8.11,9.35-1.17,3-1.4,6.14-.73,10.65C91.34,362.75,97.47,372.71,101.27,378.09Z",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              staticClass: "passport-front-cls-24",
              attrs: {
                id: "Overlay",
                x: "57.93",
                y: "309.23",
                width: "131.51",
                height: "133.91",
              },
            }),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("rect", {
              staticClass: "passport-front-cls-25",
              attrs: {
                x: "307.13",
                y: "243",
                width: "8.93",
                height: "171.93",
                transform: "translate(-17.89 640.01) rotate(-89.9)",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "passport-front-cls-25",
              attrs: {
                points:
                  "334.49 443.25 225.84 442.96 225.85 434.03 334.37 435.09 334.49 443.25",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("rect", {
                staticClass: "passport-front-cls-25",
                attrs: {
                  x: "284.73",
                  y: "303.25",
                  width: "8.93",
                  height: "127.28",
                  transform: "translate(-78.17 655.47) rotate(-89.9)",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                staticClass: "passport-front-cls-25",
                attrs: {
                  x: "284.73",
                  y: "337.08",
                  width: "8.93",
                  height: "127.28",
                  transform: "translate(-112 689.24) rotate(-89.9)",
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("path", {
            staticClass: "passport-front-cls-26",
            attrs: {
              d:
                "M449.87,561.42l-402.75-.66a12.27,12.27,0,0,1-12.25-12.25l-.54-268.09,426.73,1.39.43,268A11.61,11.61,0,0,1,449.87,561.42Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }