<template>
  <card class="document-card" :label="title" @click="routeTo">
    <slot />
  </card>
</template>

<script>
import Card from '@src/components/partials/Card'
import { appPages } from '@src/scripts/enums'

export default {
  components: {
    Card
  },

  props: {
    label: { type: String, default: undefined },
    documentType: { type: String, default: undefined }
  },

  computed: {
    title() {
      const { label } = this
      return label && this.$t(label)
    }
  },

  methods: {
    routeTo() {
      const { documentType } = this
      this.$router.replace({
        name: appPages.documentInstructionsFront,
        params: { ...this.$route.params, type: documentType }
      })
    }
  }
}
</script>

<style scoped>
.document-card {
  cursor: pointer;
}
</style>
