import Vue from 'vue'
import Router from 'vue-router'
import {
  allowedRoute,
  pushNext,
  redirectTo,
  reload,
  replaceNext
} from '@src/scripts/routerManager'
import { appPages, pageTypes } from '@src/scripts/enums'

import StartPage from '@src/components/pages/StartPage'
import ContinuePage from '@src/components/pages/ContinuePage'
import Shell from '@src/components/Shell'
import IdentifyFace from '@src/components/pages/IdentifyFace'
import IdentifyDocument from '@src/components/pages/IdentifyDocument'
import DocumentSelect from '@src/components/partials/document/DocumentSelect'
import DocumentCapture from '@src/components/partials/document/DocumentCapture'
import DocumentInstructions from '@src/components/partials/document/DocumentInstructions'
import FormPage from '@src/components/pages/FormPage'
import VideoCall from '@src/components/pages/VideoCallPage'
import CompanyRepresentation from '@src/components/pages/CompanyRepresentation'
import IdentificationSubmit from '@src/components/pages/IdentificationSubmit'
import IdentificationCheck from '@src/components/pages/IdentificationCheck'
import CameraCheck from '@src/components/pages/CameraCheck'
import ExternalFile from '@src/components/pages/ExternalFile'
import ProofOfAddress from '@src/components/pages/ProofOfAddress'
import { OmnichannelPage } from '@src/components/pages/OmnichannelPage'
import OmnichannelPageQR from '@src/components/pages/OmnichannelPageQR'
import OmnichannelCompleted from '@src/components/pages/OmnichannelCompleted'
import DocumentSign from '@src/components/pages/DocumentSign'
import SignatureAuthentication from '@src/components/pages/SignatureAuthentication'
import Status from '@src/components/pages/Status'

Vue.use(Router)

Router.prototype.allowedRoute = allowedRoute
Router.prototype.pushNext = pushNext
Router.prototype.replaceNext = replaceNext
Router.prototype.redirectTo = redirectTo
Router.prototype.reload = reload

export const routes = [
  {
    path: '/status',
    name: Status,
    component: Status
  },
  {
    path: '/start/:identificationId/:language?/:additionalLang?',
    name: 'Start',
    component: StartPage
  },
  {
    path: '/face-authentication-start/:faceAuthenticationId/:language?',
    name: 'FaceAuthenticationStart',
    component: StartPage
  },
  {
    path:
      '/signature-authentication-start/:signatureAuthenticationId/:language?',
    name: 'SignatureAuthenticationStart',
    component: StartPage
  },
  {
    path: '/sign-start/:identificationId/:language?',
    name: 'SignStart',
    component: StartPage
  },
  {
    path: '/omnichannel-start/:language?',
    name: 'OmnichannelStart',
    component: StartPage
  },
  {
    path: '/continue/:omnichannelId/:language?',
    name: 'Continue',
    component: ContinuePage
  },
  {
    path: '/permissions-continue/:omnichannelId/:language?',
    name: 'PermissionsContinue',
    component: ContinuePage
  },
  { path: '/camera-check', component: CameraCheck },
  {
    path: '/:language?/',
    name: appPages.home,
    component: Shell,
    children: [
      {
        path: 'face',
        name: appPages.face,
        props: { portraitOnly: true },
        component: IdentifyFace
      },
      {
        path: 'face-authentication',
        name: appPages.faceAuthentication,
        props: { portraitOnly: true },
        component: IdentifyFace
      },
      {
        path: 'document',
        component: IdentifyDocument,
        children: [
          {
            path: '',
            name: appPages.documentSelect,
            component: DocumentSelect
          },
          {
            path: ':type/instructions/front',
            name: appPages.documentInstructionsFront,
            props: { portraitOnly: true, side: pageTypes.front },
            component: DocumentInstructions
          },
          {
            path: ':type/instructions/back',
            name: appPages.documentInstructionsBack,
            props: { portraitOnly: true, side: pageTypes.back },
            component: DocumentInstructions
          },
          {
            path: ':type/front',
            name: appPages.documentFront,
            props: { portraitOnly: true, side: 'front' },
            component: DocumentCapture
          },
          {
            path: ':type/back',
            name: appPages.documentBack,
            props: { portraitOnly: true, side: 'back' },
            component: DocumentCapture
          }
        ]
      },
      {
        path: 'company-representation',
        name: 'CompanyRepresentation',
        component: CompanyRepresentation
      },
      {
        path: 'video-call',
        name: 'VideoCall',
        props: { portraitOnly: true, noMask: true },
        component: VideoCall
      },
      { path: 'form/:a?', name: 'FormFront', component: FormPage },
      { path: 'form-back/:a?', name: 'FormBack', component: FormPage },
      {
        path: 'identification-submit',
        name: 'IdentificationSubmit',
        component: IdentificationSubmit
      },
      {
        path: 'identification-check',
        name: 'IdentificationCheck',
        component: IdentificationCheck
      },
      {
        path: 'proof-of-address',
        name: 'ProofOfAddress',
        props: { portraitOnly: true, noMask: true },
        component: ExternalFile
      },
      {
        path: 'poa',
        name: 'ProofOfAddressOcr',
        component: ProofOfAddress
      },
      {
        path: 'omnichannel',
        name: appPages.omnichannel,
        component: OmnichannelPage
      },
      {
        path: 'omnichannel/qr',
        name: 'OmnichannelQR',
        component: OmnichannelPageQR
      },
      {
        path: 'omnichannel-completed',
        name: appPages.omnichannelCompleted,
        component: OmnichannelCompleted
      },
      { path: 'document-sign', name: 'DocumentSign', component: DocumentSign },
      {
        path: 'signature-authentication',
        name: 'SignatureAuthentication',
        component: SignatureAuthentication
      }
    ]
  },
  { path: '**', redirect: { name: appPages.home, params: { language: null } } }
]

export default new Router({
  mode: 'history',
  routes: routes
})
