var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "instructions-frame",
    {
      attrs: {
        header: _vm.$t(_vm.typeSettings.header),
        description: _vm.$t(_vm.typeSettings.description),
        "main-item": _vm.mainImage,
        "sub-items-header": _vm.$t("Instructions.RightSidebarTitle"),
        "sub-items": _vm.typeSettings.subItems,
        action: _vm.$t("Instructions.ButtonText"),
      },
      on: { onaction: _vm.goNext },
    },
    [
      !_vm.isBackSide
        ? _c("p", { staticClass: "footer-text" }, [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("Instructions.FooterText")) + "\n    "
            ),
            _c(
              "span",
              { staticClass: "clickable-text", on: { click: _vm.goHome } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("Instructions.FooterTextClick")) +
                    "\n    "
                ),
              ]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }