var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-frame",
    { staticClass: "form-page", attrs: { "full-size": "" } },
    [
      _c("iframe", {
        ref: "formView",
        attrs: { src: _vm.formUrl + "?v=" + Date.now() },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }