// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.pop-up-description[data-v-4dde56b4] {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}
.button-group[data-v-4dde56b4] {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.ui-button[data-v-4dde56b4] {
  margin: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/popUp/DefaultDetails.vue"],"names":[],"mappings":";AA+CA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACA;AAEA;EACA,gBAAA;EACA,aAAA;EACA,eAAA;EACA,uBAAA;AACA;AAEA;EACA,YAAA;AACA","sourcesContent":["<template>\n  <div>\n    <p class=\"pop-up-description\">\n      {{ $t(`Error.Description.${type}`, options) }}\n    </p>\n    <div class=\"button-group\">\n      <ui-button :label=\"buttonLabel\" @click=\"$emit('click')\" />\n      <omnichannel-controls\n        v-if=\"isOmnichannelAvailable\"\n        @click=\"$emit('close')\"\n      />\n    </div>\n  </div>\n</template>\n\n<script>\nimport OmnichannelControls from '@src/components/partials/OmnichannelControls'\nimport { errorTypes } from '@src/scripts/enums'\n\nexport default {\n  components: {\n    OmnichannelControls\n  },\n\n  props: {\n    buttonLabel: { type: String, default: undefined, required: true },\n    type: { type: String, default: undefined, required: true },\n    options: { type: Object, default: undefined }\n  },\n\n  data() {\n    return {\n      errorTypes\n    }\n  },\n\n  computed: {\n    isOmnichannelAvailable() {\n      const { type } = this\n\n      return [errorTypes.noCamera, errorTypes.screenRecord].includes(type)\n    }\n  }\n}\n</script>\n\n<style scoped>\n.pop-up-description {\n  max-width: 30rem;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.button-group {\n  margin-top: 2rem;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n.ui-button {\n  margin: 1rem;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
