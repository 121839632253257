var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 210.27 224.3",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-bad-lighting-linear-gradient",
                x1: "107.56",
                y1: "181.26",
                x2: "107.56",
                y2: "156.85",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#e3ddd5" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#bdb3a9" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-bad-lighting-linear-gradient-2",
                x1: "100.4",
                y1: "134.43",
                x2: "115.04",
                y2: "156.1",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#3b3b3b" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#545454" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-bad-lighting-linear-gradient-3",
                x1: "106.35",
                y1: "50.74",
                x2: "106.57",
                y2: "50.97",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#999" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#6e6e6e" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "face-bad-lighting-linear-gradient-4",
              x1: "80.59",
              y1: "91.68",
              x2: "298.2",
              y2: "190.43",
              "xlink:href": "#face-bad-lighting-linear-gradient",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "face-bad-lighting-linear-gradient-5",
              x1: "68.21",
              y1: "89",
              x2: "65.41",
              y2: "103.81",
              "xlink:href": "#face-bad-lighting-linear-gradient",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-bad-lighting-linear-gradient-6",
                x1: "101.27",
                y1: "100.6",
                x2: "114.66",
                y2: "137.28",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#d1cdcb" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#9c9189" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-bad-lighting-linear-gradient-7",
                x1: "106.25",
                y1: "50.69",
                x2: "106.34",
                y2: "50.67",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.13", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.28", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#1f1f1f" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.54", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.78", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "face-bad-lighting-linear-gradient-8",
              x1: "153.03",
              y1: "102.8",
              x2: "150.03",
              y2: "118.66",
              "xlink:href": "#face-bad-lighting-linear-gradient",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-bad-lighting-linear-gradient-9",
                x1: "49.39",
                y1: "67.03",
                x2: "162.58",
                y2: "67.03",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.12", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.25", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#262626" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.58", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "0.8", "stop-color": "#050505" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-bad-lighting-linear-gradient-10",
                x1: "-425.31",
                y1: "1298.4",
                x2: "-476.29",
                y2: "1193.98",
                gradientTransform:
                  "matrix(1.18, 0.01, -0.01, 1.18, 622.26, -1291.27)",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#a3a3a3" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.29", "stop-color": "#b2b2b2" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.66", "stop-color": "#bfbfbf" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#c4c4c4" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "face-bad-lighting-cls-1" }, [
        _c("g", { attrs: { id: "Face-bad-lighting" } }, [
          _c("g", [
            _c("g", [
              _c("polygon", {
                staticClass: "face-bad-lighting-cls-2",
                attrs: {
                  points:
                    "210.26 224.3 0 224.3 0.12 0.2 210.65 0.2 210.26 224.3",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-bad-lighting-cls-3",
                attrs: {
                  d:
                    "M88,143.24l-4.44,29.23a11.29,11.29,0,0,1-6,8.46l-38,19L175.62,201l-28-15.79A19.82,19.82,0,0,1,138,171.61l-5.18-27.36Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-bad-lighting-cls-4",
                attrs: {
                  d:
                    "M86.28,150.11s5.58,12.54,23.65,13.53,25.39-14.37,25.39-14.37Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-bad-lighting-cls-5",
                attrs: {
                  d:
                    "M106.35,51.21a3.53,3.53,0,0,1-.12-1.06A2.7,2.7,0,0,1,106.35,51.21Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-bad-lighting-cls-6",
                attrs: {
                  d:
                    "M149.12,110.77A65.17,65.17,0,0,1,147.3,129c-1.57,6.75-5.9,14.09-11.5,19.85,0,.07-12.42,9.83-24,10.1-.48,0-.95,0-1.43,0-1.71,0-3.48,0-5.31-.19-.84-.1-1.71-.17-2.54-.32h0c-11.18-1.79-16.89-8.36-22-15.39-6.46-8.84-11.44-19.55-11.42-30.49l0-22.59c.16-7.91,0-9.44.16-18.92,0,0,17.57,3.64,41.77-9.35a20.08,20.08,0,0,0,12.76,5.33c13,.48,15.84-5.37,15.9-6.69.8,3.37,1.53,27.55,7.66,30.81,4.58,2.88,1.92.85,1.9,1.45Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-bad-lighting-cls-7",
                attrs: {
                  d: "M66.26,99s-8.09.31-7.91,6.9,11.16,12.49,11.16,12.49Z",
                },
              }),
              _vm._v(" "),
              _c("polyline", {
                staticClass: "face-bad-lighting-cls-8",
                attrs: { points: "108.66 100.78 103.79 127.56 107.98 130.9" },
              }),
              _vm._v(" "),
              _c("circle", {
                staticClass: "face-bad-lighting-cls-9",
                attrs: { cx: "89.33", cy: "104.46", r: "3.69" },
              }),
              _vm._v(" "),
              _c("circle", {
                staticClass: "face-bad-lighting-cls-9",
                attrs: { cx: "127.04", cy: "104.46", r: "3.69" },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-bad-lighting-cls-10",
                attrs: {
                  d:
                    "M104.47,141.52s10.72-.56,16-3.08a9.77,9.77,0,0,1-8,4.73C106.6,143.62,104.47,141.52,104.47,141.52Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-bad-lighting-cls-11",
                attrs: {
                  d:
                    "M106.35,51.21a3.53,3.53,0,0,1-.12-1.06A2.7,2.7,0,0,1,106.35,51.21Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-bad-lighting-cls-12",
                attrs: {
                  d: "M149.26,96.7s8.09-.36,8.46,6.22-9,14.55-9,14.55Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-bad-lighting-cls-13",
                attrs: {
                  d:
                    "M69.28,116.52l1.44-7.37c-.6-17.42,3.06-23.37,6.91-25.32,2.23-1.12,5.14-1.22,8-4a15.81,15.81,0,0,0,4.4-9.53,33,33,0,0,0,12.29,10.71c16.88,8.18,35.46-3.08,40.39,4.19,2.88,4.22-1.88,10.28,2.87,18.54a19.7,19.7,0,0,0,3.63,4.58c3.19-3.93,14.65-19,13.21-39.64a54.55,54.55,0,0,0-7.58-24A53.81,53.81,0,0,0,142,30.44c-2.74-2.13-23.69-17.94-42.24-11.29a55.76,55.76,0,0,0-18,10.72A34.18,34.18,0,0,1,76,34.59c-4.87,3.12-7.76,3-11.36,4.59C55.73,43,52,52.7,51.2,54.62,49.28,59.55,48.89,64.77,50,72.2,52.87,91.19,63,107.63,69.28,116.52Z",
                },
              }),
              _vm._v(" "),
              _c("g", [
                _c("path", {
                  staticClass: "face-bad-lighting-cls-14",
                  attrs: {
                    d:
                      "M71.8,179.48S82.72,189.89,110.05,190s38.44-6.78,38.44-6.78l51.58,29.53,10.19,11.52H0l10.85-12.5Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "face-bad-lighting-cls-15",
                  attrs: {
                    d:
                      "M71.8,179.48S82.72,189.89,110.05,190s38.44-6.78,38.44-6.78l51.58,29.53,10.19,11.52H0l10.85-12.5Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "face-bad-lighting-cls-15",
                  attrs: {
                    d:
                      "M71.8,179.48S82.72,189.89,110.05,190s38.44-6.78,38.44-6.78l51.58,29.53,10.19,11.52H0l10.85-12.5Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "face-bad-lighting-cls-16",
                  attrs: {
                    d:
                      "M71.8,179.48S82.72,189.89,110.05,190s38.44-6.78,38.44-6.78l51.58,29.53,10.19,11.52H0l10.85-12.5Z",
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("g", [
              _c("path", {
                staticClass: "face-bad-lighting-cls-17",
                attrs: {
                  d:
                    "M126.71,81.13c5.61,62.09-9.92,76.76-9.92,76.76l1.4,31.54-.19.16,18.53,34.71h73.73L200.1,212.59l-51.57-29.52a20.25,20.25,0,0,1-2.26,1.12A19.88,19.88,0,0,1,138,171.43l-3.85-20.29a20.27,20.27,0,0,0,1.2-2,4.92,4.92,0,0,0,.48-.38c5.6-5.76,9.93-13.1,11.5-19.85A66.2,66.2,0,0,0,149.1,117c1.77-1.59,9-8.43,8.65-14.21a5.74,5.74,0,0,0-2-4.27,53.66,53.66,0,0,0-.81-54,54,54,0,0,0-11.68-13.24C133.75,34.58,123.55,46.05,126.71,81.13Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-bad-lighting-cls-17",
                attrs: {
                  d:
                    "M129.44,81.13c5.6,62.09-9.93,76.76-9.93,76.76L121.23,189l-.15.13,24.43,35.15h64.75L200.1,212.59l-51.57-29.52a20.25,20.25,0,0,1-2.26,1.12A19.88,19.88,0,0,1,138,171.43l-3.85-20.29a20.27,20.27,0,0,0,1.2-2,4.92,4.92,0,0,0,.48-.38c5.6-5.76,9.89-13.11,11.5-19.85A69,69,0,0,0,149.1,117c1.77-1.59,9-8.43,8.65-14.21a5.74,5.74,0,0,0-2-4.27,53.66,53.66,0,0,0-.81-54,54,54,0,0,0-11.68-13.24C133.75,34.58,126.27,46.05,129.44,81.13Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-bad-lighting-cls-17",
                attrs: {
                  d:
                    "M132.16,81.13c5.6,62.09-9.93,76.76-9.93,76.76l2,30.71-.12.1,30.35,35.6h55.76L200.1,212.59l-51.57-29.52a20.25,20.25,0,0,1-2.26,1.12A19.88,19.88,0,0,1,138,171.43l-3.85-20.29a20.27,20.27,0,0,0,1.2-2,4.92,4.92,0,0,0,.48-.38c5.6-5.76,9.89-13.11,11.5-19.85A69,69,0,0,0,149.1,117c1.77-1.59,9-8.43,8.65-14.21a5.74,5.74,0,0,0-2-4.27,53.66,53.66,0,0,0-.81-54,54,54,0,0,0-11.68-13.24C133.75,34.58,129,46.05,132.16,81.13Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-bad-lighting-cls-17",
                attrs: {
                  d:
                    "M134.88,81.13c5.6,62.09-9.92,76.76-9.92,76.76l2.35,30.3-.08.07,36.25,36h46.78L200.1,212.59l-51.57-29.52a20.25,20.25,0,0,1-2.26,1.12A19.88,19.88,0,0,1,138,171.43l-3.85-20.29a20.27,20.27,0,0,0,1.2-2,4.92,4.92,0,0,0,.48-.38c5.6-5.76,9.89-13.11,11.5-19.85A69,69,0,0,0,149.1,117c1.77-1.59,9-8.43,8.65-14.21a5.74,5.74,0,0,0-2-4.27,53.66,53.66,0,0,0-.81-54,54,54,0,0,0-11.68-13.24C133.75,34.58,131.72,46.05,134.88,81.13Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-bad-lighting-cls-17",
                attrs: {
                  d:
                    "M137.6,81.13c5.61,62.09-9.92,76.76-9.92,76.76l2.67,29.89,0,0,42.15,36.49h37.8L200.1,212.59l-51.57-29.52a20.25,20.25,0,0,1-2.26,1.12A19.88,19.88,0,0,1,138,171.43l-3.85-20.29a20.27,20.27,0,0,0,1.2-2,4.92,4.92,0,0,0,.48-.38c5.6-5.76,9.89-13.11,11.5-19.85A69,69,0,0,0,149.1,117c1.77-1.59,9-8.43,8.65-14.21a5.74,5.74,0,0,0-2-4.27,53.66,53.66,0,0,0-.81-54,54,54,0,0,0-11.68-13.24C133.75,34.58,134.44,46.05,137.6,81.13Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-bad-lighting-cls-17",
                attrs: {
                  d:
                    "M140.33,81.13c5.6,62.09-9.93,76.76-9.93,76.76l3,29.47h0l48.06,36.94h28.81L200.1,212.59l-51.57-29.52a20.25,20.25,0,0,1-2.26,1.12A19.88,19.88,0,0,1,138,171.43l-3.85-20.29a20.27,20.27,0,0,0,1.2-2,4.92,4.92,0,0,0,.48-.38c5.6-5.76,9.93-13.1,11.5-19.85A66.2,66.2,0,0,0,149.1,117c1.77-1.59,9-8.43,8.65-14.21a5.74,5.74,0,0,0-2-4.27,53.66,53.66,0,0,0-.81-54,54,54,0,0,0-11.68-13.24C133.75,34.58,137.16,46.05,140.33,81.13Z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "face-bad-lighting-cls-18",
              attrs: {
                id: "Overlay",
                points:
                  "210.26 224.3 0 224.3 0.12 0.53 210.65 0.69 210.26 224.3",
              },
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }