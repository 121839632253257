import { IdvMediator, ControllerType } from '@ondato/iframe-mediator'
import { formDataMethods } from '@src/scripts/enums'
import Api, { get } from '@src/scripts/api'

export const formDataHandlers = {
  data() {
    return {
      formDataEventController: null
    }
  },

  beforeDestroy() {
    if (this.formDataEventController) {
      this.formDataEventController.unregisterHandlers()
      this.formDataEventController = null
    }
  },

  methods: {
    loadFormDataHandlers(target) {
      if (!target) {
        return
      }

      const eventHandler = new IdvMediator({
        type: ControllerType.Parent,
        iframeElement: target,
        allowedOrigin: BaseUrl.resources
      })

      eventHandler.registerHandler(
        formDataMethods.getFormData,
        this.getFormData
      )
      this.formDataEventController = eventHandler
    },

    async getFormData() {
      try {
        const response = await get(Api.getFormData)

        return response
      } catch (error) {
        this.$popUp('Failed')
        throw new Error(error)
      }
    }
  }
}
