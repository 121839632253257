var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-controls-frame",
    [
      !_vm.completed
        ? _c("ui-button", {
            attrs: { slot: "main", label: "App.Start", disabled: _vm.disabled },
            on: { click: _vm.start },
            slot: "main",
          })
        : _c(
            "template",
            { slot: "main" },
            [
              _c("ui-button", {
                attrs: {
                  label: "App.TryAgain",
                  disabled: _vm.disabled,
                  additional: "",
                },
                on: { click: _vm.reset },
              }),
              _vm._v(" "),
              _vm.enableContinue && !_vm.error
                ? _c("ui-button", {
                    attrs: { label: "App.Continue", disabled: _vm.disabled },
                    on: { click: _vm.continueIdentification },
                  })
                : _vm._e(),
            ],
            1
          ),
      _vm._v(" "),
      _vm.activeControls
        ? _c("ui-button", {
            attrs: {
              slot: "active",
              label: "App.Capture",
              disabled: _vm.disabled,
            },
            on: { click: _vm.capture },
            slot: "active",
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }