var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "ui-frame",
        { staticClass: "omnichannel-page" },
        [
          _vm.isLoading
            ? _c("ui-loader", { attrs: { size: 3, center: "" } })
            : _vm.isCompleted
            ? _c(
                "div",
                { staticClass: "message-container" },
                [
                  _c("h1", [
                    _vm._v(_vm._s(_vm.$t("Omnichannel.Completed.Title"))),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("Omnichannel.Completed.Description"))),
                  ]),
                  _vm._v(" "),
                  _c("ui-button", {
                    attrs: { label: "App.Continue" },
                    on: { click: _vm.continueIdentification },
                  }),
                ],
                1
              )
            : _vm.isPending
            ? _c(
                "div",
                { staticClass: "message-container" },
                [
                  _c("h1", [
                    _vm._v(_vm._s(_vm.$t("Omnichannel.Pending.Title"))),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("Omnichannel.Pending.Description"))),
                  ]),
                  _vm._v(" "),
                  _c("ui-loader", { attrs: { size: 3, center: "" } }),
                ],
                1
              )
            : _vm.isError
            ? _c(
                "div",
                { staticClass: "message-container" },
                [
                  _c("transition", { attrs: { name: "fade" } }, [
                    _c("div", { staticClass: "result-message-group" }, [
                      _c("h2", { staticClass: "result-message" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t(_vm.messagePrimary)) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "result-message-secondary" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t(_vm.messageSecondary)) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "button-group" },
                        [
                          _vm.canRetry
                            ? _c("ui-button", {
                                attrs: {
                                  label: "App.TryAgain",
                                  disabled: _vm.isLoading,
                                },
                                on: { click: _vm.tryAgain },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isFailureUrlAvailable
                            ? _c("ui-button", {
                                attrs: { additional: "", label: "App.Close" },
                                on: { click: _vm.close },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _c("div", { staticClass: "alternative-container" }, [
                _c("div", { staticClass: "top-action-container" }, [
                  _vm.currentLinkAlternative !== _vm.LINK_ALTERNATIVES.QR
                    ? _c(
                        "a",
                        {
                          staticClass: "top-left-action",
                          on: { click: _vm.previousMethod },
                        },
                        [
                          _c("back-arrow"),
                          _vm._v(
                            _vm._s(
                              _vm.$t("Omnichannel.Start.Alternatives.GoBack")
                            ) + "\n      "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "selection-container" }, [
                  _c(
                    "h1",
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t(_vm.stepTranslationKeys.title.firstSection)
                          ) +
                          "\n        "
                      ),
                      _vm.stepTranslationKeys.title.secondSection
                        ? [
                            _c("br"),
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    _vm.stepTranslationKeys.title.secondSection
                                  )
                                ) +
                                "\n        "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "info-text" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t(_vm.stepTranslationKeys.description)) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h3", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t(_vm.stepTranslationKeys.subTitle)) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.currentLinkAlternative === _vm.LINK_ALTERNATIVES.MANUAL
                    ? _c("div", { staticClass: "step-container" }, [
                        _c("div", { staticClass: "link-container" }, [
                          _c("span", [_vm._v(_vm._s(_vm.shortUrl || _vm.url))]),
                        ]),
                      ])
                    : _vm.currentLinkAlternative === _vm.LINK_ALTERNATIVES.SMS
                    ? _c(
                        "div",
                        { staticClass: "step-container" },
                        [
                          _c("sms-confirmation", {
                            staticClass: "sms-container",
                            attrs: { country: _vm.countryCode },
                            on: { complete: _vm.submitNumber },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "step-container" },
                        [
                          _c("div", {
                            staticClass: "qr-box",
                            style: _vm.qrBackground,
                          }),
                          _vm._v(" "),
                          _c("copy-button", {
                            attrs: { url: _vm.shortUrl || _vm.url },
                          }),
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _vm.stepTranslationKeys.nextStep
                    ? _c(
                        "a",
                        {
                          staticClass: "link-button",
                          on: { click: _vm.nextMethod },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t(_vm.stepTranslationKeys.nextStep)) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }