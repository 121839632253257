import { render, staticRenderFns } from "./UiPhoneCode.vue?vue&type=template&id=270f2915&scoped=true"
import script from "./UiPhoneCode.vue?vue&type=script&lang=js"
export * from "./UiPhoneCode.vue?vue&type=script&lang=js"
import style0 from "./UiPhoneCode.vue?vue&type=style&index=0&id=270f2915&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "270f2915",
  null
  
)

export default component.exports