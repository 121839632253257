// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ui-frame[data-v-40b2cce1] {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}
.content[data-v-40b2cce1] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  max-width: 64rem;
  width: 100%;
  padding: 2rem;
  margin: auto;
  overflow: auto;
}
.omnichannel-page > .content[data-v-40b2cce1] {
  max-width: initial;
}
@media screen and (max-height: 29rem) {
.content[data-v-40b2cce1] {
    display: block;
    padding: 1.5rem;
    text-align: center;
    width: auto;
}
}
@media screen and (max-height: 48rem) {
.content[data-v-40b2cce1] {
    display: block;
}
}
.full-size[data-v-40b2cce1] {
  max-width: 100%;
  margin: 0;
  padding: 0;
}
.padding[data-v-40b2cce1] {
  padding: 2rem;
}
.max-height[data-v-40b2cce1] {
  max-height: none;
}
.ui-frame.identification-check > .content[data-v-40b2cce1] {
  overflow: initial;
  justify-content: initial;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/UiFrame.vue"],"names":[],"mappings":";AAgCA;EACA,aAAA;EACA,YAAA;EACA,WAAA;EACA,uBAAA;AACA;AAEA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;EACA,YAAA;EACA,cAAA;AACA;AAEA;EACA,kBAAA;AACA;AAEA;AACA;IACA,cAAA;IACA,eAAA;IACA,kBAAA;IACA,WAAA;AACA;AACA;AAEA;AACA;IACA,cAAA;AACA;AACA;AAEA;EACA,eAAA;EACA,SAAA;EACA,UAAA;AACA;AAEA;EACA,aAAA;AACA;AAEA;EACA,gBAAA;AACA;AAEA;EACA,iBAAA;EACA,wBAAA;AACA","sourcesContent":["<template>\n  <div class=\"ui-frame\">\n    <page-logo v-if=\"logo\" />\n    <div\n      class=\"content\"\n      :class=\"{ 'full-size': fullSize, padding, 'max-height': maxHeight }\"\n    >\n      <slot />\n    </div>\n  </div>\n</template>\n\n<script>\nimport PageLogo from '@src/components/partials/PageLogo'\n\nexport default {\n  name: 'ui-frame',\n\n  components: {\n    PageLogo\n  },\n\n  props: {\n    logo: Boolean,\n    fullSize: Boolean,\n    padding: Boolean,\n    maxHeight: Boolean\n  }\n}\n</script>\n\n<style scoped>\n.ui-frame {\n  display: flex;\n  height: 100%;\n  width: 100%;\n  justify-content: center;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  max-height: 100%;\n  max-width: 64rem;\n  width: 100%;\n  padding: 2rem;\n  margin: auto;\n  overflow: auto;\n}\n\n.omnichannel-page > .content {\n  max-width: initial;\n}\n\n@media screen and (max-height: 29rem) {\n  .content {\n    display: block;\n    padding: 1.5rem;\n    text-align: center;\n    width: auto;\n  }\n}\n\n@media screen and (max-height: 48rem) {\n  .content {\n    display: block;\n  }\n}\n\n.full-size {\n  max-width: 100%;\n  margin: 0;\n  padding: 0;\n}\n\n.padding {\n  padding: 2rem;\n}\n\n.max-height {\n  max-height: none;\n}\n\n.ui-frame.identification-check > .content {\n  overflow: initial;\n  justify-content: initial;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
