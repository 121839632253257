var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ui-frame", [
    _c("div", { staticClass: "container" }, [
      _c("h1", [_vm._v(_vm._s(_vm.$t("Error.Header.Unauthorized")))]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.$t("Error.Description.Unauthorized")))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }