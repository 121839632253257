import { mapState, mapGetters, mapMutations } from 'vuex'
import getters from '@src/store/getters'
import mutations from '@src/store/mutations'
import {
  IDV_API_REQUESTS,
  OMNICHANNEL_STATUSES,
  PAGE_NAMES,
  STORE_KEYS,
  CLAIMS,
  OMNICHANNEL_DELAY
} from '@src/scripts/constants'
import { idvApi } from '@src/scripts/idvApi'
import { delay } from '@src/scripts/helpers'
import { ruleTypes } from '@src/scripts/enums'
import store, { stateKeys } from '@src/store/index'

export const omnichannel = {
  data() {
    return {
      heartBeat: null
    }
  },

  computed: {
    ...mapState([stateKeys.omnichannel]),
    ...mapGetters([
      getters.isLoggedIn,
      getters.idvAccessToken,
      getters.claims,
      getters.rules,
      getters.isOmnichannelCompleted
    ]),

    status() {
      return this.omnichannel.status ?? OMNICHANNEL_STATUSES.UNAVAILABLE
    }
  },

  watch: {
    isLoggedIn: {
      immediate: true,
      handler() {
        this.checkOmnichannel()
      }
    },
    idvAccessToken: {
      immediate: true,
      handler() {
        this.checkOmnichannel()
      }
    },
    status: {
      immediate: true,
      handler(value) {
        if (value === OMNICHANNEL_STATUSES.COMPLETED) {
          this.stopHeartBeat()
        }
      }
    }
  },

  beforeDestroy() {
    this.stopHeartBeat()
  },

  methods: {
    ...mapMutations([
      mutations.addPage,
      mutations.initiateOmnichannel,
      mutations.updateOmnichannelStatus
    ]),

    async checkOmnichannel() {
      if (!this.isLoggedIn || !this.idvAccessToken) {
        return
      }

      if (this.claims.includes(CLAIMS.OMNICHANNEL)) {
        sessionStorage.setItem(STORE_KEYS.OMNICHANNELS, 'true')
      }

      if (sessionStorage.getItem(STORE_KEYS.OMNICHANNELS) === 'true') {
        this.updateOmnichannelStatus(OMNICHANNEL_STATUSES.AVAILABLE)
        this.initiateOmnichannel()

        if (this.$route.name !== PAGE_NAMES.OMNICHANNEL) {
          this.$router.replaceNext({ name: PAGE_NAMES.OMNICHANNEL })
        }

        return
      }

      const { idvAccessToken } = store.getters
      idvApi.updateHeaders({ authorization: idvAccessToken })
      const { success } = await idvApi.get(
        IDV_API_REQUESTS.OMNICHANNELS,
        null,
        5
      )

      if (success) {
        this.addPage(PAGE_NAMES.OMNICHANNEL_COMPLETED)
        this.omnichannelHeartBeat()
      } else {
        this.setOmnichannelStatus()
      }
    },

    setOmnichannelStatus() {
      const { claims, rules } = this
      const isFaceAuthentication = claims?.includes(CLAIMS.FACE_AUTHENTICATION)
      const isOmnichannelAvailable =
        !rules?.length || !rules.includes(ruleTypes.omnichannelDisabled)

      if (isFaceAuthentication || isOmnichannelAvailable) {
        this.updateOmnichannelStatus(OMNICHANNEL_STATUSES.AVAILABLE)
      }
    },

    omnichannelHeartBeat() {
      this.heartBeat = setInterval(this.heartBeatInterval, OMNICHANNEL_DELAY)
    },

    async heartBeatInterval() {
      const { success, status } = await idvApi.put(
        IDV_API_REQUESTS.OMNICHANNELS,
        null,
        null,
        5
      )

      if (success) return

      if (status === 404) {
        await this.restartOmnichannel()
      } else {
        await delay(OMNICHANNEL_DELAY)
        await idvApi.put(IDV_API_REQUESTS.OMNICHANNELS, null, null, 5)
      }
    },

    async restartOmnichannel() {
      if (!this.isOmnichannelCompleted) {
        await idvApi.post(IDV_API_REQUESTS.OMNICHANNELS)
      }
    },

    stopHeartBeat() {
      if (this.heartBeat) {
        clearTimeout(this.heartBeat)
        this.heartBeat = null
      }
    }
  }
}
