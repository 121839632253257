<template>
  <div>
    <div class="container">
      <div>
        <img :src="imageSource" />
      </div>
      <div class="right-section">
        <p>
          {{ $t('Error.Description.CloseIdvProcess') }}
        </p>
        <div class="pop-up-btn">
          <ui-button
            :label="buttonLabel"
            :loading="isLoading"
            additional
            @click="redirect()"
          />
          <ui-button
            :label="continueButtonLabel"
            :disabled="isLoading"
            @click="$emit('click')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { redirectFailure } from '@src/scripts/routerManager'
import { mapMutations } from 'vuex'
import mutations from '@src/store/mutations'

export default {
  props: {
    buttonLabel: { type: String, default: undefined, required: true },
    continueButtonLabel: { type: String, default: undefined, required: true }
  },

  data() {
    return {
      isLoading: false
    }
  },

  computed: {
    hasPermissionsRedirect() {
      const { session } = this.$store.state
      return !!(session && session.hasPermissionsRedirect)
    },

    imageSource() {
      return '/public/images/close-process.png'
    }
  },

  methods: {
    ...mapMutations([mutations.updateOmnichannelStatus]),

    async redirect() {
      this.isLoading = true
      await redirectFailure()
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
}

.container > div {
  padding-left: 1rem;
  padding-right: 1rem;
}

.ui-button {
  margin: 1rem 0.5rem 0;
}

img {
  width: 100%;
}

p > span {
  display: block;
  margin-top: 0.5rem;
}

.mobile-app .container {
  flex-direction: column;
}

.mobile-app .containers > div {
  margin-left: auto;
  margin-right: auto;
  max-width: 25rem;
}

.pop-up-btn {
  display: flex;
  margin-top: 1rem;
}

@media screen and (max-width: 48rem) {
  .desktop-app .container {
    flex-direction: column;
  }

  .desktop-app .container > div {
    margin-left: auto;
    margin-right: auto;
    max-width: 25rem;
  }
}

@media screen and (min-width: 48.0625rem) {
  .desktop-app .containers > div {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.right-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 22rem;
}
</style>
