var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-frame",
    [
      _c("h1", { staticClass: "document-header" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("Document.Header")) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "card-list",
        [
          _vm.hasPassport
            ? _c(
                "document-card",
                {
                  attrs: {
                    label: "Document.Passport",
                    "document-type": "passport",
                  },
                },
                [_c("document-passport")],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasIdCard
            ? _c(
                "document-card",
                {
                  attrs: {
                    label: "Document.IdCard",
                    "document-type": "id-card",
                  },
                },
                [_c("document-id-card")],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasDrivingLicense
            ? _c(
                "document-card",
                {
                  attrs: {
                    label: "Document.DriverLicense",
                    "document-type": "driver-license",
                  },
                },
                [_c("document-driver-license")],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "policy-certificates" },
        _vm._l(_vm.certificates, function (item) {
          return _c("img", {
            key: item,
            attrs: { src: "/public/images/certificates/" + item },
          })
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }