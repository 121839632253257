var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 215 215",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-start-icon-fullcolor-linear-gradient",
                x1: "-706.64",
                y1: "1717.07",
                x2: "-599.12",
                y2: "1717.07",
                gradientTransform:
                  "matrix(0.98, 0.19, -0.19, 0.94, 1063.78, -1389.86)",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#e3ddd5" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#bdb3a9" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-start-icon-fullcolor-linear-gradient-2",
                x1: "50.02",
                y1: "114.5",
                x2: "165.05",
                y2: "114.5",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#1f1f1f" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#333" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "passport-start-icon-fullcolor-cls-1" }, [
        _c("g", { attrs: { id: "passport-start-icon-fullcolor" } }, [
          _c("path", {
            staticClass: "passport-start-icon-fullcolor-cls-2",
            attrs: {
              d:
                "M50,39.65l81.31-13.44a5.34,5.34,0,0,1,6.32,5.11l.06,115L50.08,188.87Z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "passport-start-icon-fullcolor-cls-3",
            attrs: {
              d:
                "M50,39.65l81.31-13.44a5.34,5.34,0,0,1,6.32,5.11l.06,115L50.08,188.87Z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "passport-start-icon-fullcolor-cls-4",
            attrs: {
              d:
                "M50,40.15H151.62a13.43,13.43,0,0,1,13.43,13.43V175.42a13.43,13.43,0,0,1-13.43,13.43H50a0,0,0,0,1,0,0V40.15A0,0,0,0,1,50,40.15Z",
            },
          }),
          _vm._v(" "),
          _c("line", {
            staticClass: "passport-start-icon-fullcolor-cls-5",
            attrs: { x1: "74.39", y1: "59.85", x2: "138.96", y2: "59.82" },
          }),
          _vm._v(" "),
          _c("rect", {
            staticClass: "passport-start-icon-fullcolor-cls-5",
            attrs: { x: "99.04", y: "162.87", width: "18.5", height: "11.47" },
          }),
          _vm._v(" "),
          _c("g", [
            _c("circle", {
              staticClass: "passport-start-icon-fullcolor-cls-5",
              attrs: { cx: "108.27", cy: "111.36", r: "32.41" },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-start-icon-fullcolor-cls-5",
              attrs: { d: "M115,143.06a45,45,0,0,0,0-63.41" },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-start-icon-fullcolor-cls-5",
              attrs: { d: "M101.42,79.66a45.05,45.05,0,0,0,0,63.41" },
            }),
            _vm._v(" "),
            _c("line", {
              staticClass: "passport-start-icon-fullcolor-cls-5",
              attrs: { x1: "108.25", y1: "78.95", x2: "108.28", y2: "143.77" },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-start-icon-fullcolor-cls-5",
              attrs: { d: "M83.25,132a45.08,45.08,0,0,1,50,0" },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-start-icon-fullcolor-cls-5",
              attrs: { d: "M83.09,91a45.07,45.07,0,0,0,50.3-.06" },
            }),
            _vm._v(" "),
            _c("line", {
              staticClass: "passport-start-icon-fullcolor-cls-5",
              attrs: { x1: "75.81", y1: "111.33", x2: "140.63", y2: "111.3" },
            }),
          ]),
          _vm._v(" "),
          _c("path", {
            staticClass: "passport-start-icon-fullcolor-cls-6",
            attrs: {
              d:
                "M49.95,40.15H151.56A13.43,13.43,0,0,1,165,53.58V175.42a13.43,13.43,0,0,1-13.43,13.43H49.95a0,0,0,0,1,0,0V40.15A0,0,0,0,1,49.95,40.15Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }