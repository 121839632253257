var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 263.22 189.81",
      },
    },
    [
      _c(
        "defs",
        [
          _c("clipPath", { attrs: { id: "clip-path" } }, [
            _c("rect", {
              staticClass: "passport-too-dark-cls-1",
              attrs: {
                x: "-0.03",
                y: "0.04",
                width: "263.22",
                height: "189.81",
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-dark-linear-gradient",
                x1: "131.38",
                y1: "19.48",
                x2: "131.38",
                y2: "15.83",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#3b3b3b" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#545454" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-dark-linear-gradient-2",
                x1: "34.82",
                y1: "262.19",
                x2: "620.96",
                y2: "258.04",
                gradientTransform: "translate(-11.24 -102.16) rotate(-0.06)",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#a6a6a6" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-dark-linear-gradient-3",
                x1: "0.51",
                y1: "235.73",
                x2: "429.3",
                y2: "249.65",
                gradientTransform: "translate(-10.99 -102.32)",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#6f7173" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-dark-linear-gradient-4",
                x1: "60.1",
                y1: "95.72",
                x2: "60.1",
                y2: "87.48",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#e3ddd5" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#bdb3a9" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-dark-linear-gradient-5",
                x1: "-425.08",
                y1: "1150.2",
                x2: "-442.52",
                y2: "1114.48",
                gradientTransform:
                  "matrix(1.15, 0.01, -0.01, 1.15, 559.14, -1210.37)",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#a3a3a3" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.29", "stop-color": "#b2b2b2" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.66", "stop-color": "#bfbfbf" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#c4c4c4" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "passport-too-dark-linear-gradient-6",
              x1: "57.68",
              y1: "79.91",
              x2: "62.63",
              y2: "87.23",
              "xlink:href": "#passport-too-dark-linear-gradient",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-dark-linear-gradient-7",
                x1: "59.69",
                y1: "51.66",
                x2: "59.77",
                y2: "51.73",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#999" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#6e6e6e" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "passport-too-dark-linear-gradient-8",
              x1: "51",
              y1: "65.48",
              x2: "124.43",
              y2: "98.8",
              "xlink:href": "#passport-too-dark-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "passport-too-dark-linear-gradient-9",
              x1: "46.83",
              y1: "64.57",
              x2: "45.88",
              y2: "69.57",
              "xlink:href": "#passport-too-dark-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-dark-linear-gradient-10",
                x1: "57.98",
                y1: "68.49",
                x2: "62.5",
                y2: "80.88",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#d1cdcb" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#9c9189" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-dark-linear-gradient-11",
                x1: "59.66",
                y1: "51.64",
                x2: "59.69",
                y2: "51.63",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.13", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.28", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#1f1f1f" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.54", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.78", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "passport-too-dark-linear-gradient-12",
              x1: "75.44",
              y1: "69.23",
              x2: "74.43",
              y2: "74.59",
              "xlink:href": "#passport-too-dark-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-dark-linear-gradient-13",
                x1: "40.47",
                y1: "57.15",
                x2: "78.66",
                y2: "57.15",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.12", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.25", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#262626" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.58", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "0.8", "stop-color": "#050505" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "passport-too-dark-cls-2" }, [
        _c("g", { attrs: { id: "Passport-too-dark" } }, [
          _c("rect", {
            staticClass: "passport-too-dark-cls-3",
            attrs: { x: "-0.03", width: "263.22", height: "189.81" },
          }),
          _vm._v(" "),
          _c("g", { staticClass: "passport-too-dark-cls-4" }, [
            _c("g", [
              _c("path", {
                staticClass: "passport-too-dark-cls-5",
                attrs: {
                  d:
                    "M252.9,20l.18,118.6,0,34.51a6.37,6.37,0,0,1-6.37,6.37l-229.58-.14A7.71,7.71,0,0,1,10,173.68l0-34.86L10.11,19Z",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "passport-too-dark-cls-6",
                attrs: {
                  points:
                    "252.9 0 252.89 19.98 252.85 19.98 10.17 19.85 9.86 19.85 9.87 0 252.9 0",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "passport-too-dark-cls-7",
                attrs: {
                  d:
                    "M253.08,138.57l0,34.51a6.37,6.37,0,0,1-6.37,6.37l-229.58-.14A7.71,7.71,0,0,1,10,173.68l0-34.86Z",
                },
              }),
              _vm._v(" "),
              _c("g", [
                _c("polygon", {
                  staticClass: "passport-too-dark-cls-8",
                  attrs: {
                    points:
                      "18.34 159.2 16.72 157.59 21.45 152.85 16.64 148.06 18.25 146.44 24.67 152.85 18.34 159.2",
                  },
                }),
                _vm._v(" "),
                _c("polygon", {
                  staticClass: "passport-too-dark-cls-8",
                  attrs: {
                    points:
                      "29.28 159.19 27.67 157.57 32.39 152.84 27.59 148.05 29.2 146.43 35.62 152.84 29.28 159.19",
                  },
                }),
                _vm._v(" "),
                _c("polygon", {
                  staticClass: "passport-too-dark-cls-8",
                  attrs: {
                    points:
                      "40.23 159.18 38.61 157.56 43.34 152.83 38.53 148.03 40.14 146.42 46.56 152.82 40.23 159.18",
                  },
                }),
                _vm._v(" "),
                _c("polygon", {
                  staticClass: "passport-too-dark-cls-8",
                  attrs: {
                    points:
                      "51.17 159.16 49.55 157.55 54.28 152.82 49.48 148.02 51.09 146.41 57.51 152.81 51.17 159.16",
                  },
                }),
                _vm._v(" "),
                _c("polygon", {
                  staticClass: "passport-too-dark-cls-8",
                  attrs: {
                    points:
                      "62.11 159.15 60.5 157.54 65.22 152.81 60.42 148.01 62.03 146.4 68.45 152.8 62.11 159.15",
                  },
                }),
                _vm._v(" "),
                _c("polygon", {
                  staticClass: "passport-too-dark-cls-8",
                  attrs: {
                    points:
                      "239.25 172.01 232.83 165.6 239.17 159.25 240.78 160.87 236.06 165.6 240.86 170.39 239.25 172.01",
                  },
                }),
                _vm._v(" "),
                _c("polygon", {
                  staticClass: "passport-too-dark-cls-8",
                  attrs: {
                    points:
                      "228.31 172.02 221.89 165.62 228.22 159.26 229.84 160.88 225.12 165.61 229.92 170.41 228.31 172.02",
                  },
                }),
                _vm._v(" "),
                _c("polygon", {
                  staticClass: "passport-too-dark-cls-8",
                  attrs: {
                    points:
                      "217.36 172.03 210.94 165.63 217.28 159.28 218.9 160.89 214.17 165.62 218.97 170.42 217.36 172.03",
                  },
                }),
                _vm._v(" "),
                _c("polygon", {
                  staticClass: "passport-too-dark-cls-8",
                  attrs: {
                    points:
                      "206.42 172.04 200 165.64 206.34 159.29 207.95 160.9 203.23 165.63 208.03 170.43 206.42 172.04",
                  },
                }),
                _vm._v(" "),
                _c("polygon", {
                  staticClass: "passport-too-dark-cls-8",
                  attrs: {
                    points:
                      "195.47 172.06 189.06 165.65 195.39 159.3 197.01 160.91 192.28 165.65 197.09 170.44 195.47 172.06",
                  },
                }),
                _vm._v(" "),
                _c("g", [
                  _c("path", {
                    staticClass: "passport-too-dark-cls-8",
                    attrs: {
                      d:
                        "M73.33,147.66h2.45l3.42,8,3.41-8H85l0,10.74H82.9v-7l-2.87,7H78.39l-2.9-7v7H73.34Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "passport-too-dark-cls-8",
                    attrs: {
                      d:
                        "M91.21,147.64c2.58,0,3.88,1.49,3.88,3.29a3.11,3.11,0,0,1-2.45,3.1l2.58,4.35H92.73l-2.38-4.18h-1v4.19H87.18V147.64Zm-.08,1.79h-1.8v3.15h1.8c1.2,0,1.74-.63,1.74-1.6S92.33,149.42,91.13,149.43Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "passport-too-dark-cls-8",
                    attrs: {
                      d:
                        "M104.08,149.29l-4.83,7.27h4.84v1.82H96.76v-1.66l4.79-7.27h-4.8v-1.82h7.33Z",
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("rect", {
                staticClass: "passport-too-dark-cls-9",
                attrs: {
                  x: "10.04",
                  y: "137.35",
                  width: "243.04",
                  height: "1.34",
                  transform: "translate(-0.15 0.14) rotate(-0.06)",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "passport-too-dark-cls-10",
                attrs: {
                  d:
                    "M246.84,179.47l-229.68-.14a7,7,0,0,1-7-7L9.7,19.47,253.06,20l.4,152.84A6.62,6.62,0,0,1,246.84,179.47Z",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "passport-too-dark-cls-10",
                attrs: {
                  points:
                    "10.17 20.23 10.17 19.85 10.15 0 252.79 0 252.85 19.98 10.17 20.23",
                },
              }),
              _vm._v(" "),
              _c("g", [
                _c("rect", {
                  staticClass: "passport-too-dark-cls-3",
                  attrs: {
                    x: "23.08",
                    y: "35.36",
                    width: "73.68",
                    height: "74.78",
                  },
                }),
                _vm._v(" "),
                _c("g", [
                  _c("path", {
                    staticClass: "passport-too-dark-cls-11",
                    attrs: {
                      d:
                        "M53.49,82.89,52,92.76a3.8,3.8,0,0,1-2,2.85L37.14,102l45.92.36-9.45-5.33a6.68,6.68,0,0,1-3.25-4.59l-1.75-9.24Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("g", [
                    _c("path", {
                      staticClass: "passport-too-dark-cls-12",
                      attrs: {
                        d:
                          "M48.49,95.52S52.16,99,61.35,99.07s12.92-2.28,12.92-2.28l17.34,9.94,3.19,3.76-69.62-.35L28,106.4Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "passport-too-dark-cls-13",
                      attrs: {
                        d:
                          "M48.49,95.52S52.16,99,61.35,99.07s12.92-2.28,12.92-2.28l17.34,9.94,3.19,3.76-69.62-.35L28,106.4Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "passport-too-dark-cls-13",
                      attrs: {
                        d:
                          "M48.49,95.52S52.16,99,61.35,99.07s12.92-2.28,12.92-2.28l17.34,9.94,3.19,3.76-69.62-.35L28,106.4Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "passport-too-dark-cls-14",
                      attrs: {
                        d:
                          "M48.49,95.52S52.16,99,61.35,99.07s12.92-2.28,12.92-2.28l17.34,9.94,3.19,3.76-69.62-.35L28,106.4Z",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "passport-too-dark-cls-15",
                    attrs: {
                      d:
                        "M52.92,85.21s1.88,4.23,8,4.56a9.12,9.12,0,0,0,8.57-4.85Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "passport-too-dark-cls-16",
                    attrs: {
                      d:
                        "M59.69,51.81a1.38,1.38,0,0,1,0-.36A1,1,0,0,1,59.69,51.81Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "passport-too-dark-cls-17",
                    attrs: {
                      d:
                        "M74.12,71.92a21.92,21.92,0,0,1-.61,6.17,15.06,15.06,0,0,1-3.88,6.7s-4.19,3.32-8.08,3.41l-.48,0c-.58,0-1.18,0-1.79-.07-.29,0-.58-.05-.86-.1h0c-3.78-.61-5.7-2.83-7.44-5.2-2.18-3-3.86-6.6-3.85-10.29V64.93c0-2.67,0-3.19,0-6.39,0,0,5.93,1.23,14.1-3.16a6.72,6.72,0,0,0,4.3,1.8c4.4.16,5.34-1.81,5.36-2.26.27,1.14.52,9.31,2.59,10.41,1.54,1,.65.28.64.49Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "passport-too-dark-cls-18",
                    attrs: {
                      d: "M46.17,67.94s-2.73.1-2.67,2.32,3.76,4.22,3.76,4.22Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("polyline", {
                    staticClass: "passport-too-dark-cls-19",
                    attrs: { points: "60.47 68.55 58.83 77.59 60.24 78.72" },
                  }),
                  _vm._v(" "),
                  _c("circle", {
                    staticClass: "passport-too-dark-cls-20",
                    attrs: { cx: "53.95", cy: "69.79", r: "1.24" },
                  }),
                  _vm._v(" "),
                  _c("circle", {
                    staticClass: "passport-too-dark-cls-20",
                    attrs: { cx: "66.67", cy: "69.79", r: "1.24" },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "passport-too-dark-cls-5",
                    attrs: {
                      d:
                        "M59.06,82.3a19,19,0,0,0,5.41-1,3.3,3.3,0,0,1-2.71,1.59A3.55,3.55,0,0,1,59.06,82.3Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "passport-too-dark-cls-21",
                    attrs: {
                      d:
                        "M59.69,51.81a1.38,1.38,0,0,1,0-.36A1,1,0,0,1,59.69,51.81Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "passport-too-dark-cls-22",
                    attrs: {
                      d: "M74.17,67.17s2.73-.12,2.85,2.1-3,4.91-3,4.91Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "passport-too-dark-cls-23",
                    attrs: {
                      d:
                        "M47.19,73.87l.48-2.49c-.2-5.88,1-7.89,2.33-8.55a7.69,7.69,0,0,0,2.71-1.34,5.35,5.35,0,0,0,1.49-3.22,11.06,11.06,0,0,0,4.14,3.62c5.7,2.76,12-1,13.63,1.41,1,1.43-.64,3.47,1,6.26a6.62,6.62,0,0,0,1.22,1.55,19.63,19.63,0,0,0,4.46-13.38A17.54,17.54,0,0,0,71.71,44.8c-.93-.72-8-6.06-14.25-3.81a18.81,18.81,0,0,0-6.08,3.62,10.87,10.87,0,0,1-1.94,1.59c-1.64,1-2.62,1-3.83,1.55-3,1.3-4.27,4.57-4.52,5.21a11.09,11.09,0,0,0-.41,5.94C41.65,65.31,45.07,70.86,47.19,73.87Z",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("rect", {
                  staticClass: "passport-too-dark-cls-24",
                  attrs: {
                    id: "Overlay",
                    x: "23.08",
                    y: "35.36",
                    width: "73.68",
                    height: "74.78",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("g", [
                _c("rect", {
                  staticClass: "passport-too-dark-cls-25",
                  attrs: {
                    x: "164.83",
                    y: "-0.69",
                    width: "4.82",
                    height: "81.86",
                    transform: "translate(126.72 207.41) rotate(-89.9)",
                  },
                }),
                _vm._v(" "),
                _c("rect", {
                  staticClass: "passport-too-dark-cls-25",
                  attrs: {
                    x: "170.22",
                    y: "11.96",
                    width: "4.82",
                    height: "92.69",
                    transform: "translate(114.03 230.83) rotate(-89.9)",
                  },
                }),
                _vm._v(" "),
                _c("rect", {
                  staticClass: "passport-too-dark-cls-25",
                  attrs: {
                    x: "170.1",
                    y: "58.91",
                    width: "4.82",
                    height: "92.69",
                    transform: "translate(66.96 277.59) rotate(-89.9)",
                  },
                }),
                _vm._v(" "),
                _c("rect", {
                  staticClass: "passport-too-dark-cls-25",
                  attrs: {
                    x: "158.15",
                    y: "44.44",
                    width: "4.82",
                    height: "68.61",
                    transform: "matrix(0, -1, 1, 0, 81.54, 239.17)",
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("rect", {
            staticClass: "passport-too-dark-cls-26",
            attrs: { y: "0.04", width: "263.22", height: "189.38" },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }