var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-controls-frame",
    [
      _c("ui-button", {
        attrs: {
          slot: "main",
          label: "App.StartInterview",
          disabled: _vm.disabled,
        },
        on: { click: _vm.start },
        slot: "main",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }