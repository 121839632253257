var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 263.22 189.81",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "card-too-dark-linear-gradient",
                x1: "27.01",
                y1: "161.77",
                x2: "580.66",
                y2: "157.86",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#a6a6a6" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "card-too-dark-linear-gradient-2",
                x1: "66.25",
                y1: "107.09",
                x2: "66.25",
                y2: "99.81",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#e3ddd5" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#bdb3a9" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "card-too-dark-linear-gradient-3",
                x1: "-418.8",
                y1: "1123.93",
                x2: "-434.2",
                y2: "1092.38",
                gradientTransform:
                  "matrix(1.15, 0.01, -0.01, 1.15, 557.63, -1171)",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#a3a3a3" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.29", "stop-color": "#b2b2b2" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.66", "stop-color": "#bfbfbf" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#c4c4c4" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "card-too-dark-linear-gradient-4",
                x1: "64.12",
                y1: "93.13",
                x2: "68.48",
                y2: "99.59",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#3b3b3b" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#545454" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "card-too-dark-linear-gradient-5",
                x1: "65.89",
                y1: "68.17",
                x2: "65.95",
                y2: "68.24",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#999" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#6e6e6e" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "card-too-dark-linear-gradient-6",
              x1: "58.21",
              y1: "80.38",
              x2: "123.06",
              y2: "109.81",
              "xlink:href": "#card-too-dark-linear-gradient-2",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "card-too-dark-linear-gradient-7",
              x1: "54.53",
              y1: "79.58",
              x2: "53.69",
              y2: "83.99",
              "xlink:href": "#card-too-dark-linear-gradient-2",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "card-too-dark-linear-gradient-8",
                x1: "64.38",
                y1: "83.04",
                x2: "68.37",
                y2: "93.97",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#d1cdcb" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#9c9189" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "card-too-dark-linear-gradient-9",
                x1: "65.86",
                y1: "68.15",
                x2: "65.89",
                y2: "68.15",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.13", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.28", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#1f1f1f" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.54", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.78", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "card-too-dark-linear-gradient-10",
              x1: "79.8",
              y1: "83.69",
              x2: "78.9",
              y2: "88.42",
              "xlink:href": "#card-too-dark-linear-gradient-2",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "card-too-dark-linear-gradient-11",
                x1: "48.92",
                y1: "73.03",
                x2: "82.64",
                y2: "73.03",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.12", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.25", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#262626" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.58", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "0.8", "stop-color": "#050505" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "card-too-dark-cls-1" }, [
        _c("g", { attrs: { id: "Card-too-dark" } }, [
          _c("rect", {
            staticClass: "card-too-dark-cls-2",
            attrs: { width: "263.22", height: "189.81" },
          }),
          _vm._v(" "),
          _c("g", [
            _c("g", [
              _c("rect", {
                staticClass: "card-too-dark-cls-3",
                attrs: {
                  x: "13.97",
                  y: "18.19",
                  width: "229.53",
                  height: "151.89",
                  rx: "10.87",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                staticClass: "card-too-dark-cls-4",
                attrs: {
                  x: "160.22",
                  y: "13.69",
                  width: "4.81",
                  height: "92.67",
                  transform: "translate(102.33 222.55) rotate(-89.9)",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "card-too-dark-cls-4",
                attrs: {
                  points:
                    "174.97 121.62 116.41 121.47 116.42 116.65 174.91 117.23 174.97 121.62",
                },
              }),
              _vm._v(" "),
              _c("g", [
                _c("rect", {
                  staticClass: "card-too-dark-cls-4",
                  attrs: {
                    x: "148.15",
                    y: "46.16",
                    width: "4.81",
                    height: "68.6",
                    transform: "translate(69.84 230.89) rotate(-89.9)",
                  },
                }),
                _vm._v(" "),
                _c("rect", {
                  staticClass: "card-too-dark-cls-4",
                  attrs: {
                    x: "148.15",
                    y: "64.4",
                    width: "4.81",
                    height: "68.6",
                    transform: "translate(51.61 249.09) rotate(-89.9)",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("path", {
                staticClass: "card-too-dark-cls-5",
                attrs: {
                  d:
                    "M243.61,152.06l-.05,12a6,6,0,0,1-6,6l-217-.36a7.3,7.3,0,0,1-6.69-5.33V152.06Z",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                staticClass: "card-too-dark-cls-6",
                attrs: {
                  x: "13.97",
                  y: "18.1",
                  width: "229.53",
                  height: "151.89",
                  rx: "10.87",
                },
              }),
              _vm._v(" "),
              _c("g", [
                _c("rect", {
                  staticClass: "card-too-dark-cls-2",
                  attrs: {
                    x: "33.56",
                    y: "53.78",
                    width: "65.07",
                    height: "66.04",
                  },
                }),
                _vm._v(" "),
                _c("g", [
                  _c("path", {
                    staticClass: "card-too-dark-cls-7",
                    attrs: {
                      d:
                        "M60.41,95.75l-1.32,8.72A3.39,3.39,0,0,1,57.3,107L46,112.65l40.56.32-8.35-4.7a5.91,5.91,0,0,1-2.87-4.06l-1.55-8.16Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("g", [
                    _c("path", {
                      staticClass: "card-too-dark-cls-8",
                      attrs: {
                        d:
                          "M56,106.91S59.24,110,67.35,110s11.41-2,11.41-2l15.31,8.77,2.82,3.33-61.48-.32,2.49-3.3Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "card-too-dark-cls-9",
                      attrs: {
                        d:
                          "M56,106.91S59.24,110,67.35,110s11.41-2,11.41-2l15.31,8.77,2.82,3.33-61.48-.32,2.49-3.3Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "card-too-dark-cls-9",
                      attrs: {
                        d:
                          "M56,106.91S59.24,110,67.35,110s11.41-2,11.41-2l15.31,8.77,2.82,3.33-61.48-.32,2.49-3.3Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "card-too-dark-cls-10",
                      attrs: {
                        d:
                          "M56,106.91S59.24,110,67.35,110s11.41-2,11.41-2l15.31,8.77,2.82,3.33-61.48-.32,2.49-3.3Z",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "card-too-dark-cls-11",
                    attrs: {
                      d: "M59.91,97.8s1.66,3.74,7,4a8,8,0,0,0,7.56-4.28Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "card-too-dark-cls-12",
                    attrs: {
                      d:
                        "M65.89,68.31a1.09,1.09,0,0,1,0-.32A.75.75,0,0,1,65.89,68.31Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "card-too-dark-cls-13",
                    attrs: {
                      d:
                        "M78.63,86.07a19.58,19.58,0,0,1-.54,5.45,13.39,13.39,0,0,1-3.43,5.92s-3.7,2.93-7.13,3a3,3,0,0,1-.43,0c-.51,0-1,0-1.58-.06-.25,0-.51-.05-.76-.09h0c-3.33-.54-5-2.5-6.56-4.59a15.81,15.81,0,0,1-3.4-9.09V79.89c0-2.36,0-2.82,0-5.64,0,0,5.24,1.08,12.45-2.79a6,6,0,0,0,3.8,1.59c3.88.14,4.72-1.6,4.73-2,.24,1,.46,8.22,2.29,9.19,1.36.86.57.26.56.43Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "card-too-dark-cls-14",
                    attrs: {
                      d: "M54,82.55s-2.42.09-2.36,2,3.32,3.73,3.32,3.73Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("polyline", {
                    staticClass: "card-too-dark-cls-15",
                    attrs: { points: "66.58 83.09 65.13 91.07 66.38 92.07" },
                  }),
                  _vm._v(" "),
                  _c("circle", {
                    staticClass: "card-too-dark-cls-16",
                    attrs: { cx: "60.82", cy: "84.19", r: "1.1" },
                  }),
                  _vm._v(" "),
                  _c("circle", {
                    staticClass: "card-too-dark-cls-16",
                    attrs: { cx: "72.05", cy: "84.19", r: "1.1" },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "card-too-dark-cls-3",
                    attrs: {
                      d:
                        "M65.33,95.24a17,17,0,0,0,4.78-.92,2.91,2.91,0,0,1-2.39,1.41A3.16,3.16,0,0,1,65.33,95.24Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "card-too-dark-cls-17",
                    attrs: {
                      d:
                        "M65.89,68.31a1.09,1.09,0,0,1,0-.32A.75.75,0,0,1,65.89,68.31Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "card-too-dark-cls-18",
                    attrs: {
                      d: "M78.67,81.87s2.41-.11,2.52,1.86-2.69,4.34-2.69,4.34Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "card-too-dark-cls-19",
                    attrs: {
                      d:
                        "M54.84,87.78c.15-.73.29-1.46.44-2.19-.18-5.2.91-7,2-7.55a7,7,0,0,0,2.4-1.18A4.77,4.77,0,0,0,61,74a9.79,9.79,0,0,0,3.66,3.2c5,2.44,10.56-.92,12,1.25.86,1.26-.56,3.06.86,5.53a5.73,5.73,0,0,0,1.08,1.36,17.41,17.41,0,0,0,3.94-11.82,16.47,16.47,0,0,0-2.26-7.17,16.28,16.28,0,0,0-3.85-4.24c-.82-.64-7.06-5.35-12.59-3.37A16.7,16.7,0,0,0,58.55,62a10.41,10.41,0,0,1-1.71,1.4,14.89,14.89,0,0,1-3.39,1.37,8.29,8.29,0,0,0-4,4.61,9.78,9.78,0,0,0-.36,5.24C50,80.23,53,85.13,54.84,87.78Z",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("rect", {
                  staticClass: "card-too-dark-cls-20",
                  attrs: {
                    id: "Overlay",
                    x: "33.56",
                    y: "53.78",
                    width: "65.07",
                    height: "66.04",
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("rect", {
              staticClass: "card-too-dark-cls-21",
              attrs: { width: "263.22", height: "189.81" },
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }