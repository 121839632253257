var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 494.84 389.47",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-front-linear-gradient",
                x1: "58.99",
                y1: "304.79",
                x2: "1086.16",
                y2: "297.53",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#a6a6a6" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-front-linear-gradient-2",
                x1: "239.53",
                y1: "350.74",
                x2: "261.49",
                y2: "396.12",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#3b3b3b" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#2b2829" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-front-linear-gradient-3",
                x1: "122.28",
                y1: "179.06",
                x2: "122.28",
                y2: "164.28",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#e3ddd5" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#bdb3a9" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-front-linear-gradient-4",
                x1: "-343.18",
                y1: "1037.38",
                x2: "-374.46",
                y2: "973.32",
                gradientTransform:
                  "matrix(1.15, 0.01, -0.01, 1.15, 528.13, -981.6)",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#a3a3a3" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.29", "stop-color": "#b2b2b2" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.66", "stop-color": "#bfbfbf" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#c4c4c4" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-front-linear-gradient-5",
                x1: "117.95",
                y1: "150.72",
                x2: "126.81",
                y2: "163.83",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#3b3b3b" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#545454" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-front-linear-gradient-6",
                x1: "121.54",
                y1: "100.04",
                x2: "121.68",
                y2: "100.19",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#999" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#6e6e6e" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "id-front-linear-gradient-7",
              x1: "105.96",
              y1: "124.83",
              x2: "237.63",
              y2: "184.58",
              "xlink:href": "#id-front-linear-gradient-3",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "id-front-linear-gradient-8",
              x1: "98.47",
              y1: "123.21",
              x2: "96.78",
              y2: "132.17",
              "xlink:href": "#id-front-linear-gradient-3",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-front-linear-gradient-9",
                x1: "118.47",
                y1: "130.23",
                x2: "126.58",
                y2: "152.44",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#d1cdcb" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#9c9189" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-front-linear-gradient-10",
                x1: "121.49",
                y1: "100.01",
                x2: "121.54",
                y2: "100",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.13", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.28", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#1f1f1f" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.54", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.78", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "id-front-linear-gradient-11",
              x1: "149.79",
              y1: "131.56",
              x2: "147.97",
              y2: "141.16",
              "xlink:href": "#id-front-linear-gradient-3",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-front-linear-gradient-12",
                x1: "87.09",
                y1: "109.9",
                x2: "155.56",
                y2: "109.9",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.12", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.25", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#262626" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.58", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "0.8", "stop-color": "#050505" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "id-front-linear-gradient-13",
              x1: "338.95",
              y1: "237.5",
              x2: "659.51",
              y2: "500.93",
              gradientTransform: "matrix(1, 0, 0, 1, 0, 0)",
              "xlink:href": "#id-front-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "id-front-linear-gradient-14",
              x1: "352.99",
              y1: "220.41",
              x2: "673.56",
              y2: "483.85",
              gradientTransform: "matrix(1, 0, 0, 1, 0, 0)",
              "xlink:href": "#id-front-linear-gradient-4",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "id-front-cls-1" }, [
        _c("g", { attrs: { id: "ID-front" } }, [
          _c("rect", {
            staticClass: "id-front-cls-2",
            attrs: {
              x: "34.8",
              y: "38.41",
              width: "425.84",
              height: "281.79",
              rx: "10.87",
            },
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "id-front-cls-3",
            attrs: {
              d:
                "M460.86,286.77l-.1,22.3a11.17,11.17,0,0,1-11.19,11.15L47,319.55c-6.17,0-11.16-5-12.41-9.88v-22.9Z",
            },
          }),
          _vm._v(" "),
          _c("g", [
            _c("g", [
              _c("polygon", {
                staticClass: "id-front-cls-4",
                attrs: {
                  points:
                    "14.55 73.91 0 73.91 0 0 73.91 0 73.91 14.55 14.55 14.55 14.55 73.91",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "id-front-cls-4",
                attrs: {
                  points:
                    "494.84 358.47 420.92 358.47 420.92 343.92 480.29 343.92 480.29 284.55 494.84 284.55 494.84 358.47",
                },
              }),
            ]),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "id-front-cls-4",
              attrs: {
                points:
                  "494.84 73.91 480.29 73.91 480.29 14.55 420.92 14.55 420.92 0 494.84 0 494.84 73.91",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "id-front-cls-4",
              attrs: {
                points:
                  "73.91 358.47 0 358.47 0 284.55 14.55 284.55 14.55 343.92 73.91 343.92 73.91 358.47",
              },
            }),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("path", {
              staticClass: "id-front-cls-4",
              attrs: {
                d:
                  "M275.78,349.69v35.46a4.44,4.44,0,0,1-4.44,4.44h-48a4.43,4.43,0,0,1-4.43-4.43V349.69a4.44,4.44,0,0,1,4.44-4.44h9.78a4.46,4.46,0,0,0,3.53-1.74l2.74-3.56a6.74,6.74,0,0,1,5.34-2.64H251a6.74,6.74,0,0,1,5.35,2.64l2.74,3.56a4.44,4.44,0,0,0,3.53,1.74h8.72A4.44,4.44,0,0,1,275.78,349.69Z",
              },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "id-front-cls-5",
              attrs: { cx: "247.06", cy: "366.3", r: "15.62" },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "id-front-cls-6",
              attrs: { cx: "247.06", cy: "366.3", r: "10.99" },
            }),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("path", { attrs: { d: "M220.35,74.34H223V87.76h-2.69Z" } }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M237.46,81.07c0,4.06-2.79,6.69-7.08,6.69h-4.69V74.34h4.69C234.67,74.34,237.46,77,237.46,81.07Zm-7.17,4.4c2.82,0,4.42-1.61,4.42-4.4s-1.6-4.46-4.42-4.46h-1.9v8.86Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M247.26,76.51h-5.09v3.37h4.51V82h-4.51v3.56h5.09v2.19h-7.79V74.32h7.79Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M261.18,74.32V87.76h-2.69l-6.1-9.21v9.21H249.7V74.32h2.69l6.1,9.23V74.32Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M263.12,74.34H273v2.17h-3.58V87.76h-2.69V76.51h-3.57Z",
              },
            }),
            _vm._v(" "),
            _c("path", { attrs: { d: "M274.92,74.34h2.69V87.76h-2.69Z" } }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M279.55,74.34h9.84v2.17h-3.57V87.76h-2.7V76.51h-3.57Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M290.23,74.34h3l2.89,6.13L299,74.34h3l-4.54,8.75v4.67h-2.69V83.09Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M314.3,74.17a6.33,6.33,0,0,1,6.31,4.34h-3.1a3.39,3.39,0,0,0-3.23-1.92c-2.34,0-4,1.71-4,4.44s1.68,4.44,4,4.44a3.36,3.36,0,0,0,3.23-1.94h3.1a6.31,6.31,0,0,1-6.31,4.35,6.86,6.86,0,0,1,0-13.71Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M331.14,85.21h-5.35l-.88,2.55h-2.83l4.83-13.44H330l4.83,13.44H332Zm-2.68-7.77-1.94,5.61h3.89Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M341.71,74.34c3.23,0,4.84,1.87,4.84,4.12a3.87,3.87,0,0,1-3.05,3.86l3.21,5.44h-3.12l-3-5.23h-1.27v5.23h-2.69V74.34Zm-.1,2.23h-2.25v3.94h2.25c1.5,0,2.17-.78,2.17-2S343.11,76.57,341.61,76.57Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M360.76,81.07c0,4.06-2.79,6.69-7.08,6.69H349V74.34h4.69C358,74.34,360.76,77,360.76,81.07Zm-7.17,4.4c2.82,0,4.42-1.61,4.42-4.4s-1.6-4.46-4.42-4.46h-1.91v8.86Z",
              },
            }),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("path", {
              attrs: {
                d:
                  "M216.08,25.47c-2.52-2.42-3.92-6.38-3.92-10.63s1.4-8.4,4.21-10.92h2.71v.25a14.8,14.8,0,0,0-4.33,10.67,15,15,0,0,0,4,10.37v.26Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M220.75,8.27H229v2.17h-5.6v3.44h4.29V16h-4.29v5.68h-2.69Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M236,8.27c3.23,0,4.85,1.86,4.85,4.11a3.89,3.89,0,0,1-3.06,3.87L241,21.69h-3.11l-3-5.23h-1.27v5.23h-2.69V8.27Zm-.09,2.23h-2.25v3.94h2.25c1.5,0,2.17-.79,2.17-2S237.38,10.5,235.88,10.5Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M249.48,21.82a6.74,6.74,0,0,1-6.88-6.88,6.88,6.88,0,0,1,13.75,0A6.72,6.72,0,0,1,249.48,21.82Zm0-2.4c2.43,0,4.1-1.75,4.1-4.48s-1.67-4.44-4.1-4.44-4.11,1.71-4.11,4.44S247,19.42,249.48,19.42Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M269.82,8.25V21.69h-2.69L261,12.48v9.21h-2.7V8.25H261l6.09,9.23V8.25Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M271.76,8.27h9.84v2.17H278V21.69h-2.69V10.44h-3.58Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M282.87,25.47v-.26a15,15,0,0,0,4-10.37,14.8,14.8,0,0,0-4.33-10.67V3.92h2.71c2.81,2.52,4.21,6.65,4.21,10.92s-1.4,8.21-3.92,10.63Z",
              },
            }),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("rect", {
              staticClass: "id-front-cls-7",
              attrs: {
                x: "55.78",
                y: "71.01",
                width: "131.94",
                height: "133.91",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "id-front-cls-8",
              attrs: {
                d:
                  "M110.43,156l-2.69,17.7a6.83,6.83,0,0,1-3.64,5.12l-23,11.5,82.34.66-17-9.56a12.05,12.05,0,0,1-5.82-8.24l-3.14-16.56Z",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("path", {
                staticClass: "id-front-cls-9",
                attrs: {
                  d:
                    "M101.46,178.7s6.59,6.28,23.06,6.37,23.17-4.1,23.17-4.1l31.08,17.82,5.72,6.76L59.66,204.9l5.06-6.7Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "id-front-cls-10",
                attrs: {
                  d:
                    "M101.46,178.7s6.59,6.28,23.06,6.37,23.17-4.1,23.17-4.1l31.08,17.82,5.72,6.76L59.66,204.9l5.06-6.7Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "id-front-cls-10",
                attrs: {
                  d:
                    "M101.46,178.7s6.59,6.28,23.06,6.37,23.17-4.1,23.17-4.1l31.08,17.82,5.72,6.76L59.66,204.9l5.06-6.7Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "id-front-cls-11",
                attrs: {
                  d:
                    "M101.46,178.7s6.59,6.28,23.06,6.37,23.17-4.1,23.17-4.1l31.08,17.82,5.72,6.76L59.66,204.9l5.06-6.7Z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("path", {
              staticClass: "id-front-cls-12",
              attrs: {
                d: "M109.41,160.2s3.37,7.59,14.3,8.19,15.36-8.69,15.36-8.69Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "id-front-cls-13",
              attrs: {
                d:
                  "M121.55,100.33a2.25,2.25,0,0,1-.08-.65A1.76,1.76,0,0,1,121.55,100.33Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "id-front-cls-14",
              attrs: {
                d:
                  "M147.42,136.38a39.51,39.51,0,0,1-1.1,11.07,27.13,27.13,0,0,1-7,12s-7.51,5.95-14.49,6.11c-.28,0-.57,0-.86,0a32,32,0,0,1-3.21-.11c-.51-.06-1-.1-1.54-.19h0c-6.76-1.09-10.22-5.07-13.33-9.32C102,150.63,99,144.14,99,137.52l0-13.68c.1-4.79,0-5.72.1-11.45,0,0,10.62,2.2,25.27-5.67A12.14,12.14,0,0,0,132.1,110c7.89.28,9.59-3.26,9.62-4.06.48,2,.93,16.68,4.63,18.66,2.78,1.74,1.17.51,1.15.87Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "id-front-cls-15",
              attrs: {
                d: "M97.3,129.24s-4.9.18-4.79,4.17S99.26,141,99.26,141Z",
              },
            }),
            _vm._v(" "),
            _c("polyline", {
              staticClass: "id-front-cls-16",
              attrs: { points: "122.94 130.34 120 146.55 122.53 148.57" },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "id-front-cls-17",
              attrs: { cx: "111.25", cy: "132.57", r: "2.23" },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "id-front-cls-17",
              attrs: { cx: "134.06", cy: "132.57", r: "2.23" },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "id-front-cls-2",
              attrs: {
                d:
                  "M120.41,155s6.48-.34,9.71-1.86a5.89,5.89,0,0,1-4.86,2.86C121.7,156.27,120.41,155,120.41,155Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "id-front-cls-18",
              attrs: {
                d:
                  "M121.55,100.33a2.25,2.25,0,0,1-.08-.65A1.76,1.76,0,0,1,121.55,100.33Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "id-front-cls-19",
              attrs: {
                d: "M147.5,127.87s4.9-.22,5.12,3.76-5.46,8.81-5.46,8.81Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "id-front-cls-20",
              attrs: {
                d:
                  "M99.12,139.87l.87-4.46c-.36-10.55,1.85-14.15,4.18-15.33,1.35-.68,3.11-.74,4.86-2.4a9.52,9.52,0,0,0,2.66-5.77,19.94,19.94,0,0,0,7.44,6.48c10.21,5,21.45-1.86,24.43,2.54,1.74,2.56-1.14,6.22,1.74,11.23a12.52,12.52,0,0,0,2.19,2.77c1.93-2.38,8.86-11.51,8-24a33.14,33.14,0,0,0-4.59-14.56,32.8,32.8,0,0,0-7.81-8.62c-1.66-1.29-14.33-10.86-25.56-6.83a33.71,33.71,0,0,0-10.89,6.49,21,21,0,0,1-3.47,2.86c-3,1.88-4.7,1.83-6.88,2.78-5.37,2.32-7.65,8.18-8.11,9.34-1.16,3-1.4,6.15-.72,10.65C89.19,124.53,95.32,134.49,99.12,139.87Z",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              staticClass: "id-front-cls-21",
              attrs: {
                x: "55.78",
                y: "71.01",
                width: "131.51",
                height: "133.91",
              },
            }),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("rect", {
              staticClass: "id-front-cls-7",
              attrs: {
                x: "351.93",
                y: "220.21",
                width: "98.67",
                height: "92.08",
                rx: "11.49",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("path", {
                staticClass: "id-front-cls-22",
                attrs: { d: "M367.94,236.34h12v59.83h-12Z" },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "id-front-cls-23",
                attrs: {
                  d:
                    "M444.24,266.34c0,18.09-12.43,29.83-31.55,29.83H391.77V236.34h20.92C431.81,236.34,444.24,248.17,444.24,266.34Zm-32,19.63c12.6,0,19.72-7.2,19.72-19.63s-7.12-19.89-19.72-19.89h-8.48V286Z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("g", { staticClass: "id-front-cls-24" }, [
              _c("path", {
                staticClass: "id-front-cls-4",
                attrs: { d: "M367.94,236.34h12v59.83h-12Z" },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "id-front-cls-4",
                attrs: {
                  d:
                    "M444.24,266.34c0,18.09-12.43,29.83-31.55,29.83H391.77V236.34h20.92C431.81,236.34,444.24,248.17,444.24,266.34Zm-32,19.63c12.6,0,19.72-7.2,19.72-19.63s-7.12-19.89-19.72-19.89h-8.48V286Z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("g", { staticClass: "id-front-cls-24" }, [
              _c("path", {
                staticClass: "id-front-cls-4",
                attrs: { d: "M367.94,236.34h12v59.83h-12Z" },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "id-front-cls-4",
                attrs: {
                  d:
                    "M444.24,266.34c0,18.09-12.43,29.83-31.55,29.83H391.77V236.34h20.92C431.81,236.34,444.24,248.17,444.24,266.34Zm-32,19.63c12.6,0,19.72-7.2,19.72-19.63s-7.12-19.89-19.72-19.89h-8.48V286Z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("g", { staticClass: "id-front-cls-25" }, [
              _c("path", {
                staticClass: "id-front-cls-4",
                attrs: { d: "M367.94,236.34h12v59.83h-12Z" },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "id-front-cls-4",
                attrs: {
                  d:
                    "M444.24,266.34c0,18.09-12.43,29.83-31.55,29.83H391.77V236.34h20.92C431.81,236.34,444.24,248.17,444.24,266.34Zm-32,19.63c12.6,0,19.72-7.2,19.72-19.63s-7.12-19.89-19.72-19.89h-8.48V286Z",
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("rect", {
              staticClass: "id-front-cls-26",
              attrs: {
                x: "300.6",
                y: "27.62",
                width: "8.93",
                height: "171.93",
                transform: "translate(190.98 418.46) rotate(-89.9)",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "id-front-cls-26",
              attrs: {
                points:
                  "327.97 227.87 219.31 227.58 219.33 218.65 327.85 219.71 327.97 227.87",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("rect", {
                staticClass: "id-front-cls-26",
                attrs: {
                  x: "278.2",
                  y: "87.87",
                  width: "8.93",
                  height: "127.28",
                  transform: "translate(130.7 433.92) rotate(-89.9)",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                staticClass: "id-front-cls-26",
                attrs: {
                  x: "278.2",
                  y: "121.7",
                  width: "8.93",
                  height: "127.28",
                  transform: "translate(96.86 467.69) rotate(-89.9)",
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("rect", {
            staticClass: "id-front-cls-27",
            attrs: {
              x: "34.8",
              y: "37.8",
              width: "425.84",
              height: "281.79",
              rx: "10.87",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }