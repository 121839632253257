<template v-if="data">
  <span v-if="typeof data === 'object'">
    <component
      :is="item.type"
      v-for="(item, index) of data"
      :key="index"
      :href="item.type === 'a' && (item.href || item.value)"
      :target="item.type === 'a' && '_blank'"
      :class="item.class"
    >
      <view-generator :data="item.value" />
    </component>
  </span>
  <span v-else>
    {{ data }}
  </span>
</template>

<script>
export default {
  name: 'view-generator',

  props: {
    data: { type: [Array, String], default: undefined }
  }
}
</script>

<style scoped>
h2 {
  margin-bottom: 2rem;
}

p {
  margin: 1rem 0;
}

a {
  color: var(--blue);
  font-weight: 500;
  text-decoration: none;
}

h1 span,
h2 span,
p span,
p a {
  font-size: inherit;
}

ul {
  list-style: none;
  padding-left: 2rem;
  margin: 1rem 0;
}

ul li {
  margin: 0.5rem 0;
  position: relative;
}

ul li::before {
  content: '';
  border: 3px solid var(--list-bullet-color);
  border-radius: var(--list-bullet-border-radius);
  position: absolute;
  top: 0.375rem;
  left: -1.75rem;
  transform: rotate(45deg);
}
</style>
