// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
p[data-v-7d786177] {
  margin: 0;
  font-weight: bold;
  padding: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Status.vue"],"names":[],"mappings":";AAaA;EACA,SAAA;EACA,iBAAA;EACA,YAAA;AACA","sourcesContent":["<template>\n  <p>Git Hash: {{ gitHash }}</p>\n</template>\n<script>\nexport default {\n  data() {\n    return {\n      gitHash: GitHash\n    }\n  }\n}\n</script>\n<style scoped>\np {\n  margin: 0;\n  font-weight: bold;\n  padding: 1em;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
