// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.phone-input[data-v-1b40f55e] {
  display: flex;
}
.phone-input > *[data-v-1b40f55e] {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.ui-input[data-v-1b40f55e] {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/PhoneInput.vue"],"names":[],"mappings":";AA+DA;EACA,aAAA;AACA;AAEA;EACA,mBAAA;EACA,oBAAA;AACA;AAEA;EACA,WAAA;AACA","sourcesContent":["<template>\n  <div class=\"phone-input\">\n    <ui-phone-code\n      :value=\"phoneNumber.code\"\n      :country=\"country\"\n      :error=\"!!error\"\n      :disabled=\"disabled\"\n      @input=\"codeChanged\"\n    />\n    <ui-input\n      :label=\"label\"\n      :value=\"phoneNumber.number\"\n      :error=\"error\"\n      :disabled=\"disabled\"\n      max-length=\"22\"\n      @input=\"numberChanged\"\n    />\n  </div>\n</template>\n\n<script>\nexport default {\n  props: {\n    value: { type: Object, default: undefined },\n    error: { type: String, default: undefined },\n    label: { type: String, default: undefined },\n    country: { type: String, default: undefined },\n    disabled: Boolean\n  },\n\n  computed: {\n    phoneNumber() {\n      return this.value || {}\n    }\n  },\n\n  methods: {\n    codeChanged(value) {\n      const { phoneNumber } = this\n      value = value.trim()\n      if (value === phoneNumber.code) return\n\n      this.$emit('change', {\n        ...phoneNumber,\n        code: value\n      })\n    },\n\n    numberChanged(value) {\n      const { phoneNumber } = this\n      value = value.trim()\n      if (value === phoneNumber.number) return\n\n      this.$emit('change', {\n        ...phoneNumber,\n        number: value\n      })\n    }\n  }\n}\n</script>\n\n<style scoped>\n.phone-input {\n  display: flex;\n}\n\n.phone-input > * {\n  margin-left: 0.5rem;\n  margin-right: 0.5rem;\n}\n\n.ui-input {\n  width: 100%;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
