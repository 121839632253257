// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.header[data-v-8d6b8f5c] {
  margin-top: 2rem;
  margin-bottom: 1.25rem;
  text-align: center;
  color: var(--header-contrast-color);
}
@media screen and (min-width: 38.0625rem) {
.header[data-v-8d6b8f5c] {
    margin-left: 3rem;
    margin-right: 3rem;
}
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/signatureAuthentication/Types.vue"],"names":[],"mappings":";AAuDA;EACA,gBAAA;EACA,sBAAA;EACA,kBAAA;EACA,mCAAA;AACA;AAEA;AACA;IACA,iBAAA;IACA,kBAAA;AACA;AACA","sourcesContent":["<template>\n  <ui-frame logo>\n    <h1 class=\"header\">\n      {{ $t('SignatureAuthentication.Types.Header') }}\n    </h1>\n    <card-list>\n      <card\n        v-for=\"item in signatureSettings\"\n        :key=\"item.name\"\n        :label=\"label(item.name)\"\n        @click=\"$emit('change', item)\"\n      >\n        <component :is=\"image(item.name)\" />\n      </card>\n    </card-list>\n  </ui-frame>\n</template>\n\n<script>\nimport Card from '@src/components/partials/Card'\nimport CardList from '@src/components/partials/CardList'\nimport SmartId from '@src/components/images/SmartId'\nimport MobileId from '@src/components/images/MobileId'\nimport getters from '@src/store/getters'\nimport { ruleTypes } from '@src/scripts/enums'\nimport { mapGetters } from 'vuex'\n\nexport default {\n  components: {\n    Card,\n    CardList\n  },\n\n  computed: {\n    ...mapGetters([getters.signatureSettings])\n  },\n\n  methods: {\n    label(name) {\n      if (name === ruleTypes.smartId) {\n        return this.$t('SignatureAuthentication.Types.SmartId')\n      } else if (name === ruleTypes.mobileId) {\n        return this.$t('SignatureAuthentication.Types.MobileId')\n      }\n    },\n\n    image(name) {\n      if (name === ruleTypes.smartId) return SmartId\n      else if (name === ruleTypes.mobileId) return MobileId\n    }\n  }\n}\n</script>\n\n<style scoped>\n.header {\n  margin-top: 2rem;\n  margin-bottom: 1.25rem;\n  text-align: center;\n  color: var(--header-contrast-color);\n}\n\n@media screen and (min-width: 38.0625rem) {\n  .header {\n    margin-left: 3rem;\n    margin-right: 3rem;\n  }\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
