<template>
  <div
    class="file-upload"
    :class="{ 'drag-drop-active': isActive, 'file-error': error }"
  >
    <div v-if="!isFull" class="upload-container">
      <div class="upload-content" @click="fileExplorerOpen">
        <div class="upload-box-description">
          <p v-if="isActive" class="active-text">
            {{ $t('FileUpload.DragActive') }}
          </p>
          <upload-icon v-if="!isActive" class="icon-spacing" />
          <p v-if="!isActive">
            {{ $t('FileUpload.Initial') }}
            <span class="browse">{{ $t('FileUpload.Browse') }}.</span>
          </p>
        </div>
        <h3 v-if="error">
          {{ $t('FileUpload.Required') }}
        </h3>
      </div>
      <input
        ref="fileExplorer"
        type="file"
        :accept="types.accept"
        :multiple="multiple"
        hidden
        @change="fileExplorerChange"
      />
      <div
        class="drop-zone"
        @dragover.prevent
        @dragleave="dragEnd"
        @drop.prevent="dropFile"
      />
      <div class="restrictions-text">
        <span>{{ supportedFileTypes }}</span>
        <span>{{ supportedFileSize }}</span>
      </div>
    </div>
    <div v-if="uploadedFiles" class="file-list">
      <div
        v-for="(item, index) in uploadedFiles"
        :key="index"
        class="file-box"
        @click.stop
      >
        <div>
          <span class="file-icon"><file-icon class="icon-spacing" /></span>
          <div class="file-text-info">
            <span class="file-name-text">{{ item.name }}</span>
            <span class="file-size-text">{{ getFileSize(item.size) }}</span>
          </div>
        </div>
        <a class="remove-icon" @click="() => removeItem(index)">
          <trash-bin />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import UploadIcon from '@src/components/images/UploadIcon'
import TrashBin from '@src/components/images/TrashBin'
import FileIcon from '@src/components/images/FileIcon'
import { getFileSize } from '@src/scripts/helpers'

export default {
  components: {
    TrashBin,
    UploadIcon,
    FileIcon
  },

  props: {
    accept: { type: Array, required: true },
    maxItems: { type: Number, default: 5 },
    maxSize: { type: Number, default: 3 },
    multiple: Boolean,
    error: Boolean
  },

  data() {
    return {
      isActive: false,
      isFull: false,
      uploadedFiles: [],
      allowedTypes: {
        pdf: { name: '.pdf', type: 'application/pdf' },
        jpg: { name: '.jpeg', type: 'image/jpeg' },
        png: { name: '.png', type: 'image/png' }
      }
    }
  },

  computed: {
    types() {
      const { accept, allowedTypes } = this
      if (!accept) return []

      const types = []
      const names = []

      for (const key of accept) {
        const item = allowedTypes[key]
        if (!item) continue

        names.push(item.name)
        types.push(item.type)
      }

      return {
        accept: types.join(','),
        names,
        types
      }
    },

    supportedFileTypes() {
      const acceptableTypes = this.accept.map((item) => item.toUpperCase())

      return this.$t('FileUpload.FilesSupported', {
        accept: acceptableTypes.join('; ').concat('.')
      })
    },

    supportedFileSize() {
      return this.$t('FileUpload.MaxSize', { size: this.maxSize })
    },

    itemsCount() {
      const { multiple, maxItems } = this
      return (multiple && maxItems) || 1
    },

    itemSize() {
      const { maxSize } = this
      return (maxSize && maxSize * 1000000) || 0
    }
  },

  created() {
    document.addEventListener('dragenter', this.dragEnter, false)
  },

  beforeDestroy() {
    document.removeEventListener('dragenter', this.dragEnter, false)
  },

  methods: {
    getFileSize,

    dragEnter() {
      if (this.isActive) return
      this.isActive = true
    },

    dragEnd() {
      if (!this.isActive) return
      this.isActive = false
    },

    dropFile(event) {
      this.dragEnd()
      this.getFiles(event.dataTransfer.files)
    },

    fileExplorerOpen() {
      const { uploadedFiles, itemsCount } = this
      if (!uploadedFiles || uploadedFiles.length === itemsCount) return

      this.$refs.fileExplorer.click()
    },

    fileExplorerChange(event) {
      this.getFiles(event.target.files)
      event.target.value = null
    },

    getFiles(files) {
      if (!files || !files.length) return

      const { types, uploadedFiles, itemsCount, itemSize } = this
      if (!uploadedFiles || uploadedFiles.length === itemsCount) return

      const count = itemsCount - uploadedFiles.length
      const result = this.handleFiles(
        uploadedFiles,
        files,
        types,
        count,
        itemSize
      )
      if (result.error)
        return this.$popUp(result.error.type, result.error.options)
      if (!result.files.length) return

      this.uploadedFiles.push(...result.files)
      if (this.uploadedFiles.length === itemsCount) this.isFull = true
      this.$emit('change', this.uploadedFiles)
    },

    handleFiles(uploadedFiles, files, typesData, count, size) {
      const result = []
      for (const file of files) {
        if (!typesData.types.includes(file.type)) {
          const allowedTypes = typesData.names.join(', ').toUpperCase()
          return this.createError('NotAllowedFileType', { types: allowedTypes })
        }
        if (file.size > size) {
          const sizeMB = size / 1000000
          return this.createError('FileToLarge', { size: sizeMB })
        }

        const existingFile = uploadedFiles.find(
          (item) => item.name === file.name && item.size === file.size
        )
        if (!existingFile) result.push(file)
      }

      if (result.length > count) {
        return this.createError('ToMuchFiles', { count })
      }

      return { files: result }
    },

    createError(type, options) {
      return {
        error: {
          type,
          options
        }
      }
    },

    removeItem(index) {
      const { uploadedFiles } = this

      if (!uploadedFiles || index >= uploadedFiles.length) return
      uploadedFiles.splice(index, 1)

      if (this.isFull) this.isFull = false
      this.$emit('change', uploadedFiles)
    }
  }
}
</script>

<style scoped>
.file-upload {
  width: 100%;
}

.upload-content {
  min-height: 5.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed var(--file-upload-color);
  border-radius: 0.375rem;
  padding: 2rem;
  transition: transform 0.15s ease-in-out;
  z-index: 1;
  position: relative;
}

.icon-spacing {
  margin-right: 0.5rem;
}

h3 {
  color: var(--file-upload-color);
  margin: 0;
}

h3,
p {
  text-align: center;
}

strong {
  text-transform: uppercase;
}

.drop-zone {
  position: fixed;
  inset: 0;
  display: none;
  z-index: 2;
}

.file-list {
  width: 100%;
  justify-content: space-around;
}

.file-box::after {
  content: '';
  inset: 0;
  position: absolute;
  border: 1px solid var(--file-upload-color);
  border-radius: 0.25rem;
  opacity: 0.5;
}

.file-box:hover::after {
  border: 1px solid var(--file-upload-color);
  opacity: 1;
}

.file-box {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  position: relative;
  margin-bottom: 1rem;
}

.file-box:hover::before {
  content: '';
  position: absolute;
  inset: 0;
  opacity: 10%;
  background: var(--file-upload-color);
}

.remove-icon {
  cursor: pointer;
  min-width: 1.5rem;
  min-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-box > .remove-icon {
  pointer-events: auto;
}

.file-box div:first-child {
  display: flex;
  align-items: center;
}

.drag-drop-active .drop-zone {
  display: block;
}

.upload-content:hover {
  transform: scale(1.03);
  cursor: pointer;
}

.upload-content::before {
  content: '';
  position: absolute;
  inset: 0;
}

.drag-drop-active .upload-content::before {
  background: var(--file-upload-color);
  opacity: 10%;
}

.file-error {
  --file-upload-color: var(--error);
}

.upload-box-description {
  display: flex;
  align-items: center;
}

.browse {
  cursor: pointer;
  color: var(--file-upload-color);
  font-weight: bold;
  text-decoration: underline;
}

.active-text {
  color: var(--file-upload-color);
}

.restrictions-text {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

@media screen and (max-width: 30rem) {
  .restrictions-text {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

.restrictions-text span {
  font-size: 0.75rem;
}

.file-text-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.file-name-text {
  font-size: 0.875rem;
  overflow-wrap: anywhere;
}

.file-size-text {
  font-size: 0.75rem;
  opacity: 0.8;
}

.upload-container {
  margin-bottom: 2rem;
}
</style>
