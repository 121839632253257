import '@src/styles/app.css'
import '@src/styles/face-tec-override.css'
import '@src/styles/overlay.css'

import Vue from 'vue'
import Resources, { i18n } from '@src/scripts/translationController'
import Router from '@src/scripts/router'
import Store from '@src/store/index'
import App from '@src/App.vue'
import PopUp from '@src/components/popUp'
import UiElements from '@src/components/ui'

Vue.use(UiElements)
Vue.use(PopUp)
Vue.use(Resources)

new Vue({
  el: '#app',
  components: { App },
  template: '<app/>',
  router: Router,
  store: Store,
  i18n
})
