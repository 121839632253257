var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 493.08 524.1",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "selfie-linear-gradient",
                x1: "253.24",
                y1: "390.88",
                x2: "253.24",
                y2: "338.53",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#e3ddd5" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#bdb3a9" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "selfie-linear-gradient-2",
                x1: "-556.06",
                y1: "1220.17",
                x2: "-666.83",
                y2: "993.27",
                gradientTransform:
                  "matrix(1.15, 0.01, -0.01, 1.15, 916.15, -889.87)",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#a3a3a3" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.29", "stop-color": "#b2b2b2" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.66", "stop-color": "#bfbfbf" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#c4c4c4" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "selfie-linear-gradient-3",
                x1: "237.89",
                y1: "290.48",
                x2: "269.28",
                y2: "336.93",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#3b3b3b" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#545454" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "selfie-linear-gradient-4",
                x1: "250.64",
                y1: "111",
                x2: "251.11",
                y2: "111.5",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#999" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#6e6e6e" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "selfie-linear-gradient-5",
              x1: "195.43",
              y1: "198.8",
              x2: "661.81",
              y2: "410.44",
              "xlink:href": "#selfie-linear-gradient",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "selfie-linear-gradient-6",
              x1: "168.93",
              y1: "193.04",
              x2: "162.93",
              y2: "224.79",
              "xlink:href": "#selfie-linear-gradient",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "selfie-linear-gradient-7",
                x1: "239.76",
                y1: "217.91",
                x2: "268.46",
                y2: "296.58",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#d1cdcb" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#9c9189" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "selfie-linear-gradient-8",
                x1: "250.44",
                y1: "110.88",
                x2: "250.62",
                y2: "110.86",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.13", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.28", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#1f1f1f" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.54", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.78", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "selfie-linear-gradient-9",
              x1: "350.67",
              y1: "222.64",
              x2: "344.25",
              y2: "256.64",
              "xlink:href": "#selfie-linear-gradient",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "selfie-linear-gradient-10",
                x1: "128.59",
                y1: "145.92",
                x2: "371.13",
                y2: "145.92",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.12", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.25", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#262626" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.58", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "0.8", "stop-color": "#050505" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "selfie-linear-gradient-11",
              x1: "42.96",
              y1: "516.06",
              x2: "143.05",
              y2: "327.12",
              "xlink:href": "#selfie-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "selfie-linear-gradient-12",
                x1: "-16.75",
                y1: "55.38",
                x2: "170.51",
                y2: "602.32",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#d1cdcb" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#857f7c" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "selfie-linear-gradient-13",
                x1: "56.96",
                y1: "492.05",
                x2: "21.16",
                y2: "536.46",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#d9d5d3" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#bdb3a9" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "selfie-linear-gradient-14",
              x1: "71.65",
              y1: "455.21",
              x2: "147.74",
              y2: "415.23",
              "xlink:href": "#selfie-linear-gradient-12",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "selfie-linear-gradient-15",
              x1: "85.92",
              y1: "334.11",
              x2: "87.45",
              y2: "326.61",
              "xlink:href": "#selfie-linear-gradient-3",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "selfie-cls-1" }, [
        _c("g", { attrs: { id: "Selfie" } }, [
          _c("ellipse", {
            staticClass: "selfie-cls-2",
            attrs: { cx: "251.72", cy: "231.13", rx: "154.46", ry: "210.89" },
          }),
          _vm._v(" "),
          _c("g", [
            _c("path", {
              staticClass: "selfie-cls-3",
              attrs: {
                d:
                  "M211.27,309.34,201.75,372a24.2,24.2,0,0,1-12.91,18.12L107.41,430.9l291.66,2.32L339,399.36a42.48,42.48,0,0,1-20.62-29.17l-11.11-58.67Z",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("path", {
                staticClass: "selfie-cls-4",
                attrs: {
                  d:
                    "M179.51,389.59s23.32,22.26,81.66,22.56,82.07-14.5,82.07-14.5l110.11,63.12,20.24,23.92L31.44,482.41l17.94-23.73Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "selfie-cls-5",
                attrs: {
                  d:
                    "M179.51,389.59s23.32,22.26,81.66,22.56,82.07-14.5,82.07-14.5l110.11,63.12,20.24,23.92L31.44,482.41l17.94-23.73Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "selfie-cls-5",
                attrs: {
                  d:
                    "M179.51,389.59s23.32,22.26,81.66,22.56,82.07-14.5,82.07-14.5l110.11,63.12,20.24,23.92L31.44,482.41l17.94-23.73Z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("path", {
              staticClass: "selfie-cls-6",
              attrs: {
                d: "M207.64,324.08s12,26.89,50.68,29,54.41-30.8,54.41-30.8Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "selfie-cls-7",
              attrs: {
                d: "M250.65,112a8.13,8.13,0,0,1-.27-2.28A6,6,0,0,1,250.65,112Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "selfie-cls-8",
              attrs: {
                d:
                  "M342.3,239.72a140.61,140.61,0,0,1-3.9,39.18c-3.38,14.47-12.64,30.22-24.65,42.57-.05.15-26.62,21.07-51.32,21.64-1,.08-2,.1-3.05.1a110.63,110.63,0,0,1-11.38-.42c-1.82-.2-3.68-.35-5.45-.67h-.07c-24-3.85-36.19-17.94-47.22-33-13.84-19-24.52-41.92-24.47-65.38l.07-48.46c.34-17-.07-20.24.34-40.55,0,0,37.64,7.79,89.51-20.07A43,43,0,0,0,288,146.11c27.92,1,33.94-11.52,34.06-14.36,1.71,7.23,3.28,59.09,16.41,66.07,9.82,6.19,4.13,1.82,4.08,3.11Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "selfie-cls-9",
              attrs: {
                d: "M164.75,214.4s-17.35.66-17,14.79S171.71,256,171.71,256Z",
              },
            }),
            _vm._v(" "),
            _c("polyline", {
              staticClass: "selfie-cls-10",
              attrs: { points: "255.59 218.29 245.15 275.72 254.14 282.88" },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "selfie-cls-11",
              attrs: { cx: "214.17", cy: "226.2", r: "7.91" },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "selfie-cls-11",
              attrs: { cx: "294.98", cy: "226.2", r: "7.91" },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "selfie-cls-12",
              attrs: {
                d:
                  "M246.61,305.65s23-1.2,34.39-6.59c0,0-4.57,9.2-17.19,10.15S246.61,305.65,246.61,305.65Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "selfie-cls-13",
              attrs: {
                d: "M250.65,112a8.13,8.13,0,0,1-.27-2.28A6,6,0,0,1,250.65,112Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "selfie-cls-14",
              attrs: {
                d:
                  "M342.59,209.55s17.34-.78,18.12,13.34-19.35,31.19-19.35,31.19Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "selfie-cls-15",
              attrs: {
                d:
                  "M171.21,252.06q1.56-7.91,3.1-15.81c-1.29-37.35,6.55-50.12,14.8-54.29,4.77-2.41,11-2.63,17.2-8.49,6.41-6.08,8.6-14.29,9.43-20.45,5,7.08,13.43,16.69,26.35,23,36.16,17.56,76-6.58,86.54,9,6.16,9.06-4,22,6.14,39.77a42.75,42.75,0,0,0,7.78,9.82c6.83-8.44,31.39-40.75,28.31-85A117.51,117.51,0,0,0,354.62,98,115.92,115.92,0,0,0,327,67.46c-5.89-4.57-50.78-38.46-90.52-24.2a119.3,119.3,0,0,0-38.58,23,72.65,72.65,0,0,1-12.31,10.12c-10.43,6.69-16.63,6.52-24.35,9.85-19,8.22-27.12,29-28.72,33.1-4.12,10.58-5,21.76-2.56,37.71C136,197.73,157.76,233,171.21,252.06Z",
              },
            }),
          ]),
          _vm._v(" "),
          _c("path", {
            staticClass: "selfie-cls-16",
            attrs: {
              d:
                "M255.3,447.34c-43.74,0-84.71-22.76-115.37-64.08-30.14-40.64-46.74-94.55-46.74-151.8s16.6-111.16,46.74-151.8c30.66-41.33,71.63-64.09,115.37-64.09S340,38.33,370.67,79.66c30.14,40.64,46.74,94.55,46.74,151.8s-16.6,111.16-46.74,151.8C340,424.58,299.05,447.34,255.3,447.34ZM255.3,31c-80.91,0-146.73,90-146.73,200.51S174.39,432,255.3,432,402,342,402,231.46,336.21,31,255.3,31Z",
            },
          }),
          _vm._v(" "),
          _c("g", [
            _c("rect", {
              staticClass: "selfie-cls-17",
              attrs: {
                x: "49.95",
                y: "305.76",
                width: "107.36",
                height: "191.55",
                rx: "10.13",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              staticClass: "selfie-cls-18",
              attrs: {
                x: "47.33",
                y: "303.55",
                width: "107.36",
                height: "191.55",
                rx: "10.13",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              staticClass: "selfie-cls-19",
              attrs: {
                x: "62.2",
                y: "319.31",
                width: "35.06",
                height: "20.62",
                rx: "10.31",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "selfie-cls-20",
              attrs: {
                d:
                  "M107.9,453.35c-16.38,4.76-29.41,10.7-29.28,11.08.06.19,3.15-.67,8.16-2.08,7.4-2.06,13.52-3.94,18-5.34,8.8-1.52,15.46-2.82,20-3.74,3.46-.71,7.18-1.5,8.79.4s.33,5.21-.09,6.36c-3.58,10-21.89,14.18-26.92,15.23L83.07,487.58l-19.24,11.8S51.41,528,15,523.64C-2.36,521.57.12,508,.12,508c10.6-21,25.81-41.48,37.54-55.86a94.31,94.31,0,0,1,43.17-29.79,81.67,81.67,0,0,1,11.64-3.22c7.63-1.17,39-3.81,40.64,2.21a11.28,11.28,0,0,1-7.6,13.73c.49-.1,5.82-1.12,8.31,2A6.06,6.06,0,0,1,135,441.5C134.14,447.33,121.44,449.42,107.9,453.35Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "selfie-cls-21",
              attrs: {
                d:
                  "M133.48,425s-3.37,6.39-8.12,7.69c-3.72,1-17.76,2.43-30,5.3-3.67.85-21.79,7.6-25,9.56h0l28.31-8.4,26.5-3.94A11.09,11.09,0,0,0,133.48,425Z",
              },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "selfie-cls-22",
              attrs: { cx: "86.84", cy: "329.62", r: "8.52" },
            }),
          ]),
          _vm._v(" "),
          _c("path", {
            staticClass: "selfie-cls-23",
            attrs: {
              d:
                "M473.59,484.69l-20.24-23.92-99.94-57.29a191.72,191.72,0,0,0,17.26-20.22c30.14-40.64,46.74-94.55,46.74-151.8s-16.6-111.16-46.74-151.8C340,38.33,299.05,15.57,255.3,15.57S170.59,38.33,139.93,79.66c-30.14,40.64-46.74,94.55-46.74,151.8a282.54,282.54,0,0,0,9.2,72.09H57.47a10.14,10.14,0,0,0-10.14,10.13V442a93.14,93.14,0,0,0-9.67,10.18C25.93,466.51,10.72,487,.12,508c0,0-2.48,13.58,14.89,15.65,36.4,4.36,48.82-24.26,48.82-24.26l3.37-2.07h80a10.14,10.14,0,0,0,10.14-10.13v-4.12Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }