var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 215 215",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-start-icon-fullcolor-linear-gradient",
                x1: "327.66",
                y1: "-516.79",
                x2: "412.13",
                y2: "-516.79",
                gradientTransform: "translate(-163.27 636.87) rotate(-7.5)",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#a6a6a6" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-start-icon-fullcolor-linear-gradient-2",
              x1: "325.18",
              y1: "-499.6",
              x2: "387.7",
              y2: "-499.6",
              "xlink:href": "#driving-start-icon-fullcolor-linear-gradient",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-start-icon-fullcolor-linear-gradient-3",
              x1: "14.11",
              y1: "169.77",
              x2: "523.21",
              y2: "166.17",
              gradientTransform: "matrix(1, 0, 0, 1, 0, 0)",
              "xlink:href": "#driving-start-icon-fullcolor-linear-gradient",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-start-icon-fullcolor-linear-gradient-4",
                x1: "47.54",
                y1: "105.07",
                x2: "47.54",
                y2: "98.03",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#e3ddd5" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#bdb3a9" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-start-icon-fullcolor-linear-gradient-5",
                x1: "-390.38",
                y1: "1092.96",
                x2: "-405.29",
                y2: "1062.42",
                gradientTransform:
                  "matrix(1.15, 0.01, -0.01, 1.15, 505.91, -1137.98)",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#a3a3a3" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.29", "stop-color": "#b2b2b2" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.66", "stop-color": "#bfbfbf" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#c4c4c4" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-start-icon-fullcolor-linear-gradient-6",
                x1: "45.47",
                y1: "91.56",
                x2: "49.7",
                y2: "97.81",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#3b3b3b" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#545454" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-start-icon-fullcolor-linear-gradient-7",
                x1: "47.19",
                y1: "67.4",
                x2: "47.25",
                y2: "67.47",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#999" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#6e6e6e" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-start-icon-fullcolor-linear-gradient-8",
              x1: "39.76",
              y1: "79.22",
              x2: "102.53",
              y2: "107.7",
              "xlink:href": "#driving-start-icon-fullcolor-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-start-icon-fullcolor-linear-gradient-9",
              x1: "36.19",
              y1: "78.44",
              x2: "35.38",
              y2: "82.72",
              "xlink:href": "#driving-start-icon-fullcolor-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-start-icon-fullcolor-linear-gradient-10",
                x1: "45.72",
                y1: "81.79",
                x2: "49.59",
                y2: "92.38",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#d1cdcb" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#9c9189" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-start-icon-fullcolor-linear-gradient-11",
                x1: "47.16",
                y1: "67.38",
                x2: "47.19",
                y2: "67.38",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.13", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.28", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#1f1f1f" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.54", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.78", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-start-icon-fullcolor-linear-gradient-12",
              x1: "60.65",
              y1: "82.43",
              x2: "59.79",
              y2: "87",
              "xlink:href": "#driving-start-icon-fullcolor-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-start-icon-fullcolor-linear-gradient-13",
                x1: "30.76",
                y1: "72.1",
                x2: "63.41",
                y2: "72.1",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.12", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.25", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#262626" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.58", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "0.8", "stop-color": "#050505" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-start-icon-fullcolor-linear-gradient-14",
              x1: "196.18",
              y1: "160.2",
              x2: "201.42",
              y2: "160.2",
              "xlink:href": "#driving-start-icon-fullcolor-linear-gradient-13",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-start-icon-fullcolor-linear-gradient-15",
              x1: "164.73",
              y1: "160.48",
              x2: "169.96",
              y2: "160.48",
              "xlink:href": "#driving-start-icon-fullcolor-linear-gradient-13",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-start-icon-fullcolor-linear-gradient-16",
              x1: "199.44",
              y1: "140.7",
              x2: "204.04",
              y2: "140.7",
              "xlink:href": "#driving-start-icon-fullcolor-linear-gradient-13",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-start-icon-fullcolor-linear-gradient-17",
              x1: "161.82",
              y1: "140.7",
              x2: "166.43",
              y2: "140.7",
              "xlink:href": "#driving-start-icon-fullcolor-linear-gradient-13",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-start-icon-fullcolor-linear-gradient-18",
              x1: "206.73",
              y1: "146.66",
              x2: "157.61",
              y2: "156.28",
              "xlink:href": "#driving-start-icon-fullcolor-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-start-icon-fullcolor-linear-gradient-19",
              x1: "175.29",
              y1: "149.76",
              x2: "190.57",
              y2: "149.76",
              "xlink:href": "#driving-start-icon-fullcolor-linear-gradient-13",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-start-icon-fullcolor-linear-gradient-20",
              x1: "17003.69",
              y1: "146.65",
              x2: "16954.57",
              y2: "156.28",
              gradientTransform: "matrix(-1, 0, 0, 1, 17162.82, 0)",
              "xlink:href": "#driving-start-icon-fullcolor-linear-gradient-4",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "driving-start-icon-fullcolor-cls-1" }, [
        _c("g", { attrs: { id: "driving-start-icon-fullcolor" } }, [
          _c("g", [
            _c("rect", {
              staticClass: "driving-start-icon-fullcolor-cls-2",
              attrs: {
                x: "2.12",
                y: "37.75",
                width: "211.06",
                height: "139.66",
                rx: "6.68",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("line", {
                staticClass: "driving-start-icon-fullcolor-cls-3",
                attrs: { x1: "93.39", y1: "76.14", x2: "178.61", y2: "76.28" },
              }),
              _vm._v(" "),
              _c("rect", {
                staticClass: "driving-start-icon-fullcolor-cls-4",
                attrs: {
                  x: "133.79",
                  y: "33.6",
                  width: "4.43",
                  height: "85.22",
                  transform: "translate(59.56 212.08) rotate(-89.9)",
                },
              }),
            ]),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "driving-start-icon-fullcolor-cls-4",
              attrs: {
                points:
                  "147.35 132.85 93.5 132.71 93.5 128.28 147.29 128.81 147.35 132.85",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("line", {
                staticClass: "driving-start-icon-fullcolor-cls-5",
                attrs: { x1: "93.36", y1: "94.95", x2: "156.44", y2: "95.06" },
              }),
              _vm._v(" "),
              _c("rect", {
                staticClass: "driving-start-icon-fullcolor-cls-4",
                attrs: {
                  x: "122.69",
                  y: "63.46",
                  width: "4.43",
                  height: "63.08",
                  transform: "translate(29.69 219.75) rotate(-89.9)",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                staticClass: "driving-start-icon-fullcolor-cls-4",
                attrs: {
                  x: "122.69",
                  y: "80.23",
                  width: "4.43",
                  height: "63.08",
                  transform: "translate(12.92 236.49) rotate(-89.9)",
                },
              }),
            ]),
            _vm._v(" "),
            _c("path", {
              staticClass: "driving-start-icon-fullcolor-cls-6",
              attrs: {
                d:
                  "M213.29,160.84l0,11a5.55,5.55,0,0,1-5.55,5.53L8.16,177.09A6.71,6.71,0,0,1,2,172.19V160.84Z",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("path", {
                attrs: {
                  d:
                    "M99.87,58.89a3.2,3.2,0,0,1-3.5,3.31H94.05V55.56h2.32A3.21,3.21,0,0,1,99.87,58.89Zm-3.55,2.18a2,2,0,0,0,2.19-2.18,2,2,0,0,0-2.19-2.21h-.94v4.39Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M103.37,55.56c1.6,0,2.4.92,2.4,2a1.93,1.93,0,0,1-1.51,1.92l1.59,2.69H104.3l-1.46-2.59h-.63V62.2h-1.33V55.56Zm-.05,1.1h-1.11v2h1.11c.75,0,1.08-.39,1.08-1S104.07,56.66,103.32,56.66Z",
                },
              }),
              _vm._v(" "),
              _c("path", { attrs: { d: "M107,55.56h1.34V62.2H107Z" } }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M114.18,55.56h1.42l-2.44,6.64h-1.61l-2.44-6.64h1.43l1.82,5.28Z",
                },
              }),
              _vm._v(" "),
              _c("path", { attrs: { d: "M116.4,55.56h1.34V62.2H116.4Z" } }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M124.74,55.55V62.2H123.4l-3-4.56V62.2h-1.34V55.55h1.34l3,4.57V55.55Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M132.22,57.56h-1.54a1.65,1.65,0,0,0-1.56-.86,2.18,2.18,0,0,0,.06,4.36,1.8,1.8,0,0,0,1.88-1.53h-2.28v-1h3.6v1.16a3.26,3.26,0,0,1-3.25,2.59,3.4,3.4,0,1,1,0-6.79A3.05,3.05,0,0,1,132.22,57.56Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: { d: "M137,55.56v5.59h2.19V62.2h-3.52V55.56Z" },
              }),
              _vm._v(" "),
              _c("path", { attrs: { d: "M140,55.56h1.33V62.2H140Z" } }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M145.71,55.47a3.14,3.14,0,0,1,3.12,2.15H147.3a1.68,1.68,0,0,0-1.6-.95,2.21,2.21,0,0,0,0,4.4,1.67,1.67,0,0,0,1.6-1h1.53a3.12,3.12,0,0,1-3.12,2.15,3.4,3.4,0,0,1,0-6.79Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M153.83,56.63h-2.52V58.3h2.24v1.06h-2.24v1.76h2.52V62.2H150V55.55h3.85Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M160.73,55.55V62.2H159.4l-3-4.56V62.2H155V55.55h1.34l3,4.57V55.55Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M165.09,55.47a3.14,3.14,0,0,1,3.13,2.15h-1.54a1.66,1.66,0,0,0-1.6-.95,2.21,2.21,0,0,0,0,4.4,1.65,1.65,0,0,0,1.6-1h1.54a3.13,3.13,0,0,1-3.13,2.15,3.4,3.4,0,0,1,0-6.79Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M173.22,56.63H170.7V58.3h2.23v1.06H170.7v1.76h2.52V62.2h-3.86V55.55h3.86Z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("rect", {
              staticClass: "driving-start-icon-fullcolor-cls-7",
              attrs: {
                x: "2.12",
                y: "37.66",
                width: "211.06",
                height: "139.66",
                rx: "5.23",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("rect", {
                staticClass: "driving-start-icon-fullcolor-cls-8",
                attrs: {
                  x: "15.89",
                  y: "53.47",
                  width: "62.99",
                  height: "63.93",
                },
              }),
              _vm._v(" "),
              _c("g", [
                _c("path", {
                  staticClass: "driving-start-icon-fullcolor-cls-9",
                  attrs: {
                    d:
                      "M41.89,94.1l-1.28,8.44A3.24,3.24,0,0,1,38.87,105l-11,5.49,39.26.31-8.09-4.56a5.68,5.68,0,0,1-2.77-3.92l-1.5-7.9Z",
                  },
                }),
                _vm._v(" "),
                _c("g", [
                  _c("path", {
                    staticClass: "driving-start-icon-fullcolor-cls-10",
                    attrs: {
                      d:
                        "M37.61,104.9s3.14,3,11,3,11-2,11-2l14.82,8.5,2.73,3.22-59.52-.31,2.42-3.19Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "driving-start-icon-fullcolor-cls-11",
                    attrs: {
                      d:
                        "M37.61,104.9s3.14,3,11,3,11-2,11-2l14.82,8.5,2.73,3.22-59.52-.31,2.42-3.19Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "driving-start-icon-fullcolor-cls-11",
                    attrs: {
                      d:
                        "M37.61,104.9s3.14,3,11,3,11-2,11-2l14.82,8.5,2.73,3.22-59.52-.31,2.42-3.19Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "driving-start-icon-fullcolor-cls-12",
                    attrs: {
                      d:
                        "M37.61,104.9s3.14,3,11,3,11-2,11-2l14.82,8.5,2.73,3.22-59.52-.31,2.42-3.19Z",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("path", {
                  staticClass: "driving-start-icon-fullcolor-cls-13",
                  attrs: {
                    d:
                      "M41.4,96.08S43,99.7,48.22,100a7.79,7.79,0,0,0,7.32-4.14Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "driving-start-icon-fullcolor-cls-14",
                  attrs: {
                    d: "M47.19,67.54a1,1,0,0,1,0-.31A.68.68,0,0,1,47.19,67.54Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "driving-start-icon-fullcolor-cls-15",
                  attrs: {
                    d:
                      "M59.53,84.72A19,19,0,0,1,59,90a12.83,12.83,0,0,1-3.32,5.73s-3.58,2.84-6.9,2.91l-.42,0c-.49,0-1,0-1.53-.06-.24,0-.49,0-.73-.09h0c-3.23-.52-4.87-2.42-6.36-4.44a15.39,15.39,0,0,1-3.29-8.8V78.74c0-2.28,0-2.72,0-5.45,0,0,5.07,1,12-2.71a5.84,5.84,0,0,0,3.68,1.55c3.76.13,4.57-1.56,4.59-1.94.23,1,.44,8,2.21,8.9,1.32.83.55.24.54.41Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "driving-start-icon-fullcolor-cls-16",
                  attrs: {
                    d: "M35.63,81.32s-2.34.09-2.29,2,3.22,3.6,3.22,3.6Z",
                  },
                }),
                _vm._v(" "),
                _c("polyline", {
                  staticClass: "driving-start-icon-fullcolor-cls-17",
                  attrs: { points: "47.85 81.84 46.45 89.57 47.66 90.53" },
                }),
                _vm._v(" "),
                _c("circle", {
                  staticClass: "driving-start-icon-fullcolor-cls-18",
                  attrs: { cx: "42.28", cy: "82.91", r: "1.06" },
                }),
                _vm._v(" "),
                _c("circle", {
                  staticClass: "driving-start-icon-fullcolor-cls-18",
                  attrs: { cx: "53.16", cy: "82.91", r: "1.06" },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "driving-start-icon-fullcolor-cls-2",
                  attrs: {
                    d:
                      "M46.65,93.6a16.18,16.18,0,0,0,4.62-.89A2.8,2.8,0,0,1,49,94.08,3,3,0,0,1,46.65,93.6Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "driving-start-icon-fullcolor-cls-19",
                  attrs: {
                    d: "M47.19,67.54a1,1,0,0,1,0-.31A.68.68,0,0,1,47.19,67.54Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "driving-start-icon-fullcolor-cls-20",
                  attrs: {
                    d: "M59.56,80.66s2.34-.1,2.44,1.8-2.6,4.2-2.6,4.2Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "driving-start-icon-fullcolor-cls-21",
                  attrs: {
                    d:
                      "M36.5,86.39c.14-.71.27-1.42.41-2.13-.17-5,.89-6.75,2-7.31a6.54,6.54,0,0,0,2.31-1.14,4.59,4.59,0,0,0,1.27-2.75A9.59,9.59,0,0,0,46,76.15c4.86,2.36,10.22-.89,11.65,1.21.82,1.22-.55,3,.82,5.35A5.6,5.6,0,0,0,59.56,84a16.81,16.81,0,0,0,3.81-11.44,15,15,0,0,0-5.91-11.05c-.79-.62-6.83-5.18-12.18-3.26a16.26,16.26,0,0,0-5.2,3.09,9.49,9.49,0,0,1-1.65,1.37,14.8,14.8,0,0,1-3.28,1.32,8.05,8.05,0,0,0-3.87,4.46,9.48,9.48,0,0,0-.34,5.07C31.76,79.07,34.69,83.82,36.5,86.39Z",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("rect", {
                staticClass: "driving-start-icon-fullcolor-cls-22",
                attrs: {
                  id: "Overlay",
                  x: "15.89",
                  y: "53.47",
                  width: "62.99",
                  height: "63.93",
                },
              }),
            ]),
            _vm._v(" "),
            _c("g", [
              _c("rect", {
                staticClass: "driving-start-icon-fullcolor-cls-8",
                attrs: {
                  x: "156.5",
                  y: "123.49",
                  width: "50.76",
                  height: "47.37",
                  rx: "6.4",
                },
              }),
              _vm._v(" "),
              _c("g", { staticClass: "driving-start-icon-fullcolor-cls-23" }, [
                _c("rect", {
                  staticClass: "driving-start-icon-fullcolor-cls-24",
                  attrs: {
                    x: "173.93",
                    y: "154.45",
                    width: "18.31",
                    height: "3.3",
                  },
                }),
                _vm._v(" "),
                _c("g", [
                  _c("g", [
                    _c("path", {
                      staticClass: "driving-start-icon-fullcolor-cls-25",
                      attrs: {
                        d:
                          "M200,141.89a25.45,25.45,0,0,0,1.25,2.37,11.54,11.54,0,0,1,1.62,7.68,34.5,34.5,0,0,1-.63,4.14,11.12,11.12,0,0,1-.84,2,3.58,3.58,0,0,1-2.6.79c-.87,0-1.73,0-2.6,0l-10.56.06-14.69.06c-.33,0-.66,0-1,0-.94,0-1.87,0-2.8,0a4,4,0,0,1-2.42-.59,5.65,5.65,0,0,1-1.29-3.23,19.88,19.88,0,0,1-.33-6.83,10.62,10.62,0,0,1,.88-2.73c.57-1.27,1.26-2.49,1.9-3.73l.51-1.14c.88-1.74,1.67-3.52,2.64-5.2,2-3.41,1.61-3.09,5.34-3.36s7.43-.39,11.14-.38c2.62,0,5.24.31,7.85.46a2.52,2.52,0,0,1,2.18,1.35c1.15,2.07,2.27,4.14,3.4,6.21.16.31.32.62.49.93Zm-2.09-1c0-.2,0-.25,0-.29l-3.08-6.74a1.07,1.07,0,0,0-1.05-.65c-5.58,0-11.16-.06-16.74-.06-1.61,0-3.23.14-4.85.18a1.22,1.22,0,0,0-1.24.87c-.27.67-.59,1.32-.88,2-.7,1.57-1.39,3.15-2.09,4.71Zm-8.38,16.82c.5,0,.71-.17.69-.62s0-1.1,0-1.65c0-.39-.16-.59-.62-.58l-13.24,0c-.47,0-.68.2-.67.62s0,1.06,0,1.6.12.66.75.66C178.59,157.75,187.31,157.66,189.51,157.68Zm6.2-7c.92,0,1.84.05,2.76,0a2.44,2.44,0,0,0,1.28-.5,4.09,4.09,0,0,0,1.3-3.62c-.05-.83-.39-1.1-1.2-.9l-4.2,1c-2.33.58-2.33.58-3.5,2.68l0,.06c-.41.76-.11,1.28.77,1.29S194.77,150.67,195.71,150.67Zm-25.6,0c1,0,1.93,0,2.89,0s1.16-.53.76-1.25-.72-1.26-1.13-1.86a1.08,1.08,0,0,0-.6-.45q-3.1-.8-6.24-1.54a.71.71,0,0,0-.94.66,4.51,4.51,0,0,0,.85,3.53,2.59,2.59,0,0,0,2.17.91C168.7,150.6,169.28,150.67,170.11,150.67Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "driving-start-icon-fullcolor-cls-26",
                      attrs: {
                        d:
                          "M196.18,158.86c.87,0,1.73-.06,2.6,0a3.58,3.58,0,0,0,2.6-.79c0,1,.05,2.09,0,3.13a1.08,1.08,0,0,1-1.19,1.18c-1,0-1.92,0-2.88,0a1,1,0,0,1-1.14-1.14C196.17,160.43,196.18,159.65,196.18,158.86Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "driving-start-icon-fullcolor-cls-27",
                      attrs: {
                        d:
                          "M164.73,158.41a4,4,0,0,0,2.42.59c.93,0,1.86,0,2.8,0,0,.79,0,1.58,0,2.36a1,1,0,0,1-1.13,1.15c-1,0-1.93,0-2.89,0a1.06,1.06,0,0,1-1.18-1.18C164.73,160.38,164.74,159.39,164.73,158.41Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "driving-start-icon-fullcolor-cls-28",
                      attrs: {
                        d:
                          "M200.13,142.25c-.18-.37-.51-1.11-.69-1.49a1.07,1.07,0,0,0,.85-1,.6.6,0,0,1,.46-.53,5.37,5.37,0,0,1,2.39.16,1.34,1.34,0,0,1,.9,1.27,1.09,1.09,0,0,1-.67.89C202.31,141.85,201.21,142,200.13,142.25Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "driving-start-icon-fullcolor-cls-29",
                      attrs: {
                        d:
                          "M165.73,142.25c.18-.37.52-1.11.7-1.49a1.07,1.07,0,0,1-.86-1,.59.59,0,0,0-.46-.53,5.37,5.37,0,0,0-2.39.16,1.34,1.34,0,0,0-.9,1.27,1.09,1.09,0,0,0,.67.89C163.55,141.85,164.65,142,165.73,142.25Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "driving-start-icon-fullcolor-cls-30",
                      attrs: {
                        d:
                          "M197.89,140.86H168c.7-1.56,1.39-3.14,2.09-4.71.29-.66.61-1.31.88-2a1.22,1.22,0,0,1,1.24-.87c1.62,0,3.24-.17,4.85-.18,5.58,0,11.16,0,16.74.06a1.07,1.07,0,0,1,1.05.65l3.08,6.74S197.89,140.66,197.89,140.86Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "driving-start-icon-fullcolor-cls-31",
                      attrs: {
                        d:
                          "M195.71,150.67c-.94,0-1.88,0-2.82,0s-1.18-.53-.77-1.29l0-.06c1.17-2.1,1.17-2.1,3.5-2.68l4.2-1c.81-.2,1.15.07,1.2.9a4.09,4.09,0,0,1-1.3,3.62,2.44,2.44,0,0,1-1.28.5C197.55,150.72,196.63,150.67,195.71,150.67Z",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "driving-start-icon-fullcolor-cls-32",
                    attrs: {
                      d:
                        "M189,151.53a49.8,49.8,0,0,1-12.2,0,1.67,1.67,0,0,1-1.52-1.88c0-.2.06-.41.1-.61a1.7,1.7,0,0,1,1.9-1.4,47,47,0,0,0,11.24,0,1.72,1.72,0,0,1,1.91,1.4l.09.61A1.67,1.67,0,0,1,189,151.53Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("line", {
                    staticClass: "driving-start-icon-fullcolor-cls-33",
                    attrs: {
                      x1: "176.67",
                      y1: "147.73",
                      x2: "176.67",
                      y2: "151.46",
                    },
                  }),
                  _vm._v(" "),
                  _c("line", {
                    staticClass: "driving-start-icon-fullcolor-cls-33",
                    attrs: {
                      x1: "179.14",
                      y1: "147.87",
                      x2: "179.14",
                      y2: "151.76",
                    },
                  }),
                  _vm._v(" "),
                  _c("line", {
                    staticClass: "driving-start-icon-fullcolor-cls-33",
                    attrs: {
                      x1: "181.61",
                      y1: "148.02",
                      x2: "181.61",
                      y2: "151.9",
                    },
                  }),
                  _vm._v(" "),
                  _c("line", {
                    staticClass: "driving-start-icon-fullcolor-cls-33",
                    attrs: {
                      x1: "184.08",
                      y1: "148.02",
                      x2: "184.08",
                      y2: "151.87",
                    },
                  }),
                  _vm._v(" "),
                  _c("line", {
                    staticClass: "driving-start-icon-fullcolor-cls-33",
                    attrs: {
                      x1: "186.56",
                      y1: "148.02",
                      x2: "186.56",
                      y2: "151.76",
                    },
                  }),
                  _vm._v(" "),
                  _c("line", {
                    staticClass: "driving-start-icon-fullcolor-cls-33",
                    attrs: {
                      x1: "189.03",
                      y1: "147.73",
                      x2: "189.03",
                      y2: "151.46",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("path", {
                  staticClass: "driving-start-icon-fullcolor-cls-34",
                  attrs: {
                    d:
                      "M167.4,150.64a2.47,2.47,0,0,1-1.29-.5,4.11,4.11,0,0,1-1.3-3.62c.05-.83.39-1.1,1.2-.9,1.41.33,2.81.67,4.21,1,2.32.57,2.32.57,3.49,2.67l0,.06c.41.76.11,1.28-.76,1.29h-2.83C169.23,150.66,168.31,150.71,167.4,150.64Z",
                  },
                }),
                _vm._v(" "),
                _c("circle", {
                  staticClass: "driving-start-icon-fullcolor-cls-30",
                  attrs: { cx: "198.48", cy: "148.35", r: "1.15" },
                }),
                _vm._v(" "),
                _c("circle", {
                  staticClass: "driving-start-icon-fullcolor-cls-30",
                  attrs: { cx: "194.69", cy: "148.97", r: "0.81" },
                }),
                _vm._v(" "),
                _c("circle", {
                  staticClass: "driving-start-icon-fullcolor-cls-30",
                  attrs: { cx: "167.56", cy: "148.33", r: "1.15" },
                }),
                _vm._v(" "),
                _c("circle", {
                  staticClass: "driving-start-icon-fullcolor-cls-30",
                  attrs: { cx: "171.34", cy: "148.95", r: "0.81" },
                }),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }