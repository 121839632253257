var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAvailable
    ? _c("ui-button", {
        attrs: { additional: "", label: "App.Omnichannel" },
        on: { click: _vm.click },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }