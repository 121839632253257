import { httpClient } from '@ondato/http-client'
import Store from '@src/store'
import actions from '@src/store/actions'

import { uuid } from '@src/scripts/helpers'

const correlationId = uuid()

export const ondatoHttpClient = () => {
  const { accessToken } = Store.getters
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
    'Correlation-Id': correlationId
  }
  return httpClient({
    baseUrl: BaseUrl.api,
    headers,
    unauthorizedCallback: (status) => {
      if (status === 401) {
        Store.dispatch(actions.updateToken)
      }
    }
  })
}
