var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 494.84 389.59",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-license-front-linear-gradient",
                x1: "58.99",
                y1: "304.79",
                x2: "1086.16",
                y2: "297.53",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#a6a6a6" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-license-front-linear-gradient-2",
                x1: "239.53",
                y1: "350.74",
                x2: "261.5",
                y2: "396.12",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#3b3b3b" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#2b2829" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-license-front-linear-gradient-3",
                x1: "123.22",
                y1: "174.84",
                x2: "123.22",
                y2: "160.06",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#e3ddd5" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#bdb3a9" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-license-front-linear-gradient-4",
                x1: "-250.99",
                y1: "1037.31",
                x2: "-282.27",
                y2: "973.25",
                gradientTransform:
                  "matrix(1.15, 0.01, -0.01, 1.15, 422.83, -986.3)",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#a3a3a3" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.29", "stop-color": "#b2b2b2" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.66", "stop-color": "#bfbfbf" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#c4c4c4" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-license-front-linear-gradient-5",
                x1: "118.88",
                y1: "146.49",
                x2: "127.74",
                y2: "159.61",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#3b3b3b" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#545454" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-license-front-linear-gradient-6",
                x1: "122.48",
                y1: "95.82",
                x2: "122.61",
                y2: "95.96",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#999" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#6e6e6e" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-license-front-linear-gradient-7",
              x1: "106.89",
              y1: "120.61",
              x2: "238.57",
              y2: "180.36",
              "xlink:href": "#driving-license-front-linear-gradient-3",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-license-front-linear-gradient-8",
              x1: "99.41",
              y1: "118.98",
              x2: "97.72",
              y2: "127.94",
              "xlink:href": "#driving-license-front-linear-gradient-3",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-license-front-linear-gradient-9",
                x1: "119.41",
                y1: "126",
                x2: "127.51",
                y2: "148.21",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#d1cdcb" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#9c9189" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-license-front-linear-gradient-10",
                x1: "122.43",
                y1: "95.78",
                x2: "122.48",
                y2: "95.78",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.13", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.28", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#1f1f1f" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.54", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.78", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-license-front-linear-gradient-11",
              x1: "150.72",
              y1: "127.34",
              x2: "148.91",
              y2: "136.94",
              "xlink:href": "#driving-license-front-linear-gradient-3",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-license-front-linear-gradient-12",
                x1: "88.02",
                y1: "105.68",
                x2: "156.5",
                y2: "105.68",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.12", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.25", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#262626" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.58", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "0.8", "stop-color": "#050505" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-license-front-linear-gradient-13",
              x1: "399.66",
              y1: "285.55",
              x2: "399.66",
              y2: "152.66",
              gradientTransform: "matrix(1, 0, 0, 1, 0, 0)",
              "xlink:href": "#driving-license-front-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-license-front-linear-gradient-14",
              x1: "426.98",
              y1: "285.93",
              x2: "437.79",
              y2: "285.93",
              "xlink:href": "#driving-license-front-linear-gradient-12",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-license-front-linear-gradient-15",
              x1: "362.13",
              y1: "286.49",
              x2: "372.9",
              y2: "286.49",
              "xlink:href": "#driving-license-front-linear-gradient-12",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-license-front-linear-gradient-16",
              x1: "445.64",
              y1: "242.74",
              x2: "418.72",
              y2: "252.26",
              "xlink:href": "#driving-license-front-linear-gradient-12",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-license-front-linear-gradient-17",
              x1: "352.43",
              y1: "244.03",
              x2: "387.65",
              y2: "249.6",
              "xlink:href": "#driving-license-front-linear-gradient-12",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-license-front-linear-gradient-18",
              x1: "448.75",
              y1: "258",
              x2: "347.45",
              y2: "277.85",
              "xlink:href": "#driving-license-front-linear-gradient-3",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-license-front-linear-gradient-19",
              x1: "383.87",
              y1: "264.39",
              x2: "415.45",
              y2: "264.39",
              "xlink:href": "#driving-license-front-linear-gradient-12",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-license-front-linear-gradient-20",
              x1: "17824.63",
              y1: "257.98",
              x2: "17723.32",
              y2: "277.83",
              gradientTransform: "matrix(-1, 0, 0, 1, 18175.2, 0)",
              "xlink:href": "#driving-license-front-linear-gradient-3",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "driving-license-front-cls-1" }, [
        _c("g", { attrs: { id: "Driving-license-front" } }, [
          _c("rect", {
            staticClass: "driving-license-front-cls-2",
            attrs: {
              x: "34.8",
              y: "38.43",
              width: "425.84",
              height: "281.79",
              rx: "10.87",
            },
          }),
          _vm._v(" "),
          _c("g", [
            _c("rect", {
              staticClass: "driving-license-front-cls-3",
              attrs: {
                x: "300.46",
                y: "30.05",
                width: "8.93",
                height: "171.93",
                transform: "translate(188.4 420.75) rotate(-89.9)",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "driving-license-front-cls-3",
              attrs: {
                points:
                  "327.83 230.31 219.17 230.02 219.19 221.09 327.7 222.15 327.83 230.31",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("rect", {
                staticClass: "driving-license-front-cls-3",
                attrs: {
                  x: "278.06",
                  y: "90.3",
                  width: "8.93",
                  height: "127.28",
                  transform: "translate(128.11 436.21) rotate(-89.9)",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                staticClass: "driving-license-front-cls-3",
                attrs: {
                  x: "278.06",
                  y: "124.13",
                  width: "8.93",
                  height: "127.28",
                  transform: "translate(94.28 469.99) rotate(-89.9)",
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("path", {
            staticClass: "driving-license-front-cls-4",
            attrs: {
              d:
                "M460.86,286.77l-.1,22.3a11.17,11.17,0,0,1-11.19,11.15L47,319.55c-6.17,0-11.16-5-12.41-9.88v-22.9Z",
            },
          }),
          _vm._v(" "),
          _c("g", { attrs: { id: "FrameLine" } }, [
            _c("polygon", {
              staticClass: "driving-license-front-cls-5",
              attrs: {
                points:
                  "14.55 73.91 0 73.91 0 0 73.91 0 73.91 14.55 14.55 14.55 14.55 73.91",
              },
            }),
          ]),
          _vm._v(" "),
          _c("g", { attrs: { id: "FrameLine-2", "data-name": "FrameLine" } }, [
            _c("polygon", {
              staticClass: "driving-license-front-cls-5",
              attrs: {
                points:
                  "494.84 358.47 420.92 358.47 420.92 343.92 480.29 343.92 480.29 284.55 494.84 284.55 494.84 358.47",
              },
            }),
          ]),
          _vm._v(" "),
          _c("g", { attrs: { id: "FrameLine-3", "data-name": "FrameLine" } }, [
            _c("polygon", {
              staticClass: "driving-license-front-cls-5",
              attrs: {
                points:
                  "494.84 73.91 480.29 73.91 480.29 14.55 420.92 14.55 420.92 0 494.84 0 494.84 73.91",
              },
            }),
          ]),
          _vm._v(" "),
          _c("g", { attrs: { id: "FrameLine-4", "data-name": "FrameLine" } }, [
            _c("polygon", {
              staticClass: "driving-license-front-cls-5",
              attrs: {
                points:
                  "73.91 358.47 0 358.47 0 284.55 14.55 284.55 14.55 343.92 73.91 343.92 73.91 358.47",
              },
            }),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("path", {
              staticClass: "driving-license-front-cls-5",
              attrs: {
                d:
                  "M275.78,349.69v35.46a4.44,4.44,0,0,1-4.44,4.44h-48a4.43,4.43,0,0,1-4.43-4.43V349.69a4.44,4.44,0,0,1,4.44-4.44h9.78a4.44,4.44,0,0,0,3.53-1.74l2.74-3.56a6.74,6.74,0,0,1,5.35-2.64H251a6.74,6.74,0,0,1,5.34,2.64l2.74,3.56a4.46,4.46,0,0,0,3.53,1.74h8.72A4.44,4.44,0,0,1,275.78,349.69Z",
              },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "driving-license-front-cls-6",
              attrs: { cx: "247.06", cy: "366.3", r: "15.62" },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "driving-license-front-cls-7",
              attrs: { cx: "247.06", cy: "366.3", r: "10.99" },
            }),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("path", {
              attrs: {
                d:
                  "M232.05,81.07c0,4.06-2.79,6.69-7.08,6.69h-4.69V74.34H225C229.26,74.34,232.05,77,232.05,81.07Zm-7.17,4.4c2.82,0,4.42-1.61,4.42-4.4s-1.6-4.46-4.42-4.46H223v8.86Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M239.1,74.34c3.23,0,4.84,1.87,4.84,4.12a3.87,3.87,0,0,1-3.05,3.86l3.21,5.44H241l-3-5.23h-1.27v5.23h-2.69V74.34Zm-.1,2.23h-2.25v3.94H239c1.5,0,2.18-.78,2.18-2S240.5,76.57,239,76.57Z",
              },
            }),
            _vm._v(" "),
            _c("path", { attrs: { d: "M246.38,74.34h2.69V87.76h-2.69Z" } }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M260.91,74.34h2.87l-4.92,13.42h-3.27l-4.92-13.42h2.88L257.24,85Z",
              },
            }),
            _vm._v(" "),
            _c("path", { attrs: { d: "M265.39,74.34h2.69V87.76h-2.69Z" } }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M282.21,74.32V87.76h-2.69l-6.09-9.21v9.21h-2.7V74.32h2.7l6.09,9.23V74.32Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M297.3,78.38h-3.09a3.37,3.37,0,0,0-3.16-1.73c-2.4,0-4.07,1.71-4.07,4.38s1.69,4.42,4.19,4.42a3.64,3.64,0,0,0,3.8-3.09h-4.61V80.3h7.27v2.35a6.56,6.56,0,0,1-6.56,5.23,6.86,6.86,0,0,1,0-13.71A6.16,6.16,0,0,1,297.3,78.38Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: { d: "M306.89,74.34V85.63h4.42v2.13H304.2V74.34Z" },
            }),
            _vm._v(" "),
            _c("path", { attrs: { d: "M313,74.34h2.69V87.76H313Z" } }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M324.51,74.17a6.32,6.32,0,0,1,6.31,4.34h-3.1a3.38,3.38,0,0,0-3.23-1.92c-2.34,0-4,1.71-4,4.44s1.67,4.44,4,4.44a3.35,3.35,0,0,0,3.23-1.94h3.1a6.31,6.31,0,0,1-6.31,4.35,6.86,6.86,0,0,1,0-13.71Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M340.91,76.51h-5.1v3.37h4.52V82h-4.52v3.56h5.1v2.19h-7.79V74.32h7.79Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M354.83,74.32V87.76h-2.7L346,78.55v9.21h-2.69V74.32H346l6.09,9.23V74.32Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M363.63,74.17a6.31,6.31,0,0,1,6.3,4.34h-3.09a3.4,3.4,0,0,0-3.23-1.92c-2.35,0-4,1.71-4,4.44s1.67,4.44,4,4.44a3.37,3.37,0,0,0,3.23-1.94h3.09a6.29,6.29,0,0,1-6.3,4.35,6.86,6.86,0,0,1,0-13.71Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M380,76.51h-5.09v3.37h4.51V82h-4.51v3.56H380v2.19h-7.79V74.32H380Z",
              },
            }),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("path", {
              attrs: {
                d:
                  "M216.08,25.47c-2.52-2.42-3.92-6.38-3.92-10.63s1.4-8.4,4.21-10.92h2.71v.25a14.8,14.8,0,0,0-4.33,10.67,15,15,0,0,0,4,10.37v.26Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M220.75,8.27H229v2.17h-5.6v3.44h4.29V16h-4.29v5.68h-2.69Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M236,8.27c3.23,0,4.85,1.86,4.85,4.11a3.87,3.87,0,0,1-3.06,3.87L241,21.69h-3.11l-3-5.23h-1.27v5.23h-2.69V8.27Zm-.09,2.23h-2.25v3.94h2.25c1.5,0,2.17-.79,2.17-2S237.38,10.5,235.88,10.5Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M249.49,21.82a6.75,6.75,0,0,1-6.89-6.88,6.88,6.88,0,0,1,13.75,0A6.71,6.71,0,0,1,249.49,21.82Zm0-2.4c2.42,0,4.09-1.75,4.09-4.48s-1.67-4.44-4.09-4.44-4.12,1.71-4.12,4.44S247,19.42,249.49,19.42Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M269.82,8.25V21.69h-2.69L261,12.48v9.21h-2.7V8.25H261l6.09,9.23V8.25Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M271.76,8.27h9.84v2.17H278V21.69h-2.69V10.44h-3.58Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M282.87,25.47v-.26a15,15,0,0,0,4-10.37,14.8,14.8,0,0,0-4.33-10.67V3.92h2.71c2.81,2.52,4.21,6.65,4.21,10.92s-1.4,8.21-3.92,10.63Z",
              },
            }),
          ]),
          _vm._v(" "),
          _c("rect", {
            staticClass: "driving-license-front-cls-8",
            attrs: {
              x: "35.38",
              y: "38.43",
              width: "425.84",
              height: "281.79",
              rx: "10.87",
            },
          }),
          _vm._v(" "),
          _c("g", [
            _c("rect", {
              staticClass: "driving-license-front-cls-9",
              attrs: {
                x: "56.71",
                y: "66.78",
                width: "131.94",
                height: "133.91",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "driving-license-front-cls-10",
              attrs: {
                d:
                  "M111.36,151.82l-2.68,17.7a6.82,6.82,0,0,1-3.65,5.11L82,186.14l82.35.65-17-9.56a12,12,0,0,1-5.82-8.24l-3.14-16.56Z",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("path", {
                staticClass: "driving-license-front-cls-11",
                attrs: {
                  d:
                    "M102.4,174.47s6.58,6.29,23,6.37,23.17-4.09,23.17-4.09l31.09,17.82,5.72,6.75-124.84-.64,5.07-6.7Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "driving-license-front-cls-12",
                attrs: {
                  d:
                    "M102.4,174.47s6.58,6.29,23,6.37,23.17-4.09,23.17-4.09l31.09,17.82,5.72,6.75-124.84-.64,5.07-6.7Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "driving-license-front-cls-12",
                attrs: {
                  d:
                    "M102.4,174.47s6.58,6.29,23,6.37,23.17-4.09,23.17-4.09l31.09,17.82,5.72,6.75-124.84-.64,5.07-6.7Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "driving-license-front-cls-13",
                attrs: {
                  d:
                    "M102.4,174.47s6.58,6.29,23,6.37,23.17-4.09,23.17-4.09l31.09,17.82,5.72,6.75-124.84-.64,5.07-6.7Z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("path", {
              staticClass: "driving-license-front-cls-14",
              attrs: {
                d: "M110.34,156s3.38,7.59,14.31,8.19,15.36-8.7,15.36-8.7Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "driving-license-front-cls-15",
              attrs: {
                d:
                  "M122.48,96.1a2.36,2.36,0,0,1-.07-.64A1.63,1.63,0,0,1,122.48,96.1Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "driving-license-front-cls-16",
              attrs: {
                d:
                  "M148.36,132.16a39.9,39.9,0,0,1-1.1,11.06,27.22,27.22,0,0,1-7,12s-7.52,5.95-14.49,6.11c-.29,0-.57,0-.86,0-1,0-2.11,0-3.22-.12-.51-.06-1-.1-1.53-.19h0C113.41,160,110,156,106.85,151.75c-3.91-5.35-6.93-11.83-6.91-18.46l0-13.68c.09-4.78,0-5.71.09-11.45,0,0,10.63,2.2,25.27-5.66a12.14,12.14,0,0,0,7.72,3.23c7.88.29,9.58-3.25,9.62-4,.48,2,.92,16.68,4.63,18.65,2.77,1.75,1.16.51,1.15.88Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "driving-license-front-cls-17",
              attrs: { d: "M98.23,125s-4.9.19-4.79,4.18,6.76,7.56,6.76,7.56Z" },
            }),
            _vm._v(" "),
            _c("polyline", {
              staticClass: "driving-license-front-cls-18",
              attrs: { points: "123.88 126.11 120.93 142.32 123.47 144.34" },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "driving-license-front-cls-19",
              attrs: { cx: "112.18", cy: "128.34", r: "2.23" },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "driving-license-front-cls-19",
              attrs: { cx: "135", cy: "128.34", r: "2.23" },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "driving-license-front-cls-2",
              attrs: {
                d:
                  "M121.34,150.77s6.49-.34,9.71-1.86a5.9,5.9,0,0,1-4.85,2.87C122.63,152.05,121.34,150.77,121.34,150.77Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "driving-license-front-cls-20",
              attrs: {
                d:
                  "M122.48,96.1a2.36,2.36,0,0,1-.07-.64A1.63,1.63,0,0,1,122.48,96.1Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "driving-license-front-cls-21",
              attrs: {
                d: "M148.44,123.64s4.9-.22,5.12,3.77-5.47,8.8-5.47,8.8Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "driving-license-front-cls-22",
              attrs: {
                d:
                  "M100.06,135.64l.87-4.46c-.36-10.55,1.85-14.15,4.18-15.33,1.35-.68,3.11-.74,4.86-2.4a9.58,9.58,0,0,0,2.66-5.77,19.87,19.87,0,0,0,7.44,6.49c10.2,5,21.44-1.86,24.43,2.53,1.74,2.56-1.14,6.23,1.73,11.23a11.77,11.77,0,0,0,2.2,2.77c1.93-2.38,8.86-11.5,8-24a33.23,33.23,0,0,0-4.58-14.56A32.82,32.82,0,0,0,144,83.53c-1.66-1.29-14.34-10.86-25.56-6.84a33.77,33.77,0,0,0-10.89,6.49A20.22,20.22,0,0,1,104.1,86c-2.94,1.89-4.69,1.84-6.87,2.78-5.37,2.32-7.66,8.18-8.11,9.35-1.16,3-1.4,6.14-.72,10.64C90.13,120.3,96.26,130.26,100.06,135.64Z",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              staticClass: "driving-license-front-cls-23",
              attrs: {
                id: "Overlay",
                x: "56.5",
                y: "66.78",
                width: "131.51",
                height: "133.91",
              },
            }),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("rect", {
              staticClass: "driving-license-front-cls-9",
              attrs: {
                x: "345.15",
                y: "210.21",
                width: "104.69",
                height: "97.71",
                rx: "11.49",
              },
            }),
            _vm._v(" "),
            _c("g", { staticClass: "driving-license-front-cls-24" }, [
              _c("rect", {
                staticClass: "driving-license-front-cls-25",
                attrs: {
                  x: "381.09",
                  y: "274.06",
                  width: "37.77",
                  height: "6.82",
                },
              }),
              _vm._v(" "),
              _c("g", [
                _c("g", [
                  _c("path", {
                    staticClass: "driving-license-front-cls-26",
                    attrs: {
                      d:
                        "M434.82,248.16a51.7,51.7,0,0,0,2.59,4.89c3.22,4.86,3.82,10.24,3.33,15.83a70.13,70.13,0,0,1-1.3,8.54,21.94,21.94,0,0,1-1.73,4,7.45,7.45,0,0,1-5.36,1.65c-1.79-.08-3.58,0-5.37.06l-21.77.11-30.3.13c-.68,0-1.35,0-2,.06-1.92,0-3.85-.09-5.77,0a8,8,0,0,1-5-1.22c-1.89-1.82-2.2-4.28-2.66-6.65a40.8,40.8,0,0,1-.69-14.1,22.52,22.52,0,0,1,1.82-5.62c1.17-2.62,2.59-5.14,3.91-7.7.35-.78.71-1.56,1.06-2.35,1.8-3.58,3.45-7.25,5.44-10.73,4-7,3.33-6.36,11-6.93s15.32-.79,23-.79c5.39,0,10.79.66,16.19,1a5.16,5.16,0,0,1,4.49,2.79c2.36,4.26,4.67,8.54,7,12.81.35.63.68,1.27,1,1.91S434.45,247.38,434.82,248.16Zm-4.3-2.12a3.29,3.29,0,0,0,0-.6q-3.18-6.94-6.37-13.9a2.18,2.18,0,0,0-2.15-1.35c-11.51,0-23-.11-34.52-.11-3.34,0-6.67.28-10,.36a2.55,2.55,0,0,0-2.56,1.8c-.55,1.38-1.21,2.72-1.81,4.08L368.79,246Zm-17.3,34.68c1,0,1.47-.34,1.43-1.27,0-1.13,0-2.27,0-3.4,0-.81-.32-1.21-1.28-1.21l-27.3.1c-1,0-1.4.41-1.38,1.27,0,1.1,0,2.2,0,3.3s.25,1.36,1.56,1.37C390.72,280.88,408.69,280.69,413.22,280.72ZM426,266.27c1.9,0,3.8.11,5.68,0a5,5,0,0,0,2.66-1c2.3-1.93,2.83-4.61,2.67-7.47-.1-1.72-.81-2.26-2.47-1.87-2.89.69-5.79,1.39-8.68,2.11-4.79,1.18-4.78,1.18-7.2,5.52a.75.75,0,0,1-.07.12c-.84,1.57-.22,2.63,1.59,2.65S424.08,266.27,426,266.27Zm-52.8,0c2,0,4,0,5.95,0,1.74,0,2.4-1.09,1.57-2.58-.73-1.3-1.49-2.58-2.32-3.81a2.38,2.38,0,0,0-1.23-.95q-6.42-1.64-12.88-3.16a1.46,1.46,0,0,0-1.95,1.34,9.35,9.35,0,0,0,1.76,7.3,5.35,5.35,0,0,0,4.48,1.86C370.31,266.13,371.5,266.27,373.22,266.27Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "driving-license-front-cls-12",
                    attrs: {
                      d:
                        "M434.82,248.16a51.7,51.7,0,0,0,2.59,4.89c3.22,4.86,3.82,10.24,3.33,15.83a70.13,70.13,0,0,1-1.3,8.54,21.94,21.94,0,0,1-1.73,4,7.45,7.45,0,0,1-5.36,1.65c-1.79-.08-3.58,0-5.37.06l-21.77.11-30.3.13c-.68,0-1.35,0-2,.06-1.92,0-3.85-.09-5.77,0a8,8,0,0,1-5-1.22c-1.89-1.82-2.2-4.28-2.66-6.65a40.8,40.8,0,0,1-.69-14.1,22.52,22.52,0,0,1,1.82-5.62c1.17-2.62,2.59-5.14,3.91-7.7.35-.78.71-1.56,1.06-2.35,1.8-3.58,3.45-7.25,5.44-10.73,4-7,3.33-6.36,11-6.93s15.32-.79,23-.79c5.39,0,10.79.66,16.19,1a5.16,5.16,0,0,1,4.49,2.79c2.36,4.26,4.67,8.54,7,12.81.35.63.68,1.27,1,1.91S434.45,247.38,434.82,248.16Zm-4.3-2.12a3.29,3.29,0,0,0,0-.6q-3.18-6.94-6.37-13.9a2.18,2.18,0,0,0-2.15-1.35c-11.51,0-23-.11-34.52-.11-3.34,0-6.67.28-10,.36a2.55,2.55,0,0,0-2.56,1.8c-.55,1.38-1.21,2.72-1.81,4.08L368.79,246Zm-17.3,34.68c1,0,1.47-.34,1.43-1.27,0-1.13,0-2.27,0-3.4,0-.81-.32-1.21-1.28-1.21l-27.3.1c-1,0-1.4.41-1.38,1.27,0,1.1,0,2.2,0,3.3s.25,1.36,1.56,1.37C390.72,280.88,408.69,280.69,413.22,280.72ZM426,266.27c1.9,0,3.8.11,5.68,0a5,5,0,0,0,2.66-1c2.3-1.93,2.83-4.61,2.67-7.47-.1-1.72-.81-2.26-2.47-1.87-2.89.69-5.79,1.39-8.68,2.11-4.79,1.18-4.78,1.18-7.2,5.52a.75.75,0,0,1-.07.12c-.84,1.57-.22,2.63,1.59,2.65S424.08,266.27,426,266.27Zm-52.8,0c2,0,4,0,5.95,0,1.74,0,2.4-1.09,1.57-2.58-.73-1.3-1.49-2.58-2.32-3.81a2.38,2.38,0,0,0-1.23-.95q-6.42-1.64-12.88-3.16a1.46,1.46,0,0,0-1.95,1.34,9.35,9.35,0,0,0,1.76,7.3,5.35,5.35,0,0,0,4.48,1.86C370.31,266.13,371.5,266.27,373.22,266.27Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "driving-license-front-cls-12",
                    attrs: {
                      d:
                        "M434.82,248.16a51.7,51.7,0,0,0,2.59,4.89c3.22,4.86,3.82,10.24,3.33,15.83a70.13,70.13,0,0,1-1.3,8.54,21.94,21.94,0,0,1-1.73,4,7.45,7.45,0,0,1-5.36,1.65c-1.79-.08-3.58,0-5.37.06l-21.77.11-30.3.13c-.68,0-1.35,0-2,.06-1.92,0-3.85-.09-5.77,0a8,8,0,0,1-5-1.22c-1.89-1.82-2.2-4.28-2.66-6.65a40.8,40.8,0,0,1-.69-14.1,22.52,22.52,0,0,1,1.82-5.62c1.17-2.62,2.59-5.14,3.91-7.7.35-.78.71-1.56,1.06-2.35,1.8-3.58,3.45-7.25,5.44-10.73,4-7,3.33-6.36,11-6.93s15.32-.79,23-.79c5.39,0,10.79.66,16.19,1a5.16,5.16,0,0,1,4.49,2.79c2.36,4.26,4.67,8.54,7,12.81.35.63.68,1.27,1,1.91S434.45,247.38,434.82,248.16Zm-4.3-2.12a3.29,3.29,0,0,0,0-.6q-3.18-6.94-6.37-13.9a2.18,2.18,0,0,0-2.15-1.35c-11.51,0-23-.11-34.52-.11-3.34,0-6.67.28-10,.36a2.55,2.55,0,0,0-2.56,1.8c-.55,1.38-1.21,2.72-1.81,4.08L368.79,246Zm-17.3,34.68c1,0,1.47-.34,1.43-1.27,0-1.13,0-2.27,0-3.4,0-.81-.32-1.21-1.28-1.21l-27.3.1c-1,0-1.4.41-1.38,1.27,0,1.1,0,2.2,0,3.3s.25,1.36,1.56,1.37C390.72,280.88,408.69,280.69,413.22,280.72ZM426,266.27c1.9,0,3.8.11,5.68,0a5,5,0,0,0,2.66-1c2.3-1.93,2.83-4.61,2.67-7.47-.1-1.72-.81-2.26-2.47-1.87-2.89.69-5.79,1.39-8.68,2.11-4.79,1.18-4.78,1.18-7.2,5.52a.75.75,0,0,1-.07.12c-.84,1.57-.22,2.63,1.59,2.65S424.08,266.27,426,266.27Zm-52.8,0c2,0,4,0,5.95,0,1.74,0,2.4-1.09,1.57-2.58-.73-1.3-1.49-2.58-2.32-3.81a2.38,2.38,0,0,0-1.23-.95q-6.42-1.64-12.88-3.16a1.46,1.46,0,0,0-1.95,1.34,9.35,9.35,0,0,0,1.76,7.3,5.35,5.35,0,0,0,4.48,1.86C370.31,266.13,371.5,266.27,373.22,266.27Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "driving-license-front-cls-13",
                    attrs: {
                      d:
                        "M434.82,248.16a51.7,51.7,0,0,0,2.59,4.89c3.22,4.86,3.82,10.24,3.33,15.83a70.13,70.13,0,0,1-1.3,8.54,21.94,21.94,0,0,1-1.73,4,7.45,7.45,0,0,1-5.36,1.65c-1.79-.08-3.58,0-5.37.06l-21.77.11-30.3.13c-.68,0-1.35,0-2,.06-1.92,0-3.85-.09-5.77,0a8,8,0,0,1-5-1.22c-1.89-1.82-2.2-4.28-2.66-6.65a40.8,40.8,0,0,1-.69-14.1,22.52,22.52,0,0,1,1.82-5.62c1.17-2.62,2.59-5.14,3.91-7.7.35-.78.71-1.56,1.06-2.35,1.8-3.58,3.45-7.25,5.44-10.73,4-7,3.33-6.36,11-6.93s15.32-.79,23-.79c5.39,0,10.79.66,16.19,1a5.16,5.16,0,0,1,4.49,2.79c2.36,4.26,4.67,8.54,7,12.81.35.63.68,1.27,1,1.91S434.45,247.38,434.82,248.16Zm-4.3-2.12a3.29,3.29,0,0,0,0-.6q-3.18-6.94-6.37-13.9a2.18,2.18,0,0,0-2.15-1.35c-11.51,0-23-.11-34.52-.11-3.34,0-6.67.28-10,.36a2.55,2.55,0,0,0-2.56,1.8c-.55,1.38-1.21,2.72-1.81,4.08L368.79,246Zm-17.3,34.68c1,0,1.47-.34,1.43-1.27,0-1.13,0-2.27,0-3.4,0-.81-.32-1.21-1.28-1.21l-27.3.1c-1,0-1.4.41-1.38,1.27,0,1.1,0,2.2,0,3.3s.25,1.36,1.56,1.37C390.72,280.88,408.69,280.69,413.22,280.72ZM426,266.27c1.9,0,3.8.11,5.68,0a5,5,0,0,0,2.66-1c2.3-1.93,2.83-4.61,2.67-7.47-.1-1.72-.81-2.26-2.47-1.87-2.89.69-5.79,1.39-8.68,2.11-4.79,1.18-4.78,1.18-7.2,5.52a.75.75,0,0,1-.07.12c-.84,1.57-.22,2.63,1.59,2.65S424.08,266.27,426,266.27Zm-52.8,0c2,0,4,0,5.95,0,1.74,0,2.4-1.09,1.57-2.58-.73-1.3-1.49-2.58-2.32-3.81a2.38,2.38,0,0,0-1.23-.95q-6.42-1.64-12.88-3.16a1.46,1.46,0,0,0-1.95,1.34,9.35,9.35,0,0,0,1.76,7.3,5.35,5.35,0,0,0,4.48,1.86C370.31,266.13,371.5,266.27,373.22,266.27Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "driving-license-front-cls-27",
                    attrs: {
                      d:
                        "M427,283.17c1.79,0,3.58-.14,5.37-.06a7.45,7.45,0,0,0,5.36-1.65c0,2.16.1,4.31.07,6.47a2.22,2.22,0,0,1-2.46,2.42c-2,0-4,.06-5.94,0A2.16,2.16,0,0,1,427,288C427,286.4,427,284.79,427,283.17Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "driving-license-front-cls-28",
                    attrs: {
                      d:
                        "M362.13,282.23a8,8,0,0,0,5,1.22c1.92-.07,3.85,0,5.77,0,0,1.63,0,3.25,0,4.87a2.14,2.14,0,0,1-2.34,2.37c-2,0-4,.06-5.95,0a2.18,2.18,0,0,1-2.43-2.43C362.11,286.29,362.14,284.26,362.13,282.23Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "driving-license-front-cls-29",
                    attrs: {
                      d:
                        "M435.14,248.91c-.38-.78-1.06-2.31-1.44-3.08a2.19,2.19,0,0,0,1.77-2.09,1.21,1.21,0,0,1,.94-1.08,10.84,10.84,0,0,1,4.93.33,2.78,2.78,0,0,1,1.86,2.62,2.24,2.24,0,0,1-1.39,1.83C439.64,248.09,437.37,248.44,435.14,248.91Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "driving-license-front-cls-30",
                    attrs: {
                      d:
                        "M364.19,248.91c.37-.78,1.06-2.31,1.43-3.08a2.2,2.2,0,0,1-1.77-2.09,1.21,1.21,0,0,0-.94-1.08A10.84,10.84,0,0,0,358,243a2.79,2.79,0,0,0-1.86,2.62,2.24,2.24,0,0,0,1.39,1.83C359.69,248.09,362,248.44,364.19,248.91Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "driving-license-front-cls-31",
                    attrs: {
                      d:
                        "M430.52,246H368.79l4.31-9.72c.6-1.36,1.26-2.7,1.81-4.08a2.55,2.55,0,0,1,2.56-1.8c3.34-.08,6.67-.35,10-.36,11.51,0,23,.08,34.52.11a2.18,2.18,0,0,1,2.15,1.35q3.19,6.94,6.37,13.9A3.29,3.29,0,0,1,430.52,246Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "driving-license-front-cls-32",
                    attrs: {
                      d:
                        "M426,266.27c-1.94,0-3.88,0-5.82,0s-2.43-1.08-1.59-2.65a.75.75,0,0,0,.07-.12c2.42-4.34,2.41-4.34,7.2-5.52,2.89-.72,5.79-1.42,8.68-2.11,1.66-.39,2.37.15,2.47,1.87.16,2.86-.37,5.54-2.67,7.47a5,5,0,0,1-2.66,1C429.82,266.38,427.92,266.27,426,266.27Z",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("path", {
                  staticClass: "driving-license-front-cls-33",
                  attrs: {
                    d:
                      "M412.42,268a103.77,103.77,0,0,1-25.52,0,3.28,3.28,0,0,1-3-3.68c.09-.54.17-1.07.26-1.6a3.33,3.33,0,0,1,3.72-2.74,96.56,96.56,0,0,0,23.54,0,3.35,3.35,0,0,1,3.73,2.74c.08.53.16,1.06.25,1.6A3.28,3.28,0,0,1,412.42,268Z",
                  },
                }),
                _vm._v(" "),
                _c("line", {
                  staticClass: "driving-license-front-cls-34",
                  attrs: {
                    x1: "386.74",
                    y1: "260.81",
                    x2: "386.74",
                    y2: "267.91",
                  },
                }),
                _vm._v(" "),
                _c("line", {
                  staticClass: "driving-license-front-cls-34",
                  attrs: {
                    x1: "391.84",
                    y1: "260.81",
                    x2: "391.84",
                    y2: "267.91",
                  },
                }),
                _vm._v(" "),
                _c("line", {
                  staticClass: "driving-license-front-cls-34",
                  attrs: {
                    x1: "396.94",
                    y1: "260.81",
                    x2: "396.94",
                    y2: "267.91",
                  },
                }),
                _vm._v(" "),
                _c("line", {
                  staticClass: "driving-license-front-cls-34",
                  attrs: {
                    x1: "402.04",
                    y1: "260.81",
                    x2: "402.04",
                    y2: "267.91",
                  },
                }),
                _vm._v(" "),
                _c("line", {
                  staticClass: "driving-license-front-cls-34",
                  attrs: {
                    x1: "407.14",
                    y1: "260.81",
                    x2: "407.14",
                    y2: "267.91",
                  },
                }),
                _vm._v(" "),
                _c("line", {
                  staticClass: "driving-license-front-cls-34",
                  attrs: {
                    x1: "412.23",
                    y1: "260.81",
                    x2: "412.23",
                    y2: "267.91",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("path", {
                staticClass: "driving-license-front-cls-35",
                attrs: {
                  d:
                    "M367.62,266.21a5,5,0,0,1-2.65-1c-2.31-1.93-2.83-4.6-2.67-7.47.09-1.72.8-2.26,2.47-1.86l8.67,2.1c4.79,1.18,4.79,1.19,7.21,5.52l.06.12c.85,1.57.23,2.63-1.58,2.66s-3.88,0-5.83,0S369.5,266.36,367.62,266.21Z",
                },
              }),
              _vm._v(" "),
              _c("circle", {
                staticClass: "driving-license-front-cls-31",
                attrs: { cx: "431.72", cy: "261.48", r: "2.37" },
              }),
              _vm._v(" "),
              _c("circle", {
                staticClass: "driving-license-front-cls-31",
                attrs: { cx: "423.92", cy: "262.76", r: "1.67" },
              }),
              _vm._v(" "),
              _c("circle", {
                staticClass: "driving-license-front-cls-31",
                attrs: { cx: "367.95", cy: "261.44", r: "2.37" },
              }),
              _vm._v(" "),
              _c("circle", {
                staticClass: "driving-license-front-cls-31",
                attrs: { cx: "375.76", cy: "262.72", r: "1.67" },
              }),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }