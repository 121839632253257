<template>
  <ui-camera-frame
    v-if="showContent"
    :title="$td(`Header.${message}`)"
    :file="file"
    :file-thumbnail="fileThumbnail"
    active-controls
  >
    <ui-camera-description
      slot="description"
      :resource="resource"
      :message="message"
      :instructions="!error"
      :review="!!file"
    />
    <ui-camera
      slot="camera"
      :resource="resource"
      :controls="photoControls"
      message="Info"
      :capture-callback="captureComplete"
    />
    <camera-controls
      slot="controls"
      :completed="isCompleted"
      enable-continue
      active-controls
      :error="!!error"
      :reset-callback="reset"
      @continue="continueSubmition"
    />
  </ui-camera-frame>
</template>

<script>
import CameraControls from '@src/components/partials/CameraControls'
import PhotoController from '@src/scripts/photoController'
import Api, { sendFile } from '@src/scripts/api'
import { activityLogger } from '@src/scripts/activityLogger'
import actions from '@src/store/actions'
import getters from '@src/store/getters'
import { uuid } from '@src/scripts/helpers'
import {
  appPages,
  cameraStates,
  documentTypes,
  orientationTypes,
  pageTypes
} from '@src/scripts/enums'
import { mapGetters } from 'vuex'

export default {
  components: {
    CameraControls
  },

  props: {
    side: { type: String, default: undefined }
  },

  data() {
    return {
      isCompleted: false,
      error: null,
      photoControls: null,
      file: null,
      fileThumbnail: null
    }
  },

  computed: {
    ...mapGetters([getters.allowedDocuments, getters.allowedPages]),

    documentType() {
      return (
        this.$route.params &&
        documentTypes[this.$route.params.type.toLowerCase()]
      )
    },

    showContent() {
      const { allowedDocuments, documentType } = this
      return allowedDocuments.includes(documentType)
    },

    documentSide() {
      return this.$route.params && pageTypes[this.side]
    },

    resource() {
      const { documentType, documentSide } = this
      if (!documentType || !documentSide) return
      return `${documentType}${documentSide}`
    },

    orientation() {
      const { documentType, documentSide } = this
      if (
        documentSide === pageTypes.back &&
        documentType === documentTypes.passport
      ) {
        return orientationTypes.portrait
      }
      return orientationTypes.landscape
    },

    hasSeparator() {
      const { documentType, documentSide } = this
      return (
        (documentType === documentTypes.passport &&
          documentSide === pageTypes.front) ||
        (documentType === documentTypes['id-card'] &&
          documentSide === pageTypes.back)
      )
    },

    message() {
      const { error, file } = this
      return error || (file && 'ValidateDocument') || 'PreLoad'
    }
  },

  created() {
    this.initDocument()
  },

  methods: {
    initDocument() {
      const { showContent, orientation, hasSeparator } = this
      if (!showContent) return
      const documentType = this.$route.params.type
        .toUpperCase()
        .replace('-', '_')
      const sideType = this.side.toUpperCase()
      activityLogger.logActivity(`DOCUMENT_${documentType}_${sideType}_PAGE`)

      this.photoControls = new PhotoController({ orientation, hasSeparator })
    },

    reset() {
      this.isCompleted = false
      this.error = null
      this.file = null
      this.fileThumbnail = null

      return false
    },

    captureComplete({ error, blob, thumbnail }) {
      if (error) {
        this.error = error
      } else if (blob) {
        this.file = blob
        this.fileThumbnail = thumbnail
      }
      this.isCompleted = true
    },

    async continueSubmition() {
      this.$store.dispatch(actions.updateCameraState, cameraStates.loading)
      const errorMessage = await this.submit(this.file)
      if (errorMessage) {
        this.error = errorMessage
        this.$store.dispatch(actions.updateCameraState, cameraStates.ok)
      } else {
        this.continueIdentification()
      }
      this.isCompleted = true
    },

    async submit(picture) {
      try {
        const id = uuid().substring(0, 5)
        const mediaData = this.createMediaData(picture, id)
        const response = await sendFile(Api.validateDocument, mediaData)
        if (response.error) return `Document.${response.error}`
      } catch (error) {
        this.$popUp(error)
        return 'Failed'
      }
    },

    continueIdentification() {
      if (this.documentType === documentTypes.passport) {
        return this.$router.pushNext({ indexStep: 2 })
      }
      // after back was captured, go to next page
      if (this.documentSide === pageTypes.back) {
        return this.$router.pushNext()
      }
      // after front captured, go to instructions for back side
      return this.$router.replace({
        name: appPages.documentInstructionsBack,
        params: {
          ...this.$route.params,
          type: this.$route.params.type.toLowerCase()
        }
      })
    },

    createMediaData(file, id) {
      const { documentType, documentSide } = this

      let side = documentSide
      if (documentType === documentTypes.passport) {
        side =
          documentSide === pageTypes.front ? pageTypes.back : pageTypes.front
      }

      const mediaData = new FormData()
      mediaData.append('type', documentType)
      mediaData.append('side', side)
      mediaData.append('file', file, 'file')
      mediaData.append('groupId', id)

      return mediaData
    }
  }
}
</script>
