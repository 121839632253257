<template>
  <div class="camera-check">
    <div>
      <video ref="camera" autoplay playsinline muted />
      <h3>{{ message }}</h3>
      <div v-if="hasCameraDevices">
        <a
          v-for="(device, index) of cameraDevices"
          :key="index"
          :class="{ selected: selectedId === device.deviceId }"
          @click="() => changeCamera(device.deviceId)"
        >
          <template v-if="device">
            <strong>{{ device.group }}</strong>
            <span><strong>label</strong> {{ device.label }}</span>
            <br />
            <span><strong>deviceId</strong> {{ device.deviceId }}</span>
            <br />
          </template>
        </a>
      </div>
    </div>
    {{ mediaDetails }}<br /><br />
    {{ deviceDetails }}
  </div>
</template>

<script>
import { errorTypes } from '@src/scripts/enums'

export default {
  data() {
    return {
      mediaDetails: null,
      selectedId: null,
      message: null,
      active: null
    }
  },

  computed: {
    browserData() {
      return this.$store.state.browserData || {}
    },

    hasCameraDevices() {
      return !!(this.mediaDetails && this.mediaDetails.cameraDevices)
    },

    cameraDevices() {
      return (this.mediaDetails && this.mediaDetails.cameraDevices) || {}
    },

    deviceDetails() {
      const { browserData } = this

      return {
        isMobileDevice: browserData.isMobileDevice,
        isAndroid: browserData.isAndroid,
        browser: browserData.DetectRTC?.browser,
        os: browserData.DetectRTC?.osName
      }
    }
  },

  async mounted() {
    this.mediaDetails = await this.init({ video: true, audio: true })

    if (this.mediaDetails.isSupported) {
      if (!this.cameraDevices) return
      this.changeCamera(this.cameraDevices[0].deviceId)
    } else {
      if (!this.mediaDetails.cameraDevicesCount) return
      this.startCamera()
    }
  },

  methods: {
    async init(options) {
      const details = {
        isSupported: false,
        hasCamera: false,
        hasMicrophone: false,
        hasCameraPermissions: false,
        hasMicrophonePermissions: false,
        cameraDevices: null,
        cameraDevicesCount: 0
      }

      if (!options) return details
      await this.mediaDetailsCheck(details, options)

      if (!details.isSupported) return details

      if (
        (options.video && !details.hasCameraPermissions) ||
        (options.audio && !details.hasMicrophonePermissions)
      ) {
        if (await this.getMediaPermissions(options)) {
          await this.mediaDetailsCheck(details, options)
        }
      }

      this.mapCameraDevices(details)
      return details
    },

    async mediaDetailsCheck(target, options) {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices()
      if (!mediaDevices || !mediaDevices.length) return

      target.isSupported = this.validateBrowser()
      if (options.video) this.mapCameraDetails(target, mediaDevices)
      if (options.audio) this.mapAudioDetails(target, mediaDevices)
    },

    async getMediaPermissions(options) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(options)
        if (!stream) return false

        stream.getVideoTracks()[0].stop()
        return true
      } catch (error) {
        return false
      }
    },

    validateBrowser() {
      const { DetectRTC } = this.browserData
      if (!DetectRTC || !DetectRTC.browser) return false

      const { isChrome, isSafari, isSamsung } = DetectRTC.browser
      if (!isChrome && !isSafari && !isSamsung) return false

      return true
    },

    mapCameraDetails(target, mediaDevices) {
      const devices = mediaDevices
        .filter((item) => item.kind === 'videoinput')
        .map((item) => ({ label: item.label, deviceId: item.deviceId }))

      if (devices && devices.length) {
        target.hasCamera = true
        target.cameraDevicesCount = devices.length

        if (devices[0].label) {
          target.hasCameraPermissions = true
          target.cameraDevices = devices
        }
      }
    },

    mapAudioDetails(target, mediaDevices) {
      const device = mediaDevices.find((item) => item.kind === 'audioinput')

      if (device) {
        target.hasMicrophone = true
        target.hasMicrophonePermissions = !!device.label
      }
    },

    mapCameraDevices(details) {
      const { isAndroid } = this.browserData
      if (!isAndroid) return

      details.cameraDevices.sort((a, b) => (a.label > b.label ? 1 : -1))

      const frontCamera = details.cameraDevices.find((item) =>
        item.label.toLowerCase().includes('front')
      )

      const backCamera = details.cameraDevices.find((item) =>
        item.label.toLowerCase().includes('back')
      )

      details.cameraDevices = [frontCamera, backCamera]
    },

    changeCamera(id) {
      if (id === this.selectedId) return

      this.selectedId = id
      this.stopCamera()
      this.startCamera(id)
    },

    stopCamera() {
      if (this.active) {
        this.active.stop()
        this.$refs.camera.srcObject = null
      }
      this.message = null
    },

    async startCamera(deviceId) {
      try {
        const videoSettings = {}

        if (deviceId) {
          const camera = this.cameraDevices.find(
            (item) => item.deviceId === deviceId
          )
          if (!camera) return
          videoSettings.deviceId = { exact: camera.deviceId }
        }

        const stream = await navigator.mediaDevices.getUserMedia({
          video: videoSettings,
          audio: false
        })
        if (!stream) return

        this.active = stream.getVideoTracks()[0]
        this.$refs.camera.srcObject = stream
      } catch (error) {
        console.warn(error)
        this.message = error
      }
    },

    async loadCameraDevices() {
      const { hasWebcam, hasPermissions } = this.browserData
      if (!hasWebcam) return { error: { type: errorTypes.noCamera } }

      if (!hasPermissions) {
        const hasPermissions = await this.getPermissions()
        if (!hasPermissions)
          return { error: { type: errorTypes.noPermissions } }
      }

      const devices = await this.getCameraDevices()
      if (!devices) return { error: { type: errorTypes.noCamera } }

      this.cameraDevices = devices
    },

    async getPermissions() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true
        })
        if (!stream) return false
        stream.getVideoTracks()[0].stop()
        return true
      } catch (error) {
        return false
      }
    },

    async getCameraDevices() {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices()
      if (!mediaDevices || !mediaDevices.length) return null

      const cameraDevices = mediaDevices
        .filter((item) => item.kind === 'videoinput')
        .map((item) => ({ deviceId: item.deviceId, label: item.label }))

      if (!cameraDevices || !cameraDevices.length) return null
      return this.mapCameraDevices(cameraDevices)
    }
  }
}
</script>

<style scoped>
.camera-check {
  padding: 2rem;
  word-break: break-all;
}

a {
  padding: 0.5rem 1rem;
  margin-bottom: 2.5rem;
  display: block;
  cursor: pointer;
}

a,
span {
  font-size: 0.75rem;
}

a > :first-child {
  margin-bottom: 0.25rem;
  display: block;
}

video {
  height: 20rem;
}

.selected {
  border: 1px solid green;
  border-radius: 3px;
}
</style>
