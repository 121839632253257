import CameraController from '@src/scripts/cameraController'
import { activityLogger } from '@src/scripts/activityLogger'
import api, { sendFile } from '@src/scripts/api'
import { errorTypes } from '@src/scripts/enums'
import { mediaSourceTypes } from '@src/scripts/enums'

import { PASSIVE_LIVENESS_IMAGE_HEIGHT } from '@src/scripts/constants'
import { delay, takePhoto, getCssVarValue } from '@src/scripts/helpers'
import JSZip from 'jszip'

export default class {
  get mode() {
    return mediaSourceTypes.photo
  }

  callback = {
    captureCompleted: null
  }

  state = {
    isReady: false,
    isSuccess: false,
    sessionResult: null,
    requestController: null,
    changeAvailable: false,
    listener: null,
    progressResolve: null
  }

  // public
  init(params) {
    try {
      this.validateRquired(params)
      return true
    } catch (error) {
      console.error('p error', error)
      return false
    }
  }

  changeCamera() {}

  async initCamera() {
    try {
      this.interfaceInit()
      await this.initCameraController()
      this.state.isReady = true
    } catch (error) {
      console.error(error)
    }
  }

  capture() {
    if (!this.state.isReady) {
      this.cancel()
      return { error: 'Face.Generic' }
    }

    activityLogger.logActivity('FACE_PASSIVE_CAPTURE')
    return new Promise((resolve) => this.captureImages(resolve))
  }

  cancel() {
    if (this.state.cameraController) {
      this.state.cameraController.stopCamera()
      this.state.cameraController = null
    }

    if (this.state.maskId) {
      const maskElement = document.getElementById(this.state.maskId)
      while (maskElement.firstChild) {
        maskElement.firstChild.remove()
      }
    }

    this.state.isReady = false
  }

  // private
  validateRquired({ parentId, maskId, videoId, browserData }) {
    if (!parentId || !maskId || !videoId)
      throw { type: errorTypes.noRequiredElements }

    this.state.parentId = parentId
    this.state.maskId = maskId
    this.state.videoId = videoId
    this.state.browserData = browserData
  }

  async initCameraController() {
    this.state.cameraController = new CameraController({
      browserData: this.state.browserData,
      parentId: this.state.parentId,
      videoId: this.state.videoId
    })

    await this.state.cameraController.initCamera()
  }

  async captureImages(resolve) {
    this.state.progressResolve = resolve

    try {
      const framesCount = 5
      const frames = []
      const video = document.getElementById(this.state.videoId)

      for (let i = 0; i < framesCount; i++) {
        const frame = this.takeFrame(video)
        if (!frame) break

        frames.push(frame)
        await delay(250)
      }

      this.cancel()

      if (frames.length !== framesCount) {
        return resolve({ error: 'Failed' })
      }

      const status = await this.validateFace(frames)
      if (status === 'SUCCESSFUL') resolve({})
      else if (status === 'ERROR') resolve({ error: 'Face.Generic' })
      else resolve({ error: 'Failed' })

      this.callback.captureCompleted?.()
    } catch (error) {
      console.error(error)
      resolve({ error: 'Failed' })
    }
  }

  takeFrame(video) {
    const { aspectRatio, clientWidth, clientHeight } = this.state.maskOptions

    const { height, width } = this.getFrameHeightWidth(
      aspectRatio,
      clientWidth,
      clientHeight
    )

    const isVideoOrientiationPortrait = 1 < video.videoHeight / video.videoWidth

    let cropDimensions = isVideoOrientiationPortrait
      ? null
      : {
          clientWidth,
          clientHeight,
          x: (clientWidth - width) / 2,
          y: 0,
          width,
          height
        }

    return takePhoto({
      video,
      cropDimensions,
      resizeDimensions: {
        height: PASSIVE_LIVENESS_IMAGE_HEIGHT
      }
    })
  }

  async validateFace(faceMetrics) {
    const metricsZip = await this.createZipBody(faceMetrics)
    const body = this.createValidationBody(metricsZip)

    try {
      const { error } = await sendFile(api.passiveFaceValidation, body)
      if (error) return 'ERROR'
      return 'SUCCESSFUL'
    } catch (error) {
      return 'FAILED'
    }
  }

  async createZipBody(faceMetrics) {
    const zip = new JSZip()

    const auditTrail = zip.folder('auditTrail')
    for (const index in faceMetrics) {
      const image = faceMetrics[index]
      auditTrail.file(`${index}.jpg`, image)
    }

    return await zip.generateAsync({ type: 'blob' })
  }

  createValidationBody(content) {
    const dataToUpload = new FormData()
    dataToUpload.append('sessionData', content)
    return dataToUpload
  }

  interfaceInit() {
    const parent = document.getElementById(this.state.parentId)
    const maskElement = document.getElementById(this.state.maskId)

    if (maskElement.classList.contains('camera-mask')) {
      return
    }

    maskElement.classList.add('camera-mask')

    this.state.maskOptions = this.getDimensions(parent)

    if (this.state.maskOptions) {
      const message = this.createMessage()
      const mask = this.createMask(this.state.maskOptions)
      const frame = this.createFrame(this.state.maskOptions)

      maskElement.appendChild(message)
      maskElement.appendChild(mask)
      maskElement.appendChild(frame)
    }
  }

  createMessage() {
    const messageBox = document.createElement('div')
    messageBox.id = 'alternative-method-message'

    const message = document.createElement('div')
    message.id = 'zoom-feedback'
    message.innerText = window.zoomTranslations?.FaceTec_feedback_face_not_found

    messageBox.appendChild(message)
    return messageBox
  }

  createMask(options) {
    const mask = document.createElement('canvas')
    mask.classList.add('mask-background')
    mask.width = options.clientWidth
    mask.height = options.clientHeight

    const context = mask.getContext('2d')
    context.fillStyle = getCssVarValue('--page-color')
    context.fillRect(0, 0, options.clientWidth, options.clientHeight)
    context.globalCompositeOperation = 'xor'

    context.ellipse(
      options.x,
      options.y,
      options.width,
      options.height,
      0,
      0,
      2 * Math.PI
    )
    context.fill()

    return mask
  }

  createFrame(options) {
    const frame = document.createElement('canvas')
    frame.classList.add('mask-frame')
    frame.width = options.clientWidth
    frame.height = options.clientHeight

    const frameColor = getCssVarValue('--dark')

    const context = frame.getContext('2d')
    context.strokeStyle = frameColor
    context.lineWidth = 4
    context.beginPath()
    context.ellipse(
      options.x,
      options.y,
      options.width,
      options.height,
      0,
      0,
      2 * Math.PI
    )
    context.stroke()

    return frame
  }

  getDimensions(parent) {
    const aspectRatio = 0.6761363636363636
    const options = {
      aspectRatio,
      clientWidth: parent.clientWidth,
      clientHeight: parent.clientHeight
    }

    options.height = options.clientHeight * 0.35
    options.width = options.height * aspectRatio
    options.y = (options.clientHeight - options.height * 2) / 2 + options.height
    options.x = (options.clientWidth - options.width * 2) / 2 + options.width

    return options
  }

  getFrameHeightWidth(maskAspectRatio, cameraWidth, cameraHeight) {
    const cameraAspectRatio = cameraWidth / cameraHeight

    if (maskAspectRatio < cameraAspectRatio) {
      const height = cameraHeight
      const width = height * maskAspectRatio

      return {
        height,
        width
      }
    }

    const width = cameraWidth
    const height = width * maskAspectRatio

    return {
      height,
      width
    }
  }
}
