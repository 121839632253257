<template>
  <ui-frame class="omnichannel-page">
    <div class="back-btn" @click="goToOmnichannelPage">
      &larr; {{ $t('OmnichannelQrId.Back') }}
    </div>
    <h1>{{ $t('Omnichannel.Start.Title') }}</h1>
    <div class="group-container">
      <div class="group-item">
        <h3>{{ $t('OmnichannelQrId.InstallApp.Title') }}</h3>
        <p>{{ $t('OmnichannelQrId.InstallApp.Text') }}</p>
        <ul class="app-store-list">
          <li>
            <div class="qr-box-store" :style="qrBackground" />
            <img src="/public/images/icons/apple-store-badge.svg" />
          </li>
          <li>
            <div class="qr-box-store" :style="qrBackground" />
            <img src="/public/images/icons/google-play-badge.svg" />
          </li>
          <li>
            <div class="qr-box-store" :style="qrBackground" />
            <img src="/public/images/icons/huawei-badge.svg" />
          </li>
        </ul>
      </div>
      <div class="group-item">
        <h3>{{ $t('OmnichannelQrId.ScanQrCode.Title') }}</h3>
        <p>{{ $t('OmnichannelQrId.ScanQrCode.Text') }}</p>
        <div class="qr-box" :style="qrBackground" />
      </div>
    </div>
  </ui-frame>
</template>

<script>
import QrCode from 'qrcode'
import { appPages } from '@src/scripts/enums'

export default {
  data() {
    return {
      showContent: false,
      qrBackground: null
    }
  },

  computed: {
    omnichannelId() {
      return this.$route.params.omnichannelId
    },

    language() {
      return this.$language()
    }
  },

  async created() {
    setTimeout(() => {
      this.showContent = true
    })

    const identityVerificationId = window.sessionStorage.getItem(
      'identityVerificationId'
    )
    const idvAccessCode = window.sessionStorage.getItem('idvAccessCode')
    const payload = JSON.stringify({ identityVerificationId, idvAccessCode })
    await this.generateQrCode(payload)
  },

  methods: {
    goToOmnichannelPage() {
      this.$router.replace({ name: appPages.omnichannel })
    },

    async generateQrCode(url) {
      const qrCodeUrl = await QrCode.toDataURL(url, { width: 200 })
      this.qrBackground = {
        backgroundImage: `url(${qrCodeUrl})`
      }
    }
  }
}
</script>

<style scoped>
.back-btn {
  position: absolute;
  top: 2rem;
  left: 2rem;
  font-weight: bold;
  cursor: pointer;
}

.group-container {
  display: flex;
  text-align: center;
}

.group-item {
  width: 50%;
  padding: 1rem;
}

.qr-box {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 2rem auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.qr-box-store {
  position: relative;
  width: 60px;
  height: 60px;
  margin: 0.5rem 1rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

ul.app-store-list {
  max-width: min-content;
  margin: 2rem auto;
  padding: 0;
}

ul.app-store-list li {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 1rem;
  padding: 0.5rem;
  border: 2px solid #ccc;
  border-radius: 2rem;
}

.app-store-list li img {
  width: 120px;
  height: auto;
  margin: 0.5rem 1rem;
}
</style>
