<template>
  <div class="phone-input">
    <ui-phone-code
      :value="phoneNumber.code"
      :country="country"
      :error="!!error"
      :disabled="disabled"
      @input="codeChanged"
    />
    <ui-input
      :label="label"
      :value="phoneNumber.number"
      :error="error"
      :disabled="disabled"
      max-length="22"
      @input="numberChanged"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Object, default: undefined },
    error: { type: String, default: undefined },
    label: { type: String, default: undefined },
    country: { type: String, default: undefined },
    disabled: Boolean
  },

  computed: {
    phoneNumber() {
      return this.value || {}
    }
  },

  methods: {
    codeChanged(value) {
      const { phoneNumber } = this
      value = value.trim()
      if (value === phoneNumber.code) return

      this.$emit('change', {
        ...phoneNumber,
        code: value
      })
    },

    numberChanged(value) {
      const { phoneNumber } = this
      value = value.trim()
      if (value === phoneNumber.number) return

      this.$emit('change', {
        ...phoneNumber,
        number: value
      })
    }
  }
}
</script>

<style scoped>
.phone-input {
  display: flex;
}

.phone-input > * {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.ui-input {
  width: 100%;
}
</style>
