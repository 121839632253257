// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  height: 100%;
}

body {
  margin: 0;
  min-width: 18.75rem;
}

h1,
h2 {
  color: var(--header-color);
  line-height: var(--header-line-height);
  font-family: var(--header-font-family);
  margin-top: 0;
  margin-bottom: 0;
}

h1 {
  font-size: var(--header-font-size-primary);
  font-weight: var(--header-font-weight-primary);
}

h2 {
  font-size: var(--header-font-size-additional);
  font-weight: var(--header-font-weight-additional);
}

p {
  font-size: var(--text-font-size);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

span {
  font-size: var(--text-font-size);
}

*,
::before,
::after {
  box-sizing: border-box;
}

.hidden {
  display: none;
}

.participant-window {
  box-shadow: 0 0 3rem rgba(0, 0, 0, 0.4);
  width: 0;
  height: 0;
  transform: scaleX(-1);
}

.participant-window video {
  height: 100%;
  width: 100%;
}

.terms-and-conditions h3 > span {
  font-family: var(--header-font-family);
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
}

.terms-and-conditions h4 {
  margin-bottom: 0;
}

.terms-and-conditions h4 + p {
  margin-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/app.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;AACd;;AAEA;EACE,SAAS;EACT,mBAAmB;AACrB;;AAEA;;EAEE,0BAA0B;EAC1B,sCAAsC;EACtC,sCAAsC;EACtC,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,0CAA0C;EAC1C,8CAA8C;AAChD;;AAEA;EACE,6CAA6C;EAC7C,iDAAiD;AACnD;;AAEA;EACE,gCAAgC;EAChC,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;;;EAGE,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,uCAAuC;EACvC,QAAQ;EACR,SAAS;EACT,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,sCAAsC;EACtC,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf","sourcesContent":["html,\nbody {\n  height: 100%;\n}\n\nbody {\n  margin: 0;\n  min-width: 18.75rem;\n}\n\nh1,\nh2 {\n  color: var(--header-color);\n  line-height: var(--header-line-height);\n  font-family: var(--header-font-family);\n  margin-top: 0;\n  margin-bottom: 0;\n}\n\nh1 {\n  font-size: var(--header-font-size-primary);\n  font-weight: var(--header-font-weight-primary);\n}\n\nh2 {\n  font-size: var(--header-font-size-additional);\n  font-weight: var(--header-font-weight-additional);\n}\n\np {\n  font-size: var(--text-font-size);\n  margin-top: 0.75rem;\n  margin-bottom: 0.75rem;\n}\n\nspan {\n  font-size: var(--text-font-size);\n}\n\n*,\n::before,\n::after {\n  box-sizing: border-box;\n}\n\n.hidden {\n  display: none;\n}\n\n.participant-window {\n  box-shadow: 0 0 3rem rgba(0, 0, 0, 0.4);\n  width: 0;\n  height: 0;\n  transform: scaleX(-1);\n}\n\n.participant-window video {\n  height: 100%;\n  width: 100%;\n}\n\n.terms-and-conditions h3 > span {\n  font-family: var(--header-font-family);\n  font-size: 18px;\n  line-height: 1.5;\n  font-weight: 600;\n}\n\n.terms-and-conditions h4 {\n  margin-bottom: 0;\n}\n\n.terms-and-conditions h4 + p {\n  margin-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
