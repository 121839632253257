<template>
  <types v-if="type === 'SELECTION'" @change="change" />
  <smart-id v-else-if="type === 'SMART_ID'" :settings="settings" @wait="wait" />
  <mobile-id v-else-if="type === 'MOBILE_ID'" @wait="wait" />
  <check
    v-else-if="type === 'CHECK'"
    :type="method"
    :code="verificationCode"
    @complete="complete"
    @error="error"
  />
</template>

<script>
import Types from '@src/components/partials/signatureAuthentication/Types'
import SmartId from '@src/components/partials/signatureAuthentication/SmartId'
import MobileId from '@src/components/partials/signatureAuthentication/MobileId'
import Check from '@src/components/partials/signatureAuthentication/Check'
import { ruleTypes } from '@src/scripts/enums'

export default {
  components: {
    Types,
    SmartId,
    MobileId,
    Check
  },

  data() {
    return {
      type: 'SELECTION',
      method: null,
      settings: null,
      verificationCode: null
    }
  },

  methods: {
    change(value) {
      if (value.name === ruleTypes.smartId) this.type = 'SMART_ID'
      else if (value.name === ruleTypes.mobileId) this.type = 'MOBILE_ID'

      this.method = value.name
      this.settings = value.parameters
      this.verificationCode = null
    },

    wait(code) {
      if (!code) return

      this.verificationCode = code
      this.type = 'CHECK'
    },

    complete() {
      this.$router.pushNext()
    },

    error() {
      this.change({
        name: this.method,
        parameters: this.settings
      })
    }
  }
}
</script>
