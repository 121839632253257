var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("instructions-frame", {
    attrs: {
      header: _vm.$t("Instructions.Headers.Face"),
      description: _vm.$t("Instructions.Descriptions.Face"),
      "main-item": _vm.mainItem,
      "sub-items-header": _vm.$t("Instructions.RightSidebarTitle"),
      "sub-items": _vm.subItems,
      action: _vm.$t("Instructions.ButtonText"),
    },
    on: {
      onaction: function ($event) {
        return _vm.$emit("go-next")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }