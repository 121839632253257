var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "phone-input" },
    [
      _c("ui-phone-code", {
        attrs: {
          value: _vm.phoneNumber.code,
          country: _vm.country,
          error: !!_vm.error,
          disabled: _vm.disabled,
        },
        on: { input: _vm.codeChanged },
      }),
      _vm._v(" "),
      _c("ui-input", {
        attrs: {
          label: _vm.label,
          value: _vm.phoneNumber.number,
          error: _vm.error,
          disabled: _vm.disabled,
          "max-length": "22",
        },
        on: { input: _vm.numberChanged },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }