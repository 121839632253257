// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.mobile-app .review .instructions-container[data-v-6af0ddcc] {
  display: none;
}
@media screen and (max-width: 30em) {
.desktop-app .review .instructions-container[data-v-6af0ddcc] {
    display: none;
}
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/UiCameraDescription.vue"],"names":[],"mappings":";AA+BA;EACA,aAAA;AACA;AAEA;AACA;IACA,aAAA;AACA;AACA","sourcesContent":["<template>\n  <div v-if=\"message\" class=\"ui-camera-description\" :class=\"{ review }\">\n    <h2>{{ $td(`Header.${message}`) }}</h2>\n    <p>\n      {{ $td(`Description.${message}`) }}\n    </p>\n    <instructions-container v-if=\"instructions\" :resource=\"resource\" />\n  </div>\n</template>\n\n<script>\nimport InstructionsContainer from '@src/components/partials/InstructionsContainer'\n\nexport default {\n  name: 'ui-camera-description',\n\n  components: {\n    InstructionsContainer\n  },\n\n  props: {\n    message: { type: String, default: undefined, required: true },\n    resource: { type: String, default: undefined, required: true },\n    live: Boolean,\n    instructions: Boolean,\n    review: Boolean\n  }\n}\n</script>\n\n<style scoped>\n.mobile-app .review .instructions-container {\n  display: none;\n}\n\n@media screen and (max-width: 30em) {\n  .desktop-app .review .instructions-container {\n    display: none;\n  }\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
