<template>
  <div class="message-overlay" :class="{ show: show }">
    <div class="message-content">
      {{ $td(`Header.${message}`) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    resource: { type: String, default: undefined, required: true },
    message: { type: String, default: undefined },
    show: Boolean
  }
}
</script>

<style scoped>
.message-overlay {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 100;
  animation: message-hide var(--message-hide-time) ease-out forwards;
}

.message-content {
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 3.125rem;
  color: var(--message-text);
  padding: 0 2rem;
  margin: 0 auto;
  box-shadow: 0 0.1875rem 0.625rem var(--message-shadow);
  background-color: var(--mask);
}
</style>

<style>
.camera-active .message-overlay {
  animation: message-show var(--message-show-time) ease-out
    var(--message-hide-time) forwards;
}

@keyframes message-hide {
  0% {
    top: 18%;
    opacity: 1;
  }

  100% {
    top: 12%;
    opacity: 0;
  }
}

@keyframes message-show {
  100% {
    top: 18%;
    opacity: 1;
  }

  0% {
    top: 12%;
    opacity: 0;
  }
}
</style>
