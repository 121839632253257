var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ui-camera",
      class: {
        "camera-active": _vm.isCameraActive,
        "no-mask": _vm.noMask,
        "camera-loading": _vm.isCameraLoading,
      },
      attrs: { id: "video-box" },
    },
    [
      _c(
        "div",
        { staticClass: "camera-box" },
        [
          _c("div", { staticClass: "video-mask", attrs: { id: "video-mask" } }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showWatermarks,
                  expression: "showWatermarks",
                },
              ],
              staticClass: "video-mask camera-mask water-mark-container",
              attrs: { id: "water-mark" },
            },
            [
              _c(
                "div",
                { attrs: { id: "video-mask-container" } },
                [
                  _c("water-mark"),
                  _vm._v(" "),
                  _vm.isTest ? _c("test-water-mark") : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("video", {
            staticClass: "video-element",
            attrs: { id: "video-element", playsinline: "", muted: "" },
            domProps: { muted: true },
          }),
          _vm._v(" "),
          _vm.message
            ? _c("message-overlay", {
                attrs: { resource: _vm.resource, message: _vm.message },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.canChange
        ? _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isCameraActive,
                  expression: "isCameraActive",
                },
              ],
              staticClass: "camera-switch",
              on: { click: _vm.changeCamera },
            },
            [_c("camera-switch")],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "video-disabled" }),
      _vm._v(" "),
      _vm.isCameraLoading
        ? _c(
            "div",
            { staticClass: "video-loader" },
            [
              _vm.loadingVideo
                ? _c("commercial-player", {
                    attrs: { source: _vm.loadingVideo },
                  })
                : _c("ui-loader", { attrs: { size: 3 } }),
              _vm._v(" "),
              _c("h3", [_vm._v(_vm._s(_vm.loadingMessage))]),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }