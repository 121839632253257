// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.camera-active .message-overlay {
  animation: message-show var(--message-show-time) ease-out
    var(--message-hide-time) forwards;
}
@keyframes message-hide {
0% {
    top: 18%;
    opacity: 1;
}
100% {
    top: 12%;
    opacity: 0;
}
}
@keyframes message-show {
100% {
    top: 18%;
    opacity: 1;
}
0% {
    top: 12%;
    opacity: 0;
}
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/MessageOverlay.vue"],"names":[],"mappings":";AA0CA;EACA;qCACA;AACA;AAEA;AACA;IACA,QAAA;IACA,UAAA;AACA;AAEA;IACA,QAAA;IACA,UAAA;AACA;AACA;AAEA;AACA;IACA,QAAA;IACA,UAAA;AACA;AAEA;IACA,QAAA;IACA,UAAA;AACA;AACA","sourcesContent":["<template>\n  <div class=\"message-overlay\" :class=\"{ show: show }\">\n    <div class=\"message-content\">\n      {{ $td(`Header.${message}`) }}\n    </div>\n  </div>\n</template>\n\n<script>\nexport default {\n  props: {\n    resource: { type: String, default: undefined, required: true },\n    message: { type: String, default: undefined },\n    show: Boolean\n  }\n}\n</script>\n\n<style scoped>\n.message-overlay {\n  display: flex;\n  position: absolute;\n  left: 0;\n  right: 0;\n  z-index: 100;\n  animation: message-hide var(--message-hide-time) ease-out forwards;\n}\n\n.message-content {\n  font-family: 'Source Sans Pro', Helvetica, sans-serif;\n  font-size: 1.375rem;\n  font-weight: 700;\n  line-height: 3.125rem;\n  color: var(--message-text);\n  padding: 0 2rem;\n  margin: 0 auto;\n  box-shadow: 0 0.1875rem 0.625rem var(--message-shadow);\n  background-color: var(--mask);\n}\n</style>\n\n<style>\n.camera-active .message-overlay {\n  animation: message-show var(--message-show-time) ease-out\n    var(--message-hide-time) forwards;\n}\n\n@keyframes message-hide {\n  0% {\n    top: 18%;\n    opacity: 1;\n  }\n\n  100% {\n    top: 12%;\n    opacity: 0;\n  }\n}\n\n@keyframes message-show {\n  100% {\n    top: 18%;\n    opacity: 1;\n  }\n\n  0% {\n    top: 12%;\n    opacity: 0;\n  }\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
