<template>
  <ui-frame v-if="isReady" class="omnichannel-page">
    <ui-loader v-if="isLoading" :size="3" center />
    <div v-else-if="isCompleted" class="message-container">
      <h1>{{ $t('Omnichannel.Completed.Title') }}</h1>
      <p>{{ $t('Omnichannel.Completed.Description') }}</p>
      <ui-button label="App.Continue" @click="continueIdentification" />
    </div>
    <div v-else-if="isPending" class="message-container">
      <h1>{{ $t('Omnichannel.Pending.Title') }}</h1>
      <p>{{ $t('Omnichannel.Pending.Description') }}</p>
      <ui-loader :size="3" center />
    </div>
    <div v-else-if="isError" class="message-container">
      <transition name="fade">
        <div class="result-message-group">
          <h2 class="result-message">
            {{ $t(messagePrimary) }}
          </h2>
          <p class="result-message-secondary">
            {{ $t(messageSecondary) }}
          </p>
          <div class="button-group">
            <ui-button
              v-if="canRetry"
              label="App.TryAgain"
              :disabled="isLoading"
              @click="tryAgain"
            />
            <ui-button
              v-if="isFailureUrlAvailable"
              additional
              label="App.Close"
              @click="close"
            />
          </div>
        </div>
      </transition>
    </div>
    <div v-else class="alternative-container">
      <div class="top-action-container">
        <a
          v-if="currentLinkAlternative !== LINK_ALTERNATIVES.QR"
          class="top-left-action"
          @click="previousMethod"
        >
          <back-arrow />{{ $t('Omnichannel.Start.Alternatives.GoBack') }}
        </a>
      </div>
      <div class="selection-container">
        <h1>
          {{ $t(stepTranslationKeys.title.firstSection) }}
          <template v-if="stepTranslationKeys.title.secondSection">
            <br />
            {{ $t(stepTranslationKeys.title.secondSection) }}
          </template>
        </h1>
        <p class="info-text">
          {{ $t(stepTranslationKeys.description) }}
        </p>
        <h3>
          {{ $t(stepTranslationKeys.subTitle) }}
        </h3>

        <div
          v-if="currentLinkAlternative === LINK_ALTERNATIVES.MANUAL"
          class="step-container"
        >
          <div class="link-container">
            <span>{{ shortUrl || url }}</span>
          </div>
        </div>

        <div
          v-else-if="currentLinkAlternative === LINK_ALTERNATIVES.SMS"
          class="step-container"
        >
          <sms-confirmation
            class="sms-container"
            :country="countryCode"
            @complete="submitNumber"
          />
        </div>

        <div v-else class="step-container">
          <div class="qr-box" :style="qrBackground" />
          <copy-button :url="shortUrl || url" />
        </div>
        <a
          v-if="stepTranslationKeys.nextStep"
          class="link-button"
          @click="nextMethod"
        >
          {{ $t(stepTranslationKeys.nextStep) }}
        </a>
      </div>
    </div>
  </ui-frame>
</template>

<script>
import BackArrow from '@src/components/images/BackArrow'
import SmsConfirmation from '@src/components/partials/SmsConfirmation'
import QrCode from 'qrcode'
import getters from '@src/store/getters'
import {
  API_ERRORS,
  DEFAULT_OMNICHANNEL_DELAY,
  IDV_API_REQUESTS,
  OMNICHANNEL_DELAY,
  OMNICHANNEL_STATUSES,
  STORE_KEYS,
  LINK_ALTERNATIVES
} from '@src/scripts/constants'
import {
  appPages,
  OmnichannelFailReason,
  popUpTypes,
  deadEndReasons
} from '@src/scripts/enums'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { stateKeys } from '@src/store'
import { idvApi } from '@src/scripts/idvApi'
import { delay, toPascalCase } from '@src/scripts/helpers'
import Api, { call } from '@src/scripts/api'
import JwtDecode from 'jwt-decode'
import actions from '@src/store/actions'
import mutations from '@src/store/mutations'
import { redirectFailure } from '@src/scripts/routerManager'
import CopyButton from './CopyButton.vue'

export default {
  components: {
    BackArrow,
    CopyButton,
    SmsConfirmation
  },

  data() {
    return {
      OMNICHANNEL_STATUSES,
      LINK_ALTERNATIVES,
      cancellationToken: new AbortController(),
      isReady: false,
      isCompleted: false,
      isPending: false,
      isLoading: true,
      isError: false,
      qrBackground: null,
      ocStarted: false,
      url: null,
      shortUrl: null,
      delay: DEFAULT_OMNICHANNEL_DELAY,
      idvId: null,
      idvAccessCode: null,
      kycAccessCode: null,
      isSmsLimitReached: false,
      checkMessageKey: null,
      canRetry: true,
      currentLinkAlternative: LINK_ALTERNATIVES.QR,
      linkedLinkAlternatives: {
        [LINK_ALTERNATIVES.QR]: {
          type: LINK_ALTERNATIVES.QR,
          previous: null,
          next: LINK_ALTERNATIVES.SMS
        },
        [LINK_ALTERNATIVES.SMS]: {
          type: LINK_ALTERNATIVES.SMS,
          previous: LINK_ALTERNATIVES.QR,
          next: LINK_ALTERNATIVES.MANUAL
        },
        [LINK_ALTERNATIVES.MANUAL]: {
          type: LINK_ALTERNATIVES.MANUAL,
          previous: LINK_ALTERNATIVES.SMS,
          next: null
        }
      }
    }
  },

  computed: {
    ...mapState([stateKeys.omnichannel]),
    ...mapGetters([
      getters.isOmnichannelActive,
      getters.countryCode,
      getters.isAppStoreEnabled,
      getters.idvAccessToken,
      getters.isFailureUrlAvailable,
      getters.claims
    ]),

    status() {
      return this.omnichannel.status ?? OMNICHANNEL_STATUSES.UNAVAILABLE
    },

    messagePrimary() {
      const { checkMessageKey } = this
      return `Check.Failed.${checkMessageKey || 'Undefined'}.primaryMessage`
    },

    messageSecondary() {
      const { checkMessageKey } = this
      return `Check.Failed.${checkMessageKey || 'Undefined'}.secondaryMessage`
    },

    language() {
      return this.$language()
    },

    faceAuthenticationIdFromStorage() {
      return sessionStorage.getItem(STORE_KEYS.FACE_AUTHENTICATION_ID)
    },

    stepTranslationKeys() {
      switch (this.currentLinkAlternative) {
        case LINK_ALTERNATIVES.SMS: {
          return {
            title: {
              firstSection: 'Omnichannel.Start.Alternatives.Sms.Title.Section1',
              secondSection: 'Omnichannel.Start.Alternatives.Sms.Title.Section2'
            },
            subTitle: 'Omnichannel.Start.Alternatives.Sms.SubTitle',
            description: 'Omnichannel.Start.Alternatives.Sms.Description',
            nextStep: 'Omnichannel.Start.Alternatives.Sms.NextStep'
          }
        }
        case LINK_ALTERNATIVES.MANUAL: {
          return {
            title: {
              firstSection:
                'Omnichannel.Start.Alternatives.Manual.Title.Section1',
              secondSection:
                'Omnichannel.Start.Alternatives.Manual.Title.Section2'
            },
            subTitle: 'Omnichannel.Start.Alternatives.Manual.SubTitle',
            description: 'Omnichannel.Start.Alternatives.Manual.Description',
            next: null
          }
        }
        case LINK_ALTERNATIVES.QR:
        default: {
          return {
            title: {
              firstSection: 'Omnichannel.Start.Alternatives.Qr.Title.Section1',
              secondSection: null
            },
            subTitle: 'Omnichannel.Start.Alternatives.Qr.SubTitle',
            description: 'Omnichannel.Start.Alternatives.Qr.Description',
            nextStep: 'Omnichannel.Start.Alternatives.Qr.NextStep'
          }
        }
      }
    }
  },

  watch: {
    status: {
      immediate: true,
      handler(value) {
        if (this.isReady || value !== OMNICHANNEL_STATUSES.AVAILABLE) {
          if (value === OMNICHANNEL_STATUSES.INACTIVE) {
            this.updateOmnichannelStatus(OMNICHANNEL_STATUSES.ACTIVE)
          }
          return
        }

        this.isReady = true
        sessionStorage.setItem(STORE_KEYS.OMNICHANNELS, true)
        this.checkOmnichannelStatus()
      }
    },

    isCompleted(newValue, prevValue) {
      if (newValue !== prevValue) {
        this.updateOmnichannelCloseStatus(newValue, this.isPending)
      }
    },

    isPending(newValue, prevValue) {
      if (newValue !== prevValue) {
        this.updateOmnichannelCloseStatus(this.isCompleted, newValue)
      }
    }
  },

  beforeDestroy() {
    this.isReady = false
    this.cancellationToken?.abort()
    if (this.status !== OMNICHANNEL_STATUSES.AVAILABLE) {
      this.updateOmnichannelStatus(OMNICHANNEL_STATUSES.AVAILABLE)
    }
  },

  methods: {
    previousMethod() {
      const { previous } = this.linkedLinkAlternatives[
        this.currentLinkAlternative
      ]
      if (previous) {
        this.currentLinkAlternative = this.linkedLinkAlternatives[previous].type
      }
    },

    nextMethod() {
      const { next } = this.linkedLinkAlternatives[this.currentLinkAlternative]
      if (next) {
        this.currentLinkAlternative = this.linkedLinkAlternatives[next].type
      }
    },

    ...mapMutations([
      mutations.updateOmnichannelStatus,
      mutations.updateOmnichannelCloseableStatus
    ]),

    updateOmnichannelCloseStatus(isCompleted, isPending) {
      const status = isCompleted || isPending ? true : false

      return this.updateOmnichannelCloseableStatus(status)
    },

    async checkOmnichannelStatus() {
      try {
        const { idvAccessToken } = this.$store.getters
        idvApi.updateHeaders({ authorization: idvAccessToken })
        const { success, status } = await idvApi.get(
          IDV_API_REQUESTS.OMNICHANNELS,
          this.cancellationToken,
          5
        )

        if (success) {
          if (this.status !== OMNICHANNEL_STATUSES.ACTIVE) {
            this.updateOmnichannelStatus(OMNICHANNEL_STATUSES.ACTIVE)
          }
          this.isPending = true
          this.ocStarted = true
        } else if (status === 401 || status === 403) {
          this.isPending = false
          this.$store.dispatch(actions.updateToken)
          throw new Error(API_ERRORS.UNAUTHORIZED)
        } else {
          if (this.ocStarted) {
            this.isLoading = true
            this.checkIdentification()
            this.ocStarted = false
          }

          if (this.status !== OMNICHANNEL_STATUSES.INACTIVE) {
            this.updateOmnichannelStatus(OMNICHANNEL_STATUSES.INACTIVE)

            if (this.url === null) {
              this.getUrlAndQR()
            }
          }
          this.isPending = false
        }

        await delay(OMNICHANNEL_DELAY)
        this.checkOmnichannelStatus()
      } catch (error) {
        console.error({ type: error?.message })
        if (this.isReady) {
          this.$popUp('Failed')
        }
      }
      this.isLoading = false
    },

    tryAgain() {
      this.getUrlAndQR()
      this.isCompleted = false
      this.isPending = false
      this.isError = false
      this.ocStarted = false
    },

    async close() {
      this.isLoading = true
      await redirectFailure()
    },

    async checkIdentification() {
      try {
        const isFaceAuthenticationFlow = !!sessionStorage.getItem(
          STORE_KEYS.FACE_AUTHENTICATION_ID
        )
        const apiRequest = isFaceAuthenticationFlow
          ? Api.checkFaceIdentification
          : Api.checkIdentification

        const { error, data } = await call(apiRequest)

        if (error) {
          return null
        }

        if (
          data.status === 'APPROVED' ||
          data.status === 'SUCCESSFUL' ||
          data.isAuthenticated === true
        ) {
          this.isCompleted = true
          this.$router.pushNext()
        } else if (
          (data.status === 'FAILED' ||
            data.status === 'ABORTED' ||
            data.isAuthenticated === false) &&
          this.isFailureUrlAvailable
        ) {
          this.close()
        } else {
          this.checkMessageKey = toPascalCase(data.failReason)
          const isDeadend = deadEndReasons.includes(data.failReason)
          this.canRetry = !isDeadend
          this.isCompleted = false
          this.isPending = false
          this.isError = true
        }
      } catch (error) {
        this.$popUp(error)
      }
    },

    async generateQrCode(url) {
      const qrCodeUrl = await QrCode.toDataURL(url)
      this.qrBackground = {
        backgroundImage: `url(${qrCodeUrl})`
      }
    },

    continueIdentification() {
      this.$router.pushNext()
    },

    async submitNumber(phoneNumber, callback) {
      const phoneNumberasNumber = parseFloat(
        `${phoneNumber?.code ?? ''}${phoneNumber?.number ?? ''}`
      )
      const isSuccess = await this.sendSms(phoneNumberasNumber)
      if (isSuccess) this.$popUp(popUpTypes.SmsSuccess)
      callback(isSuccess)
    },

    getIdentityVerificationId() {
      if (!this.idvAccessToken) return

      const { client_entity_id } = JwtDecode(this.idvAccessToken)
      return client_entity_id
    },

    async getIdvAccessCode(idvId) {
      const { data, error } = await idvApi.post(
        IDV_API_REQUESTS.SESSIONS + '/' + idvId + '/access-code'
      )

      if (error) {
        this.$popUp('Failed')
        return null
      }

      const { accessCode } = data
      return accessCode
    },

    async getKycAccessCode() {
      try {
        const request = this.faceAuthenticationIdFromStorage
          ? Api.startFaceAccessCodes
          : Api.startAccessCodes
        const { error, data } = await call(request)
        if (error) {
          this.$popUp(error)
          return {}
        }

        return data.id
      } catch (error) {
        this.$popUp('Failed')
      }
    },

    async getUrlPath() {
      const identityVerificationId = this.getIdentityVerificationId()
      const idvAccessCode = await this.getIdvAccessCode(identityVerificationId)
      const kycAccessCode = await this.getKycAccessCode()
      const faceAuthenticationId = this.faceAuthenticationIdFromStorage

      const path = this.language
        ? `omnichannel-start/${this.language}/`
        : `omnichannel-start`
      const url = new URL(path, window.location.origin)

      url.searchParams.append('identityVerificationId', identityVerificationId)
      url.searchParams.append('idvAccessCode', idvAccessCode)
      url.searchParams.append('kycAccessCode', kycAccessCode)

      if (faceAuthenticationId) {
        url.searchParams.append('faceAuthenticationId', faceAuthenticationId)
      }

      if (this.idvAccessCode === null) this.idvAccessCode = idvAccessCode
      if (this.idvId === null) this.idvId = identityVerificationId
      window.sessionStorage.setItem('idvAccessCode', idvAccessCode)
      window.sessionStorage.setItem(
        'identityVerificationId',
        identityVerificationId
      )

      return url.href
    },

    async getUrlAndQR() {
      this.isLoading = true
      this.url = await this.getUrlPath()
      this.shortUrl = await this.shortenUrl(this.url)
      await this.generateQrCode(this.shortUrl || this.url)
      this.isLoading = false
    },

    async shortenUrl(url) {
      if (IsDevelopment) {
        return null
      }

      try {
        const identityVerificationId = this.getIdentityVerificationId()

        const payload = {
          identityVerificationId,
          urlPath: url
        }

        const { error, success, data } = await idvApi.post(
          IDV_API_REQUESTS.URL,
          payload
        )

        if (error || !success || !data?.shortUrl) {
          this.$popUp(error || 'Failed')
          return null
        }

        return data.shortUrl
      } catch (error) {
        this.$popUp('Failed')
        return null
      }
    },

    async sendSms(phoneNumber) {
      try {
        const identityVerificationId = this.getIdentityVerificationId()
        const payload = {
          identityVerificationId,
          phoneNumber,
          urlPath: this.url
        }
        const { error, success, data } = await idvApi.post(
          IDV_API_REQUESTS.SMS,
          payload
        )

        if (error || !success) {
          if (
            data?.reason === OmnichannelFailReason.OmnichannelSmsLimitReached
          ) {
            this.disableSmsLinkDelivery()
            return false
          }
          this.$popUp(error || 'Failed')
          return false
        }

        return true
      } catch (error) {
        this.$popUp('Failed')
        return false
      }
    },

    disableSmsLinkDelivery() {
      this.$popUp('Omnichannel.SmsLimit', { isOpaque: true })

      const smsMethod = this.linkedLinkAlternatives[LINK_ALTERNATIVES.SMS]
      const { previous, next } = smsMethod

      if (previous) {
        this.linkedLinkAlternatives[previous].next = next
      }
      if (next) {
        this.linkedLinkAlternatives[next].previous = previous
      }

      this.currentLinkAlternative = next || previous
    },

    continueQR() {
      this.$router.replace({ name: appPages.omnichannelQR })
    }
  }
}
</script>

<style scoped>
h1,
h3 {
  color: var(--header-contrast-color);
}

p {
  color: var(--text-contrast-color);
}

.message-container h1,
.message-container h2,
.message-container p {
  text-align: center;
  margin-bottom: 2rem;
}

.message-container .ui-button {
  margin: auto;
}

.alternative-container {
  height: 100vh;
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
}

.alternative-container a,
.alternative-container a:visited {
  color: var(--brand);
  font-weight: bold;
  text-decoration: underline;
}

.alternative-container a:hover {
  cursor: pointer;
  filter: brightness(80%);
}

.alternative-container,
.selection-container,
.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selection-container {
  height: 100%;
  text-align: center;
  max-width: 30rem;
}

.selection-container h1 {
  text-align: center;
  margin-bottom: 1rem;
}

.selection-container h3 {
  margin-bottom: 0;
}

.top-action-container {
  width: 100%;
  margin-bottom: 2rem;
}

.top-left-action {
  margin-right: auto;
}

.top-left-action svg {
  margin-right: 0.5rem;
}

.step-container {
  width: 100%;
  max-width: 25rem;
}

.qr-box {
  width: 70%;
  padding-top: 70%;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.sms-container {
  margin-bottom: 1.5rem;
}

.link-container {
  background: var(--grey);
  filter: brightness(1.05);
  border-radius: 2px;
  margin-top: 1rem;
  padding: 0.5rem 1.5rem;
  text-align: center;
}

.result-message,
.result-message-secondary {
  text-align: center;
  margin-inline: auto;
}

.info-text {
  margin-bottom: 2rem;
  text-align: center;
}

.link-button {
  margin-top: auto;
}

.button-group {
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}
</style>
