var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app",
      class: _vm.isMobileDevice ? "mobile-app" : "desktop-app",
      attrs: { id: "app" },
    },
    [
      _c("link", {
        attrs: { rel: "stylesheet", type: "text/css", href: _vm.defaultStyles },
      }),
      _vm._v(" "),
      _vm.isBrowserSupported
        ? _c(
            "div",
            { staticClass: "app-content" },
            [
              _vm.cssSource
                ? _c("link", {
                    attrs: {
                      rel: "stylesheet",
                      type: "text/css",
                      href: _vm.cssSource,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.jsSource
                ? _c("script", {
                    attrs: {
                      type: "application/javascript",
                      src: _vm.jsSource,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("router-view", {
                attrs: { "is-shell-hidden": _vm.isShellHidden },
              }),
            ],
            1
          )
        : _vm.isBrowserDefined
        ? _c("not-supported", { staticClass: "app-content" })
        : _vm._e(),
      _vm._v(" "),
      _c("pop-up", { on: { change: _vm.popUpChange } }),
      _vm._v(" "),
      _c("div", { class: { "portrait-only": _vm.portraitOnly } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }