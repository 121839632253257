// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.instructions-container[data-v-5e8435e8] {
  display: flex;
}
.instructions-container > div[data-v-5e8435e8] {
  width: 33%;
  padding-top: 30%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.instructions-container > div[data-v-5e8435e8]:first-child {
  position: relative;
}
.instructions-container > div[data-v-5e8435e8]:first-child::before {
  content: '';
  position: absolute;
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/InstructionsContainer.vue"],"names":[],"mappings":";AA6BA;EACA,aAAA;AACA;AAEA;EACA,UAAA;EACA,gBAAA;EACA,2BAAA;EACA,wBAAA;EACA,4BAAA;AACA;AAEA;EACA,kBAAA;AACA;AAEA;EACA,WAAA;EACA,kBAAA;AACA","sourcesContent":["<template>\n  <div class=\"instructions-container\">\n    <div\n      :style=\"{\n        backgroundImage: `url('/public/images/instructions/${resource}-1.png')`\n      }\"\n    />\n    <div\n      :style=\"{\n        backgroundImage: `url('/public/images/instructions/${resource}-2.png')`\n      }\"\n    />\n    <div\n      :style=\"{\n        backgroundImage: `url('/public/images/instructions/${resource}-3.png')`\n      }\"\n    />\n  </div>\n</template>\n\n<script>\nexport default {\n  props: {\n    resource: { type: String, default: undefined, required: true }\n  }\n}\n</script>\n\n<style scoped>\n.instructions-container {\n  display: flex;\n}\n\n.instructions-container > div {\n  width: 33%;\n  padding-top: 30%;\n  background-position: center;\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n\n.instructions-container > div:first-child {\n  position: relative;\n}\n\n.instructions-container > div:first-child::before {\n  content: '';\n  position: absolute;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
