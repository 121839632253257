var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "start-page", class: { "show-content": _vm.showContent } },
    [_c("h2", [_vm._v(_vm._s(_vm.$t("App.Loading.Start")))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }