<template>
  <div class="ui-phone-code" :class="[{ 'has-error': error }, touchedClass]">
    <div class="select-container" :class="{ 'select-disabled': disabled }">
      <div class="select-tag">
        <select
          ref="select"
          :value="value"
          :disabled="disabled"
          @change="valueChange"
          @blur="lostFocus"
        >
          <option value="" hidden />
          <option
            v-for="item in phoneCodes"
            :key="item.key"
            :value="item.value"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
      <span>{{ value ? '+' : '-' }}{{ value }}</span>
    </div>
  </div>
</template>

<script>
import PhoneCodes from '@src/scripts/phone-codes'

export default {
  name: 'ui-phone-code',

  props: {
    value: { type: String, default: undefined },
    country: { type: String, default: undefined },
    error: Boolean,
    disabled: Boolean
  },

  data() {
    return {
      isTouched: false
    }
  },

  computed: {
    phoneCodes() {
      const phoneCodes = PhoneCodes

      for (const item of phoneCodes) {
        const country = this.$t(`Countries.${item.key}`)
        item.name = `${country} (+${item.value})`
      }

      phoneCodes.sort(({ name: i1 }, { name: i2 }) =>
        i1 > i2 ? 1 : i1 < i2 ? -1 : 0
      )

      return phoneCodes
    },

    touchedClass() {
      return this.isTouched ? 'ui-touched' : 'ui-untouched'
    }
  },

  mounted() {
    const { country, value, phoneCodes } = this

    let phoneData = null
    if (value) {
      phoneData = phoneCodes.find((item) => item.value === value)
    }

    if (!phoneData) {
      if (!country) return

      phoneData = phoneCodes.find((item) => item.key === country)
      if (!phoneData) return
    }

    this.$emit('input', phoneData.value)
  },

  methods: {
    valueChange(event) {
      const { value } = event.target
      this.$emit('input', value)
    },

    lostFocus() {
      this.isTouched = true
    }
  }
}
</script>

<style scoped>
.ui-phone-code {
  padding-top: 1rem;
  padding-bottom: 1rem;
  min-width: 6rem;
}

.select-container {
  padding: 0.5rem 1.75rem 0.5rem 1rem;
  position: relative;
}

.select-tag {
  border-bottom: 1px solid var(--input-color);
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
}

select {
  font-weight: var(--text-font-weight);
  opacity: 0;
  border: none;
  outline: none;
  height: 100%;
}

span {
  color: var(--text-contrast-color);
  overflow: hidden;
  font-weight: 400;
  text-align: center;
}

select,
span {
  font-family: var(--primary-font);
  font-size: 1rem;
  line-height: 1.25;
  display: block;
  width: 100%;
}

.select-container::after {
  content: '';
  position: absolute;
  top: 0.6875rem;
  right: 0.5rem;
  height: 0.75rem;
  width: 0.75rem;
  border-left: 1px solid var(--input-color);
  border-bottom: 1px solid var(--input-color);
  transform: rotate(-45deg);
}

select:hover {
  cursor: pointer;
}

.select-disabled {
  opacity: 0.5;
}

select:disabled {
  cursor: default;
}

.has-error.ui-touched {
  --input-color: var(--error);
  --dark: var(--error);
}
</style>
