import { IdvMediator, ControllerType } from '@ondato/iframe-mediator'
import { kybMethods } from '@src/scripts/enums'
import Api, { call } from '@src/scripts/api'

export const kybHandlers = {
  data() {
    return {
      kybEventController: null
    }
  },

  beforeDestroy() {
    if (this.kybEventController) {
      this.kybEventController.unregisterHandlers()
      this.kybEventController = null
    }
  },

  methods: {
    loadKybHandlers(target) {
      if (!target) {
        return
      }

      const eventHandler = new IdvMediator({
        type: ControllerType.Parent,
        iframeElement: target,
        allowedOrigin: BaseUrl.resources
      })

      eventHandler.registerHandler(kybMethods.kybForms, this.kybForms)
      this.kybEventController = eventHandler
    },

    async kybForms(data) {
      const { error } = await call(Api.kybForms, data)
      if (error) {
        this.$popUp('Failed')
        throw new Error('Failed')
      }
    }
  }
}
