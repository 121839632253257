<template>
  <ui-controls-frame>
    <ui-button
      slot="main"
      label="App.StartInterview"
      :disabled="disabled"
      @click="start"
    />
  </ui-controls-frame>
</template>

<script>
import getters from '@src/store/getters'
import actions from '@src/store/actions'
import { cameraStates } from '@src/scripts/enums'
import { mapGetters } from 'vuex'

export default {
  props: {
    startCallback: { type: Function, default: undefined }
  },

  computed: {
    ...mapGetters([getters.cameraState]),

    disabled() {
      const { cameraState } = this
      return (
        cameraState === cameraStates.pending ||
        cameraState === cameraStates.failed
      )
    }
  },

  methods: {
    start() {
      this.$store.dispatch(actions.updateCameraState, cameraStates.loading)
      if (this.startCallback) this.startCallback()
    }
  }
}
</script>
