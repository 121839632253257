<template>
  <ui-camera-frame
    v-if="!isFaceAuthenticated"
    class="passive-liveness-check"
    :resource="resource"
    :error="!!error"
    :class="{ 'hide-logo': isCameraActive }"
    active-controls
  >
    <ui-camera-description
      slot="description"
      :resource="resource"
      :message="message"
      instructions
    />
    <ui-camera
      slot="camera"
      :resource="resource"
      :controls="controller"
      :capture-callback="captureComplete"
      face
    />
    <camera-controls
      slot="controls"
      :completed="isCompleted"
      :reset-callback="reset"
      active-controls
      @start="start"
    />
  </ui-camera-frame>
  <ui-loader v-else :size="3" center />
</template>

<script>
import CameraControls from '@src/components/partials/CameraControls'
import PassiveLiveness from '@src/scripts/passiveLivenessController'
import { activityLogger } from '@src/scripts/activityLogger'
import getters from '@src/store/getters'
import mutations from '@src/store/mutations'
import { mapGetters } from 'vuex'

export default {
  components: {
    CameraControls
  },

  data() {
    return {
      isFaceAuthenticated: false,
      isCompleted: false,
      resource: 'Face',
      controller: null,
      error: null
    }
  },

  computed: {
    ...mapGetters([getters.isCameraActive]),

    message() {
      const { error } = this
      return error || 'PreLoad'
    }
  },

  created() {
    activityLogger.logActivity('IDENTIFY_FACE_PASSIVE_PAGE')
    this.controller = new PassiveLiveness()
  },

  beforeDestroy() {
    this.$store.commit(mutations.enableLiveness)
  },

  methods: {
    captureComplete({ error }) {
      if (error) {
        this.error = error
      } else {
        this.$router.pushNext()
      }

      this.isCompleted = true
    },

    reset() {
      this.error = null
      this.isCompleted = false
    },

    start() {
      this.controller.initCamera()
    }
  }
}
</script>

<style>
.passive-liveness-check .page-logo {
  transition: opacity 0.25s ease-in-out;
}

.hide-logo .page-logo {
  opacity: 0;
}

.camera-mask #alternative-method-message {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: auto;
  padding: 0.5rem 2rem;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 2rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0.5rem 1rem;
  opacity: 1;
}

.camera-mask #zoom-feedback {
  font-family: var(--text-font-family);
  letter-spacing: normal;
  color: var(--text-color);
  font-size: var(--text-font-size);
  opacity: 1;
  display: block;
}
</style>
