var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: _vm.transitionName, mode: "out-in" } },
    [
      _vm.popUp.length
        ? _c(
            "div",
            { staticClass: "pop-up", class: { opaque: _vm.isOpaque } },
            [
              !_vm.hasReloadOption &&
              _vm.popUp[0].type !== _vm.errorTypes.termsAndConditions
                ? _c(
                    "i",
                    { staticClass: "icon", on: { click: _vm.close } },
                    [_c("close-icon")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.popUp[0].type !== _vm.errorTypes.termsAndConditions
                ? _c(
                    "ui-frame",
                    { attrs: { "max-height": "" } },
                    [
                      _vm.errorTypes
                        ? _c("h1", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("Error.Header." + _vm.popUp[0].type)
                              )
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.popUp[0].type === _vm.errorTypes.noPermissions
                        ? _c("permission-details", {
                            attrs: { "button-label": _vm.buttonLabel },
                            on: { click: _vm.onClick, close: _vm.close },
                          })
                        : _vm.popUp[0].type === _vm.popUpTypes.CloseIdvProcess
                        ? _c("close-idv-process", {
                            attrs: {
                              "button-label": "App.Quit",
                              "continue-button-label": "App.ContinueProcess",
                            },
                            on: { click: _vm.onClick, close: _vm.close },
                          })
                        : _c("default-details", {
                            attrs: {
                              type: _vm.popUp[0].type,
                              options: _vm.popUp[0].options,
                              "button-label": _vm.buttonLabel,
                            },
                            on: { click: _vm.onClick, close: _vm.close },
                          }),
                    ],
                    1
                  )
                : _c(
                    "ui-frame",
                    { attrs: { "max-height": "" } },
                    [_c("terms-and-conditions", { on: { close: _vm.close } })],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }