<template>
  <div class="test-footer">
    <div class="footer-container">
      <a @click="navigate({ name: 'VideoCall' })">
        <span>Video</span>
      </a>
      <a @click="navigateFace({ name: 'Face' }, 'active')">
        <span>Face Active</span>
      </a>
      <a @click="navigateFace({ name: 'Face' }, 'passive')">
        <span>Face Passive</span>
      </a>
      <a @click="navigate({ name: 'DocumentSelect' })">
        <span>Document</span>
      </a>
      <a
        @click="
          navigate({ name: 'DocumentFront', params: { type: 'id-card' } })
        "
      >
        <span>ID Front</span>
      </a>
      <a
        @click="navigate({ name: 'DocumentBack', params: { type: 'id-card' } })"
      >
        <span>ID Back</span>
      </a>
      <a @click="navigate({ name: 'IdentificationCheck' })">
        <span>Check</span>
      </a>
      <a @click="navigate({ name: 'CompanyRepresentation' })">
        <span>Company</span>
      </a>
      <a @click="zoomEdit">
        <span>Zoom test</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import mutations from '@src/store/mutations'

export default {
  data() {
    return {
      isZoomEditing: false
    }
  },

  methods: {
    ...mapMutations([mutations.disableLiveness, mutations.enableLiveness]),

    navigateFace(options, type) {
      if (type === 'passive') {
        this.disableLiveness()
      } else if (type === 'active') {
        this.enableLiveness()
      }

      this.navigate(options)
    },

    navigate(options) {
      this.$router.pushNext(options)
    },

    zoomEdit() {
      if (this.isZoomEditing) {
        const a = document.querySelectorAll('*[id*=hmm]')
        a.forEach((item) => (item.id = item.id.replace('hmm', 'zoom')))
      } else {
        const a = document.querySelectorAll('*[id*=zoom]')
        a.forEach((item) => (item.id = item.id.replace('zoom', 'hmm')))
      }

      this.isZoomEditing = !this.isZoomEditing
    }
  }
}
</script>

<style scoped>
.test-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 5;
}

.test-footer .footer-container {
  margin: auto;
  padding: 5px 10px;
  opacity: 0;
  border: 1px solid var(--grey);
  border-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: opacity 0.3s ease;
  background-color: var(--white);
}

.test-footer .footer-container:hover {
  opacity: 1;
}

.test-footer .btn__text {
  font-size: 16px;
}

a:hover {
  cursor: pointer;
  background-color: #e9e9e9;
}
</style>
