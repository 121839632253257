var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "smart-id",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "-128 -128 768 768",
      },
    },
    [
      _c("path", {
        attrs: {
          fill: "#00B2AB",
          d:
            "M198 182.2v-158c0-14.8 3.4-18.7 18.2-21 128.6-19.9 245 51.8 284.7 175.4 31.3 97.5-5.8 212.1-88.4 273.3-9.8 7.3-18.2 7.1-23.8-.4-5.5-7.4-3.2-15.3 6.4-23 61.4-49.3 90.9-113.5 87.2-191.9-4.6-98.6-83.9-187-181.4-204-20-3.5-40.1-3.4-60.3-3.1-10.7.2-13.8 3.4-13.7 14.3.5 97.5.3 195 .3 292.5 0 4.9-.1 10-1.3 14.6-1.8 6.7-7.2 10.2-14 9.9-7.4-.4-12.3-4.7-13.5-12.1-.6-3.6-.3-7.4-.3-11.1-.1-51.7-.1-103.6-.1-155.4",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          fill: "#00B2AB",
          d:
            "M314 382.2c0 34.6.1 69.1 0 103.7 0 15.1-3.1 18.6-18 21.6-129.1 26-262.1-62.6-289.6-195.6-20.9-100.7 10-185.3 86.4-253 2.8-2.5 5.8-4.6 8.8-6.9 7.8-5.9 15.7-6 21.6 2.1 6.1 8.3 3.1 15.8-4.4 21.6-26.4 20.2-47.6 44.8-63 74.3-73.3 140.8 12.4 308.6 168.3 330 16.9 2.3 33.6 2.3 50.4 1 8-.6 10.7-3.8 10.6-12.4-.4-62.9-.2-125.7-.3-188.6 0-3.7-.1-7.5.2-11.2.6-7.9 5.6-12.2 12.7-12.9 7.2-.7 12.9 2.9 14.9 10.3 1 3.9 1.3 8.2 1.3 12.3.2 34.6.1 69.1.1 103.7M320.8 160.1c.2 9.8-7.2 17.4-17 17.4-9 0-16.8-7.6-17.1-16.5-.3-9.1 8.2-17.7 17.3-17.7 8.9.1 16.6 7.8 16.8 16.8",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }