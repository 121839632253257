<template>
  <img class="photo-review" :src="source" />
</template>

<script>
import { blobToDataUrl } from '@src/scripts/helpers'

export default {
  props: {
    photo: { type: Blob, default: undefined },
    thumbnail: { type: String, default: undefined }
  },

  data() {
    return {
      source: null
    }
  },

  async created() {
    const { photo, thumbnail } = this
    if (!photo) return (this.source = null)

    this.source = thumbnail || (await blobToDataUrl(photo))
  }
}
</script>

<style scoped>
.photo-review {
  width: 100%;
}
</style>
