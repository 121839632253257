<template>
  <div class="shell" :class="{ 'shell-hidden': isLoading }">
    <i v-if="shouldCloseBeVisible" class="icon" @click="handleClose">
      <close-icon />
    </i>
    <div class="shell-content" :class="{ 'content-hide': isShellHiden }">
      <progress-bar />
      <div class="page-content">
        <transition name="slide" mode="out-in">
          <router-view v-if="showContent" :key="pageId" />
          <no-session v-else-if="hasSession" />
        </transition>
      </div>
    </div>
    <test-footer v-if="hasTestFooter" />
  </div>
</template>

<script>
import CloseIcon from '@src/components/images/CloseIcon'
import NoSession from '@src/components/partials/NoSession'
import ProgressBar from '@src/components/partials/ProgressBar'
import TestFooter from '@src/components/partials/TestFooter'
import mutations from '@src/store/mutations'
import getters from '@src/store/getters'
import Api, { call } from '@src/scripts/api'
import ScreenRecorder from '@src/scripts/screenRecorder'
import { appPages, errorTypes, popUpTypes } from '@src/scripts/enums'
import { mapGetters, mapMutations } from 'vuex'
import { start as realTimeApiStart } from '@src/scripts/realTimeApi'
import { omnichannel } from '@src/mixins/omnichannel'
import { popUp } from '@src/components/popUp'

export default {
  components: {
    CloseIcon,
    NoSession,
    ProgressBar,
    TestFooter
  },

  mixins: [omnichannel],

  props: {
    isShellHiden: Boolean
  },

  data() {
    return {
      hasTestFooter: IsDevelopment,
      isLoading: true,
      pageName: null,
      screenRecorder: null
    }
  },

  computed: {
    ...mapGetters([
      getters.isLoggedIn,
      getters.isLoggedIn,
      getters.hasSession,
      getters.termsStatus,
      getters.isRecordRequired,
      getters.isOmnichannelNonCloseable,
      getters.isFailureUrlAvailable
    ]),

    isOmnichannelInNonCloseableState() {
      return this.isOmnichannelNonCloseable
    },

    shouldCloseBeVisible() {
      const routesWithoutClose = [
        'IdentificationCheck',
        appPages.omnichannelCompleted
      ]

      return (
        !routesWithoutClose.includes(this.$route.name) &&
        this.isFailureUrlAvailable &&
        !this.isOmnichannelInNonCloseableState
      )
    },

    showContent() {
      const { isLoggedIn } = this
      return isLoggedIn
    },

    pageId() {
      const { path } = this.$route
      return path
    },

    session() {
      return this.$store.state.session
    },

    browserData() {
      return this.$store.state.browserData
    },

    recordRequired() {
      const { isLoggedIn, isRecordRequired } = this
      return isLoggedIn && isRecordRequired
    }
  },

  watch: {
    isLoggedIn: {
      handler() {
        if (this.isLoggedIn) realTimeApiStart()
      },
      immediate: true
    },

    termsStatus(value) {
      this.loadTerms(value)
    },

    recordRequired(value, oldValue) {
      if (value === oldValue) return

      if (value) {
        if (this.screenRecorder) return

        this.screenRecorder = new ScreenRecorder({
          browserData: this.browserData,
          onError: this.onRecordError
        })
      } else if (this.screenRecorder) {
        this.screenRecorder.stop()
        this.screenRecorder = null
      }
    }
  },

  created() {
    this.validateBrowser()
    this.$nextTick(() => {
      this.isLoading = false
    })
  },

  updated() {
    if (this.pageName === this.$route.name) return
    this.pageName = this.$route.name
    this.loadTerms(this.termsStatus)
  },

  methods: {
    ...mapMutations([mutations.updateSession]),

    loadTerms(rule) {
      if (rule && rule.required) {
        if (rule.parameters && rule.parameters !== this.pageName) return
        this.$popUp(errorTypes.termsAndConditions)
      }
    },

    async validateBrowser() {
      const settings = await this.getSettings()
      if (settings && !this.$router.allowedRoute()) {
        this.$router.replaceNext()
      }
    },

    async getSettings() {
      const { session } = this
      if (session.clientName) return session

      const { error, data } = await call(Api.settings)
      if (error) {
        this.$popUp(error)
        return null
      } else {
        this.updateSession(data)
        return data
      }
    },

    onRecordError(errorType) {
      this.$popUp(errorType)
    },

    handleClose() {
      popUp(popUpTypes.CloseIdvProcess)
    }
  }
}
</script>

<style scoped>
.shell {
  position: relative;
  height: 100%;
}

.shell-content {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 0;
  transition: filter var(--page-time) ease-in-out;
}

.page-content {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
}

.content-hide {
  filter: blur(0.5rem);
}

.shell,
.shell .page-content,
.slide-enter-active,
.slide-leave-active {
  transition-duration: var(--page-time);
  transition-property: opacity, transform;
  transition-timing-function: ease-in-out;
}

.shell .page-content {
  transition-delay: var(--page-time);
}

.shell-hidden > .page-content,
.slide-enter {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-leave-active {
  transform: translateX(100%);
  opacity: 0;
}

.shell-hidden {
  opacity: 0;
}

.icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 2;
}

.icon:hover {
  cursor: pointer;
}
</style>
