// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.page-logo[data-v-6141419e] {
  position: absolute;
  top: var(--logo-top);
  left: var(--logo-left);
  height: var(--logo-height);
  width: var(--logo-width);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/PageLogo.vue"],"names":[],"mappings":";AAkBA;EACA,kBAAA;EACA,oBAAA;EACA,sBAAA;EACA,0BAAA;EACA,wBAAA;EACA,wBAAA;EACA,2BAAA;EACA,4BAAA;EACA,UAAA;AACA","sourcesContent":["<template>\n  <div\n    v-if=\"logoSource\"\n    class=\"page-logo\"\n    :style=\"{ backgroundImage: `url('${logoSource}')` }\"\n  />\n</template>\n\n<script>\nimport getters from '@src/store/getters'\nimport { mapGetters } from 'vuex'\n\nexport default {\n  computed: mapGetters([getters.logoSource])\n}\n</script>\n\n<style scoped>\n.page-logo {\n  position: absolute;\n  top: var(--logo-top);\n  left: var(--logo-left);\n  height: var(--logo-height);\n  width: var(--logo-width);\n  background-size: contain;\n  background-position: center;\n  background-repeat: no-repeat;\n  z-index: 1;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
