var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ui-check-box" }, [
    _c("label", { class: [{ "has-error": _vm.error }, _vm.touchedClass] }, [
      _c("input", {
        attrs: { type: "checkbox", disabled: _vm.disabled },
        domProps: { value: true, checked: _vm.value },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event.target.checked)
          },
          change: _vm.onChange,
        },
      }),
      _vm._v(" "),
      _c("i", { staticClass: "check-box-square" }),
      _vm._v(" "),
      _c("span", { staticClass: "check-box-name" }, [
        _vm._v(_vm._s(_vm.$t(_vm.label))),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "check-box-error" }, [
        _vm._v(_vm._s(_vm.$t(_vm.error))),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }