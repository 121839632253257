<template>
  <div class="identification-check-container">
    <div class="identification-check-box">
      <div class="policy-content">
        <ui-frame v-if="isReady" class="identification-check">
          <div class="frame-content">
            <id-check-loader
              v-if="isIdentityCheckFailed || isSuccess"
              :is-success="isSuccess"
              :is-error="isError"
            />
            <transition name="fade">
              <div class="result-message-group">
                <h2
                  v-if="isIdentityCheckFailed || isSuccess"
                  class="result-message"
                >
                  {{ $t(messagePrimary) }}
                </h2>
                <div
                  v-if="isIdentityCheckFailed || isSuccess"
                  class="result-message-secondary"
                >
                  {{ $t(messageSecondary) }}
                </div>
              </div>
            </transition>
            <div v-if="isIdentityCheckFailed" class="button-group">
              <ui-button
                v-if="canRetry"
                label="App.TryAgain"
                :disabled="isLoading"
                @click="action"
              />
              <ui-button
                v-if="isFailureUrlAvailable"
                additional
                label="App.Close"
                @click="close"
              />
              <omnichannel-controls v-if="isOmnichannelEnabled" />
            </div>
            <div
              v-if="!isIdentityCheckFailed && isSuccess"
              class="button-group"
            >
              <ui-button
                type="button"
                label="Check.Success.button"
                :timer="getRedirectTimer(redirectTimer)"
                :disabled="isLoading"
                @click="forceRedirect"
              />
            </div>
            <id-check-loader
              v-if="!isIdentityCheckFailed && !isSuccess"
              :is-success="isSuccess"
              :is-error="isError"
            />
          </div>
        </ui-frame>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import OmnichannelControls from '@src/components/partials/OmnichannelControls'
import { activityLogger } from '@src/scripts/activityLogger'
import Api, { call } from '@src/scripts/api'
import actions from '@src/store/actions'
import getters from '@src/store/getters'
import mutations from '@src/store/mutations'
import { ID_CHECK_LOADER } from '@src/scripts/constants'
import { toPascalCase } from '@src/scripts/helpers'
import IdCheckLoader from '@src/components/partials/IdCheckLoader'
import { appPages, deadEndReasons } from '@src/scripts/enums'
import { redirectFailure } from '@src/scripts/routerManager'

export default {
  components: {
    OmnichannelControls,
    IdCheckLoader
  },

  data() {
    return {
      isReady: false,
      isTest: !IsProduction,
      isCompleted: false,
      isLoading: false,
      isOmnichannelEnabled: false,
      canRetry: true,
      checkMessageKey: null,
      isSuccess: false,
      isError: false,
      redirectTimer: ID_CHECK_LOADER.APPROVE_REDIRECT_DELAY,
      interval: null
    }
  },

  computed: {
    ...mapGetters([
      getters.claims,
      getters.sessionExpiration,
      getters.isFailureUrlAvailable
    ]),

    // Percentage fake loaded after error occurred
    isIdentityCheckFailed() {
      return this.$store.getters.isIdentityCheckFailed
    },

    messagePrimary() {
      const { checkMessageKey, isSuccess } = this

      return isSuccess
        ? `Check.Success.primaryMessage`
        : `Check.Failed.${checkMessageKey || 'Undefined'}.primaryMessage`
    },

    messageSecondary() {
      const { checkMessageKey, isSuccess } = this

      return isSuccess
        ? `Check.Success.secondaryMessage`
        : `Check.Failed.${checkMessageKey || 'Undefined'}.secondaryMessage`
    },

    video() {
      const { isCompleted } = this
      return !isCompleted && this.$te('Check.Video') && this.$t('Check.Video')
    }
  },

  async created() {
    if (
      !this.claims.includes('check') &&
      !(await this.createCheckAccessToken())
    ) {
      return
    }
    this.checkIdentification()

    activityLogger.logActivity('WAITING_PAGE')
  },

  methods: {
    ...mapMutations([mutations.setIdentityCheckFailed]),

    repeatCheck() {
      const now = Date.now()
      const waitMinutes =
        30 - Math.floor((this.sessionExpiration - now) / 60000)

      let delay = 60000
      if (waitMinutes < 2) {
        delay = 15000
      } else if (waitMinutes < 5) {
        delay = 30000
      }

      setTimeout(this.checkIdentification, delay)
    },

    async close() {
      this.isLoading = true
      await redirectFailure(this.failureUrl)
    },

    async checkIdentification() {
      const details = await this.getIdentificationDetails()
      if (!details) {
        activityLogger.logActivity('WAITING_PAGE_EMPTY_RESPONSE')
      }

      const { status, failReason } = details || {}

      if (
        status !== 'APPROVED' &&
        status !== 'SUCCESSFUL' &&
        status !== 'FAILED'
      ) {
        this.isReady = true
        this.repeatCheck()
        return
      }

      if (status === 'APPROVED') {
        activityLogger.logActivity('WAITING_PAGE_APPROVED')
        this.autoRedirect()

        return
      }

      if (status === 'SUCCESSFUL') {
        activityLogger.logActivity('WAITING_PAGE_SUCCESSFUL')
        this.autoRedirect()

        return
      }

      activityLogger.logActivity('WAITING_PAGE_REJECTED')

      this.isReady = true
      this.isCompleted = true
      this.isError = true

      this.checkMessageKey = toPascalCase(failReason)
      const isDeadend = deadEndReasons.includes(failReason)
      this.canRetry = !isDeadend

      if (!isDeadend) {
        this.validateOmnichannel(failReason)
      }
    },

    validateOmnichannel(reason) {
      const omnichannelReasons = ['POOR_PHOTO_QUALITY']

      this.isOmnichannelEnabled = omnichannelReasons.includes(reason)
    },

    autoRedirect() {
      this.isSuccess = true
      this.isCompleted = true

      this.interval = setInterval(() => {
        if (this.redirectTimer > 0) {
          this.redirectTimer = this.redirectTimer - 1000
        } else if (this.redirectTimer <= 0) {
          this.next()
          clearInterval(this.interval)
          this.interval = null
        }
      }, 1000)
    },

    action() {
      this.isLoading = true
      this.startOver()
    },

    forceRedirect() {
      this.isLoading = true
      clearInterval(this.interval)
      this.interval = null
      this.next()
    },

    getRedirectTimer(timeMilliseconds) {
      return timeMilliseconds / 1000
    },

    async startOver() {
      if (await this.updateAccessToken(Api.identificationStartOver)) {
        this.$router.replaceNext({ name: appPages.home })
        this.setIdentityCheckFailed(false)
      }
    },

    async next() {
      if (await this.updateAccessToken(Api.checkContinue)) {
        this.$router.pushNext()
      }
    },

    async updateAccessToken(method) {
      const sessionData = await this.getAccessToken(method)
      if (!sessionData || !sessionData.accessToken) {
        this.isLoading = false
        return false
      }

      this.$store.dispatch(actions.updateToken, sessionData.accessToken)
      return true
    },

    async createCheckAccessToken() {
      try {
        const { error, data } = await call(Api.checkAccessToken)
        if (error) {
          activityLogger.logActivity('WAITING_PAGE_ERROR_CREATE_ACCESS')
          this.$popUp(error)
          return false
        }

        this.$store.dispatch(actions.updateToken, data.accessToken)
        return true
      } catch (error) {
        activityLogger.logActivity('WAITING_PAGE_ERROR_CREATE_ACCESS_CALL')
        this.$popUp(error)
        return false
      }
    },

    async getIdentificationDetails() {
      try {
        const { error, data } = await call(Api.checkIdentification)
        if (error) {
          activityLogger.logActivity('WAITING_PAGE_ERROR_DETAILS')
          this.$popUp(error)
          return null
        }

        return data
      } catch (error) {
        activityLogger.logActivity('WAITING_PAGE_ERROR_DETAILS_CALL')
        this.$popUp(error)
        return null
      }
    },

    async getAccessToken(method) {
      try {
        const { error, data } = await call(method)
        if (error) {
          activityLogger.logActivity('WAITING_PAGE_ERROR_ACCESS')
          this.$popUp(error)
          return
        }
        return data
      } catch (error) {
        activityLogger.logActivity('WAITING_PAGE_ERROR_ACCESS_CALL')
        this.$popUp(error)
      }
    }
  }
}
</script>

<style scoped>
.identification-check-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
}

.identification-check-box {
  width: 55rem;
  height: max-content;
  margin: 2rem;
  background-color: var(--white);
  border: var(--popup-border);
  border-radius: var(--popup-border-radius);
  box-shadow: var(--popup-shadow-size) #ccc;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease-in;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.identification-check {
  align-items: center;
}

.frame-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}

h2,
.counter {
  color: var(--header-contrast-color);
}

p {
  max-width: 26rem;
  text-align: center;
  color: var(--text-contrast-color);
}

.result-message-group {
  width: 100%;
}

.result-message,
.result-message-secondary {
  max-width: 30rem;
  text-align: center;
  margin-inline: auto;
}

.result-message-secondary {
  margin-top: 2rem;
}

.button-group {
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}
.button-group > .ui-button {
  margin-left: 0.5rem;
}

.ui-frame.identification-check {
  overflow: auto;
}

.counter {
  font-size: 3rem;
  font-weight: 500;
  margin-top: 1rem;
}

.check-video {
  margin-top: 2rem;
}

.check-video:focus {
  outline: none;
}

@media screen and (min-height: 600px),
  (min-width: 480px) and (max-height: 1200px),
  (max-width: 720px) {
  h2 {
    font-size: 1.25em;
  }
}

@media screen and (max-height: 600px), (max-width: 720px) {
  h2 {
    font-size: 1em;
  }
}
</style>

<style>
.mobile-app .check-video {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .desktop-app .check-video {
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .desktop-app .check-video {
    height: 45vh;
  }
}
</style>
