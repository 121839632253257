var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "mobile-id",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "-15 -15 115 115",
        fill: "none",
      },
    },
    [
      _c("circle", {
        attrs: { cx: "42.5", cy: "42.5", r: "42.5", fill: "#076CE3" },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M45.2266 68.3292C59.3786 68.0229 70.4268 56.931 70.7522 44.4061C71.0229 34.0553 63.9319 24.1502 53.1572 20.472",
          stroke: "white",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M44.6877 46.4653C45.8205 46.4653 46.7388 45.547 46.7388 44.4143C46.7388 43.2815 45.8205 42.3633 44.6877 42.3633C43.555 42.3633 42.6367 43.2815 42.6367 44.4143C42.6367 45.547 43.555 46.4653 44.6877 46.4653Z",
          fill: "white",
          stroke: "white",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M22.1265 20.4857C24.2933 23.8002 33.3751 35.2531 33.3751 35.2531C33.3751 35.2531 43.5859 22.7637 44.8244 20.4857",
          stroke: "white",
          "stroke-width": "3",
          "stroke-miterlimit": "10",
          "stroke-linecap": "round",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M22.1265 58.2245L22.1265 20.4857",
          stroke: "white",
          "stroke-width": "3",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M45.0981 38.2612L45.0981 20.4857",
          stroke: "white",
          "stroke-width": "3",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M44.8247 68.3428L44.8247 50.5673",
          stroke: "white",
          "stroke-width": "3",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }