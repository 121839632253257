<template>
  <ui-loader v-if="step === 'START'" :size="3" center />
  <ui-frame v-else-if="step === 'SEND'" class="document-sign" full-size padding>
    <div class="sign-content">
      <h1 class="document-header">
        {{ $t('DocumentSign.Header') }}
      </h1>
      <p class="sign-description">
        {{ $t('DocumentSign.Description') }}
      </p>
      <div class="pdf-box">
        <iframe :src="client.document" width="100%" height="100%" />
      </div>
      <p class="client-details">
        <span>{{ $t('DocumentSign.Client') }}:</span>
        {{ client.firstName }} {{ client.lastName }}
      </p>
      <div class="sign-footer">
        <div class="sign-footer-item">
          <sms-confirmation
            resource="DocumentSign"
            :country="countryCode"
            :phone-number="phoneNumber"
            :read-only="isReadOnly"
            @complete="validationCompleted"
          />
        </div>
      </div>
    </div>
  </ui-frame>
  <ui-frame v-else-if="step === 'CONFIRM'">
    <ui-form class="sign-content" @submit="submitCode">
      <h1 class="document-header">
        {{ $t('DocumentSign.Header') }}
      </h1>
      <p class="client-details details-center">
        <span>{{ $t('DocumentSign.Client') }}:</span>
        {{ client.firstName }} {{ client.lastName }}
      </p>
      <div class="code-content">
        <ui-input
          v-model="code"
          label="DocumentSign.Code"
          max-length="6"
          :error="codeError"
          :disabled="isConfirmed"
        />
        <ui-loader :size="2" />
        {{ time }}
      </div>
      <div class="confirmation-content">
        <ui-check-box
          v-model="confirmation"
          label="DocumentSign.Confirmation"
          :error="confirmationError"
          :disabled="isConfirmed"
        />
      </div>
      <div class="code-controls">
        <ui-button
          label="DocumentSign.Change"
          type="button"
          additional
          :disabled="isConfirmed"
          @click="changeNumber"
        />
        <ui-button label="DocumentSign.CodeSend" :disabled="isConfirmed" />
      </div>
    </ui-form>
  </ui-frame>
  <ui-frame v-else-if="step === 'COMPLETED'">
    <div class="sign-content">
      <h1 class="document-header">
        {{ $t('DocumentSign.Header') }}
      </h1>
      <p class="sign-description">
        {{ $t('DocumentSign.Completed') }}
      </p>
      <div class="pdf-box">
        <iframe :src="client.document" width="100%" height="100%" />
      </div>
      <div class="sign-footer">
        <ui-button
          class="sign-complete"
          label="App.Continue"
          @click="continueProcess"
        />
      </div>
    </div>
  </ui-frame>
</template>

<script>
import SmsConfirmation from '@src/components/partials/SmsConfirmation'
import Validators from '@src/scripts/validators'
import { activityLogger } from '@src/scripts/activityLogger'
import getters from '@src/store/getters'
import Api, { call } from '@src/scripts/api'
import { mapGetters } from 'vuex'

export default {
  components: {
    SmsConfirmation
  },

  data() {
    return {
      step: 'Start',
      client: null,
      defaultTime: 120,
      time: null,
      code: null,
      confirmation: null,
      codeError: null,
      confirmationError: null,
      phoneNumber: null,
      isConfirmed: false
    }
  },

  computed: {
    ...mapGetters([getters.countryCode]),

    isReadOnly() {
      return !!this.client?.phoneNumber
    }
  },

  watch: {
    step(value) {
      if (value === 'SEND') {
        activityLogger.logActivity('DOCUMENT_SIGN_PAGE')
      } else if (value === 'CONFIRM') {
        activityLogger.logActivity('DOCUMENT_SIGN_CONFIRMATION')
      } else if (value === 'COMPLETED') {
        activityLogger.logActivity('DISPLAYED_SIGNED_DOCUMENT')
      }
    }
  },

  async created() {
    this.client = await this.getSignData()
    if (this.client?.isSigned) this.step = 'COMPLETED'
    else this.step = 'SEND'

    if (!this.client?.phoneNumber) return

    const phoneNumber = this.client.phoneNumber.replace(/\D+/g, '')
    this.phoneNumber = {
      number: phoneNumber
    }
  },

  methods: {
    changeNumber() {
      this.step = 'SEND'
    },

    continueProcess() {
      this.$router.pushNext()
    },

    async validationCompleted(phoneNumberDetails, callback) {
      const number = this.isReadOnly
        ? phoneNumberDetails.number
        : `${phoneNumberDetails.code}${phoneNumberDetails.number}`
      const isSent = await this.sendSms(number)
      callback(isSent)
      if (isSent) {
        this.phoneNumber = {
          ...phoneNumberDetails
        }
        this.step = 'CONFIRM'
        this.time = this.defaultTime
        this.code = null
        this.confirmationDelay()
      }
    },

    confirmationDelay() {
      if (this.step !== 'CONFIRM') return

      setTimeout(() => {
        this.time--
        if (!this.time) {
          this.step = 'SEND'
        } else {
          this.confirmationDelay()
        }
      }, 1000)
    },

    async submitCode() {
      if (!this.validate()) return

      const { code, confirmation } = this
      this.isConfirmed = true

      const result = await this.confirmCode({ code, confirmation })
      if (result) {
        this.client.document = result.document
        this.step = 'COMPLETED'
      } else {
        this.step = 'SEND'
        this.isConfirmed = false
      }
    },

    validate() {
      let isValid = true
      if (!this.validateCode()) isValid = false
      if (!this.validateConfirmation()) isValid = false

      return isValid
    },

    validateCode() {
      const { code } = this

      let error = Validators.requiredValidator(code)
      if (!error) error = Validators.minLengthValidator(code, 6)
      if (!error) error = Validators.integerValidator(code)

      if (error) this.codeError = `DocumentSign.Error.${error}`
      else this.codeError = null

      return !error
    },

    validateConfirmation() {
      const { confirmation } = this
      if (confirmation) {
        this.confirmationError = null
        return true
      } else {
        this.confirmationError = 'DocumentSign.Error.Required'
        return false
      }
    },

    async getSignData() {
      try {
        const { error, data } = await call(Api.documentSignData)
        if (error) {
          this.$popUp(error)
          return
        }
        return data
      } catch (error) {
        this.$popUp('Failed')
      }
    },

    async sendSms(phoneNumber) {
      try {
        const { error } = await call(Api.documentSmsSign, {
          phoneNumber
        })
        if (error) {
          this.$popUp(error)
          return false
        }
        return true
      } catch (error) {
        this.$popUp('Failed')
        return false
      }
    },

    async confirmCode(codeBody) {
      try {
        const { error, data } = await call(Api.documentSignConfirm, codeBody)
        if (error) {
          this.$popUp(error)
          return
        }
        return data
      } catch (error) {
        this.$popUp('Failed')
      }
    }
  }
}
</script>

<style scoped>
.sign-content {
  max-width: 60rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  text-align: center;
  margin-bottom: 3rem;
  color: var(--header-contrast-color);
}

p,
span {
  color: var(--text-contrast-color);
}

.sign-description {
  text-align: center;
  margin-top: -2rem;
  margin-bottom: 2rem;
}

.pdf-box {
  height: 25rem;
}

.client-details,
.client-details > span {
  font-size: 1.25rem;
}

.client-details {
  margin-top: 1.5rem;
  text-align: center;
}

.client-details > span {
  font-weight: 500;
}

.sign-footer {
  display: flex;
  justify-content: center;
}

.sign-footer-item {
  min-width: 18.75rem;
}

.code-controls,
.code-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.code-content .ui-loader {
  margin-left: 2rem;
  margin-right: 1rem;
}

.code-content .ui-input {
  max-width: 10rem;
}

.confirmation-content {
  max-width: 19rem;
  margin-left: auto;
  margin-right: auto;
}

.code-controls .ui-button {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.sign-complete {
  margin-top: 2rem;
}
</style>
