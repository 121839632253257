// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.test-water-mark[data-v-3e1ef631] {
  position: absolute;
  top: 0;
  left: 35%;
  height: 100%;
  width: 1.5rem;
  background-color: var(--white);
  opacity: 0.2;
  overflow: hidden;
}
.test-water-mark > span[data-v-3e1ef631] {
  color: var(--black);
  white-space: nowrap;
  display: block;
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: 700;
  transform: rotate(90deg);
  word-spacing: 0.5rem;
  letter-spacing: 0.25rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/TestWaterMark.vue"],"names":[],"mappings":";AAcA;EACA,kBAAA;EACA,MAAA;EACA,SAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,YAAA;EACA,gBAAA;AACA;AAEA;EACA,mBAAA;EACA,mBAAA;EACA,cAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,wBAAA;EACA,oBAAA;EACA,uBAAA;AACA","sourcesContent":["<template>\n  <div class=\"test-water-mark\">\n    <span>\n      TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST\n      TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST\n    </span>\n  </div>\n</template>\n\n<script>\nexport default {}\n</script>\n\n<style scoped>\n.test-water-mark {\n  position: absolute;\n  top: 0;\n  left: 35%;\n  height: 100%;\n  width: 1.5rem;\n  background-color: var(--white);\n  opacity: 0.2;\n  overflow: hidden;\n}\n\n.test-water-mark > span {\n  color: var(--black);\n  white-space: nowrap;\n  display: block;\n  line-height: 1.5rem;\n  font-size: 1rem;\n  font-weight: 700;\n  transform: rotate(90deg);\n  word-spacing: 0.5rem;\n  letter-spacing: 0.25rem;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
