<template>
  <div class="start-page" :class="{ 'show-content': showContent }">
    <h2>{{ $t('App.Loading.Start') }}</h2>
  </div>
</template>

<script>
import Api, { call } from '@src/scripts/api'
import actions from '@src/store/actions'
import { appPages } from '@src/scripts/enums'

export default {
  data() {
    return {
      showContent: false
    }
  },

  computed: {
    omnichannelId() {
      return this.$route.params.omnichannelId
    },

    language() {
      return this.$language()
    }
  },

  async created() {
    setTimeout(() => {
      this.showContent = true
    })

    const method =
      this.$route.name === 'PermissionsContinue'
        ? Api.permissionsContinue
        : Api.omnichannelContinue

    const { omnichannelId, language } = this
    const { data } = await call(method, {
      omnichannelId
    })

    if (data) {
      this.$store.dispatch(actions.updateToken, data.accessToken)
    }
    this.$router.replace({ name: appPages.home, params: { language } })
  }
}
</script>

<style scoped>
.start-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity var(--page-time) ease 0.5s;
}

.start-page > h2 {
  margin: auto;
  color: var(--header-contrast-color);
}

.start-page.show-content {
  opacity: 1;
}
</style>
