var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ui-drop-down" }, [
    _c(
      "label",
      {
        class: [
          { "not-empty": _vm.value, "has-error": _vm.error },
          _vm.touchedClass,
        ],
      },
      [
        _c(
          "select",
          {
            domProps: { value: _vm.value },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event.target.value)
              },
              blur: _vm.lostFocus,
            },
          },
          [
            _c("option", { attrs: { hidden: "", disabled: "", selected: "" } }),
            _vm._v(" "),
            _vm._l(_vm.sortedOptions, function (item) {
              return _c(
                "option",
                { key: item.key, domProps: { value: item.key } },
                [_vm._v("\n        " + _vm._s(item.value) + "\n      ")]
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("span", { staticClass: "drop-down-name" }, [
          _vm._v(_vm._s(_vm.$t(_vm.label))),
        ]),
        _vm._v(" "),
        _c("i", { staticClass: "drop-down-caret" }),
        _vm._v(" "),
        _c("span", { staticClass: "drop-down-error" }, [
          _vm._v(_vm._s(_vm.$t(_vm.error))),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }