var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.message
    ? _c(
        "div",
        { staticClass: "ui-camera-description", class: { review: _vm.review } },
        [
          _c("h2", [_vm._v(_vm._s(_vm.$td("Header." + _vm.message)))]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n    " + _vm._s(_vm.$td("Description." + _vm.message)) + "\n  "
            ),
          ]),
          _vm._v(" "),
          _vm.instructions
            ? _c("instructions-container", {
                attrs: { resource: _vm.resource },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }