var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c(
        "ui-frame",
        [
          _c("h1", { staticClass: "document-header" }, [
            _vm._v("\n    " + _vm._s(_vm.$t("Company.Header")) + "\n  "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "document-description" }, [
            _vm._v("\n    " + _vm._s(_vm.$t("Company.Description")) + "\n  "),
          ]),
          _vm._v(" "),
          _c(
            "ui-form",
            { staticClass: "document-content", on: { submit: _vm.submit } },
            [
              _c("ui-drop-down", {
                attrs: {
                  label: "Company.CountryLabel",
                  options: _vm.countryList,
                  value: _vm.country,
                  error: _vm.countryErrorMessage,
                  sort: "",
                },
                on: { input: _vm.countryChanged },
              }),
              _vm._v(" "),
              _c("ui-input", {
                attrs: {
                  label: "Company.TitleLabel",
                  value: _vm.companyName,
                  error: _vm.companyNameErrorMessage,
                  "max-length": "50",
                },
                on: { input: _vm.companyNameChanged },
              }),
              _vm._v(" "),
              _c("ui-input", {
                attrs: {
                  label: "Company.InputLabel",
                  value: _vm.companyCode,
                  error: _vm.companyCodeErrorMessage,
                  "max-length": "20",
                },
                on: { input: _vm.companyCodeChanged },
              }),
              _vm._v(" "),
              _c("ui-button", { attrs: { label: "App.Continue" } }),
            ],
            1
          ),
        ],
        1
      )
    : _c("ui-frame", [_c("ui-loader", { attrs: { size: 3, center: "" } })], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }