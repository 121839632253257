<template>
  <ui-frame class="form-page" full-size>
    <iframe ref="formView" :src="`${formUrl}?v=${Date.now()}`" />
  </ui-frame>
</template>

<script>
import Api, { call, get } from '@src/scripts/api'
import { loginHandlers } from '@src/mixins/loginHandlers'
import { kybHandlers } from '@src/mixins/kybHandlers'
import { apiProxyHandlers } from '@src/mixins/apiProxyHandlers'
import { fileHandlers } from '@src/mixins/fileHandlers'
import { formDataHandlers } from '@src/mixins/formDataHandlers'
import { activityLogger } from '@src/scripts/activityLogger'
import EventController, { eventTypes } from '@src/scripts/eventController'
import getters from '@src/store/getters'
import actions from '@src/store/actions'
import { mapGetters } from 'vuex'
import { STORE_KEYS } from '@src/scripts/constants'
import { signMethodType, errorTypes } from '@src/scripts/enums'
import { ondatoHttpClient } from '@src/scripts/ondatoHttpClient'

export default {
  mixins: [
    apiProxyHandlers,
    loginHandlers,
    kybHandlers,
    fileHandlers,
    formDataHandlers
  ],

  props: {
    signRequired: Boolean
  },

  data() {
    return {
      formUrl: null,
      formStart: null,
      formEvent: null
    }
  },

  computed: {
    ...mapGetters([
      getters.formType,
      getters.sessionExpiration,
      getters.cssSource,
      getters.resourceDirectory,
      getters.accessToken,
      getters.googleMapsKey
    ]),

    formSide() {
      return this.$route.name === 'FormFront' ? 'Front' : 'Back'
    }
  },

  watch: {
    formType(value) {
      this.loadFormView(value)
    }
  },

  mounted() {
    activityLogger.logActivity('FORM_PAGE')

    this.formStart = this.getFormData()

    this.loadFormView(this.formType)
    this.loadApiProxyHandlers(this.$refs.formView)
    this.loadLoginHandlers(this.$refs.formView)
    this.loadKybHandlers(this.$refs.formView)
    this.loadFileHandlers(this.$refs.formView)
    this.loadFormDataHandlers(this.$refs.formView)
    this.registerEvents()
  },

  beforeDestroy() {
    const { formEvent } = this
    if (formEvent) {
      formEvent.clearListeners()
    }
  },

  methods: {
    loadFormView(formName) {
      if (!formName) return

      const path = formName.toLowerCase()
      this.formUrl = `${BaseUrl.resources}/forms/${path}/index.html`
    },

    registerEvents() {
      this.formEvent = new EventController({
        target: this.$refs.formView,
        allowedOrigin: BaseUrl.resources
      })

      this.formEvent.addListener(eventTypes.formReady, this.formReady)
      this.formEvent.addListener(eventTypes.formUpdate, this.formUpdate)
      this.formEvent.addListener(eventTypes.formComplete, this.formComplete)
      this.formEvent.addListener(
        eventTypes.formClientDetails,
        this.formClientDetails
      )
      this.formEvent.addListener(
        eventTypes.formPdfSignature,
        this.formPdfSignature
      )
      this.formEvent.addListener(eventTypes.formSign, this.formSign)
      this.formEvent.addListener(
        eventTypes.formLanguageChange,
        this.formLanguageChange
      )
      this.formEvent.addListener(eventTypes.formSessionEnd, this.formSessionEnd)
      this.formEvent.addListener(eventTypes.formLoaded, this.formLoaded)
      this.formEvent.addListener(eventTypes.formLog, this.formLog)
      this.formEvent.addListener(
        eventTypes.formSessionRestart,
        this.formSessionRestart
      )
      this.formEvent.addListener(eventTypes.formCall, this.formCall)
      this.formEvent.addListener(
        eventTypes.formLoginFinalize,
        this.formLoginFinalize
      )
      this.formEvent.addListener(eventTypes.formSmsLogin, this.formSmsLogin)
      this.formEvent.addListener(
        eventTypes.formSmsLoginVerify,
        this.formSmsLoginVerify
      )
      this.formEvent.addListener(
        eventTypes.formLoginSmartId,
        this.formLoginSmartId
      )
      this.formEvent.addListener(
        eventTypes.formGetPersonData,
        this.formGetPersonData
      )
      this.formEvent.addListener(
        eventTypes.formGetOfficialsData,
        this.formGetOfficialsData
      )
    },

    async formReady() {
      const data = this.handleResponse(await this.formStart)
      if (!data) return

      this.formEvent.triggerSuccess(eventTypes.formLoad, {
        language: this.$language(),
        type: this.formSide,
        signRequired: this.signRequired,
        sessionExpiration: this.sessionExpiration,
        loggerId: window.loggerId,
        resourceDirectory: this.resourceDirectory,
        accessToken: this.accessToken,
        googleMapsKey: this.googleMapsKey,
        styles: {
          default: `${BaseUrl.resources}/customs/ondato/styles.css`,
          custom: this.cssSource
        },
        queryParams: this.parseQuery(),
        ...data
      })
    },

    async formClientDetails() {
      const data = await this.getClientDetails()
      if (data) {
        this.formEvent.triggerSuccess(eventTypes.formClientDetailsStatus, data)
      }
    },

    async formUpdate({ data }) {
      const isUpdated = await this.saveFormData(data)
      if (isUpdated) {
        this.formEvent.triggerSuccess(eventTypes.formUpdateStatus)
      } else {
        this.formEvent.triggerError(eventTypes.formUpdateStatus)
      }
    },

    formComplete() {
      this.formEvent.triggerError(eventTypes.formCompleteStatus)
      this.$router.pushNext()
      this.$emit('complete')
    },

    formLanguageChange({ data }) {
      if (!data || !data.language) return
      this.$changeLang(data.language)

      const language = data.language !== 'en' ? data.language : undefined
      this.$router.replaceNext({ name: this.$route.name, params: { language } })
    },

    formSessionEnd() {
      this.$store.dispatch(actions.updateToken)
    },

    formCall() {
      this.$emit('call')
    },

    async formPdfSignature({ data: pdfData }) {
      const { data, error } = await call(Api.pdfSignature, pdfData)
      if (error) {
        this.$popUp(error)
        return
      }
      this.formEvent.triggerSuccess(eventTypes.formPdfSignatureStatus, data)
    },

    async formSign({ data, error }) {
      if (error) {
        this.$popUp(error)
        return
      }

      const signatureData = await this.signatureCreate(data)
      if (!signatureData) {
        this.formEvent.triggerError(eventTypes.formSignStatus)
        return
      }

      if (signatureData.url) {
        this.$router.redirectTo(signatureData.url)
      } else {
        this.checkSignStatus(signatureData, data.type)
      }
    },

    checkSignStatus(signatureData, signatureType) {
      const waitStatusData = { ...signatureData }

      if (signatureType === signMethodType.eIdCard) {
        delete waitStatusData.verificationCode
      }

      this.formEvent.triggerSuccess(eventTypes.formSignStatus, waitStatusData)
      if (signatureData.dtbs) return

      this.formLoginFinalize({ data: { signatureData, signatureType } })
    },

    async formLoginFinalize({ data, error }) {
      window.sessionStorage.removeItem(STORE_KEYS.QUERY)

      if (error) {
        this.$popUp(error)
        return
      }

      const { signatureData, signatureType } = data

      const signatureStatusData = await this.signatureStatus({
        ...signatureData,
        type: signatureType
      })

      if (!signatureStatusData) {
        this.formEvent.triggerError(eventTypes.formSignCompleteStatus)
        return
      }

      this.formEvent.triggerSuccess(eventTypes.formSignCompleteStatus, {
        sessionId: signatureData.sessionId,
        signatureData: signatureStatusData
      })
    },

    formLog({ data }) {
      if (!data || !data.log) return

      activityLogger.logActivity(data.log)
    },

    async formSmsLogin({ data }) {
      try {
        if (data.phoneNumber) {
          const { error } = await call(Api.loginCodeSend, data)
          if (!error) {
            this.formEvent.triggerSuccess(eventTypes.formSmsLoginStatus)
            return
          }
        }
      } catch (error) {
        // error
      }

      this.$popUp('Failed')
      this.formEvent.triggerError(eventTypes.formSmsLoginStatus)
    },

    async formSmsLoginVerify({ data }) {
      try {
        if (data.code) {
          const { error } = await call(Api.loginCodeVerify, data)
          if (!error) {
            this.formEvent.triggerSuccess(eventTypes.formSmsLoginVerifyStatus)
            return
          }
        }
      } catch (error) {
        // error
      }

      this.formEvent.triggerError(eventTypes.formSmsLoginVerifyStatus)
    },

    async formSessionRestart() {
      const sessionData = await this.startNewSession()
      if (!sessionData || !sessionData.accessToken) {
        this.isLoading = false
        return
      }

      this.$store.dispatch(actions.updateToken, sessionData.accessToken)

      this.formEvent.triggerSuccess(eventTypes.formSessionRestartStatus, {
        sessionExpiration: this.sessionExpiration
      })
    },

    // ----- smart-id ------
    async formLoginSmartId({ data }) {
      if (!data) return
      const signature = await this.createLoginSmartId(data)
      if (!signature) {
        this.formEvent.triggerError(eventTypes.formLoginStatus, {
          id: data.id,
          message: 'NotFound'
        })
        return
      }

      this.formEvent.triggerSuccess(eventTypes.formLoginStatus, {
        id: data.id,
        ...signature
      })

      const status = await this.checkLoginSmartId()
      if (!status) {
        this.formEvent.triggerError(eventTypes.formLoginStatus, {
          id: data.id,
          message: 'NotAllowed'
        })
        return
      }

      this.formEvent.triggerSuccess(eventTypes.formLoginCompleteStatus, {
        id: data.id
      })
    },

    async createLoginSmartId(payload) {
      const { data, error } = await call(Api.smartIdLoginLegal, payload)
      if (error) {
        this.$popUp('SmartIdSignFailed')
        return null
      }

      return data
    },

    async checkLoginSmartId() {
      const { error } = await get(Api.smartIdLoginLegal)
      if (error) return false
      return true
    },
    // ----- smart-id ------

    // ----- get-person ------
    async formGetPersonData() {
      const person = await this.getPersonData()
      if (!person) {
        return this.formEvent.triggerError(eventTypes.formGetPersonDataStatus)
      }

      this.formEvent.triggerSuccess(eventTypes.formGetPersonDataStatus, person)
    },

    async getPersonData() {
      const { data, error } = await get(Api.smartIdLoginLegal)
      if (error) {
        this.$popUp('Failed')
        return null
      }

      return data
    },
    // ----- get-person ------

    // ----- get-officials ------
    async formGetOfficialsData() {
      const officials = await this.getOfficialsData()
      if (!officials) {
        return this.formEvent.triggerError(
          eventTypes.formGetOfficialsDataStatus
        )
      }

      this.formEvent.triggerSuccess(
        eventTypes.formGetOfficialsDataStatus,
        officials
      )
    },

    async getOfficialsData() {
      const { data, error } = await get(Api.officialsRegistry)
      if (error) {
        this.$popUp('Failed')
        return null
      }

      return data
    },
    // ----- get-officials ------

    async startNewSession() {
      try {
        const { error, data } = await call(Api.identificationStartOver)
        if (error) {
          this.$popUp(error)
          return
        }
        return data
      } catch (error) {
        this.$popUp(error)
      }
    },

    async signatureCreate(signatureData) {
      const { data, error } = await call(Api.signatureCreate, signatureData)
      if (error) {
        let message = error
        if (signatureData.type === 'SMART_ID') {
          message = 'SmartIdSignFailed'
        } else if (signatureData.type === 'MOBILE_ID') {
          message = 'MobileIdSignFailed'
        }

        this.$popUp(message)
        return null
      }

      return data
    },

    async signatureStatus(signatureData) {
      const response = await ondatoHttpClient().post(
        '/app/auth/signature-sign',
        signatureData,
        null,
        null,
        5
      )

      if (!response.success || response.data?.error) {
        let message = response.data?.error ?? errorTypes.failed

        if (signatureData.type === 'MOBILE_ID') {
          message = 'MobileIdSignFailed'
        }

        this.$popUp(message)
        return null
      }

      return response.data?.data
    },

    handleResponse({ error, data }) {
      if (error) {
        this.$popUp(error)
        return
      }
      return data
    },

    async getFormData() {
      try {
        return await call(Api.getFormData)
      } catch (error) {
        return { error }
      }
    },

    async saveFormData(payload) {
      try {
        const { error } = await call(Api.updateForm, payload)
        if (error) {
          this.$popUp(error)
          return false
        }
        return true
      } catch (error) {
        this.$popUp(error)
        return false
      }
    },

    async getClientDetails() {
      try {
        const { error, data } = await call(Api.clientDetails)
        if (error) {
          this.$popUp(error)
          return
        }
        return data
      } catch (error) {
        this.$popUp(error)
      }
    },

    formLoaded() {
      const data = this.parseQuery()
      if (!data) return null

      this.checkSignStatus(data, signMethodType.eIdCard)
    },

    parseQuery() {
      let { code, state } = this.$route.query

      if (!code && !state) {
        const item = window.sessionStorage.getItem(STORE_KEYS.QUERY)
        const query = item ? JSON.parse(item) : null

        if (query) {
          code = query.code
          state = query.state
        }
      } else if (code && state) {
        window.sessionStorage.setItem(
          STORE_KEYS.QUERY,
          JSON.stringify({ code, state })
        )
      }

      if (!code || !state) {
        window.sessionStorage.removeItem(STORE_KEYS.QUERY)
        return null
      }

      this.$router.replace({
        ...this.$router.currentRoute,
        query: null
      })

      return {
        sessionId: state,
        verificationCode: code
      }
    }
  }
}
</script>

<style scoped>
.form-page {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.form-page iframe {
  height: 100%;
  width: 100%;
  border: none;
}
</style>
