var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container" }, [
      _c("div", [_c("img", { attrs: { src: _vm.imageSource } })]),
      _vm._v(" "),
      _c("div", { staticClass: "right-section" }, [
        _c("p", [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("Error.Description.CloseIdvProcess")) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pop-up-btn" },
          [
            _c("ui-button", {
              attrs: {
                label: _vm.buttonLabel,
                loading: _vm.isLoading,
                additional: "",
              },
              on: {
                click: function ($event) {
                  return _vm.redirect()
                },
              },
            }),
            _vm._v(" "),
            _c("ui-button", {
              attrs: {
                label: _vm.continueButtonLabel,
                disabled: _vm.isLoading,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("click")
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }