var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "instructions-container" }, [
    _c("div", {
      style: {
        backgroundImage:
          "url('/public/images/instructions/" + _vm.resource + "-1.png')",
      },
    }),
    _vm._v(" "),
    _c("div", {
      style: {
        backgroundImage:
          "url('/public/images/instructions/" + _vm.resource + "-2.png')",
      },
    }),
    _vm._v(" "),
    _c("div", {
      style: {
        backgroundImage:
          "url('/public/images/instructions/" + _vm.resource + "-3.png')",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }