var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-frame",
    { attrs: { logo: "" } },
    [
      _c("h1", { staticClass: "header" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("SignatureAuthentication.Types.Header")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "card-list",
        _vm._l(_vm.signatureSettings, function (item) {
          return _c(
            "card",
            {
              key: item.name,
              attrs: { label: _vm.label(item.name) },
              on: {
                click: function ($event) {
                  return _vm.$emit("change", item)
                },
              },
            },
            [_c(_vm.image(item.name), { tag: "component" })],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }