var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "content-container" },
      [
        _c("div", { staticClass: "page-description" }, [
          _c("h1", { staticClass: "instructions-header" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("ProofOfAddress.Title")) + "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.$t("ProofOfAddress.Description")))]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "file-upload-container" },
          [
            _c("file-upload-box", {
              attrs: {
                accept: _vm.accept,
                "max-size": 15,
                error: !_vm.isValid,
              },
              on: { change: _vm.filesChanged },
            }),
            _vm._v(" "),
            _vm.isLoading
              ? _c(
                  "div",
                  { staticClass: "upload-loader" },
                  [_c("ui-loader", { attrs: { size: 3, center: "" } })],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("ui-button", {
          staticClass: "continue-button",
          attrs: {
            label: "App.Continue",
            disabled: _vm.isLoading || _vm.isEmpty,
          },
          on: { click: _vm.uploadFile },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }