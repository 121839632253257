var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-form",
    { staticClass: "sms-confirmation", on: { submit: _vm.submit } },
    [
      _vm.readOnly
        ? _c("span", { staticClass: "read-only-number" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("App.Sms.PhoneNumber")) + ":")]),
            _vm._v("\n    +" + _vm._s(_vm.phoneNumberDetails.number) + "\n  "),
          ])
        : _c("phone-input", {
            attrs: {
              label: "App.Sms.PhoneNumber",
              value: _vm.phoneNumberDetails,
              error: _vm.error,
              country: _vm.country,
              disabled: _vm.isCompleted,
            },
            on: { change: _vm.phoneNumberChange },
          }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "controls-container" },
        [
          !_vm.isCompleted
            ? _c("ui-button", {
                attrs: {
                  label: _vm.resource + ".Send",
                  disabled: _vm.isLoading,
                },
              })
            : [
                _c("ui-button", {
                  attrs: {
                    label: _vm.resource + ".Resend",
                    disabled: _vm.isLoading,
                  },
                }),
                _vm._v(" "),
                !_vm.readOnly
                  ? _c("ui-button", {
                      attrs: {
                        label: _vm.resource + ".Change",
                        type: "button",
                        additional: "",
                        disabled: _vm.isLoading,
                      },
                      on: { click: _vm.changeNumber },
                    })
                  : _vm._e(),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }