<template>
  <face-instructions v-if="showInstructions" @go-next="hideInstructions" />
  <liveness-check v-else-if="isLivenessEnabled" />
  <passive-liveness-check v-else />
</template>

<script>
import LivenessCheck from '@src/components/partials/face/LivenessCheck'
import PassiveLivenessCheck from '@src/components/partials/face/PassiveLivenessCheck'
import getters from '@src/store/getters'
import { mapGetters } from 'vuex'
import FaceInstructions from './FaceInstructions'

export default {
  components: {
    LivenessCheck,
    PassiveLivenessCheck,
    FaceInstructions
  },

  data() {
    return {
      showInstructions: true
    }
  },

  computed: mapGetters([
    getters.isLivenessEnabled,
    getters.isFaceCaptureDisabled
  ]),

  created() {
    if (this.isFaceCaptureDisabled) {
      return this.$router.pushNext()
    }
  },

  methods: {
    hideInstructions() {
      this.showInstructions = false
    }
  }
}
</script>
