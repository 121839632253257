var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "terms-and-conditions",
      class: { clear: _vm.consentHeader },
    },
    [
      _vm.consentHeader
        ? _c("terms-and-conditions-header", {
            attrs: { data: _vm.consentHeader },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "policy-content" },
        [_c("view-generator", { attrs: { data: _vm.$t("App.DataPolicy") } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "policy-controls" },
        [
          _c("ui-button", {
            attrs: {
              label: "App.Disagree",
              disabled: _vm.isLoading,
              additional: "",
            },
            on: { click: _vm.reject },
          }),
          _vm._v(" "),
          _c("ui-button", {
            attrs: { label: "App.Agree", disabled: _vm.isLoading },
            on: { click: _vm.confirm },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }