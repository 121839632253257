var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showContent
    ? _c(
        "ui-camera-frame",
        {
          attrs: {
            title: _vm.$td("Header." + _vm.message),
            file: _vm.file,
            "file-thumbnail": _vm.fileThumbnail,
            "active-controls": "",
          },
        },
        [
          _c("ui-camera-description", {
            attrs: {
              slot: "description",
              resource: _vm.resource,
              message: _vm.message,
              instructions: !_vm.error,
              review: !!_vm.file,
            },
            slot: "description",
          }),
          _vm._v(" "),
          _c("ui-camera", {
            attrs: {
              slot: "camera",
              resource: _vm.resource,
              controls: _vm.photoControls,
              message: "Info",
              "capture-callback": _vm.captureComplete,
            },
            slot: "camera",
          }),
          _vm._v(" "),
          _c("camera-controls", {
            attrs: {
              slot: "controls",
              completed: _vm.isCompleted,
              "enable-continue": "",
              "active-controls": "",
              error: !!_vm.error,
              "reset-callback": _vm.reset,
            },
            on: { continue: _vm.continueSubmition },
            slot: "controls",
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }