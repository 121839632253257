// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.message-overlay[data-v-8930581a] {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 100;
  animation: message-hide var(--message-hide-time) ease-out forwards;
}
.message-content[data-v-8930581a] {
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 3.125rem;
  color: var(--message-text);
  padding: 0 2rem;
  margin: 0 auto;
  box-shadow: 0 0.1875rem 0.625rem var(--message-shadow);
  background-color: var(--mask);
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/MessageOverlay.vue"],"names":[],"mappings":";AAmBA;EACA,aAAA;EACA,kBAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;EACA,kEAAA;AACA;AAEA;EACA,qDAAA;EACA,mBAAA;EACA,gBAAA;EACA,qBAAA;EACA,0BAAA;EACA,eAAA;EACA,cAAA;EACA,sDAAA;EACA,6BAAA;AACA","sourcesContent":["<template>\n  <div class=\"message-overlay\" :class=\"{ show: show }\">\n    <div class=\"message-content\">\n      {{ $td(`Header.${message}`) }}\n    </div>\n  </div>\n</template>\n\n<script>\nexport default {\n  props: {\n    resource: { type: String, default: undefined, required: true },\n    message: { type: String, default: undefined },\n    show: Boolean\n  }\n}\n</script>\n\n<style scoped>\n.message-overlay {\n  display: flex;\n  position: absolute;\n  left: 0;\n  right: 0;\n  z-index: 100;\n  animation: message-hide var(--message-hide-time) ease-out forwards;\n}\n\n.message-content {\n  font-family: 'Source Sans Pro', Helvetica, sans-serif;\n  font-size: 1.375rem;\n  font-weight: 700;\n  line-height: 3.125rem;\n  color: var(--message-text);\n  padding: 0 2rem;\n  margin: 0 auto;\n  box-shadow: 0 0.1875rem 0.625rem var(--message-shadow);\n  background-color: var(--mask);\n}\n</style>\n\n<style>\n.camera-active .message-overlay {\n  animation: message-show var(--message-show-time) ease-out\n    var(--message-hide-time) forwards;\n}\n\n@keyframes message-hide {\n  0% {\n    top: 18%;\n    opacity: 1;\n  }\n\n  100% {\n    top: 12%;\n    opacity: 0;\n  }\n}\n\n@keyframes message-show {\n  100% {\n    top: 18%;\n    opacity: 1;\n  }\n\n  0% {\n    top: 12%;\n    opacity: 0;\n  }\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
