// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.start-page[data-v-7cab4bab] {
  height: 100%;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity var(--page-time) ease 0.5s;
}
.start-page > h2[data-v-7cab4bab] {
  margin: auto;
  color: var(--header-contrast-color);
}
.start-page.show-content[data-v-7cab4bab] {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/ContinuePage.vue"],"names":[],"mappings":";AAoDA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,UAAA;EACA,8CAAA;AACA;AAEA;EACA,YAAA;EACA,mCAAA;AACA;AAEA;EACA,UAAA;AACA","sourcesContent":["<template>\n  <div class=\"start-page\" :class=\"{ 'show-content': showContent }\">\n    <h2>{{ $t('App.Loading.Start') }}</h2>\n  </div>\n</template>\n\n<script>\nimport Api, { call } from '@src/scripts/api'\nimport actions from '@src/store/actions'\nimport { appPages } from '@src/scripts/enums'\n\nexport default {\n  data() {\n    return {\n      showContent: false\n    }\n  },\n\n  computed: {\n    omnichannelId() {\n      return this.$route.params.omnichannelId\n    },\n\n    language() {\n      return this.$language()\n    }\n  },\n\n  async created() {\n    setTimeout(() => {\n      this.showContent = true\n    })\n\n    const method =\n      this.$route.name === 'PermissionsContinue'\n        ? Api.permissionsContinue\n        : Api.omnichannelContinue\n\n    const { omnichannelId, language } = this\n    const { data } = await call(method, {\n      omnichannelId\n    })\n\n    if (data) {\n      this.$store.dispatch(actions.updateToken, data.accessToken)\n    }\n    this.$router.replace({ name: appPages.home, params: { language } })\n  }\n}\n</script>\n\n<style scoped>\n.start-page {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  opacity: 0;\n  transition: opacity var(--page-time) ease 0.5s;\n}\n\n.start-page > h2 {\n  margin: auto;\n  color: var(--header-contrast-color);\n}\n\n.start-page.show-content {\n  opacity: 1;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
