<template>
  <div class="test-water-mark">
    <span>
      TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST
      TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST
    </span>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.test-water-mark {
  position: absolute;
  top: 0;
  left: 35%;
  height: 100%;
  width: 1.5rem;
  background-color: var(--white);
  opacity: 0.2;
  overflow: hidden;
}

.test-water-mark > span {
  color: var(--black);
  white-space: nowrap;
  display: block;
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: 700;
  transform: rotate(90deg);
  word-spacing: 0.5rem;
  letter-spacing: 0.25rem;
}
</style>
