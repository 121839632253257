// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.loader-stroke[data-v-214dab19] {
  stroke: var(--brand);
}
`, "",{"version":3,"sources":["webpack://./src/components/images/Loader.vue"],"names":[],"mappings":";AAkBA;EACA,oBAAA;AACA","sourcesContent":["<template>\n  <svg\n    width=\"16\"\n    height=\"16\"\n    viewBox=\"0 0 16 16\"\n    fill=\"none\"\n    xmlns=\"http://www.w3.org/2000/svg\"\n  >\n    <path\n      class=\"loader-stroke\"\n      d=\"M1 8C1 4.13401 4.13401 1 8 1\"\n      stroke-width=\"2\"\n      stroke-linecap=\"round\"\n    />\n  </svg>\n</template>\n\n<style scoped>\n.loader-stroke {\n  stroke: var(--brand);\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
