<template>
  <div class="page-container">
    <div class="content-container">
      <div class="page-description">
        <h1 class="instructions-header">
          {{ $t('ProofOfAddress.Title') }}
        </h1>
        <p>{{ $t('ProofOfAddress.Description') }}</p>
      </div>
      <div class="file-upload-container">
        <file-upload-box
          :accept="accept"
          :max-size="15"
          :error="!isValid"
          @change="filesChanged"
        />
        <div v-if="isLoading" class="upload-loader">
          <ui-loader :size="3" center />
        </div>
      </div>
      <ui-button
        class="continue-button"
        label="App.Continue"
        :disabled="isLoading || isEmpty"
        @click="uploadFile"
      />
    </div>
  </div>
</template>
<script>
import FileUploadBox from '@src/components/partials/FileUpload'
import Api, { sendFile } from '@src/scripts/api'
export default {
  components: { FileUploadBox },

  data() {
    return {
      isLoading: false,
      isValid: true,
      accept: ['pdf', 'jpg', 'png'],
      files: null
    }
  },

  computed: {
    isEmpty() {
      return !this.files?.length
    }
  },

  methods: {
    filesChanged(files) {
      this.files = files
      this.isValid = true
    },

    async uploadFile() {
      const { files } = this
      if (!files || !files.length) {
        this.isValid = false
        return
      }

      this.isLoading = true
      const request = new FormData()
      for (const index in files) {
        request.append('file', files[index])
      }

      const { error } = await sendFile(Api.poaUpload, request)
      if (error) {
        this.$popUp(error)
        this.isLoading = false
      } else {
        this.$router.pushNext()
      }
    }
  }
}
</script>
<style scoped>
.page-container {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  padding: 2rem 1rem;
  width: 38rem;
}

.page-description {
  max-width: 28.125rem;
  text-align: center;
}

.file-upload-container {
  min-width: 100%;
}

.upload-loader {
  position: absolute;
  inset: 0;
  display: flex;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
}

.continue-button {
  margin-top: auto;
}

@media screen and (min-width: 28.125rem) {
  .continue-button {
    margin-bottom: 2rem;
  }
  .content-container {
    padding: 2rem;
  }
}
</style>
