import { httpClient } from '@ondato/http-client'

const idvBaseUrl = BaseUrl.idvApi

const initIdvApi = () => {
  const headers = {}
  return httpClient({ baseUrl: idvBaseUrl, headers })
}

export const idvApi = initIdvApi()
