var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasPermissionsRedirect
    ? _c("div", [
        _c("p", { staticClass: "pop-up-description" }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("Error.Description.NoPermissionsRedirect")) +
              "\n  "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "button-group" },
          [
            _c("ui-button", {
              attrs: { label: _vm.buttonLabel, disabled: _vm.isLoading },
              on: { click: _vm.redirect },
            }),
            _vm._v(" "),
            _c("omnichannel-controls", {
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            }),
          ],
          1
        ),
      ])
    : _c("div", { staticClass: "permission-details" }, [
        _c("div", [_c("img", { attrs: { src: _vm.imageSource } })]),
        _vm._v(" "),
        _c("div", [
          _vm.isMobile
            ? _c("div", [
                _vm.browser.isSafari
                  ? _c("p", [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Error.Description.NoPermissions.Mobile.SafariStep1"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Error.Description.NoPermissions.Mobile.SafariStep2"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Error.Description.NoPermissions.Mobile.SafariStep3"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Error.Description.NoPermissions.Mobile.SafariStep4"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Error.Description.NoPermissions.Mobile.SafariStep5"
                            )
                          )
                        ),
                      ]),
                    ])
                  : _c("p", [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Error.Description.NoPermissions.Mobile.AndroidStep1",
                              {
                                browser: _vm.browser.name,
                              }
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Error.Description.NoPermissions.Mobile.AndroidStep2"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Error.Description.NoPermissions.Mobile.AndroidStep3"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Error.Description.NoPermissions.Mobile.AndroidStep4",
                              {
                                browser: _vm.browser.name,
                              }
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Error.Description.NoPermissions.Mobile.AndroidStep5"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Error.Description.NoPermissions.Mobile.AndroidStep6"
                            )
                          )
                        ),
                      ]),
                    ]),
              ])
            : _c("div", [
                _vm.browser.isChrome
                  ? _c("p", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("Error.Description.NoPermissions.Chrome")
                          ) +
                          "\n      "
                      ),
                    ])
                  : _vm.browser.isFirefox
                  ? _c("p", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("Error.Description.NoPermissions.Firefox")
                          ) +
                          "\n      "
                      ),
                    ])
                  : _vm.browser.isEdge
                  ? _c("p", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("Error.Description.NoPermissions.Edge")
                          ) +
                          "\n      "
                      ),
                    ])
                  : _vm.browser.isSafari
                  ? _c("p", [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Error.Description.NoPermissions.SafariStep1"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Error.Description.NoPermissions.SafariStep2"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Error.Description.NoPermissions.SafariStep3"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Error.Description.NoPermissions.SafariStep4"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Error.Description.NoPermissions.SafariStep5"
                            )
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pop-up-btn" },
            [
              _c("ui-button", {
                attrs: { label: _vm.buttonLabel },
                on: {
                  click: function ($event) {
                    return _vm.$emit("click")
                  },
                },
              }),
              _vm._v(" "),
              _c("omnichannel-controls", {
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }