<template>
  <div
    v-if="logoSource"
    class="page-logo"
    :style="{ backgroundImage: `url('${logoSource}')` }"
  />
</template>

<script>
import getters from '@src/store/getters'
import { mapGetters } from 'vuex'

export default {
  computed: mapGetters([getters.logoSource])
}
</script>

<style scoped>
.page-logo {
  position: absolute;
  top: var(--logo-top);
  left: var(--logo-left);
  height: var(--logo-height);
  width: var(--logo-width);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}
</style>
