var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ui-input" }, [
    _c(
      "label",
      {
        class: [
          { "not-empty": _vm.value, "has-error": _vm.error },
          _vm.touchedClass,
        ],
      },
      [
        _c("input", {
          attrs: {
            type: _vm.type,
            disabled: _vm.disabled,
            maxlength: _vm.maxLength,
          },
          domProps: { value: _vm.value },
          on: { input: _vm.inputChange, blur: _vm.lostFocus },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "input-name" }, [
          _vm._v(_vm._s(_vm.$t(_vm.label))),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "input-error" }, [
          _vm._v(_vm._s(_vm.$t(_vm.error))),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }