// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.terms-and-conditions-header[data-v-33fc23cc],
.header-item[data-v-33fc23cc] {
  display: flex;
}
.terms-and-conditions-header[data-v-33fc23cc] {
  justify-content: space-between;
  padding: 0 15% 1rem 3rem;
}
.header-item[data-v-33fc23cc] {
  opacity: 0.5;
}
.header-item[data-v-33fc23cc]:last-child {
  opacity: 1;
}
.header-image[data-v-33fc23cc] {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 2rem;
  width: 2rem;
}
p[data-v-33fc23cc] {
  font-size: 1rem;
  line-height: 1;
  margin: 0.75rem 1rem 0.5rem;
}
@media screen and (max-width: 62rem) {
.terms-and-conditions-header[data-v-33fc23cc] {
    flex-direction: column;
}
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/TermsAndConditionsHeader.vue"],"names":[],"mappings":";AAqBA;;EAEA,aAAA;AACA;AAEA;EACA,8BAAA;EACA,wBAAA;AACA;AAEA;EACA,YAAA;AACA;AAEA;EACA,UAAA;AACA;AAEA;EACA,2BAAA;EACA,4BAAA;EACA,wBAAA;EACA,YAAA;EACA,WAAA;AACA;AAEA;EACA,eAAA;EACA,cAAA;EACA,2BAAA;AACA;AAEA;AACA;IACA,sBAAA;AACA;AACA","sourcesContent":["<template>\n  <div class=\"terms-and-conditions-header\">\n    <div v-for=\"(item, index) in data\" :key=\"index\" class=\"header-item\">\n      <div\n        class=\"header-image\"\n        :style=\"{ backgroundImage: `url(${item.image})` }\"\n      />\n      <p>{{ $t(item.title) }}</p>\n    </div>\n  </div>\n</template>\n\n<script>\nexport default {\n  props: {\n    data: { type: Array, default: undefined }\n  }\n}\n</script>\n\n<style scoped>\n.terms-and-conditions-header,\n.header-item {\n  display: flex;\n}\n\n.terms-and-conditions-header {\n  justify-content: space-between;\n  padding: 0 15% 1rem 3rem;\n}\n\n.header-item {\n  opacity: 0.5;\n}\n\n.header-item:last-child {\n  opacity: 1;\n}\n\n.header-image {\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: contain;\n  height: 2rem;\n  width: 2rem;\n}\n\np {\n  font-size: 1rem;\n  line-height: 1;\n  margin: 0.75rem 1rem 0.5rem;\n}\n\n@media screen and (max-width: 62rem) {\n  .terms-and-conditions-header {\n    flex-direction: column;\n  }\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
