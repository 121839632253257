import { render, staticRenderFns } from "./TrashBin.vue?vue&type=template&id=fd6dfd82&scoped=true"
var script = {}
import style0 from "./TrashBin.vue?vue&type=style&index=0&id=fd6dfd82&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd6dfd82",
  null
  
)

export default component.exports