import JwtDecode from 'jwt-decode'
import { faceCheckTypes, appPages, ruleTypes } from '@src/scripts/enums'
import {
  PAGE_NAMES,
  OMNICHANNEL_STATUSES,
  CLAIMS
} from '@src/scripts/constants'

export const methods = {
  updateSession,
  changeBrowserData,
  changeToken,
  changeCameraState,
  updateRules,
  setPages,
  updatePages,
  enableLiveness,
  disableLiveness,
  setIdentityCheckFailed,
  removeAgeVerification,
  removeFaceRequirement,
  setIdvToken,
  addPage,
  initiateOmnichannel,
  updateOmnichannelStatus,
  updateOmnichannelCloseableStatus
}

export default new Proxy(methods, {
  get(target, key) {
    if (!target[key]) {
      throw `Mutation key ${key} not exist.`
    }
    return key
  }
})

async function updateSession(state, data) {
  if (!data) {
    state.sessionExists = true
  }

  data = data || {}
  if (state.accessData?.claims.includes(CLAIMS.OMNICHANNEL)) {
    data.pages = [PAGE_NAMES.OMNICHANNEL]
  }
  state.session = data

  if (!data.resourceName || !(await setResources(state, data.resourceName))) {
    if (data.resourceDirectory) {
      await setResources(state, data.resourceDirectory)
    }
  }
}

function changeBrowserData(state, data) {
  state.browserData = data
}

function changeToken(state, value) {
  if (!value?.token) {
    state.accessData = null
    return
  }

  let expirationTime
  let claims
  try {
    const tokenData = JwtDecode(value.token)

    expirationTime = tokenData.exp * 1000
    claims = Object.keys(tokenData)
  } catch (error) {
    expirationTime = undefined
  }

  const accessData = {
    ...state.accessData,
    token: value.token,
    expirationTime,
    claims
  }

  state.accessData = accessData
  if (claims.includes(CLAIMS.OMNICHANNEL)) {
    state.session.pages = [PAGE_NAMES.OMNICHANNEL]
  }
}

function setIdvToken(state, value) {
  value = value || {}

  if (state.accessData?.idvToken === value.idvToken) {
    return
  }

  const accessData = {
    ...state.accessData,
    idvToken: value.idvToken
  }

  state.accessData = accessData
}

function changeCameraState(state, value) {
  state.camera = {
    ...state.camera,
    state: value
  }
}

function updateRules({ session }, value) {
  session.rules = value
}

function setPages({ session }) {
  if (!session) return
  session.pages = [appPages.omnichannel]
}

function addPage({ session }, value) {
  if (!session || !session.pages || session.pages.includes(value)) return
  session.pages.push(value)
}

function updatePages({ session }, value) {
  if (!session) return
  session.pages.push(value)
}

async function setResources(state, path) {
  try {
    const response = await fetch(
      `${BaseUrl.resources}/customs/${path}/settings.json`
    )
    if (!response.ok) return false

    state.resources = await response.json()
    state.resources.root = `${BaseUrl.resources}/customs/${path}`

    return true
  } catch (error) {
    // no customisations
    return false
  }
}

function enableLiveness(state) {
  state.faceCheckMode = faceCheckTypes.liveness
}

function disableLiveness(state) {
  state.faceCheckMode = faceCheckTypes.passive
}

function setIdentityCheckFailed(state, value) {
  state.isIdentityCheckFailed = value
}

function removeAgeVerification({ session }) {
  session.rules = session.rules.filter(
    (rule) => rule.name !== ruleTypes.ageVerification
  )
}

function removeFaceRequirement(state) {
  state.isFaceStepDisabled = true
}

function initiateOmnichannel({ session }) {
  if (!session) return
  session.pages = [PAGE_NAMES.OMNICHANNEL]
}

function updateOmnichannelStatus({ omnichannel }, value) {
  if (
    !value ||
    omnichannel.status === value ||
    !Object.values(OMNICHANNEL_STATUSES).includes(value)
  ) {
    return
  }

  omnichannel.status = value
}

function updateOmnichannelCloseableStatus({ omnichannel }, value) {
  omnichannel.isNonCloseable = value
}
