var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "ui-button",
      class: {
        "button-additional": _vm.additional,
        "button-primary": !_vm.additional,
        "with-icon": !!_vm.photoIcon,
      },
      attrs: {
        type: _vm.type,
        disabled: _vm.disabled || _vm.loading,
        title: _vm.title,
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm._v("\n  " + _vm._s(_vm.title) + "\n  "),
      _vm.loading ? _c("loader", { staticClass: "button-loader" }) : _vm._e(),
      _vm._v(" "),
      _vm.photoIcon ? _c("i", { staticClass: "photo-icon" }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }