// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.water-mark[data-v-1aecae09] {
  position: absolute;
  color: var(--white);
  display: flex;
  left: 50%;
  bottom: 2%;
  transform: translateX(-50%);
  flex-direction: column;
  z-index: 1;
}
.water-mark span[data-v-1aecae09] {
  font-size: 0.625rem;
  word-spacing: 0.25rem;
  margin: auto;
  font-weight: 400;
}
.water-mark-logo[data-v-1aecae09] {
  background-image: url('/public/images/bw-logo.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 5.5rem;
  height: 1.875rem;
}
.icon[data-v-1aecae09] {
  display: flex;
  flex-direction: row;
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/WaterMark.vue"],"names":[],"mappings":";AAUA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,SAAA;EACA,UAAA;EACA,2BAAA;EACA,sBAAA;EACA,UAAA;AACA;AAEA;EACA,mBAAA;EACA,qBAAA;EACA,YAAA;EACA,gBAAA;AACA;AAEA;EACA,mDAAA;EACA,2BAAA;EACA,wBAAA;EACA,4BAAA;EACA,aAAA;EACA,gBAAA;AACA;AAEA;EACA,aAAA;EACA,mBAAA;AACA","sourcesContent":["<template>\n  <div class=\"water-mark\">\n    <span></span>\n    <div class=\"icon\">\n      <div class=\"water-mark-logo\" />\n    </div>\n  </div>\n</template>\n\n<style scoped>\n.water-mark {\n  position: absolute;\n  color: var(--white);\n  display: flex;\n  left: 50%;\n  bottom: 2%;\n  transform: translateX(-50%);\n  flex-direction: column;\n  z-index: 1;\n}\n\n.water-mark span {\n  font-size: 0.625rem;\n  word-spacing: 0.25rem;\n  margin: auto;\n  font-weight: 400;\n}\n\n.water-mark-logo {\n  background-image: url('/public/images/bw-logo.png');\n  background-position: center;\n  background-size: contain;\n  background-repeat: no-repeat;\n  width: 5.5rem;\n  height: 1.875rem;\n}\n\n.icon {\n  display: flex;\n  flex-direction: row;\n}\n</style>\n\n<style>\n.mobile-app .no-mask .water-mark {\n  top: auto;\n  margin-top: auto;\n  bottom: 20%;\n}\n\n@media screen and (max-width: 48rem) {\n  .desktop-app .no-mask .water-mark {\n    top: auto;\n    margin-top: auto;\n    bottom: 20%;\n  }\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
