<template>
  <ui-frame>
    <div>
      <h1>{{ $t('Omnichannel.Status.Title') }}</h1>
      <p>{{ $t('Omnichannel.Status.Description') }}</p>
    </div>
  </ui-frame>
</template>

<script>
import { activityLogger } from '@src/scripts/activityLogger'
import { mapGetters, mapMutations } from 'vuex'
import mutations from '@src/store/mutations'
import { OMNICHANNEL_STATUSES } from '@src/scripts/constants'
import getters from '@src/store/getters'

export default {
  computed: {
    ...mapGetters([getters.isOmnichannelCompleted])
  },

  created() {
    if (this.setCompleteStatus()) {
      activityLogger.logActivity('CONTINUED_SESSION_COMPLETED')
    } else {
      activityLogger.logActivity('CONTINUED_SESSION_COMPLETE_ERROR')
    }
  },

  methods: {
    ...mapMutations([mutations.updateOmnichannelStatus]),

    setCompleteStatus() {
      if (!this.isOmnichannelCompleted) {
        this.updateOmnichannelStatus(OMNICHANNEL_STATUSES.COMPLETED)
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
h1 {
  color: var(--header-contrast-color);
}

p {
  color: var(--text-contrast-color);
}

h1,
p {
  text-align: center;
  margin-bottom: 2rem;
}
</style>
