var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "file-upload",
      class: { "drag-drop-active": _vm.isActive, "file-error": _vm.error },
    },
    [
      !_vm.isFull
        ? _c("div", { staticClass: "upload-container" }, [
            _c(
              "div",
              {
                staticClass: "upload-content",
                on: { click: _vm.fileExplorerOpen },
              },
              [
                _c(
                  "div",
                  { staticClass: "upload-box-description" },
                  [
                    _vm.isActive
                      ? _c("p", { staticClass: "active-text" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("FileUpload.DragActive")) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isActive
                      ? _c("upload-icon", { staticClass: "icon-spacing" })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isActive
                      ? _c("p", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("FileUpload.Initial")) +
                              "\n          "
                          ),
                          _c("span", { staticClass: "browse" }, [
                            _vm._v(_vm._s(_vm.$t("FileUpload.Browse")) + "."),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.error
                  ? _c("h3", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("FileUpload.Required")) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c("input", {
              ref: "fileExplorer",
              attrs: {
                type: "file",
                accept: _vm.types.accept,
                multiple: _vm.multiple,
                hidden: "",
              },
              on: { change: _vm.fileExplorerChange },
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "drop-zone",
              on: {
                dragover: function ($event) {
                  $event.preventDefault()
                },
                dragleave: _vm.dragEnd,
                drop: function ($event) {
                  $event.preventDefault()
                  return _vm.dropFile($event)
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "restrictions-text" }, [
              _c("span", [_vm._v(_vm._s(_vm.supportedFileTypes))]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.supportedFileSize))]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.uploadedFiles
        ? _c(
            "div",
            { staticClass: "file-list" },
            _vm._l(_vm.uploadedFiles, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "file-box",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c("div", [
                    _c(
                      "span",
                      { staticClass: "file-icon" },
                      [_c("file-icon", { staticClass: "icon-spacing" })],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "file-text-info" }, [
                      _c("span", { staticClass: "file-name-text" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "file-size-text" }, [
                        _vm._v(_vm._s(_vm.getFileSize(item.size))),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "remove-icon",
                      on: {
                        click: function () {
                          return _vm.removeItem(index)
                        },
                      },
                    },
                    [_c("trash-bin")],
                    1
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }