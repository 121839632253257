<template>
  <ui-form class="sms-confirmation" @submit="submit">
    <span v-if="readOnly" class="read-only-number">
      <span>{{ $t('App.Sms.PhoneNumber') }}:</span>
      +{{ phoneNumberDetails.number }}
    </span>
    <phone-input
      v-else
      label="App.Sms.PhoneNumber"
      :value="phoneNumberDetails"
      :error="error"
      :country="country"
      :disabled="isCompleted"
      @change="phoneNumberChange"
    />
    <div class="controls-container">
      <ui-button
        v-if="!isCompleted"
        :label="`${resource}.Send`"
        :disabled="isLoading"
      />
      <template v-else>
        <ui-button :label="`${resource}.Resend`" :disabled="isLoading" />
        <ui-button
          v-if="!readOnly"
          :label="`${resource}.Change`"
          type="button"
          additional
          :disabled="isLoading"
          @click="changeNumber"
        />
      </template>
    </div>
  </ui-form>
</template>

<script>
import PhoneInput from '@src/components/partials/PhoneInput'
import Validators from '@src/scripts/validators'

export default {
  components: {
    PhoneInput
  },

  props: {
    resource: { type: String, default: 'App.Sms' },
    country: { type: String, default: undefined },
    phoneNumber: { type: Object, default: undefined },
    readOnly: Boolean
  },

  data() {
    return {
      isLoading: false,
      isCompleted: false,
      phoneNumberDetails: {
        code: '',
        number: ''
      },
      error: null
    }
  },

  created() {
    const { phoneNumber, phoneNumberDetails } = this
    if (!phoneNumber) return

    phoneNumberDetails.number = phoneNumber.number || ''
    phoneNumberDetails.code = phoneNumber.code || ''
  },

  methods: {
    phoneNumberChange(value) {
      this.phoneNumberDetails = value
    },

    changeNumber() {
      this.isCompleted = false
      this.$emit('restart')
    },

    validate(phoneNumberDetails) {
      const error = Validators.phoneNumberValidator(phoneNumberDetails)
      if (!error) this.error = null
      else this.error = `App.Sms.Error.${error}`

      return !this.error
    },

    submit() {
      const { phoneNumberDetails, readOnly } = this
      if (!readOnly && !this.validate(phoneNumberDetails)) return

      this.isLoading = true
      this.isCompleted = true

      this.$emit('complete', phoneNumberDetails, (isSuccess) => {
        this.isLoading = false
        if (!isSuccess) this.isCompleted = false
      })
    }
  }
}
</script>

<style scoped>
.sms-confirmation {
  margin-top: 2rem;
  width: 100%;
}

.controls-container {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.controls-container .ui-button {
  margin-left: 1rem;
  margin-right: 1rem;
}

.read-only-number,
.read-only-number > span {
  font-size: 1rem;
  text-align: center;
  color: var(--text-contrast-color);
}

.read-only-number {
  display: block;
}

.read-only-number > span {
  font-weight: 500;
}
</style>
