var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "card",
      attrs: { title: _vm.label },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("i", { staticClass: "image" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c("span", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }