var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 210.27 224.3",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "filter",
            { attrs: { id: "face-too-blurryfilter1", x: "0", y: "0" } },
            [
              _c("feGaussianBlur", {
                attrs: { in: "SourceGraphic", stdDeviation: "4" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-too-blurry-linear-gradient",
                x1: "106.43",
                y1: "179.02",
                x2: "106.43",
                y2: "154.61",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#e3ddd5" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#bdb3a9" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-too-blurry-linear-gradient-2",
                x1: "99.26",
                y1: "132.19",
                x2: "113.91",
                y2: "153.87",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#3b3b3b" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#545454" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-too-blurry-linear-gradient-3",
                x1: "105.21",
                y1: "48.5",
                x2: "105.43",
                y2: "48.74",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#999" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#6e6e6e" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "face-too-blurry-linear-gradient-4",
              x1: "79.45",
              y1: "89.44",
              x2: "297.06",
              y2: "188.19",
              "xlink:href": "#face-too-blurry-linear-gradient",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "face-too-blurry-linear-gradient-5",
              x1: "67.07",
              y1: "86.76",
              x2: "64.28",
              y2: "101.57",
              "xlink:href": "#face-too-blurry-linear-gradient",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-too-blurry-linear-gradient-6",
                x1: "100.14",
                y1: "98.36",
                x2: "113.52",
                y2: "135.05",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#d1cdcb" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#9c9189" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-too-blurry-linear-gradient-7",
                x1: "105.12",
                y1: "48.45",
                x2: "105.2",
                y2: "48.44",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.13", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.28", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#1f1f1f" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.54", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.78", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "face-too-blurry-linear-gradient-8",
              x1: "151.89",
              y1: "100.56",
              x2: "148.9",
              y2: "116.42",
              "xlink:href": "#face-too-blurry-linear-gradient",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-too-blurry-linear-gradient-9",
                x1: "48.25",
                y1: "64.79",
                x2: "161.44",
                y2: "64.79",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.12", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.25", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#262626" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.58", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "0.8", "stop-color": "#050505" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-too-blurry-linear-gradient-10",
                x1: "-388.54",
                y1: "1299.4",
                x2: "-439.8",
                y2: "1194.4",
                gradientTransform:
                  "matrix(1.18, 0.01, -0.01, 1.18, 578.81, -1292.81)",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#a3a3a3" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.29", "stop-color": "#b2b2b2" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.66", "stop-color": "#bfbfbf" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#c4c4c4" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "face-too-blurry-cls-1" }, [
        _c("g", { attrs: { id: "Face-too-blurry" } }, [
          _c("rect", {
            staticClass: "face-too-blurry-cls-2",
            attrs: { width: "210.27", height: "224.31" },
          }),
          _vm._v(" "),
          _c("g", { attrs: { filter: "url(#face-too-blurryfilter1)" } }, [
            _c("path", {
              staticClass: "face-too-blurry-cls-3",
              attrs: {
                d:
                  "M86.84,141l-4.45,29.24a11.27,11.27,0,0,1-6,8.45l-38,19,136.11,1.08-28-15.79a19.8,19.8,0,0,1-9.62-13.61L131.64,142Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "face-too-blurry-cls-4",
              attrs: {
                d:
                  "M85.15,147.87s5.58,12.54,23.65,13.53S134.19,147,134.19,147Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "face-too-blurry-cls-5",
              attrs: {
                d:
                  "M105.22,49a3.83,3.83,0,0,1-.13-1.06A2.79,2.79,0,0,1,105.22,49Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "face-too-blurry-cls-6",
              attrs: {
                d:
                  "M148,108.53a65.6,65.6,0,0,1-1.82,18.27c-1.58,6.75-5.9,14.09-11.5,19.85,0,.07-12.43,9.83-23.95,10.1-.48,0-1,0-1.43,0-1.72,0-3.48,0-5.31-.19-.85-.1-1.72-.17-2.54-.32h0c-11.18-1.79-16.89-8.36-22-15.39-6.46-8.84-11.45-19.55-11.43-30.49l0-22.59c.16-7.91,0-9.44.16-18.92,0,0,17.56,3.64,41.77-9.35a20,20,0,0,0,12.75,5.33c13,.48,15.85-5.37,15.9-6.69.8,3.37,1.53,27.55,7.66,30.81,4.58,2.88,1.93.85,1.9,1.45Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "face-too-blurry-cls-7",
              attrs: {
                d: "M65.13,96.72s-8.1.31-7.92,6.9,11.16,12.49,11.16,12.49Z",
              },
            }),
            _vm._v(" "),
            _c("polyline", {
              staticClass: "face-too-blurry-cls-8",
              attrs: { points: "107.52 98.54 102.65 125.32 106.84 128.66" },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "face-too-blurry-cls-9",
              attrs: { cx: "88.19", cy: "102.22", r: "3.69" },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "face-too-blurry-cls-9",
              attrs: { cx: "125.9", cy: "102.22", r: "3.69" },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "face-too-blurry-cls-10",
              attrs: {
                d:
                  "M103.33,139.28s10.72-.56,16-3.08a9.76,9.76,0,0,1-8,4.74C105.47,141.38,103.33,139.28,103.33,139.28Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "face-too-blurry-cls-11",
              attrs: {
                d:
                  "M105.22,49a3.83,3.83,0,0,1-.13-1.06A2.79,2.79,0,0,1,105.22,49Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "face-too-blurry-cls-12",
              attrs: { d: "M148.12,94.46s8.1-.36,8.46,6.22-9,14.55-9,14.55Z" },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "face-too-blurry-cls-13",
              attrs: {
                d:
                  "M68.14,114.29c.48-2.46,1-4.92,1.45-7.38-.6-17.42,3.06-23.37,6.9-25.31,2.23-1.13,5.15-1.23,8-4a15.83,15.83,0,0,0,4.41-9.53,32.92,32.92,0,0,0,12.29,10.71C118.09,87,136.67,75.73,141.61,83c2.87,4.22-1.89,10.28,2.86,18.55a19.83,19.83,0,0,0,3.63,4.57c3.19-3.93,14.65-19,13.22-39.64a54.74,54.74,0,0,0-7.58-24.05A54.16,54.16,0,0,0,140.83,28.2c-2.75-2.13-23.7-17.94-42.25-11.29a55.76,55.76,0,0,0-18,10.72,33.76,33.76,0,0,1-5.75,4.72c-4.86,3.12-7.76,3-11.36,4.59-8.88,3.83-12.66,13.52-13.4,15.44-1.93,4.93-2.32,10.15-1.2,17.58C51.73,89,61.87,105.39,68.14,114.29Z",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("path", {
                staticClass: "face-too-blurry-cls-14",
                attrs: {
                  d:
                    "M71,179.28s10.93,10.41,38.25,10.55,38.45-6.78,38.45-6.78l51.57,29.53,11,11.73H0L10,211.6Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-too-blurry-cls-15",
                attrs: {
                  d:
                    "M71,179.28s10.93,10.41,38.25,10.55,38.45-6.78,38.45-6.78l51.57,29.53,11,11.73H0L10,211.6Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-too-blurry-cls-15",
                attrs: {
                  d:
                    "M71,179.28s10.93,10.41,38.25,10.55,38.45-6.78,38.45-6.78l51.57,29.53,11,11.73H0L10,211.6Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-too-blurry-cls-16",
                attrs: {
                  d:
                    "M71,179.28s10.93,10.41,38.25,10.55,38.45-6.78,38.45-6.78l51.57,29.53,11,11.73H0L10,211.6Z",
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("rect", {
            staticClass: "face-too-blurry-cls-17",
            attrs: { width: "210.27", height: "224.31" },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }