import PopUp from '@src/components/popUp/PopUp'
import { errorTypes } from '@src/scripts/enums'

export const popUpData = []

export default {
  install: (Vue) => {
    Vue.component('pop-up', PopUp)
    Vue.prototype.$popUp = popUp
  }
}

export function popUp(type, options) {
  if (!type || type === errorTypes.unauthorized) return

  if (type === errorTypes.termsAndConditions) {
    const termsMessage = popUpData.find(
      (item) => item.type === errorTypes.termsAndConditions
    )
    if (termsMessage) return
  }

  popUpData.push({
    type,
    options
  })
}
