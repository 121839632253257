import { render, staticRenderFns } from "./OmnichannelPageQR.vue?vue&type=template&id=3bab460a&scoped=true"
import script from "./OmnichannelPageQR.vue?vue&type=script&lang=js"
export * from "./OmnichannelPageQR.vue?vue&type=script&lang=js"
import style0 from "./OmnichannelPageQR.vue?vue&type=style&index=0&id=3bab460a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bab460a",
  null
  
)

export default component.exports