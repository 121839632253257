var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 263.22 189.81",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "card-too-small-linear-gradient",
                x1: "76.18",
                y1: "130.84",
                x2: "377.88",
                y2: "128.7",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#a6a6a6" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "card-too-small-linear-gradient-2",
                x1: "97.06",
                y1: "92.8",
                x2: "97.06",
                y2: "88.5",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#e3ddd5" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#bdb3a9" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "card-too-small-linear-gradient-3",
                x1: "-313.11",
                y1: "1108.02",
                x2: "-322.2",
                y2: "1089.39",
                gradientTransform:
                  "matrix(1.15, 0.01, -0.01, 1.15, 465.7, -1174.67)",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#a3a3a3" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.29", "stop-color": "#b2b2b2" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.66", "stop-color": "#bfbfbf" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#c4c4c4" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "card-too-small-linear-gradient-4",
                x1: "95.8",
                y1: "84.55",
                x2: "98.37",
                y2: "88.37",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#3b3b3b" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#545454" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "card-too-small-linear-gradient-5",
                x1: "96.84",
                y1: "69.81",
                x2: "96.88",
                y2: "69.86",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#999" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#6e6e6e" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "card-too-small-linear-gradient-6",
              x1: "92.31",
              y1: "77.02",
              x2: "130.61",
              y2: "94.4",
              "xlink:href": "#card-too-small-linear-gradient-2",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "card-too-small-linear-gradient-7",
              x1: "90.13",
              y1: "76.55",
              x2: "89.64",
              y2: "79.16",
              "xlink:href": "#card-too-small-linear-gradient-2",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "card-too-small-linear-gradient-8",
                x1: "95.95",
                y1: "78.59",
                x2: "98.31",
                y2: "85.05",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#d1cdcb" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#9c9189" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "card-too-small-linear-gradient-9",
                x1: "96.83",
                y1: "69.8",
                x2: "96.84",
                y2: "69.8",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.13", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.28", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#1f1f1f" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.54", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.78", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "card-too-small-linear-gradient-10",
              x1: "105.06",
              y1: "78.98",
              x2: "104.53",
              y2: "81.77",
              "xlink:href": "#card-too-small-linear-gradient-2",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "card-too-small-linear-gradient-11",
                x1: "86.82",
                y1: "72.68",
                x2: "106.74",
                y2: "72.68",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.12", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.25", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#262626" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.58", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "0.8", "stop-color": "#050505" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "card-too-small-cls-1" }, [
        _c("g", { attrs: { id: "Card-too-small" } }, [
          _c("rect", {
            staticClass: "card-too-small-cls-2",
            attrs: { width: "263.22", height: "189.81" },
          }),
          _vm._v(" "),
          _c("g", [
            _c("rect", {
              staticClass: "card-too-small-cls-3",
              attrs: {
                x: "69.08",
                y: "52.6",
                width: "125.08",
                height: "82.77",
                rx: "4.02",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              staticClass: "card-too-small-cls-4",
              attrs: {
                x: "151.19",
                y: "50.14",
                width: "2.62",
                height: "50.5",
                transform: "translate(76.85 227.76) rotate(-89.9)",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "card-too-small-cls-4",
              attrs: {
                points:
                  "159.23 108.96 127.31 108.87 127.32 106.25 159.19 106.56 159.23 108.96",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("rect", {
                staticClass: "card-too-small-cls-4",
                attrs: {
                  x: "144.61",
                  y: "67.84",
                  width: "2.62",
                  height: "37.38",
                  transform: "translate(59.15 232.31) rotate(-89.9)",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                staticClass: "card-too-small-cls-4",
                attrs: {
                  x: "144.61",
                  y: "77.77",
                  width: "2.62",
                  height: "37.38",
                  transform: "translate(49.21 242.23) rotate(-89.9)",
                },
              }),
            ]),
            _vm._v(" "),
            _c("path", {
              staticClass: "card-too-small-cls-5",
              attrs: {
                d:
                  "M194.21,125.54l0,6.55a3.29,3.29,0,0,1-3.29,3.28l-118.24-.2a4,4,0,0,1-3.65-2.9v-6.73Z",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              staticClass: "card-too-small-cls-6",
              attrs: {
                id: "Overlay",
                x: "69.08",
                y: "52.55",
                width: "125.08",
                height: "82.77",
                rx: "3.75",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("rect", {
                staticClass: "card-too-small-cls-2",
                attrs: { x: "77.75", y: "61.32", width: "38.43", height: "39" },
              }),
              _vm._v(" "),
              _c("g", [
                _c("path", {
                  staticClass: "card-too-small-cls-7",
                  attrs: {
                    d:
                      "M93.61,86.1l-.78,5.15a2,2,0,0,1-1.06,1.49l-6.69,3.34,24,.19-4.93-2.78a3.45,3.45,0,0,1-1.69-2.39l-.92-4.82Z",
                  },
                }),
                _vm._v(" "),
                _c("g", [
                  _c("path", {
                    staticClass: "card-too-small-cls-8",
                    attrs: {
                      d:
                        "M91,92.69s1.92,1.83,6.71,1.85,6.74-1.19,6.74-1.19l9,5.19,1.66,2-36.31-.19,1.48-2Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "card-too-small-cls-9",
                    attrs: {
                      d:
                        "M91,92.69s1.92,1.83,6.71,1.85,6.74-1.19,6.74-1.19l9,5.19,1.66,2-36.31-.19,1.48-2Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "card-too-small-cls-9",
                    attrs: {
                      d:
                        "M91,92.69s1.92,1.83,6.71,1.85,6.74-1.19,6.74-1.19l9,5.19,1.66,2-36.31-.19,1.48-2Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "card-too-small-cls-10",
                    attrs: {
                      d:
                        "M91,92.69s1.92,1.83,6.71,1.85,6.74-1.19,6.74-1.19l9,5.19,1.66,2-36.31-.19,1.48-2Z",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("path", {
                  staticClass: "card-too-small-cls-11",
                  attrs: {
                    d:
                      "M93.31,87.31a4.7,4.7,0,0,0,4.16,2.38,4.75,4.75,0,0,0,4.47-2.53Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "card-too-small-cls-12",
                  attrs: {
                    d:
                      "M96.84,69.9a1.29,1.29,0,0,1,0-.19A.39.39,0,0,1,96.84,69.9Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "card-too-small-cls-13",
                  attrs: {
                    d:
                      "M104.37,80.38a11.39,11.39,0,0,1-.32,3.22,8,8,0,0,1-2,3.5,8.56,8.56,0,0,1-4.22,1.77h-.25c-.3,0-.61,0-.93,0-.15,0-.31,0-.45-.06h0a5.67,5.67,0,0,1-3.87-2.71,9.28,9.28,0,0,1-2-5.37v-4c0-1.4,0-1.67,0-3.33,0,0,3.09.64,7.35-1.65a3.52,3.52,0,0,0,2.24.94c2.3.08,2.79-1,2.8-1.18.14.59.27,4.85,1.35,5.42.81.51.34.15.33.26Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "card-too-small-cls-14",
                  attrs: { d: "M89.79,78.31s-1.42,0-1.39,1.21,2,2.2,2,2.2Z" },
                }),
                _vm._v(" "),
                _c("polyline", {
                  staticClass: "card-too-small-cls-15",
                  attrs: { points: "97.25 78.63 96.39 83.34 97.13 83.93" },
                }),
                _vm._v(" "),
                _c("circle", {
                  staticClass: "card-too-small-cls-16",
                  attrs: { cx: "93.85", cy: "79.27", r: "0.65" },
                }),
                _vm._v(" "),
                _c("circle", {
                  staticClass: "card-too-small-cls-16",
                  attrs: { cx: "100.48", cy: "79.27", r: "0.65" },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "card-too-small-cls-3",
                  attrs: {
                    d:
                      "M96.51,85.8a10.12,10.12,0,0,0,2.83-.54,1.73,1.73,0,0,1-1.41.83A1.88,1.88,0,0,1,96.51,85.8Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "card-too-small-cls-17",
                  attrs: {
                    d:
                      "M96.84,69.9a1.29,1.29,0,0,1,0-.19A.39.39,0,0,1,96.84,69.9Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "card-too-small-cls-18",
                  attrs: {
                    d: "M104.39,77.91s1.43-.07,1.49,1.09-1.59,2.56-1.59,2.56Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "card-too-small-cls-19",
                  attrs: {
                    d:
                      "M90.32,81.4l.26-1.3c-.11-3.07.53-4.12,1.21-4.46a3.91,3.91,0,0,0,1.41-.7A2.75,2.75,0,0,0,94,73.27a5.72,5.72,0,0,0,2.16,1.88c3,1.44,6.24-.54,7.11.74.5.74-.33,1.81.5,3.26a3.42,3.42,0,0,0,.64.81,10.28,10.28,0,0,0,2.33-7,9.2,9.2,0,0,0-3.61-6.74c-.48-.38-4.17-3.16-7.43-2a9.8,9.8,0,0,0-3.17,1.89,6.22,6.22,0,0,1-1,.83,8.89,8.89,0,0,1-2,.81,4.88,4.88,0,0,0-2.36,2.72,5.68,5.68,0,0,0-.21,3.09A19.14,19.14,0,0,0,90.32,81.4Z",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("rect", {
                staticClass: "card-too-small-cls-20",
                attrs: {
                  id: "Overlay-2",
                  "data-name": "Overlay",
                  x: "77.75",
                  y: "61.32",
                  width: "38.43",
                  height: "39",
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }