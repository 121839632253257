var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ui-frame" },
    [
      _vm.logo ? _c("page-logo") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "content",
          class: {
            "full-size": _vm.fullSize,
            padding: _vm.padding,
            "max-height": _vm.maxHeight,
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }