import {
  LOADER_VIDEO_RESPONSE_TYPES,
  IMAGE_RESIZE_STEP,
  JPEG_QUALITY
} from './constants'

export function dataUrlToBlob(data) {
  if (!data) return null

  const dataContent = data.split(',')
  const contentType = dataContent[0].match(/:(.*?);/)[1]
  const b64Data = dataContent[1]
  const sliceSize = 512

  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

export function blobToDataUrl(blob) {
  if (!blob) return null
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      resolve(event.target.result)
    }
    reader.readAsDataURL(blob)
  })
}

export function replaceAll(target, search, replacement) {
  return target.replace(new RegExp(escapeRegExp(search), 'g'), replacement)
}

export function uuid() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (indicator) =>
    (
      indicator ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (indicator / 4)))
    ).toString(16)
  )
}

export function deepMerge(target, source, skipEmpty) {
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} })
        }
        deepMerge(target[key], source[key], skipEmpty)
      } else {
        const sourceValue = source[key]
        if (!skipEmpty || sourceValue) {
          Object.assign(target, { [key]: sourceValue })
        }
      }
    }
  }

  return target
}

export function toPascalCase(value) {
  if (!value || typeof value !== 'string') return value

  return value
    .replace(/[-_]+/g, ' ')
    .replace(/(\w)(\w*)/g, (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase())
    .replace(/\s/g, '')
}

export function delay(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export const takePhoto = ({
  video,
  cropDimensions,
  resizeDimensions,
  sharpIndex
}) => {
  const canvas = document.createElement('canvas')
  canvas.width = video.videoWidth
  canvas.height = video.videoHeight

  const context = canvas.getContext('2d')
  context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)

  if (cropDimensions) {
    photoCrop({
      video,
      canvas,
      dimensions: cropDimensions
    })
  }

  if (sharpIndex) {
    sharpen(canvas, sharpIndex)
  }

  const resizedImage =
    resizeDimensions &&
    resizeImage({
      source: canvas,
      step: IMAGE_RESIZE_STEP,
      dimensions: resizeDimensions
    })

  const imageSource = resizedImage ?? canvas
  const imageUrl = imageSource.toDataURL('image/jpeg', JPEG_QUALITY)
  if (!imageUrl) {
    return null
  }

  return dataUrlToBlob(imageUrl)
}

export const photoCrop = ({ video, canvas, dimensions }) => {
  const canvasWidthWithoutPadding =
    video.videoWidth * (dimensions.width / video.clientWidth)
  const canvasHeightWithoutPadding =
    video.videoHeight * (dimensions.height / video.clientHeight)

  canvas.width = canvasWidthWithoutPadding
  canvas.height = canvasHeightWithoutPadding

  const maskFramePaddingWidth = dimensions.clientWidth - dimensions.width
  const maskFramePaddingHeight = dimensions.clientHeight - dimensions.height

  const photoPaddingWidth = video.videoWidth - canvasWidthWithoutPadding
  const photoPaddingHeight = video.videoHeight - canvasHeightWithoutPadding

  const xOffset = dimensions.x ? maskFramePaddingWidth / dimensions.x : 0
  const yOffset = dimensions.y ? maskFramePaddingHeight / dimensions.y : 0

  const x = xOffset ? -photoPaddingWidth / xOffset : 0
  const y = yOffset ? -photoPaddingHeight / yOffset : 0

  const cropWidth = video.videoWidth
  const cropHeight = video.videoHeight

  const context = canvas.getContext('2d')
  context.drawImage(
    video,
    0,
    0,
    video.videoWidth,
    video.videoHeight,
    x,
    y,
    cropWidth,
    cropHeight
  )
}

export const resizeImage = ({ source, dimensions, step }) => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  step = (step && 1 - step) ?? 0.5

  const size = {
    width: source.videoWidth ?? source.width,
    height: source.videoHeight ?? source.height
  }

  if (dimensions.width && dimensions.height) {
    if (size.width === dimensions.width && size.height === dimensions.height) {
      return
    }

    canvas.width = dimensions.width
    canvas.height = dimensions.height
  } else {
    const aspectRatio = size.height / size.width

    if (dimensions.height) {
      if (size.height === dimensions.height) {
        return
      }

      canvas.height = dimensions.height
      canvas.width = Math.floor(canvas.height / aspectRatio)
    } else {
      if (size.width === dimensions.width) {
        return
      }

      canvas.width = dimensions.width
      canvas.height = Math.floor(canvas.width * aspectRatio)
    }
  }

  if (size.width * step <= canvas.width) {
    context.drawImage(source, 0, 0, canvas.width, canvas.height)
    return canvas
  }

  const resizeCanvas = document.createElement('canvas')
  const resizeContext = resizeCanvas.getContext('2d')
  const multiplier = 1 / step

  size.width = Math.floor(size.width * step)
  size.height = Math.floor(size.height * step)

  resizeCanvas.width = size.width
  resizeCanvas.height = size.height
  resizeContext.drawImage(source, 0, 0, size.width, size.height)

  while (size.width * step > canvas.width) {
    size.width = Math.floor(size.width * step)
    size.height = Math.floor(size.height * step)

    resizeContext.drawImage(
      resizeCanvas,
      0,
      0,
      size.width * multiplier,
      size.height * multiplier,
      0,
      0,
      size.width,
      size.height
    )
  }

  context.drawImage(
    resizeCanvas,
    0,
    0,
    size.width,
    size.height,
    0,
    0,
    canvas.width,
    canvas.height
  )

  return canvas
}

export function copyToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyToClipboard(text)
    return
  }

  navigator.clipboard.writeText(text)
}

function fallbackCopyToClipboard(text) {
  const textArea = document.createElement('textarea')
  textArea.value = text
  textArea.hidden = true

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (error) {
    // empty
  }

  document.body.removeChild(textArea)
}

function escapeRegExp(str) {
  return str.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1')
}

function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

export function sharpen(canvas, mix) {
  const ctx = canvas.getContext('2d')
  const w = canvas.width
  const h = canvas.height

  let x,
    sx,
    sy,
    r,
    g,
    b,
    dstOff,
    srcOff,
    wt,
    cx,
    cy,
    scy,
    scx,
    weights = [0, -1, 0, -1, 5, -1, 0, -1, 0],
    katet = Math.round(Math.sqrt(weights.length)),
    half = (katet * 0.5) | 0,
    dstData = ctx.createImageData(w, h),
    dstBuff = dstData.data,
    srcBuff = ctx.getImageData(0, 0, w, h).data,
    y = h

  while (y--) {
    x = w
    while (x--) {
      sy = y
      sx = x
      dstOff = (y * w + x) * 4
      r = 0
      g = 0
      b = 0

      for (cy = 0; cy < katet; cy++) {
        for (cx = 0; cx < katet; cx++) {
          scy = sy + cy - half
          scx = sx + cx - half

          if (scy >= 0 && scy < h && scx >= 0 && scx < w) {
            srcOff = (scy * w + scx) * 4
            wt = weights[cy * katet + cx]

            r += srcBuff[srcOff] * wt
            g += srcBuff[srcOff + 1] * wt
            b += srcBuff[srcOff + 2] * wt
          }
        }
      }

      dstBuff[dstOff] = r * mix + srcBuff[dstOff] * (1 - mix)
      dstBuff[dstOff + 1] = g * mix + srcBuff[dstOff + 1] * (1 - mix)
      dstBuff[dstOff + 2] = b * mix + srcBuff[dstOff + 2] * (1 - mix)
      dstBuff[dstOff + 3] = srcBuff[dstOff + 3]
    }
  }

  ctx.putImageData(dstData, 0, 0)
}

export const getTotalCurrentTime = (loaderVideos, currentTime, videoId) => {
  const totalCurrentTime = Object.entries(loaderVideos).reduce(
    (acc, current, index) => {
      return acc + (index < videoId - 1 ? current[1].duration : 0)
    },
    0
  )

  return Math.round(totalCurrentTime + currentTime)
}

export const getTotalDuration = (loaderVideos) => {
  const totalDuration = Object.entries(loaderVideos).reduce((acc, current) => {
    return acc + current[1].duration
  }, 0)

  return Math.round(totalDuration)
}

export const getPercent = (totalCurrentTime, totalDuration) => {
  const duration = Math.round((90 * totalCurrentTime) / totalDuration)
  return (Number.isFinite(duration) && duration) || 0
}

export const getMessage = ({
  currentTime,
  duration,
  loaderVideos,
  responseVideos,
  currentVideoId,
  isResponseVideoStarted,
  isSuccess,
  isError
}) => {
  const { messages } =
    (!isSuccess && !isError) || !isResponseVideoStarted
      ? loaderVideos[`video${currentVideoId}`]
      : responseVideos[
          isSuccess
            ? LOADER_VIDEO_RESPONSE_TYPES.SUCCESS
            : LOADER_VIDEO_RESPONSE_TYPES.ERROR
        ]
  const interval = Math.ceil(duration / messages.length)
  const cues = messages.map((_, index) => interval * index)
  const cueIdx = cues.findIndex(
    (cue) => cue + interval > Math.floor(currentTime)
  )

  if (cueIdx < 0 || cueIdx > messages.length - 1) {
    return messages[messages.length - 1]
  }

  return messages[cueIdx]
}

export const getPercentOnError = async (currPercent) => {
  function easeInOutQuart(x) {
    return x < 0.5 ? 8 * Math.pow(x, 4) : 1 - Math.pow(-2 * x + 2, 4) / 2
  }

  function mapRange(value, a, b, c, d) {
    value = (value - a) / (b - a)

    return Math.floor(c + value * (d - c))
  }

  function startMagic() {
    return new Promise((resolve) => {
      let time = 0

      const animation = setInterval(function () {
        const newPercent = currPercent + 1
        time += 0.006
        let p = mapRange(easeInOutQuart(time), 0, 1, newPercent, 101)

        if (p === 100) {
          resolve()
          clearInterval(animation)
        }

        if (document.getElementById('percentage')) {
          document.getElementById('percentage').innerHTML = p
        }
      }, 30)
    })
  }

  await startMagic()
}

export function getCssVarValue(key) {
  if (!key) {
    return null
  }
  return getComputedStyle(document.body).getPropertyValue(key).trim() || null
}

export function getFileSize(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const kb = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizeUnits = ['Bytes', 'KB', 'MB']

  const size = Math.floor(Math.log(bytes) / Math.log(kb))

  return parseFloat((bytes / Math.pow(kb, size)).toFixed(dm)) + sizeUnits[size]
}

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea')
  textArea.value = text
  textArea.tabIndex = 3

  // hide fallback copy element
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'
  textArea.style.visibility = 'hidden'

  document.body.appendChild(textArea)

  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (error) {
    throw new Error('CopyLinkFailed', { message: error })
  }

  document.body.removeChild(textArea)
}

export async function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
  }

  try {
    await navigator.clipboard.writeText(text)
  } catch (error) {
    throw new Error('CopyLinkFailed', { message: error })
  }
}
