<template>
  <div class="terms-and-conditions" :class="{ clear: consentHeader }">
    <terms-and-conditions-header v-if="consentHeader" :data="consentHeader" />
    <div class="policy-content">
      <view-generator :data="$t('App.DataPolicy')" />
    </div>
    <div class="policy-controls">
      <ui-button
        label="App.Disagree"
        :disabled="isLoading"
        additional
        @click="reject"
      />
      <ui-button label="App.Agree" :disabled="isLoading" @click="confirm" />
    </div>
  </div>
</template>

<script>
import TermsAndConditionsHeader from '@src/components/partials/TermsAndConditionsHeader'
import ViewGenerator from '@src/components/partials/ViewGenerator'
import Api, { call } from '@src/scripts/api'
import actions from '@src/store/actions'
import { activityLogger } from '@src/scripts/activityLogger'
import getters from '@src/store/getters'
import { mapGetters } from 'vuex'

export default {
  components: {
    TermsAndConditionsHeader,
    ViewGenerator
  },

  data() {
    return {
      isLoading: false
    }
  },

  computed: mapGetters([getters.consentHeader]),

  created() {
    activityLogger.logActivity('TERMS_SHOWN')
  },

  methods: {
    async confirm() {
      this.isLoading = true
      const { error } = await call(Api.acceptTerms)
      if (!error) {
        this.$store.dispatch(actions.acceptTerms)
        this.$emit('close')

        activityLogger.logActivity('TERMS_AGREED')
      }
      this.isLoading = false
    },

    reject() {
      const redirectKey = 'App.NoTermsPermissions.Url'
      const url = this.$t(redirectKey)

      activityLogger.logActivity('TERMS_DISAGREED')

      if (url && url !== redirectKey) this.$router.redirectTo(url)
      else this.loadDisagreeView()
    },

    loadDisagreeView() {
      const container = this.createContainer()
      container.appendChild(this.createImage())
      container.appendChild(this.createHeader())
      container.appendChild(this.createDescription())
      // container.appendChild(this.createButton())

      document.body.innerHTML = ''
      document.body.style.display = 'flex'
      document.body.appendChild(container)
    },

    createContainer() {
      const div = document.createElement('div')
      div.style.margin = 'auto'
      div.style.padding = '2rem'

      return div
    },

    createImage() {
      const img = document.createElement('img')
      img.src = '/public/images/no-permissions.png'
      img.style.display = 'block'
      img.style.height = '6rem'
      img.style.marginLeft = 'auto'
      img.style.marginRight = 'auto'
      img.style.marginBottom = '2rem'

      return img
    },

    createHeader() {
      const h2 = document.createElement('h2')
      h2.innerHTML = this.$t('App.NoTermsPermissions.Header')
      h2.style.textAlign = 'center'

      return h2
    },

    createDescription() {
      const p = document.createElement('p')
      p.innerHTML = this.$t('App.NoTermsPermissions.Description')
      p.style.textAlign = 'center'

      return p
    },

    createButton() {
      const button = document.createElement('button')
      button.className = 'ui-button'

      return button
    }
  }
}
</script>

<style scoped>
.terms-and-conditions {
  background-color: var(--white);
  padding-top: 3rem;
  padding-bottom: 3rem;
  border: var(--popup-border);
  border-radius: var(--popup-border-radius);
  max-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.terms-and-conditions::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--popup-border-radius);
  box-shadow: var(--popup-shadow-size) var(--popup-shadow-color);
  opacity: var(--popup-shadow-opacity);
  pointer-events: none;
}

.policy-content {
  text-align: left;
  line-height: 1.5;
  padding-left: 3rem;
  padding-right: 3rem;
  overflow: auto;
  word-wrap: break-word;
}

.policy-controls {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
}

.policy-controls > .ui-button {
  margin: 0.5rem 2rem 0;
}

.clear::before {
  content: none;
}
</style>
