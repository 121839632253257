var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ui-frame", { staticClass: "omnichannel-page" }, [
    _c(
      "div",
      { staticClass: "back-btn", on: { click: _vm.goToOmnichannelPage } },
      [_vm._v("\n    ← " + _vm._s(_vm.$t("OmnichannelQrId.Back")) + "\n  ")]
    ),
    _vm._v(" "),
    _c("h1", [_vm._v(_vm._s(_vm.$t("Omnichannel.Start.Title")))]),
    _vm._v(" "),
    _c("div", { staticClass: "group-container" }, [
      _c("div", { staticClass: "group-item" }, [
        _c("h3", [_vm._v(_vm._s(_vm.$t("OmnichannelQrId.InstallApp.Title")))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("OmnichannelQrId.InstallApp.Text")))]),
        _vm._v(" "),
        _c("ul", { staticClass: "app-store-list" }, [
          _c("li", [
            _c("div", { staticClass: "qr-box-store", style: _vm.qrBackground }),
            _vm._v(" "),
            _c("img", {
              attrs: { src: "/public/images/icons/apple-store-badge.svg" },
            }),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("div", { staticClass: "qr-box-store", style: _vm.qrBackground }),
            _vm._v(" "),
            _c("img", {
              attrs: { src: "/public/images/icons/google-play-badge.svg" },
            }),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("div", { staticClass: "qr-box-store", style: _vm.qrBackground }),
            _vm._v(" "),
            _c("img", {
              attrs: { src: "/public/images/icons/huawei-badge.svg" },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "group-item" }, [
        _c("h3", [_vm._v(_vm._s(_vm.$t("OmnichannelQrId.ScanQrCode.Title")))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("OmnichannelQrId.ScanQrCode.Text")))]),
        _vm._v(" "),
        _c("div", { staticClass: "qr-box", style: _vm.qrBackground }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }