var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 215 215",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-start-icon-linear-gradient",
                x1: "13.26",
                y1: "170.23",
                x2: "526.44",
                y2: "166.6",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#a6a6a6" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-start-icon-linear-gradient-2",
                x1: "47.44",
                y1: "105.22",
                x2: "47.44",
                y2: "98.18",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#e3ddd5" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#bdb3a9" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-start-icon-linear-gradient-3",
                x1: "-349.54",
                y1: "1094.48",
                x2: "-364.45",
                y2: "1063.94",
                gradientTransform:
                  "matrix(1.15, 0.01, -0.01, 1.15, 458.76, -1139.82)",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#a3a3a3" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.29", "stop-color": "#b2b2b2" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.66", "stop-color": "#bfbfbf" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#c4c4c4" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-start-icon-linear-gradient-4",
                x1: "45.37",
                y1: "91.71",
                x2: "49.6",
                y2: "97.96",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#3b3b3b" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#545454" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-start-icon-linear-gradient-5",
                x1: "47.09",
                y1: "67.55",
                x2: "47.15",
                y2: "67.62",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#999" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#6e6e6e" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "id-start-icon-linear-gradient-6",
              x1: "39.66",
              y1: "79.37",
              x2: "102.43",
              y2: "107.85",
              "xlink:href": "#id-start-icon-linear-gradient-2",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "id-start-icon-linear-gradient-7",
              x1: "36.09",
              y1: "78.59",
              x2: "35.28",
              y2: "82.87",
              "xlink:href": "#id-start-icon-linear-gradient-2",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-start-icon-linear-gradient-8",
                x1: "45.62",
                y1: "81.94",
                x2: "49.49",
                y2: "92.53",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#d1cdcb" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#9c9189" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-start-icon-linear-gradient-9",
                x1: "47.06",
                y1: "67.53",
                x2: "47.08",
                y2: "67.53",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.13", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.28", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#1f1f1f" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.54", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.78", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "id-start-icon-linear-gradient-10",
              x1: "60.55",
              y1: "82.58",
              x2: "59.69",
              y2: "87.15",
              "xlink:href": "#id-start-icon-linear-gradient-2",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-start-icon-linear-gradient-11",
                x1: "30.66",
                y1: "72.25",
                x2: "63.31",
                y2: "72.25",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.12", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.25", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#262626" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.58", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "0.8", "stop-color": "#050505" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "id-start-icon-fullcolor-cls-1" }, [
        _c("g", { attrs: { id: "id-start-icon-fullcolor" } }, [
          _c("g", [
            _c("rect", {
              staticClass: "id-start-icon-fullcolor-cls-2",
              attrs: {
                x: "1.18",
                y: "37.14",
                width: "212.75",
                height: "140.79",
                rx: "6.69",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              staticClass: "id-start-icon-fullcolor-cls-3",
              attrs: {
                x: "133.97",
                y: "32.97",
                width: "4.46",
                height: "85.9",
                transform: "translate(60.06 211.99) rotate(-89.9)",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "id-start-icon-fullcolor-cls-3",
              attrs: {
                points:
                  "147.58 133.01 93.36 132.87 93.37 128.41 147.58 128.94 147.58 133.01",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("rect", {
                staticClass: "id-start-icon-fullcolor-cls-3",
                attrs: {
                  x: "122.78",
                  y: "63.07",
                  width: "4.46",
                  height: "63.59",
                  transform: "matrix(0, -1, 1, 0, 29.94, 219.72)",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                staticClass: "id-start-icon-fullcolor-cls-3",
                attrs: {
                  x: "122.78",
                  y: "79.97",
                  width: "4.46",
                  height: "63.59",
                  transform: "matrix(0, -1, 1, 0, 13.04, 236.59)",
                },
              }),
            ]),
            _vm._v(" "),
            _c("path", {
              staticClass: "id-start-icon-fullcolor-cls-4",
              attrs: {
                d:
                  "M214,161.23l0,11.14a5.59,5.59,0,0,1-5.59,5.57L7.27,177.6a6.73,6.73,0,0,1-6.2-4.93V161.23Z",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("path", { attrs: { d: "M93.88,55.1h1.34v6.7H93.88Z" } }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M102.42,58.46a3.23,3.23,0,0,1-3.53,3.34H96.55V55.1h2.34A3.23,3.23,0,0,1,102.42,58.46Zm-3.58,2.2a2,2,0,0,0,2.21-2.2,2,2,0,0,0-2.21-2.23h-1v4.43Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M107.32,56.18h-2.55v1.68H107v1.07h-2.26V60.7h2.55v1.1h-3.89V55.09h3.89Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M114.27,55.09V61.8h-1.34l-3.05-4.6v4.6h-1.34V55.09h1.34l3.05,4.61V55.09Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M115.24,55.1h4.92v1.08h-1.79V61.8H117V56.18h-1.79Z",
                },
              }),
              _vm._v(" "),
              _c("path", { attrs: { d: "M121.14,55.1h1.34v6.7h-1.34Z" } }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M123.45,55.1h4.92v1.08h-1.79V61.8h-1.34V56.18h-1.79Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M128.79,55.1h1.52l1.44,3.06,1.43-3.06h1.5l-2.27,4.37V61.8h-1.34V59.47Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M140.81,55A3.16,3.16,0,0,1,144,57.18h-1.54a1.71,1.71,0,0,0-1.62-1,2.23,2.23,0,0,0,0,4.44,1.7,1.7,0,0,0,1.62-1H144a3.15,3.15,0,0,1-3.15,2.17,3.43,3.43,0,0,1,0-6.85Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M149.23,60.52h-2.67l-.45,1.28H144.7l2.41-6.71h1.57l2.41,6.71h-1.42Zm-1.34-3.88-1,2.81h1.94Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M154.51,55.1c1.61,0,2.42.93,2.42,2a1.94,1.94,0,0,1-1.53,1.93L157,61.8h-1.55L154,59.19h-.63V61.8H152V55.1Zm0,1.11h-1.12v2h1.12c.75,0,1.08-.39,1.08-1S155.21,56.21,154.46,56.21Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M164,58.46a3.23,3.23,0,0,1-3.53,3.34h-2.34V55.1h2.34A3.23,3.23,0,0,1,164,58.46Zm-3.58,2.2a2,2,0,0,0,2.21-2.2,2,2,0,0,0-2.21-2.23h-.95v4.43Z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("rect", {
              staticClass: "id-start-icon-fullcolor-cls-5",
              attrs: {
                id: "Overlay_lighter",
                x: "1.07",
                y: "37.06",
                width: "212.75",
                height: "140.79",
                rx: "6.65",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("rect", {
                staticClass: "id-start-icon-fullcolor-cls-6",
                attrs: {
                  x: "15.79",
                  y: "53.62",
                  width: "62.99",
                  height: "63.93",
                },
              }),
              _vm._v(" "),
              _c("g", [
                _c("path", {
                  staticClass: "id-start-icon-fullcolor-cls-7",
                  attrs: {
                    d:
                      "M41.79,94.25l-1.28,8.44a3.26,3.26,0,0,1-1.74,2.43l-11,5.49,39.26.31L59,106.36a5.68,5.68,0,0,1-2.77-3.92l-1.5-7.9Z",
                  },
                }),
                _vm._v(" "),
                _c("g", [
                  _c("path", {
                    staticClass: "id-start-icon-fullcolor-cls-8",
                    attrs: {
                      d:
                        "M37.51,105.05s3.14,3,11,3,11-2,11-2l14.82,8.5,2.73,3.22-59.52-.31L20,114.35Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "id-start-icon-fullcolor-cls-9",
                    attrs: {
                      d:
                        "M37.51,105.05s3.14,3,11,3,11-2,11-2l14.82,8.5,2.73,3.22-59.52-.31L20,114.35Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "id-start-icon-fullcolor-cls-9",
                    attrs: {
                      d:
                        "M37.51,105.05s3.14,3,11,3,11-2,11-2l14.82,8.5,2.73,3.22-59.52-.31L20,114.35Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "id-start-icon-fullcolor-cls-10",
                    attrs: {
                      d:
                        "M37.51,105.05s3.14,3,11,3,11-2,11-2l14.82,8.5,2.73,3.22-59.52-.31L20,114.35Z",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("path", {
                  staticClass: "id-start-icon-fullcolor-cls-11",
                  attrs: {
                    d:
                      "M41.3,96.23s1.61,3.62,6.82,3.9A7.79,7.79,0,0,0,55.44,96Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "id-start-icon-fullcolor-cls-12",
                  attrs: {
                    d: "M47.09,67.69a1,1,0,0,1,0-.31A.68.68,0,0,1,47.09,67.69Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "id-start-icon-fullcolor-cls-13",
                  attrs: {
                    d:
                      "M59.43,84.87a19,19,0,0,1-.53,5.28,12.83,12.83,0,0,1-3.32,5.73S52,98.72,48.67,98.79l-.41,0c-.49,0-1,0-1.53-.06-.24,0-.49,0-.73-.09h0c-3.23-.52-4.87-2.42-6.36-4.44a15.39,15.39,0,0,1-3.29-8.8V78.89c0-2.28,0-2.72,0-5.45,0,0,5.07,1,12-2.71a5.84,5.84,0,0,0,3.68,1.55c3.76.13,4.57-1.55,4.59-1.94.23,1,.44,8,2.21,8.9,1.32.83.55.24.54.41Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "id-start-icon-fullcolor-cls-14",
                  attrs: {
                    d: "M35.53,81.47s-2.34.09-2.29,2,3.22,3.6,3.22,3.6Z",
                  },
                }),
                _vm._v(" "),
                _c("polyline", {
                  staticClass: "id-start-icon-fullcolor-cls-15",
                  attrs: { points: "47.75 81.99 46.35 89.72 47.56 90.69" },
                }),
                _vm._v(" "),
                _c("circle", {
                  staticClass: "id-start-icon-fullcolor-cls-16",
                  attrs: { cx: "42.18", cy: "83.06", r: "1.06" },
                }),
                _vm._v(" "),
                _c("circle", {
                  staticClass: "id-start-icon-fullcolor-cls-16",
                  attrs: { cx: "53.06", cy: "83.06", r: "1.06" },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "id-start-icon-fullcolor-cls-2",
                  attrs: {
                    d:
                      "M46.55,93.75a16.18,16.18,0,0,0,4.62-.89,2.8,2.8,0,0,1-2.31,1.37A3,3,0,0,1,46.55,93.75Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "id-start-icon-fullcolor-cls-17",
                  attrs: {
                    d: "M47.09,67.69a1,1,0,0,1,0-.31A.68.68,0,0,1,47.09,67.69Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "id-start-icon-fullcolor-cls-18",
                  attrs: {
                    d: "M59.46,80.81s2.34-.1,2.44,1.8-2.6,4.2-2.6,4.2Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "id-start-icon-fullcolor-cls-19",
                  attrs: {
                    d:
                      "M36.4,86.54c.14-.71.27-1.42.41-2.13-.17-5,.89-6.75,2-7.31A6.54,6.54,0,0,0,41.12,76a4.59,4.59,0,0,0,1.27-2.75,9.59,9.59,0,0,0,3.55,3.09c4.86,2.36,10.22-.89,11.65,1.21.82,1.22-.55,3,.82,5.35a5.6,5.6,0,0,0,1.05,1.32,16.81,16.81,0,0,0,3.81-11.44,15,15,0,0,0-5.91-11c-.79-.62-6.83-5.18-12.18-3.26a16,16,0,0,0-5.2,3.1,10,10,0,0,1-1.65,1.36,14.8,14.8,0,0,1-3.28,1.32,8.05,8.05,0,0,0-3.87,4.46,9.48,9.48,0,0,0-.34,5.07C31.66,79.22,34.59,84,36.4,86.54Z",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("rect", {
                staticClass: "id-start-icon-fullcolor-cls-20",
                attrs: {
                  id: "Overlay",
                  x: "15.79",
                  y: "53.62",
                  width: "62.99",
                  height: "63.93",
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("rect", {
              staticClass: "id-start-icon-fullcolor-cls-6",
              attrs: {
                x: "158.34",
                y: "127.97",
                width: "48.87",
                height: "45.61",
                rx: "5.52",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("path", {
                staticClass: "id-start-icon-fullcolor-cls-21",
                attrs: { d: "M168.8,138.28h5v25h-5Z" },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "id-start-icon-fullcolor-cls-21",
                attrs: {
                  d:
                    "M200.67,150.81c0,7.56-5.19,12.47-13.18,12.47h-8.73v-25h8.73C195.48,138.28,200.67,143.22,200.67,150.81ZM187.31,159c5.27,0,8.24-3,8.24-8.2s-3-8.3-8.24-8.3h-3.54V159Z",
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }