<template>
  <div v-if="message" class="ui-camera-description" :class="{ review }">
    <h2>{{ $td(`Header.${message}`) }}</h2>
    <p>
      {{ $td(`Description.${message}`) }}
    </p>
    <instructions-container v-if="instructions" :resource="resource" />
  </div>
</template>

<script>
import InstructionsContainer from '@src/components/partials/InstructionsContainer'

export default {
  name: 'ui-camera-description',

  components: {
    InstructionsContainer
  },

  props: {
    message: { type: String, default: undefined, required: true },
    resource: { type: String, default: undefined, required: true },
    live: Boolean,
    instructions: Boolean,
    review: Boolean
  }
}
</script>

<style scoped>
.mobile-app .review .instructions-container {
  display: none;
}

@media screen and (max-width: 30em) {
  .desktop-app .review .instructions-container {
    display: none;
  }
}
</style>
