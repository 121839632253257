var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-camera-frame",
    { attrs: { file: _vm.file, "active-controls": "" } },
    [
      _c("ui-camera-description", {
        attrs: {
          slot: "description",
          resource: _vm.resource,
          message: _vm.message,
        },
        slot: "description",
      }),
      _vm._v(" "),
      _c("ui-camera", {
        attrs: {
          slot: "camera",
          resource: _vm.resource,
          message: "Info",
          controls: _vm.photoControls,
          "capture-callback": _vm.captureComplete,
        },
        slot: "camera",
      }),
      _vm._v(" "),
      _c("camera-controls", {
        attrs: {
          slot: "controls",
          completed: _vm.isCompleted,
          "enable-continue": "",
          "active-controls": "",
          error: !!_vm.error,
          "reset-callback": _vm.reset,
        },
        on: { continue: _vm.continueSubmition },
        slot: "controls",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }