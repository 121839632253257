// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.card-list[data-v-ca134ea6] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (max-width: 38rem) {
.card-list[data-v-ca134ea6] {
    margin-top: 1.5rem;
}
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/CardList.vue"],"names":[],"mappings":";AAOA;EACA,aAAA;EACA,eAAA;EACA,uBAAA;AACA;AAEA;AACA;IACA,kBAAA;AACA;AACA","sourcesContent":["<template>\n  <div class=\"card-list\">\n    <slot />\n  </div>\n</template>\n\n<style scoped>\n.card-list {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n@media screen and (max-width: 38rem) {\n  .card-list {\n    margin-top: 1.5rem;\n  }\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
