// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (orientation: landscape) {
  .mobile-app .app-content + .portrait-only {
    background-color: var(--white);
    background-image: url('/public/images/landscape-error.png');
    background-size: auto 70%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    inset: 0;
    z-index: 5;
  }
}

/* @media screen and (min-width: 48.0625rem) and (max-height: 37.5rem) and (min-aspect-ratio: 16/10) {
  .app-content {
    position: relative;
    filter: blur(10px);
  }

  .app-content + .app-overlay {
    background-color: rgba(255, 255, 255, .5);
    background-image: url('/static/images/screen-desktop-error.png');
    background-size: auto 70%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
  }

  .app-content + .app-overlay.mobile {
    background-image: url('/static/images/screen-mobile-error.png');
  }
} */
`, "",{"version":3,"sources":["webpack://./src/styles/overlay.css"],"names":[],"mappings":"AAAA;EACE;IACE,8BAA8B;IAC9B,2DAA2D;IAC3D,yBAAyB;IACzB,2BAA2B;IAC3B,4BAA4B;IAC5B,kBAAkB;IAClB,QAAQ;IACR,UAAU;EACZ;AACF;;AAEA;;;;;;;;;;;;;;;;;;;;;;;GAuBG","sourcesContent":["@media screen and (orientation: landscape) {\n  .mobile-app .app-content + .portrait-only {\n    background-color: var(--white);\n    background-image: url('/public/images/landscape-error.png');\n    background-size: auto 70%;\n    background-position: center;\n    background-repeat: no-repeat;\n    position: absolute;\n    inset: 0;\n    z-index: 5;\n  }\n}\n\n/* @media screen and (min-width: 48.0625rem) and (max-height: 37.5rem) and (min-aspect-ratio: 16/10) {\n  .app-content {\n    position: relative;\n    filter: blur(10px);\n  }\n\n  .app-content + .app-overlay {\n    background-color: rgba(255, 255, 255, .5);\n    background-image: url('/static/images/screen-desktop-error.png');\n    background-size: auto 70%;\n    background-position: center;\n    background-repeat: no-repeat;\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    z-index: 5;\n  }\n\n  .app-content + .app-overlay.mobile {\n    background-image: url('/static/images/screen-mobile-error.png');\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
