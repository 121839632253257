var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 105 76",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_302_400)" } }, [
        _c("path", {
          attrs: {
            opacity: "0.5",
            d:
              "M100.109 6.07346H4.04889C3.12359 6.07346 2.37349 6.82333 2.37349 7.74834V70.1618C2.37349 71.0868 3.12359 71.8367 4.04889 71.8367H100.109C101.035 71.8367 101.785 71.0868 101.785 70.1618V7.74834C101.785 6.82333 101.035 6.07346 100.109 6.07346Z",
            fill: "#F0EFEF",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d:
              "M100.608 6.68359H4.54752C3.62222 6.68359 2.87212 7.43347 2.87212 8.35848V70.772C2.87212 71.697 3.62222 72.4468 4.54752 72.4468H100.608C101.533 72.4468 102.283 71.697 102.283 70.772V8.35848C102.283 7.43347 101.533 6.68359 100.608 6.68359Z",
            fill: "#F0EFEF",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d: "M38.4505 14.0092H7.65102V45.2578H38.4505V14.0092Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.2",
            d:
              "M101.785 62.0785V68.5906C101.857 71.6413 99.7025 71.8447 98.4619 71.8447L4.32015 71.8925C3.04365 71.8925 2.41338 71.2106 2.41338 69.7032L2.39343 62.0785H101.785Z",
            fill: "#BABABA",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.15",
            d:
              "M102.279 62.1184V68.9894C102.347 72.2156 100.197 72.4309 98.9566 72.4309H4.74299C3.19923 72.4309 2.86813 71.749 2.86813 69.9704V62.1184H102.279Z",
            fill: "#BABABA",
          },
        }),
        _vm._v(" "),
        _c("g", { attrs: { opacity: "0.7" } }, [
          _c("path", {
            attrs: {
              d:
                "M95.6935 45.6726H77.1604C76.0037 45.6726 75.0661 46.6099 75.0661 47.7662V64.7783C75.0661 65.9345 76.0037 66.8719 77.1604 66.8719H95.6935C96.8502 66.8719 97.7878 65.9345 97.7878 64.7783V47.7662C97.7878 46.6099 96.8502 45.6726 95.6935 45.6726Z",
              fill: "white",
            },
          }),
        ]),
        _vm._v(" "),
        _c("g", { attrs: { opacity: "0.15" } }, [
          _c("path", {
            attrs: {
              d: "M46.1853 14.755H46.8156V17.8854H46.1853V14.755Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M50.1824 16.3501C50.1824 17.2952 49.5321 17.9093 48.5309 17.9093H47.4339V14.755H48.5309C48.7481 14.7344 48.9672 14.7611 49.1731 14.8332C49.379 14.9053 49.5668 15.0212 49.7237 15.1727C49.8806 15.3242 50.0028 15.5079 50.082 15.7112C50.1612 15.9144 50.1954 16.1324 50.1824 16.3501ZM48.507 17.3789C48.6458 17.3932 48.7861 17.3765 48.9177 17.3299C49.0492 17.2833 49.1688 17.208 49.2677 17.1095C49.3666 17.011 49.4423 16.8918 49.4894 16.7605C49.5366 16.6291 49.5539 16.4889 49.5401 16.3501C49.5543 16.2106 49.5374 16.0697 49.4906 15.9375C49.4438 15.8054 49.3683 15.6852 49.2695 15.5857C49.1707 15.4862 49.0511 15.4098 48.9192 15.362C48.7874 15.3142 48.6466 15.2962 48.507 15.3093H48.0642V17.3789H48.507Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M52.4681 15.2774H51.2714V16.0749H52.3285V16.5734H51.2714V17.4029H52.4681V17.9133H50.661V14.755H52.4761L52.4681 15.2774Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M55.7192 14.755V17.8894H55.0889L53.6688 15.74V17.8894H53.0545V14.755H53.6847L55.1048 16.9084V14.755H55.7192Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M56.1699 14.755H58.4676V15.2614H57.6339V17.8854H57.0036V15.2774H56.1699V14.755Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M58.9264 14.755H59.5527V17.8854H58.9264V14.755Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M60.0074 14.755H62.3051V15.2614H61.4714V17.8854H60.8411V15.2774H60.0074V14.755Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M62.5006 14.755H63.2106L63.8848 16.1866L64.5509 14.755H65.253L64.1919 16.7967V17.8854H63.5656V16.8127L62.5006 14.755Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M68.1172 14.731C68.4402 14.7149 68.7596 14.8052 69.0263 14.9882C69.293 15.1712 69.4921 15.4368 69.5931 15.7439H68.8671C68.7988 15.6041 68.6909 15.4872 68.557 15.4079C68.423 15.3285 68.2687 15.2901 68.1132 15.2973C67.9797 15.2963 67.8476 15.3238 67.7256 15.3779C67.6036 15.4321 67.4945 15.5117 67.4058 15.6113C67.3171 15.711 67.2506 15.8285 67.2109 15.9559C67.1713 16.0833 67.1593 16.2177 67.1757 16.3501C67.1623 16.4811 67.1766 16.6136 67.2176 16.7387C67.2587 16.8639 67.3255 16.9791 67.4139 17.0769C67.5022 17.1746 67.6101 17.2527 67.7306 17.3061C67.8511 17.3595 67.9814 17.3871 68.1132 17.3869C68.2696 17.3935 68.4245 17.3538 68.5586 17.273C68.6926 17.1922 68.7999 17.0737 68.8671 16.9323H69.5931C69.4927 17.2399 69.2937 17.5058 69.0269 17.6889C68.7601 17.872 68.4403 17.9621 68.1172 17.9452C67.694 17.9452 67.2881 17.7772 66.9889 17.478C66.6897 17.1789 66.5215 16.7731 66.5215 16.3501C66.5215 15.927 66.6897 15.5213 66.9889 15.2222C67.2881 14.923 67.694 14.755 68.1172 14.755V14.731Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M72.0504 17.3072H70.8018L70.6063 17.9014H69.9481L71.0611 14.755H71.8031L72.928 17.8894H72.2658L72.0504 17.3072ZM71.4241 15.4927L70.9733 16.8047H71.8788L71.4241 15.4927Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M74.5156 14.755C75.2696 14.755 75.6485 15.1896 75.6485 15.716C75.6523 15.9257 75.5832 16.1301 75.453 16.2945C75.3228 16.4589 75.1395 16.573 74.9345 16.6173L75.6844 17.8854H74.9584L74.2643 16.6651H73.9691V17.8854H73.3428V14.755H74.5156ZM74.4957 15.2774H73.9691V16.1946H74.4957C74.8467 16.1946 75.0023 16.0111 75.0023 15.728C75.0023 15.4449 74.8467 15.2933 74.4957 15.2933V15.2774Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M78.9834 16.3501C78.9834 17.2952 78.3331 17.9093 77.3319 17.9093H76.2389V14.755H77.3319C77.5491 14.7344 77.7682 14.7611 77.9741 14.8332C78.18 14.9053 78.3678 15.0212 78.5247 15.1727C78.6816 15.3242 78.8038 15.5079 78.883 15.7112C78.9622 15.9144 78.9964 16.1324 78.9834 16.3501ZM77.3119 17.3789C77.4491 17.3902 77.587 17.3711 77.7159 17.3231C77.8448 17.2751 77.9616 17.1993 78.0579 17.1011C78.1542 17.0029 78.2277 16.8847 78.2732 16.7549C78.3187 16.6251 78.3351 16.4869 78.3212 16.3501C78.336 16.2108 78.3196 16.07 78.2733 15.9378C78.227 15.8056 78.1519 15.6854 78.0534 15.5858C77.9549 15.4861 77.8355 15.4097 77.7038 15.3618C77.5722 15.314 77.4315 15.2961 77.292 15.3093H76.8452V17.3789H77.3119Z",
              fill: "#010101",
            },
          }),
        ]),
        _vm._v(" "),
        _c("g", { attrs: { opacity: "0.2" } }, [
          _c("path", {
            attrs: {
              d: "M45.8742 14.4958H46.5005V17.6302H45.8742V14.4958Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M49.8632 16.067C49.8716 16.2821 49.8338 16.4965 49.7525 16.6958C49.6713 16.8951 49.5483 17.0748 49.392 17.2228C49.2356 17.3708 49.0494 17.4837 48.8459 17.554C48.6424 17.6242 48.4262 17.6502 48.2118 17.6302H47.1148V14.4957H48.2118C48.4269 14.4756 48.6438 14.5018 48.8479 14.5725C49.052 14.6432 49.2386 14.7568 49.3951 14.9057C49.5516 15.0546 49.6744 15.2353 49.7551 15.4356C49.8359 15.6359 49.8727 15.8512 49.8632 16.067ZM48.1878 17.0958C48.3268 17.1108 48.4674 17.0946 48.5994 17.0483C48.7313 17.0019 48.8511 16.9267 48.9502 16.828C49.0493 16.7294 49.125 16.6099 49.1718 16.4782C49.2187 16.3465 49.2355 16.206 49.221 16.067C49.2359 15.9273 49.2195 15.7861 49.173 15.6536C49.1264 15.5211 49.051 15.4006 48.952 15.3009C48.8531 15.2012 48.7331 15.1249 48.6009 15.0773C48.4688 15.0298 48.3276 15.0123 48.1878 15.0261H47.749V17.0958H48.1878Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M52.1569 15.0022H50.9602V15.7998H52.0173V16.2982H50.9602V17.1277H52.1569V17.6421H50.3379V14.4918H52.1569V15.0022Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M55.404 14.4918V17.6302H54.7738L53.3537 15.4807V17.6302H52.7234V14.4918H53.3537L54.7738 16.6452V14.4918H55.404Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M55.8468 14.4958H58.1445V15.0022H57.3108V17.6302H56.6845V15.0022H55.8468V14.4958Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M58.6112 14.4958H59.2415V17.6302H58.6112V14.4958Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M59.6923 14.4958H61.99V15.0022H61.1563V17.6302H60.526V15.0022H59.6923V14.4958Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M62.1854 14.4958H62.8955L63.5696 15.9274L64.2238 14.4958H64.9219L63.8648 16.5375V17.6302H63.2346V16.5375L62.1854 14.4958Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M67.814 14.4559C68.1364 14.4406 68.4549 14.5314 68.7207 14.7143C68.9866 14.8973 69.1851 15.1623 69.286 15.4688H68.5639C68.4959 15.3295 68.3887 15.2131 68.2555 15.1338C68.1223 15.0545 67.9689 15.0157 67.814 15.0221C67.6817 15.0208 67.5506 15.0475 67.4294 15.1005C67.3081 15.1534 67.1995 15.2314 67.1106 15.3294C67.0216 15.4273 66.9545 15.543 66.9134 15.6687C66.8724 15.7945 66.8585 15.9275 66.8726 16.059C66.8591 16.1904 66.8735 16.3231 66.9148 16.4486C66.956 16.5741 67.0232 16.6894 67.112 16.7872C67.2008 16.885 67.3092 16.963 67.4302 17.0162C67.5511 17.0694 67.6819 17.0965 67.814 17.0958C67.9704 17.1023 68.1253 17.0627 68.2594 16.9819C68.3934 16.9011 68.5007 16.7826 68.5679 16.6412H69.29C69.1895 16.9488 68.9905 17.2147 68.7237 17.3978C68.4569 17.5809 68.1372 17.671 67.814 17.6541C67.3908 17.6541 66.985 17.4861 66.6857 17.1869C66.3865 16.8878 66.2184 16.482 66.2184 16.059C66.2184 15.6359 66.3865 15.2302 66.6857 14.9311C66.985 14.6319 67.3908 14.4638 67.814 14.4638V14.4559Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M71.7352 17.032H70.4867L70.2832 17.6302H69.621L70.7499 14.4918H71.4799L72.6009 17.6302H71.9387L71.7352 17.032ZM71.113 15.2175L70.6582 16.5295H71.5637L71.113 15.2175Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M74.1965 14.4958C74.9504 14.4958 75.3254 14.9304 75.3254 15.4568C75.3277 15.664 75.2589 15.8657 75.1304 16.0283C75.0019 16.1909 74.8215 16.3045 74.6193 16.3501L75.3693 17.6222H74.6433L73.9532 16.4019H73.654V17.6222H73.0277V14.4958H74.1965ZM74.1965 15.0182H73.6699V15.9513H74.1965C74.5475 15.9513 74.7031 15.7679 74.7031 15.4847C74.7031 15.2016 74.5316 15.0182 74.1965 15.0182Z",
              fill: "#010101",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M78.6483 16.067C78.6567 16.2828 78.6188 16.4979 78.5371 16.6979C78.4553 16.8978 78.3317 17.078 78.1744 17.2261C78.0172 17.3742 77.83 17.487 77.6255 17.5567C77.4209 17.6264 77.2039 17.6514 76.9888 17.6302H75.8958V14.4957H76.9888C77.2046 14.4744 77.4224 14.4996 77.6274 14.5698C77.8325 14.64 78.0202 14.7534 78.1775 14.9024C78.3349 15.0515 78.4584 15.2326 78.5397 15.4335C78.6209 15.6344 78.6579 15.8505 78.6483 16.067ZM76.9888 17.0958C77.1277 17.1101 77.268 17.0933 77.3995 17.0467C77.5311 17.0001 77.6507 16.9249 77.7496 16.8264C77.8484 16.7279 77.9242 16.6087 77.9713 16.4773C78.0184 16.346 78.0357 16.2058 78.022 16.067C78.0362 15.9275 78.0192 15.7866 77.9724 15.6544C77.9256 15.5222 77.8501 15.4021 77.7514 15.3026C77.6526 15.203 77.533 15.1266 77.4011 15.0788C77.2693 15.031 77.1285 15.013 76.9888 15.0261H76.5421V17.0958H76.9888Z",
              fill: "#010101",
            },
          }),
        ]),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d: "M87.9268 30.8419H45.6907V33.099H87.9268V30.8419Z",
            fill: "#B7B6B9",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.3",
            d: "M88.6848 31.2646H46.4486V33.5217H88.6848V31.2646Z",
            fill: "#B7B6B9",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d: "M87.9268 25.0475H45.6907V27.3047H87.9268V25.0475Z",
            fill: "#B7B6B9",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.3",
            d: "M88.6848 25.5261H46.4486V27.7832H88.6848V25.5261Z",
            fill: "#B7B6B9",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d: "M87.9268 36.8276H45.6907V39.0847H87.9268V36.8276Z",
            fill: "#B7B6B9",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.3",
            d: "M88.6848 37.6052H46.4486V39.8623H88.6848V37.6052Z",
            fill: "#B7B6B9",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d: "M64.906 42.8173H45.6907V45.0744H64.906V42.8173Z",
            fill: "#B7B6B9",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.3",
            d: "M65.6639 43.4155H46.4486V45.6726H65.6639V43.4155Z",
            fill: "#B7B6B9",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d:
              "M18.4015 39.0129C18.4015 39.0129 20.2684 40.4286 23.4916 40.4804C26.7147 40.5323 28.5816 39.4556 28.5816 39.4556C28.2285 39.2536 27.9238 38.9769 27.6888 38.6449C27.4539 38.3129 27.2943 37.9335 27.2213 37.5334L26.9221 33.8965L25.2747 34.5705C23.7867 35.1886 22.3148 35.4717 20.8867 34.722L19.69 33.9603L19.5464 37.6531C19.4579 37.9438 19.3121 38.214 19.1175 38.4475C18.9229 38.6811 18.6835 38.8733 18.4135 39.0129",
            fill: "#E1DCD4",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d:
              "M18.8962 39.1963C18.8962 39.1963 20.7431 40.612 23.9862 40.6758C27.2293 40.7396 29.0762 39.647 29.0762 39.647C28.7231 39.445 28.4184 39.1683 28.1835 38.8363C27.9485 38.5043 27.789 38.1249 27.716 37.7248L27.0179 34.0441L19.9652 34.2953L19.8655 37.8843C19.8076 38.1619 19.6913 38.4238 19.5241 38.6528C19.357 38.8818 19.1429 39.0725 18.8962 39.2123",
            fill: "#E1DCD4",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.3",
            d:
              "M19.694 34.0281C19.694 34.0281 20.5397 36.4687 23.1246 36.6082C25.7933 36.7518 27.0179 34.0321 27.0179 34.0321L19.694 34.0281Z",
            fill: "#B0ABA7",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d:
              "M19.8655 34.1996C20.2583 34.842 20.8174 35.3665 21.4838 35.7174C22.1502 36.0684 22.899 36.2329 23.6511 36.1935C24.357 36.1738 25.044 35.9612 25.6375 35.5788C26.2311 35.1964 26.7085 34.6587 27.0179 34.0241L19.8655 34.1996Z",
            fill: "#B0ABA7",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d:
              "M29.0124 27.1172C29.0124 27.1172 30.1573 27.0654 30.2091 27.9985C30.261 28.9317 28.821 30.0762 28.821 30.0762L29.0124 27.1172Z",
            fill: "#E1DCD5",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d:
              "M29.5789 26.4712C29.5789 26.4712 30.7197 26.4193 30.7756 27.3525C30.8314 28.2857 29.3954 29.486 29.3954 29.486L29.5789 26.4712Z",
            fill: "#E1DCD5",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d:
              "M17.4561 27.181C17.4561 27.181 16.3192 27.0295 16.1876 27.9507C16.056 28.8719 17.4362 29.9446 17.4362 29.9446L17.4561 27.181Z",
            fill: "#E1DCD4",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d:
              "M17.8112 26.85C17.8112 26.85 16.6783 26.6945 16.5426 27.6197C16.407 28.5449 17.7912 29.6136 17.7912 29.6136L17.8112 26.85Z",
            fill: "#E1DCD4",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.2",
            d:
              "M17.3165 27.8709V29.5099C17.3165 29.5099 17.4561 32.9235 19.8655 34.1956C22.4624 35.5674 24.441 35.5634 25.2787 35.1527C26.1164 34.7419 27.0897 34.399 28.5297 32.1499C29.9698 29.9007 28.8648 25.4144 28.8648 25.4144C28.8648 25.4144 28.6295 21.6499 26.8703 21.1714C25.1111 20.6928 21.1899 20.2143 19.4786 21.5702C17.7673 22.926 17.3165 27.8709 17.3165 27.8709Z",
            fill: "#E1DCD3",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M17.1729 27.5599L17.3165 28.7562C17.3165 28.7562 17.7593 32.1419 19.8655 34.1916C20.5601 34.8878 21.4524 35.3532 22.421 35.5244C23.3895 35.6956 24.3873 35.5645 25.2787 35.1487C26.1124 34.7499 27.4008 34.2634 28.3861 31.8348C29.3714 29.4062 28.7212 25.1233 28.7212 25.1233C28.7212 25.1233 28.4859 21.3628 26.7267 20.8842C24.9675 20.4057 21.0463 19.9272 19.339 21.283C17.6316 22.6389 17.1729 27.5599 17.1729 27.5599Z",
            fill: "#E9E4DE",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.3",
            d:
              "M23.1525 27.7832L22.5382 31.2287L23.1964 31.6035L23.1525 27.7832Z",
            fill: "#B0ABA7",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.3",
            d:
              "M23.1525 27.7832L22.2869 31.0851L22.9451 31.46L23.1525 27.7832Z",
            fill: "#B0ABA7",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M21.2377 22.5312C21.2377 22.5312 22.0356 24.8641 25.6616 24.9997C26.4594 25.0675 27.1017 25.1034 27.1017 25.1034C27.1017 25.1034 28.7332 25.2629 28.6374 26.5031C28.5537 27.6994 28.7531 28.3375 28.8648 29.4062C29.7344 28.0544 30.7636 27.2488 31.3341 24.0586C31.5974 22.6666 31.4572 21.2285 30.9299 19.9136C30.4027 18.5987 29.5104 17.4619 28.3582 16.6372C26.24 15.082 24.5766 14.8586 23.1724 15.401C21.7683 15.9433 21.872 15.8357 20.5955 16.6452C20.0962 16.9713 19.5227 17.166 18.9281 17.2115C18.0548 17.2723 17.2176 17.5839 16.517 18.1088C15.8165 18.6337 15.2824 19.3495 14.9789 20.1704C14.0574 22.611 16.8498 27.201 17.3205 28.7642C17.7912 27.1172 17.1529 26.3197 18.6728 24.7245C19.8615 23.5641 20.9346 23.3088 21.2377 22.5312Z",
            fill: "#646565",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.3",
            d:
              "M21.7045 22.7306C21.7045 22.7306 22.4065 24.8481 26.0326 24.9837C26.8304 25.0515 27.4687 25.0874 27.4687 25.0874C27.4687 25.0874 29.1002 25.2469 29.0084 26.4871C28.9207 27.6835 28.7531 28.3215 28.8648 29.3903C29.7344 28.0384 31.2942 26.8341 31.8646 23.6398C32.435 20.4456 30.7157 17.8056 28.5816 16.2544C26.4475 14.7031 24.5925 14.5915 23.1964 15.1338C21.8002 15.6761 21.868 15.8357 20.5915 16.6452C20.0689 16.9124 20.1168 17.1516 19.2911 17.2115C18.4178 17.2723 17.5806 17.5839 16.88 18.1088C16.1795 18.6337 15.6454 19.3495 15.3419 20.1704C14.4204 22.611 16.8458 27.201 17.3165 28.7642C17.7912 27.1332 17.7433 26.4473 19.2632 24.8481C20.4479 23.6877 21.4013 23.5282 21.7045 22.7306Z",
            fill: "#333333",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d:
              "M20.2205 28.5688C20.5091 28.5688 20.7431 28.3349 20.7431 28.0464C20.7431 27.7579 20.5091 27.524 20.2205 27.524C19.9319 27.524 19.698 27.7579 19.698 28.0464C19.698 28.3349 19.9319 28.5688 20.2205 28.5688Z",
            fill: "#333333",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d:
              "M25.5459 28.5688C25.8345 28.5688 26.0685 28.3349 26.0685 28.0464C26.0685 27.7579 25.8345 27.524 25.5459 27.524C25.2573 27.524 25.0234 27.7579 25.0234 28.0464C25.0234 28.3349 25.2573 28.5688 25.5459 28.5688Z",
            fill: "#333333",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d:
              "M20.4639 28.7004C20.7525 28.7004 20.9864 28.4665 20.9864 28.178C20.9864 27.8895 20.7525 27.6556 20.4639 27.6556C20.1753 27.6556 19.9413 27.8895 19.9413 28.178C19.9413 28.4665 20.1753 28.7004 20.4639 28.7004Z",
            fill: "#333333",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d:
              "M25.7893 28.7004C26.0779 28.7004 26.3118 28.4665 26.3118 28.178C26.3118 27.8895 26.0779 27.6556 25.7893 27.6556C25.5007 27.6556 25.2667 27.8895 25.2667 28.178C25.2667 28.4665 25.5007 28.7004 25.7893 28.7004Z",
            fill: "#333333",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M22.3587 33.2784C23.1309 33.2435 23.8941 33.0971 24.6245 32.8437C24.5047 33.0382 24.3385 33.1997 24.1407 33.3139C23.9429 33.4281 23.7199 33.4913 23.4916 33.4977C22.6618 33.5775 22.3587 33.2784 22.3587 33.2784Z",
            fill: "#F2F2F2",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M104.398 0.578235V75.3699H0.602348V0.578235H104.398ZM105 0H0V75.9801H105V0Z",
            fill: "#333333",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d: "M77.4635 49.9236H79.9048V63.4423H77.4635V49.9236Z",
            fill: "#BBC7D5",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d:
              "M82.9126 49.9236H87.6755C92.1472 49.9236 95.2946 52.7151 95.2946 56.671C95.2946 60.6269 92.1472 63.4503 87.6755 63.4503H82.9126V49.9236ZM87.9348 61.2171C90.787 61.2171 92.7935 59.3269 92.7935 56.671C92.7935 54.0151 90.799 52.1448 87.9348 52.1448H85.3659V61.2171H87.9348Z",
            fill: "#BBC7D5",
          },
        }),
        _vm._v(" "),
        _c("path", {
          staticClass: "brand-color",
          attrs: {
            opacity: "0.5",
            d: "M78.2494 50.6773H80.6428V64.204H78.2494V50.6773Z",
            fill: "#BBC7D5",
          },
        }),
        _vm._v(" "),
        _c("path", {
          staticClass: "brand-color",
          attrs: {
            opacity: "0.5",
            d:
              "M83.6346 50.6773H88.3417C92.7576 50.6773 95.869 53.4688 95.869 57.4287C95.869 61.3886 92.7576 64.208 88.3417 64.208H83.6346V50.6773ZM88.593 61.9748C91.4133 61.9748 93.3799 60.0846 93.3799 57.4287C93.3799 54.7728 91.3853 52.9025 88.593 52.9025H86.0679V61.9748H88.593Z",
            fill: "#BBC7D5",
          },
        }),
        _vm._v(" "),
        _c("g", [
          _c("path", {
            staticClass: "brand-color",
            attrs: {
              d:
                "M15.2342 64.3077C14.5772 64.3069 13.9382 64.0931 13.4131 63.6984C12.888 63.3036 12.5052 62.7492 12.3222 62.1184H7.23615V64.9497C7.23615 65.3347 7.38913 65.7039 7.66143 65.9761C7.93374 66.2484 8.30306 66.4013 8.68816 66.4013H21.7803C22.1654 66.4013 22.5347 66.2484 22.807 65.9761C23.0793 65.7039 23.2323 65.3347 23.2323 64.9497V62.1184H18.1462C17.9632 62.7492 17.5804 63.3036 17.0553 63.6984C16.5302 64.0931 15.8912 64.3069 15.2342 64.3077Z",
              fill: "#BBC7D5",
            },
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "brand-color",
            attrs: {
              d:
                "M21.7803 56.1486H8.68816C8.30306 56.1486 7.93374 56.3015 7.66143 56.5738C7.38913 56.846 7.23615 57.2152 7.23615 57.6002V60.4315H12.3222C12.5049 59.8007 12.8877 59.2463 13.4129 58.8518C13.9381 58.4572 14.5773 58.2439 15.2342 58.2439C15.8912 58.2439 16.5303 58.4572 17.0555 58.8518C17.5807 59.2463 17.9635 59.8007 18.1462 60.4315H23.2323V57.6002C23.2323 57.2152 23.0793 56.846 22.807 56.5738C22.5347 56.3015 22.1654 56.1486 21.7803 56.1486Z",
              fill: "#BBC7D5",
            },
          }),
        ]),
        _vm._v(" "),
        _c("path", {
          staticClass: "brand-color",
          attrs: {
            d:
              "M15.2342 63.0874C16.2344 63.0874 17.0453 62.2768 17.0453 61.2769C17.0453 60.277 16.2344 59.4665 15.2342 59.4665C14.234 59.4665 13.4232 60.277 13.4232 61.2769C13.4232 62.2768 14.234 63.0874 15.2342 63.0874Z",
          },
        }),
        _vm._v(" "),
        _c("g", { attrs: { opacity: "0.5" } }, [
          _c("path", {
            attrs: {
              d:
                "M15.8445 64.6706C15.1879 64.6703 14.5492 64.4565 14.0246 64.0616C13.5001 63.6667 13.1182 63.1121 12.9365 62.4813H7.84648V65.3166C7.84648 65.7016 7.99946 66.0708 8.27177 66.343C8.54407 66.6152 8.9134 66.7682 9.2985 66.7682H22.3906C22.7757 66.7682 23.145 66.6152 23.4173 66.343C23.6896 66.0708 23.8426 65.7016 23.8426 65.3166V62.4813H18.7486C18.5665 63.1111 18.1851 63.6649 17.6615 64.0596C17.1379 64.4544 16.5003 64.6687 15.8445 64.6706Z",
              fill: "#BBC7D5",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M22.3906 56.5115H9.2985C8.9134 56.5115 8.54407 56.6644 8.27177 56.9366C7.99946 57.2089 7.84648 57.5781 7.84648 57.9631V60.7944H12.9365C13.1204 60.1652 13.5034 59.6126 14.0281 59.2194C14.5528 58.8262 15.1908 58.6136 15.8465 58.6136C16.5023 58.6136 17.1403 58.8262 17.665 59.2194C18.1896 59.6126 18.5727 60.1652 18.7566 60.7944H23.8426V57.9631C23.8426 57.5781 23.6896 57.2089 23.4173 56.9366C23.145 56.6644 22.7757 56.5115 22.3906 56.5115Z",
              fill: "#BBC7D5",
            },
          }),
        ]),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d:
              "M15.8445 63.4503C16.8447 63.4503 17.6556 62.6397 17.6556 61.6398C17.6556 60.6399 16.8447 59.8294 15.8445 59.8294C14.8443 59.8294 14.0335 60.6399 14.0335 61.6398C14.0335 62.6397 14.8443 63.4503 15.8445 63.4503Z",
            fill: "#BBC7D5",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d:
              "M8.19751 45.1103L9.41417 43.3636L18.589 38.4905C18.589 38.4905 20.1447 40.5761 23.691 40.4844C27.2373 40.3927 29.0324 39.3638 29.0324 39.3638L35.9773 43.1762L37.4054 45.1183L8.19751 45.1103Z",
            fill: "#BBC7D5",
          },
        }),
        _vm._v(" "),
        _c("path", {
          staticClass: "brand-color",
          attrs: {
            opacity: "0.5",
            d:
              "M9.27855 45.1063L10.4753 43.5431L19.0517 38.993C19.0517 38.993 20.8867 40.5881 24.433 40.4804C27.9793 40.3728 29.3555 39.1365 29.3555 39.1365L37.0264 43.3477L38.4067 45.1103L9.27855 45.1063Z",
          },
        }),
        _vm._v(" "),
        _c("g", { attrs: { opacity: "0.7" } }, [
          _c("path", {
            attrs: {
              d:
                "M0.658188 45.8281C0.658188 45.8281 3.18326 45.864 4.009 44.4483C4.83473 43.0326 6.80134 43.1762 6.80134 43.1762C7.3197 43.1654 7.83288 43.2811 8.29648 43.5132C8.76008 43.7453 9.16014 44.0868 9.46204 44.5081C10.4314 45.8441 12.7091 45.8281 12.7091 45.8281H12.8248C12.8248 45.8281 14.8911 45.856 16.1756 44.4523C16.9178 43.6993 17.9124 43.2476 18.968 43.1842C19.4895 43.1471 20.0114 43.2517 20.4784 43.4869C20.9454 43.722 21.3401 44.079 21.6207 44.5201C22.4903 45.86 24.9196 45.86 24.9196 45.86",
              stroke: "#B7B6B9",
              "stroke-miterlimit": "10",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M0.66217 42.917C0.66217 42.917 3.18326 42.9489 4.00899 41.5372C4.83473 40.1255 6.82128 40.2771 6.82128 40.2771C7.33897 40.2669 7.85135 40.3829 8.31421 40.615C8.77706 40.847 9.17647 41.1882 9.47799 41.609C10.4473 42.9449 12.7251 42.933 12.7251 42.933H12.8408C12.8408 42.933 14.9111 42.9649 16.1916 41.5611C16.934 40.8079 17.9282 40.355 18.9839 40.289C19.506 40.2521 20.0284 40.3567 20.496 40.5918C20.9636 40.8269 21.3591 41.1839 21.6406 41.6249C22.4704 42.9649 24.8997 42.9609 24.8997 42.9609",
              stroke: "#B7B6B9",
              "stroke-miterlimit": "10",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M0.66217 39.8264C0.66217 39.8264 3.19123 39.8783 4.00899 38.4506C4.82675 37.023 6.82128 37.1745 6.82128 37.1745C7.33897 37.1644 7.85135 37.2804 8.3142 37.5124C8.77706 37.7445 9.17647 38.0856 9.47799 38.5065C10.4473 39.8424 12.7251 39.8264 12.7251 39.8264H12.8408C12.8408 39.8264 14.9111 39.8583 16.1916 38.4506C16.935 37.6995 17.929 37.2481 18.9839 37.1825C19.5097 37.1423 20.0365 37.2457 20.508 37.4817C20.9794 37.7177 21.3779 38.0775 21.6606 38.5224C22.4903 39.8783 24.9196 39.8783 24.9196 39.8783",
              stroke: "#B7B6B9",
              "stroke-miterlimit": "10",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M0.658188 36.688C0.658188 36.688 2.97184 36.7678 3.80157 35.3521C4.63129 33.9364 6.59391 34.08 6.59391 34.08C7.11125 34.0694 7.62341 34.1848 8.08623 34.4161C8.54905 34.6474 8.94866 34.9878 9.25062 35.4079C10.22 36.7478 12.4977 36.7319 12.4977 36.7319H12.6134C12.6134 36.7319 14.6837 36.7638 15.9642 35.3601C16.7063 34.607 17.701 34.1553 18.7565 34.0919C19.2788 34.0537 19.8017 34.1577 20.2695 34.393C20.7374 34.6282 21.1326 34.9859 21.4133 35.4278C22.2789 36.7717 24.7322 36.7678 24.7322 36.7678",
              stroke: "#B7B6B9",
              "stroke-miterlimit": "10",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M0.662064 33.8208C0.662064 33.8208 2.97173 33.8606 3.80145 32.449C4.63118 31.0373 6.59379 31.1728 6.59379 31.1728C7.11183 31.1607 7.62494 31.2757 8.08813 31.508C8.55132 31.7402 8.95045 32.0825 9.25051 32.5048C10.2198 33.8407 12.4976 33.8287 12.4976 33.8287H12.6133C12.6133 33.8287 14.6836 33.8606 15.9681 32.4569C16.71 31.7031 17.7045 31.25 18.7604 31.1848C19.282 31.1477 19.8039 31.2523 20.2709 31.4875C20.7378 31.7227 21.1325 32.0797 21.4131 32.5207C22.2828 33.8606 24.732 33.8567 24.732 33.8567",
              stroke: "#B7B6B9",
              "stroke-miterlimit": "10",
            },
          }),
        ]),
        _vm._v(" "),
        _c("g", { attrs: { opacity: "0.31" } }, [
          _c("path", {
            attrs: {
              d:
                "M0.66217 46.4303C0.66217 46.4303 3.642 46.4303 4.46773 45.0226C5.29347 43.6149 7.28002 43.7465 7.28002 43.7465C7.79632 43.7345 8.30779 43.8481 8.77054 44.0773C9.23328 44.3065 9.63346 44.6446 9.93673 45.0624C10.9061 46.3984 13.1838 46.3864 13.1838 46.3864H13.2995C13.2995 46.3864 15.3698 46.4183 16.6503 45.0146C17.3927 44.2614 18.387 43.8084 19.4427 43.7425C19.9648 43.7055 20.4872 43.8101 20.9548 44.0453C21.4223 44.2804 21.8178 44.6373 22.0994 45.0784C22.9291 46.4183 25.3584 46.4143 25.3584 46.4143",
              stroke: "#B7B6B9",
              "stroke-miterlimit": "10",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M0.658188 43.4673C0.658188 43.4673 3.642 43.5191 4.46774 42.1075C5.29347 40.6958 7.28003 40.8314 7.28003 40.8314C7.79806 40.8192 8.31117 40.9342 8.77436 41.1665C9.23755 41.3987 9.63668 41.741 9.93674 42.1633C10.9061 43.4992 13.1838 43.4833 13.1838 43.4833H13.2995C13.2995 43.4833 15.3698 43.5152 16.6503 42.1075C17.3938 41.3563 18.3877 40.9049 19.4427 40.8393C19.9648 40.802 20.4874 40.9064 20.9551 41.1415C21.4227 41.3767 21.8181 41.7339 22.0994 42.1753C22.9291 43.5112 25.3584 43.5112 25.3584 43.5112",
              stroke: "#B7B6B9",
              "stroke-miterlimit": "10",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M0.658188 40.4286C0.658188 40.4286 3.642 40.4286 4.46774 39.0209C5.29347 37.6132 7.28003 37.7488 7.28003 37.7488C7.79772 37.7387 8.3101 37.8546 8.77295 38.0867C9.2358 38.3187 9.63522 38.6599 9.93674 39.0807C10.9061 40.4206 13.1838 40.4047 13.1838 40.4047H13.2995C13.2995 40.4047 15.3698 40.4366 16.6503 39.0328C17.3925 38.2798 18.3871 37.8281 19.4427 37.7647C19.9663 37.7207 20.492 37.8199 20.9635 38.0517C21.435 38.2835 21.8346 38.6392 22.1193 39.0807C22.949 40.4166 25.3784 40.4126 25.3784 40.4126",
              stroke: "#B7B6B9",
              "stroke-miterlimit": "10",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M0.66217 37.342C0.66217 37.342 3.43058 37.342 4.25232 35.9343C5.07407 34.5266 7.04466 34.6582 7.04466 34.6582C7.5627 34.646 8.07581 34.7611 8.539 34.9933C9.00219 35.2255 9.40132 35.5678 9.70138 35.9901C10.6707 37.3261 12.9485 37.3141 12.9485 37.3141H13.0642C13.0642 37.3141 15.1345 37.346 16.419 35.9423C17.1659 35.184 18.1684 34.7305 19.2312 34.6702C19.7528 34.6331 20.2747 34.7377 20.7417 34.9728C21.2087 35.208 21.6033 35.565 21.884 36.0061C22.7177 37.346 25.147 37.342 25.147 37.342",
              stroke: "#B7B6B9",
              "stroke-miterlimit": "10",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M0.658188 34.4309C0.658188 34.4309 3.45053 34.4309 4.24834 33.0192C5.04615 31.6075 7.04068 31.7471 7.04068 31.7471C7.56516 31.7325 8.08505 31.8484 8.55364 32.0844C9.02224 32.3203 9.42485 32.669 9.72532 33.099C10.6947 34.4349 12.9724 34.4189 12.9724 34.4189H13.0881C13.0881 34.4189 15.1584 34.4468 16.4429 33.0431C17.19 32.2856 18.1927 31.8335 19.2552 31.775C19.7756 31.7367 20.2967 31.8395 20.7636 32.0725C21.2305 32.3055 21.6258 32.6601 21.9079 33.099C22.7416 34.4349 25.171 34.4349 25.171 34.4349",
              stroke: "#B7B6B9",
              "stroke-miterlimit": "10",
            },
          }),
        ]),
        _vm._v(" "),
        _c("g", { attrs: { opacity: "0.18" } }, [
          _c("path", {
            attrs: {
              d:
                "M90.2365 29.3703C95.1692 29.3703 99.168 25.3728 99.168 20.4416C99.168 15.5104 95.1692 11.5129 90.2365 11.5129C85.3038 11.5129 81.305 15.5104 81.305 20.4416C81.305 25.3728 85.3038 29.3703 90.2365 29.3703Z",
              stroke: "#333333",
              "stroke-miterlimit": "10",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M90.2365 27.6516C94.2197 27.6516 97.4487 24.4236 97.4487 20.4416C97.4487 16.4596 94.2197 13.2316 90.2365 13.2316C86.2533 13.2316 83.0243 16.4596 83.0243 20.4416C83.0243 24.4236 86.2533 27.6516 90.2365 27.6516Z",
              stroke: "#333333",
              "stroke-miterlimit": "10",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M91.522 15.7602L85.4792 19.4723L86.5986 21.2934L92.6414 17.5814L91.522 15.7602Z",
              fill: "#333333",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M87.2726 22.3956L93.316 18.683",
              stroke: "#333333",
              "stroke-miterlimit": "10",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M91.7643 23.8552C92.2776 23.8552 92.6938 23.4392 92.6938 22.926C92.6938 22.4129 92.2776 21.9969 91.7643 21.9969C91.251 21.9969 90.8349 22.4129 90.8349 22.926C90.8349 23.4392 91.251 23.8552 91.7643 23.8552Z",
              fill: "#333333",
            },
          }),
        ]),
        _vm._v(" "),
        _c("g", { attrs: { opacity: "0.09" } }, [
          _c("path", {
            attrs: {
              d:
                "M91.0223 29.47C95.9551 29.47 99.9538 25.4725 99.9538 20.5413C99.9538 15.6101 95.9551 11.6125 91.0223 11.6125C86.0896 11.6125 82.0908 15.6101 82.0908 20.5413C82.0908 25.4725 86.0896 29.47 91.0223 29.47Z",
              stroke: "#333333",
              "stroke-miterlimit": "10",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M91.0223 27.7513C95.0055 27.7513 98.2346 24.5233 98.2346 20.5413C98.2346 16.5593 95.0055 13.3313 91.0223 13.3313C87.0391 13.3313 83.8101 16.5593 83.8101 20.5413C83.8101 24.5233 87.0391 27.7513 91.0223 27.7513Z",
              stroke: "#333333",
              "stroke-miterlimit": "10",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M92.3076 15.8606L86.2647 19.5726L87.3841 21.3938L93.427 17.6817L92.3076 15.8606Z",
              fill: "#333333",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M88.0625 22.4953L94.1059 18.7827",
              stroke: "#333333",
              "stroke-miterlimit": "10",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M92.5501 23.9549C93.0635 23.9549 93.4796 23.5389 93.4796 23.0257C93.4796 22.5125 93.0635 22.0965 92.5501 22.0965C92.0368 22.0965 91.6207 22.5125 91.6207 23.0257C91.6207 23.5389 92.0368 23.9549 92.5501 23.9549Z",
              fill: "#333333",
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_302_400" } }, [
          _c("rect", { attrs: { width: "105", height: "76", fill: "white" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }