<template>
  <ui-frame logo>
    <i class="icon">
      <smart-id />
    </i>
    <h1 class="header">
      {{ $t('SignatureAuthentication.SmartId.Header') }}
    </h1>
    <p class="description">
      {{ $t('SignatureAuthentication.SmartId.Description') }}
    </p>
    <ui-form @submit="submit">
      <ui-drop-down
        v-if="options"
        label="SignatureAuthentication.SmartId.Country"
        :options="options"
        :value="country"
        @input="countryChanged"
      />
      <ui-input
        :label="$t('SignatureAuthentication.SmartId.PersonCode')"
        :value="personCode"
        :error="personCodeErrorMessage"
        :max-length="14"
        :disabled="isLoading"
        @input="personCodeChange"
      />
      <ui-button :label="$t('App.Continue')" :disabled="isLoading" />
    </ui-form>
  </ui-frame>
</template>

<script>
import SmartId from '@src/components/images/SmartId'
import Validators from '@src/scripts/validators'
import Api, { call } from '@src/scripts/api'

export default {
  components: {
    SmartId
  },

  props: {
    settings: { type: Object, default: null }
  },

  data() {
    return {
      isLoading: false,
      country: null,
      personCode: null,
      personCodeError: 'Required'
    }
  },

  computed: {
    personCodeErrorMessage() {
      const { personCodeError } = this

      return (
        (personCodeError &&
          `SignatureAuthentication.Validators.${personCodeError}`) ||
        null
      )
    },

    options() {
      const codes = this.settings?.countryCodes
      if (!(codes?.length > 1)) return null

      const list = codes.map((item) => ({
        key: item,
        value: `Countries.${item}`
      }))

      list.sort((a, b) => a.value.localeCompare(b.value))
      return list
    }
  },

  created() {
    this.country = this.settings?.countryCodes?.[0]
  },

  methods: {
    countryChanged(value) {
      this.country = value
    },

    personCodeChange(value) {
      this.personCode = value
      this.personCodeError = Validators.requiredValidator(value)
    },

    async submit() {
      const { country, personCode, personCodeError } = this
      if (personCodeError) return

      this.isLoading = true

      try {
        const { error, data } = await call(Api.signatureAuthenticationSmartId, {
          countryCode: country,
          nationalIdentityNumber: personCode
        })

        if (error || !data) {
          this.$popUp('SmartIdSignFailed')
        } else {
          this.$emit('wait', data.verificationCode)
          return
        }
      } catch (error) {
        this.$popUp('SmartIdSignFailed')
      }

      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.icon {
  width: 6rem;
  display: block;
}

.header {
  margin-bottom: 1.25rem;
  text-align: center;
  color: var(--header-contrast-color);
}

.description {
  text-align: center;
  max-width: 30rem;
  color: var(--text-contrast-color);
}

.ui-form {
  max-width: 10rem;
}

.ui-button {
  margin: 1rem auto auto;
}

@media screen and (min-width: 38.0625rem) {
  .header,
  .description {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}
</style>
