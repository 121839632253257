<template>
  <div class="ui-frame">
    <page-logo v-if="logo" />
    <div
      class="content"
      :class="{ 'full-size': fullSize, padding, 'max-height': maxHeight }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import PageLogo from '@src/components/partials/PageLogo'

export default {
  name: 'ui-frame',

  components: {
    PageLogo
  },

  props: {
    logo: Boolean,
    fullSize: Boolean,
    padding: Boolean,
    maxHeight: Boolean
  }
}
</script>

<style scoped>
.ui-frame {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  max-width: 64rem;
  width: 100%;
  padding: 2rem;
  margin: auto;
  overflow: auto;
}

.omnichannel-page > .content {
  max-width: initial;
}

@media screen and (max-height: 29rem) {
  .content {
    display: block;
    padding: 1.5rem;
    text-align: center;
    width: auto;
  }
}

@media screen and (max-height: 48rem) {
  .content {
    display: block;
  }
}

.full-size {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.padding {
  padding: 2rem;
}

.max-height {
  max-height: none;
}

.ui-frame.identification-check > .content {
  overflow: initial;
  justify-content: initial;
}
</style>
