var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-frame",
    { attrs: { logo: "" } },
    [
      _c("i", { staticClass: "icon" }, [_c("mobile-id")], 1),
      _vm._v(" "),
      _c("h1", { staticClass: "header" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("SignatureAuthentication.MobileId.Header")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "description" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("SignatureAuthentication.MobileId.Description")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "ui-form",
        { on: { submit: _vm.submit } },
        [
          _c("ui-input", {
            attrs: {
              label: "SignatureAuthentication.MobileId.PersonCode",
              value: _vm.personCode,
              error: _vm.personCodeErrorMessage,
              "max-length": 14,
              disabled: _vm.isLoading,
            },
            on: { input: _vm.personeCodeChange },
          }),
          _vm._v(" "),
          _c("phone-input", {
            attrs: {
              label: "SignatureAuthentication.MobileId.PhoneNumber",
              value: _vm.phoneNumber,
              error: _vm.phoneNumberErrorMessage,
              country: _vm.country,
              disabled: _vm.isLoading,
            },
            on: { change: _vm.phoneNumberChange },
          }),
          _vm._v(" "),
          _c("ui-button", {
            attrs: { label: _vm.$t("App.Continue"), disabled: _vm.isLoading },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }