var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 263.22 189.81",
      },
    },
    [
      _c(
        "defs",
        [
          _c("clipPath", { attrs: { id: "clip-path" } }, [
            _c("rect", {
              staticClass: "passport-too-blurry-line-cls-1",
              attrs: { width: "263.22", height: "189.81" },
            }),
          ]),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-blurry-line-linear-gradient",
                x1: "131.6",
                y1: "21.17",
                x2: "131.6",
                y2: "-6.59",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#3b3b3b" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#545454" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-blurry-line-linear-gradient-2",
                x1: "29.7",
                y1: "157.09",
                x2: "583.79",
                y2: "153.17",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#a6a6a6" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-blurry-line-linear-gradient-3",
                x1: "-2.73",
                y1: "132.08",
                x2: "402.61",
                y2: "145.24",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#6f7173" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-blurry-line-linear-gradient-4",
                x1: "65.57",
                y1: "95.92",
                x2: "65.57",
                y2: "87.6",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#e3ddd5" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#bdb3a9" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-blurry-line-linear-gradient-5",
                x1: "-300.59",
                y1: "1154.63",
                x2: "-318.22",
                y2: "1118.53",
                gradientTransform:
                  "matrix(1.15, 0.01, -0.01, 1.15, 421.21, -1215.81)",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#a3a3a3" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.29", "stop-color": "#b2b2b2" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.66", "stop-color": "#bfbfbf" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#c4c4c4" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "passport-too-blurry-line-linear-gradient-6",
              x1: "63.13",
              y1: "79.95",
              x2: "68.12",
              y2: "87.34",
              "xlink:href": "#passport-too-blurry-line-linear-gradient",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-blurry-line-linear-gradient-7",
                x1: "65.16",
                y1: "51.4",
                x2: "65.23",
                y2: "51.48",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#999" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#6e6e6e" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "passport-too-blurry-line-linear-gradient-8",
              x1: "56.37",
              y1: "65.37",
              x2: "130.57",
              y2: "99.04",
              "xlink:href": "#passport-too-blurry-line-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "passport-too-blurry-line-linear-gradient-9",
              x1: "52.16",
              y1: "64.45",
              x2: "51.2",
              y2: "69.5",
              "xlink:href": "#passport-too-blurry-line-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-blurry-line-linear-gradient-10",
                x1: "63.43",
                y1: "68.41",
                x2: "67.99",
                y2: "80.92",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#d1cdcb" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#9c9189" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-blurry-line-linear-gradient-11",
                x1: "65.13",
                y1: "51.38",
                x2: "65.15",
                y2: "51.38",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.13", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.28", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#1f1f1f" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.54", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.78", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "passport-too-blurry-line-linear-gradient-12",
              x1: "81.07",
              y1: "69.16",
              x2: "80.05",
              y2: "74.57",
              "xlink:href": "#passport-too-blurry-line-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-blurry-line-linear-gradient-13",
                x1: "45.74",
                y1: "56.95",
                x2: "84.32",
                y2: "56.95",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.12", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.25", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#262626" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.58", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "0.8", "stop-color": "#050505" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "passport-too-blurry-line-cls-2" }, [
        _c("g", { attrs: { id: "Passport-too-blurry" } }, [
          _c("rect", {
            staticClass: "passport-too-blurry-line-cls-3",
            attrs: { width: "263.22", height: "189.81" },
          }),
          _vm._v(" "),
          _c("g", { staticClass: "passport-too-blurry-line-cls-4" }, [
            _c("g", [
              _c("path", {
                staticClass: "passport-too-blurry-line-cls-5",
                attrs: {
                  d:
                    "M23.3,174.85A6.76,6.76,0,0,1,17.11,170l.23-145.67,228.51,1.17.06,111.61-.06,32.63a5.52,5.52,0,0,1-5.52,5.51Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "passport-too-blurry-line-cls-6",
                attrs: {
                  d:
                    "M17.84,24.79,245.35,26l.06,111.11-.06,32.63a5,5,0,0,1-5,5l-217-.36a6.32,6.32,0,0,1-5.69-4.46l.05-32.81.18-112.29m-1-1-.18,113.28-.05,33a7.27,7.27,0,0,0,6.69,5.33l217,.36h0a6,6,0,0,0,6-6l.06-32.63L246.35,25,16.84,23.79Z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-too-blurry-line-cls-7",
              attrs: {
                d:
                  "M16.61,24.6l.18-112.92.05-32.63a6,6,0,0,1,6-6l217,.36a7.3,7.3,0,0,1,6.69,5.33l-.06,33L246.35,25Z",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("rect", {
                staticClass: "passport-too-blurry-line-cls-8",
                attrs: {
                  x: "174.05",
                  y: "1.41",
                  width: "4.82",
                  height: "81.86",
                  transform: "translate(133.82 218.72) rotate(-89.9)",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                staticClass: "passport-too-blurry-line-cls-8",
                attrs: {
                  x: "179.43",
                  y: "14.06",
                  width: "4.82",
                  height: "92.69",
                  transform: "translate(121.14 242.14) rotate(-89.9)",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                staticClass: "passport-too-blurry-line-cls-8",
                attrs: {
                  x: "179.32",
                  y: "61.01",
                  width: "4.82",
                  height: "92.69",
                  transform: "translate(74.06 288.9) rotate(-89.9)",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                staticClass: "passport-too-blurry-line-cls-8",
                attrs: {
                  x: "167.36",
                  y: "46.54",
                  width: "4.82",
                  height: "68.61",
                  transform: "matrix(0, -1, 1, 0, 88.64, 250.48)",
                },
              }),
            ]),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-too-blurry-line-cls-9",
              attrs: {
                d:
                  "M246.41,137.07l-.06,32.63a6,6,0,0,1-6,6l-217-.36A7.27,7.27,0,0,1,16.61,170v-33Z",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("polygon", {
                attrs: {
                  points:
                    "24.48 156.34 22.96 154.82 27.43 150.35 22.89 145.81 24.42 144.28 30.48 150.35 24.48 156.34",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                attrs: {
                  points:
                    "34.83 156.34 33.3 154.82 37.77 150.35 33.24 145.81 34.76 144.28 40.82 150.35 34.83 156.34",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                attrs: {
                  points:
                    "45.17 156.34 43.65 154.82 48.12 150.35 43.58 145.81 45.11 144.28 51.17 150.35 45.17 156.34",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                attrs: {
                  points:
                    "55.52 156.34 53.99 154.82 58.47 150.35 53.93 145.81 55.45 144.28 61.52 150.35 55.52 156.34",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                attrs: {
                  points:
                    "65.86 156.34 64.34 154.82 68.81 150.35 64.27 145.81 65.8 144.28 71.86 150.35 65.86 156.34",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                attrs: {
                  points:
                    "233.3 168.67 227.24 162.61 233.24 156.62 234.76 158.14 230.29 162.61 234.83 167.15 233.3 168.67",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                attrs: {
                  points:
                    "222.96 168.67 216.9 162.61 222.89 156.62 224.42 158.14 219.94 162.61 224.48 167.15 222.96 168.67",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                attrs: {
                  points:
                    "212.61 168.67 206.55 162.61 212.54 156.62 214.07 158.14 209.6 162.61 214.13 167.15 212.61 168.67",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                attrs: {
                  points:
                    "202.26 168.67 196.2 162.61 202.2 156.62 203.72 158.14 199.25 162.61 203.79 167.15 202.26 168.67",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                attrs: {
                  points:
                    "191.92 168.67 185.86 162.61 191.85 156.62 193.38 158.14 188.91 162.61 193.44 167.15 191.92 168.67",
                },
              }),
              _vm._v(" "),
              _c("g", [
                _c("path", {
                  attrs: {
                    d:
                      "M76.48,145.49h2.31L82,153l3.23-7.55h2.3v10.16h-2V149l-2.73,6.61H81.25L78.52,149v6.61h-2Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d:
                      "M93.38,145.49c2.44,0,3.66,1.41,3.66,3.11a2.93,2.93,0,0,1-2.31,2.93l2.43,4.12H94.8l-2.24-4h-1v4h-2V145.49Zm-.08,1.69H91.6v3h1.7c1.14,0,1.65-.6,1.65-1.51S94.44,147.18,93.3,147.18Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d:
                      "M105.54,147.06,101,153.93h4.57v1.72H98.62v-1.58l4.54-6.86H98.62v-1.72h6.92Z",
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("rect", {
              staticClass: "passport-too-blurry-line-cls-10",
              attrs: {
                x: "16.66",
                y: "135.81",
                width: "229.75",
                height: "1.27",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-too-blurry-line-cls-11",
              attrs: {
                d:
                  "M23.17-127l217.12.36c3.65,0,6.6,3,5.74,6.6l.29,145H16.91V-120.7A6.26,6.26,0,0,1,23.17-127Z",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("rect", {
                staticClass: "passport-too-blurry-line-cls-3",
                attrs: {
                  x: "28.17",
                  y: "34.94",
                  width: "74.44",
                  height: "75.56",
                },
              }),
              _vm._v(" "),
              _c("g", [
                _c("path", {
                  staticClass: "passport-too-blurry-line-cls-12",
                  attrs: {
                    d:
                      "M58.89,83l-1.51,10a3.85,3.85,0,0,1-2.05,2.88l-13,6.48,46.4.37-9.56-5.39a6.77,6.77,0,0,1-3.28-4.64L74.17,83.3Z",
                  },
                }),
                _vm._v(" "),
                _c("g", [
                  _c("path", {
                    staticClass: "passport-too-blurry-line-cls-13",
                    attrs: {
                      d:
                        "M53.84,95.72s3.71,3.54,13,3.59S79.89,97,79.89,97L97.4,107l3.23,3.81-70.34-.37,2.85-3.77Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "passport-too-blurry-line-cls-14",
                    attrs: {
                      d:
                        "M53.84,95.72s3.71,3.54,13,3.59S79.89,97,79.89,97L97.4,107l3.23,3.81-70.34-.37,2.85-3.77Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "passport-too-blurry-line-cls-14",
                    attrs: {
                      d:
                        "M53.84,95.72s3.71,3.54,13,3.59S79.89,97,79.89,97L97.4,107l3.23,3.81-70.34-.37,2.85-3.77Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "passport-too-blurry-line-cls-15",
                    attrs: {
                      d:
                        "M53.84,95.72s3.71,3.54,13,3.59S79.89,97,79.89,97L97.4,107l3.23,3.81-70.34-.37,2.85-3.77Z",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("path", {
                  staticClass: "passport-too-blurry-line-cls-16",
                  attrs: {
                    d: "M58.32,85.3s1.9,4.27,8.06,4.61A9.2,9.2,0,0,0,75,85Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "passport-too-blurry-line-cls-17",
                  attrs: {
                    d: "M65.16,51.56a1,1,0,0,1,0-.36A.92.92,0,0,1,65.16,51.56Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "passport-too-blurry-line-cls-18",
                  attrs: {
                    d:
                      "M79.74,71.88a22.6,22.6,0,0,1-.62,6.23,15.28,15.28,0,0,1-3.92,6.77S71,88.23,67,88.32a3.85,3.85,0,0,1-.48,0c-.59,0-1.19,0-1.81-.07-.29,0-.59,0-.87-.1h0c-3.81-.62-5.76-2.86-7.51-5.25-2.21-3-3.9-6.67-3.9-10.4l0-7.71c.05-2.7,0-3.22.05-6.45,0,0,6,1.24,14.24-3.2A6.8,6.8,0,0,0,71.11,57c4.44.17,5.4-1.83,5.41-2.28.28,1.15.53,9.4,2.62,10.51,1.56,1,.65.29.64.5Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "passport-too-blurry-line-cls-19",
                  attrs: {
                    d: "M51.49,67.85s-2.76.1-2.69,2.35,3.8,4.26,3.8,4.26Z",
                  },
                }),
                _vm._v(" "),
                _c("polyline", {
                  staticClass: "passport-too-blurry-line-cls-20",
                  attrs: { points: "65.94 68.47 64.28 77.6 65.71 78.74" },
                }),
                _vm._v(" "),
                _c("circle", {
                  staticClass: "passport-too-blurry-line-cls-21",
                  attrs: { cx: "59.35", cy: "69.73", r: "1.26" },
                }),
                _vm._v(" "),
                _c("circle", {
                  staticClass: "passport-too-blurry-line-cls-21",
                  attrs: { cx: "72.21", cy: "69.73", r: "1.26" },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "passport-too-blurry-line-cls-5",
                  attrs: {
                    d:
                      "M64.52,82.37A19.69,19.69,0,0,0,70,81.32a3.33,3.33,0,0,1-2.74,1.61C65.24,83.08,64.52,82.37,64.52,82.37Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "passport-too-blurry-line-cls-22",
                  attrs: {
                    d: "M65.16,51.56a1,1,0,0,1,0-.36A.92.92,0,0,1,65.16,51.56Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "passport-too-blurry-line-cls-23",
                  attrs: {
                    d: "M79.78,67.08s2.76-.13,2.89,2.12-3.08,5-3.08,5Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "passport-too-blurry-line-cls-24",
                  attrs: {
                    d:
                      "M52.52,73.84c.17-.84.33-1.68.49-2.52-.2-5.94,1.05-8,2.36-8.63a8.1,8.1,0,0,0,2.74-1.35,5.52,5.52,0,0,0,1.5-3.26,11.16,11.16,0,0,0,4.19,3.66c5.75,2.79,12.08-1.05,13.76,1.43,1,1.44-.64,3.5,1,6.32a6.79,6.79,0,0,0,1.24,1.57,19.9,19.9,0,0,0,4.5-13.53,17.73,17.73,0,0,0-7-13.06c-.94-.72-8.08-6.12-14.4-3.85a19,19,0,0,0-6.14,3.66,11.54,11.54,0,0,1-2,1.61c-1.66,1.06-2.64,1-3.87,1.57-3,1.3-4.32,4.61-4.57,5.26a11.1,11.1,0,0,0-.41,6C46.93,65.2,50.38,70.81,52.52,73.84Z",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("rect", {
                staticClass: "passport-too-blurry-line-cls-25",
                attrs: {
                  id: "Overlay",
                  x: "28.17",
                  y: "34.94",
                  width: "74.44",
                  height: "75.56",
                },
              }),
            ]),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-too-blurry-line-cls-26",
              attrs: {
                d:
                  "M240.48,175.7l-217.12-.36a6.61,6.61,0,0,1-6.6-6.6L16.47,24.21,246.52,25l.23,144.47A6.26,6.26,0,0,1,240.48,175.7Z",
              },
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }