var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "camera-check" }, [
    _c("div", [
      _c("video", {
        ref: "camera",
        attrs: { autoplay: "", playsinline: "", muted: "" },
        domProps: { muted: true },
      }),
      _vm._v(" "),
      _c("h3", [_vm._v(_vm._s(_vm.message))]),
      _vm._v(" "),
      _vm.hasCameraDevices
        ? _c(
            "div",
            _vm._l(_vm.cameraDevices, function (device, index) {
              return _c(
                "a",
                {
                  key: index,
                  class: { selected: _vm.selectedId === device.deviceId },
                  on: {
                    click: function () {
                      return _vm.changeCamera(device.deviceId)
                    },
                  },
                },
                [
                  device
                    ? [
                        _c("strong", [_vm._v(_vm._s(device.group))]),
                        _vm._v(" "),
                        _c("span", [
                          _c("strong", [_vm._v("label")]),
                          _vm._v(" " + _vm._s(device.label)),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("span", [
                          _c("strong", [_vm._v("deviceId")]),
                          _vm._v(" " + _vm._s(device.deviceId)),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            0
          )
        : _vm._e(),
    ]),
    _vm._v("\n  " + _vm._s(_vm.mediaDetails)),
    _c("br"),
    _c("br"),
    _vm._v("\n  " + _vm._s(_vm.deviceDetails) + "\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }