var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 494.84 389.59",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-license-back-linear-gradient",
                x1: "58.99",
                y1: "304.79",
                x2: "1086.16",
                y2: "297.53",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#a6a6a6" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-license-back-linear-gradient-2",
                x1: "239.53",
                y1: "350.74",
                x2: "261.5",
                y2: "396.12",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#3b3b3b" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#2b2829" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-license-back-linear-gradient-3",
                x1: "399.66",
                y1: "286.75",
                x2: "399.66",
                y2: "153.86",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#a3a3a3" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.29", "stop-color": "#b2b2b2" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.66", "stop-color": "#bfbfbf" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#c4c4c4" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-license-back-linear-gradient-4",
                x1: "426.98",
                y1: "287.12",
                x2: "437.79",
                y2: "287.12",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.12", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.25", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#262626" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.58", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "0.8", "stop-color": "#050505" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-license-back-linear-gradient-5",
              x1: "362.13",
              y1: "287.69",
              x2: "372.9",
              y2: "287.69",
              "xlink:href": "#driving-license-back-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-license-back-linear-gradient-6",
              x1: "445.64",
              y1: "243.94",
              x2: "418.72",
              y2: "253.45",
              "xlink:href": "#driving-license-back-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-license-back-linear-gradient-7",
              x1: "352.43",
              y1: "245.22",
              x2: "387.65",
              y2: "250.8",
              "xlink:href": "#driving-license-back-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "driving-license-back-linear-gradient-8",
                x1: "448.75",
                y1: "259.19",
                x2: "347.45",
                y2: "279.05",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#e3ddd5" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#bdb3a9" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-license-back-linear-gradient-9",
              x1: "383.87",
              y1: "265.59",
              x2: "415.45",
              y2: "265.59",
              "xlink:href": "#driving-license-back-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "driving-license-back-linear-gradient-10",
              x1: "17848.71",
              y1: "259.18",
              x2: "17747.4",
              y2: "279.03",
              gradientTransform: "matrix(-1, 0, 0, 1, 18199.28, 0)",
              "xlink:href": "#driving-license-back-linear-gradient-8",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "driving-license-back-cls-1" }, [
        _c("g", { attrs: { id: "Driving-license-back" } }, [
          _c("g", [
            _c("g", [
              _c("path", {
                attrs: {
                  d:
                    "M216.08,32.09c-2.52-2.42-3.92-6.38-3.92-10.63s1.4-8.4,4.21-10.92h2.71v.25a14.79,14.79,0,0,0-4.33,10.67,14.94,14.94,0,0,0,4,10.36v.27Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M226.54,28.3h-5.79V14.88h5.54c2.8,0,4.36,1.46,4.36,3.46a3.09,3.09,0,0,1-2.29,3.08,3.26,3.26,0,0,1,2.6,3.25C231,26.8,229.32,28.3,226.54,28.3Zm-.64-11.24h-2.46v3.36h2.46c1.27,0,2-.58,2-1.67S227.17,17.06,225.9,17.06Zm.19,5.44h-2.65v3.61h2.71c1.31,0,2.08-.63,2.08-1.77S227.4,22.5,226.09,22.5Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M241.34,25.75H236l-.88,2.55h-2.83l4.83-13.44h3.13l4.83,13.44h-2.85ZM238.66,18l-1.94,5.61h3.89Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M253,14.71a6.34,6.34,0,0,1,6.31,4.34h-3.1A3.39,3.39,0,0,0,253,17.13c-2.34,0-4,1.71-4,4.44S250.66,26,253,26a3.36,3.36,0,0,0,3.23-1.94h3.1A6.31,6.31,0,0,1,253,28.42a6.62,6.62,0,0,1-6.8-6.85A6.63,6.63,0,0,1,253,14.71Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M264.32,22.32v6h-2.69V14.88h2.69v6l4.88-6h3.25l-5.53,6.66,5.69,6.76H269.2Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M273.68,32.09v-.27a14.9,14.9,0,0,0,4-10.36,14.75,14.75,0,0,0-4.33-10.67v-.25h2.71c2.81,2.52,4.21,6.65,4.21,10.92s-1.4,8.21-3.92,10.63Z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("rect", {
              staticClass: "driving-license-back-cls-2",
              attrs: {
                x: "34.8",
                y: "38.41",
                width: "425.84",
                height: "281.79",
                rx: "10.87",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "driving-license-back-cls-3",
              attrs: {
                d:
                  "M460.86,286.77l-.1,22.3a11.17,11.17,0,0,1-11.19,11.15L47,319.55c-6.17,0-11.16-5-12.41-9.88v-22.9Z",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("g", [
                _c("polygon", {
                  staticClass: "driving-license-back-cls-4",
                  attrs: {
                    points:
                      "14.55 73.91 0 73.91 0 0 73.91 0 73.91 14.55 14.55 14.55 14.55 73.91",
                  },
                }),
                _vm._v(" "),
                _c("polygon", {
                  staticClass: "driving-license-back-cls-4",
                  attrs: {
                    points:
                      "494.84 358.47 420.92 358.47 420.92 343.92 480.29 343.92 480.29 284.55 494.84 284.55 494.84 358.47",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "driving-license-back-cls-4",
                attrs: {
                  points:
                    "494.84 73.91 480.29 73.91 480.29 14.55 420.92 14.55 420.92 0 494.84 0 494.84 73.91",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "driving-license-back-cls-4",
                attrs: {
                  points:
                    "73.91 358.47 0 358.47 0 284.55 14.55 284.55 14.55 343.92 73.91 343.92 73.91 358.47",
                },
              }),
            ]),
            _vm._v(" "),
            _c("g", [
              _c("path", {
                staticClass: "driving-license-back-cls-4",
                attrs: {
                  d:
                    "M275.78,349.69v35.46a4.44,4.44,0,0,1-4.44,4.44h-48a4.43,4.43,0,0,1-4.43-4.43V349.69a4.44,4.44,0,0,1,4.44-4.44h9.78a4.44,4.44,0,0,0,3.53-1.74l2.74-3.56a6.74,6.74,0,0,1,5.35-2.64H251a6.74,6.74,0,0,1,5.34,2.64l2.74,3.56a4.46,4.46,0,0,0,3.53,1.74h8.72A4.44,4.44,0,0,1,275.78,349.69Z",
                },
              }),
              _vm._v(" "),
              _c("circle", {
                staticClass: "driving-license-back-cls-5",
                attrs: { cx: "247.06", cy: "366.3", r: "15.62" },
              }),
              _vm._v(" "),
              _c("circle", {
                staticClass: "driving-license-back-cls-6",
                attrs: { cx: "247.06", cy: "366.3", r: "10.99" },
              }),
            ]),
            _vm._v(" "),
            _c("g", [
              _c("path", {
                attrs: {
                  d:
                    "M103.82,81.07c0,4.06-2.79,6.69-7.08,6.69H92.05V74.34h4.69C101,74.34,103.82,77,103.82,81.07Zm-7.17,4.4c2.82,0,4.42-1.61,4.42-4.4s-1.6-4.46-4.42-4.46H94.74v8.86Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M110.87,74.34c3.23,0,4.85,1.87,4.85,4.12a3.88,3.88,0,0,1-3.06,3.86l3.21,5.44h-3.11l-3-5.23h-1.26v5.23h-2.7V74.34Zm-.09,2.23h-2.25v3.94h2.25c1.5,0,2.17-.78,2.17-2S112.28,76.57,110.78,76.57Z",
                },
              }),
              _vm._v(" "),
              _c("path", { attrs: { d: "M118.15,74.34h2.7V87.76h-2.7Z" } }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M132.69,74.34h2.86l-4.92,13.42h-3.27l-4.92-13.42h2.88L129,85Z",
                },
              }),
              _vm._v(" "),
              _c("path", { attrs: { d: "M137.16,74.34h2.7V87.76h-2.7Z" } }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M154,74.32V87.76h-2.7l-6.09-9.21v9.21h-2.69V74.32h2.69l6.09,9.23V74.32Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M169.07,78.38H166a3.38,3.38,0,0,0-3.15-1.73c-2.41,0-4.08,1.71-4.08,4.38s1.69,4.43,4.19,4.43a3.65,3.65,0,0,0,3.81-3.1h-4.62V80.3h7.27v2.35a6.56,6.56,0,0,1-6.56,5.23,6.86,6.86,0,0,1,0-13.71A6.16,6.16,0,0,1,169.07,78.38Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: { d: "M178.66,74.34V85.63h4.42v2.13H176V74.34Z" },
              }),
              _vm._v(" "),
              _c("path", { attrs: { d: "M184.79,74.34h2.69V87.76h-2.69Z" } }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M196.29,74.17a6.31,6.31,0,0,1,6.3,4.34H199.5a3.4,3.4,0,0,0-3.23-1.92c-2.35,0-4,1.71-4,4.44s1.67,4.44,4,4.44a3.37,3.37,0,0,0,3.23-1.94h3.09a6.29,6.29,0,0,1-6.3,4.35,6.86,6.86,0,0,1,0-13.71Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M212.68,76.51h-5.09v3.37h4.51V82h-4.51v3.56h5.09v2.19h-7.79V74.32h7.79Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M226.6,74.32V87.76h-2.69l-6.1-9.21v9.21h-2.69V74.32h2.69l6.1,9.23V74.32Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M235.4,74.17a6.33,6.33,0,0,1,6.31,4.34h-3.1a3.39,3.39,0,0,0-3.23-1.92c-2.34,0-4,1.71-4,4.44s1.68,4.44,4,4.44a3.36,3.36,0,0,0,3.23-1.94h3.1a6.31,6.31,0,0,1-6.31,4.35,6.86,6.86,0,0,1,0-13.71Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M251.79,76.51H246.7v3.37h4.52V82H246.7v3.56h5.09v2.19H244V74.32h7.78Z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("g", [
              _c("rect", {
                staticClass: "driving-license-back-cls-7",
                attrs: {
                  x: "168.9",
                  y: "38.51",
                  width: "8.93",
                  height: "171.93",
                  transform: "translate(48.6 297.62) rotate(-89.9)",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "driving-license-back-cls-7",
                attrs: {
                  points:
                    "196.26 238.76 87.6 238.47 87.62 229.54 196.14 230.6 196.26 238.76",
                },
              }),
              _vm._v(" "),
              _c("g", [
                _c("rect", {
                  staticClass: "driving-license-back-cls-7",
                  attrs: {
                    x: "146.5",
                    y: "98.76",
                    width: "8.93",
                    height: "127.28",
                    transform: "translate(-11.68 313.09) rotate(-89.9)",
                  },
                }),
                _vm._v(" "),
                _c("rect", {
                  staticClass: "driving-license-back-cls-7",
                  attrs: {
                    x: "146.5",
                    y: "132.59",
                    width: "8.93",
                    height: "127.28",
                    transform: "translate(-45.51 346.86) rotate(-89.9)",
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("g", [
              _c("rect", {
                staticClass: "driving-license-back-cls-8",
                attrs: {
                  x: "345.15",
                  y: "211.4",
                  width: "104.69",
                  height: "97.71",
                  rx: "11.49",
                },
              }),
              _vm._v(" "),
              _c("g", { staticClass: "driving-license-back-cls-9" }, [
                _c("rect", {
                  staticClass: "driving-license-back-cls-10",
                  attrs: {
                    x: "381.09",
                    y: "275.26",
                    width: "37.77",
                    height: "6.82",
                  },
                }),
                _vm._v(" "),
                _c("g", [
                  _c("g", [
                    _c("path", {
                      staticClass: "driving-license-back-cls-11",
                      attrs: {
                        d:
                          "M434.82,249.36a53,53,0,0,0,2.59,4.89c3.22,4.85,3.82,10.24,3.33,15.83a70.72,70.72,0,0,1-1.3,8.54,22.54,22.54,0,0,1-1.73,4,7.5,7.5,0,0,1-5.36,1.65c-1.79-.08-3.58,0-5.37.05q-10.87.06-21.77.12l-30.3.12c-.68,0-1.35,0-2,.07-1.92,0-3.85-.09-5.77,0a8,8,0,0,1-5-1.22c-1.89-1.82-2.2-4.28-2.66-6.66a40.79,40.79,0,0,1-.69-14.1,22.58,22.58,0,0,1,1.82-5.61c1.17-2.62,2.59-5.14,3.91-7.7l1.06-2.35c1.8-3.58,3.45-7.26,5.44-10.73,4-7,3.33-6.36,11-6.94s15.32-.78,23-.78c5.39,0,10.79.65,16.19,1a5.16,5.16,0,0,1,4.49,2.79c2.36,4.25,4.67,8.53,7,12.81.35.63.68,1.27,1,1.9S434.45,248.58,434.82,249.36Zm-4.3-2.12a3.2,3.2,0,0,0,0-.6q-3.18-7-6.37-13.91a2.17,2.17,0,0,0-2.15-1.34c-11.51,0-23-.12-34.52-.11-3.34,0-6.67.28-10,.36a2.55,2.55,0,0,0-2.56,1.79c-.55,1.38-1.21,2.72-1.81,4.08l-4.31,9.73Zm-17.3,34.68c1,0,1.47-.34,1.43-1.27,0-1.14,0-2.27,0-3.4,0-.81-.32-1.22-1.28-1.21l-27.3.09c-1,0-1.4.42-1.38,1.27,0,1.1,0,2.2,0,3.3s.25,1.37,1.56,1.37C390.72,282.08,408.69,281.89,413.22,281.92ZM426,267.47c1.9,0,3.8.1,5.68-.05a5.06,5.06,0,0,0,2.66-1c2.3-1.94,2.83-4.61,2.67-7.48-.1-1.71-.81-2.25-2.47-1.86-2.89.69-5.79,1.39-8.68,2.1-4.79,1.19-4.78,1.19-7.2,5.52a.75.75,0,0,1-.07.12c-.84,1.57-.22,2.64,1.59,2.66S424.08,267.47,426,267.47Zm-52.8,0c2,0,4,0,5.95,0,1.74,0,2.4-1.09,1.57-2.58-.73-1.3-1.49-2.58-2.32-3.82a2.41,2.41,0,0,0-1.23-.94c-4.28-1.1-8.58-2.14-12.88-3.16a1.46,1.46,0,0,0-1.95,1.34,9.33,9.33,0,0,0,1.76,7.29,5.33,5.33,0,0,0,4.48,1.87C370.31,267.33,371.5,267.47,373.22,267.47Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "driving-license-back-cls-12",
                      attrs: {
                        d:
                          "M434.82,249.36a53,53,0,0,0,2.59,4.89c3.22,4.85,3.82,10.24,3.33,15.83a70.72,70.72,0,0,1-1.3,8.54,22.54,22.54,0,0,1-1.73,4,7.5,7.5,0,0,1-5.36,1.65c-1.79-.08-3.58,0-5.37.05q-10.87.06-21.77.12l-30.3.12c-.68,0-1.35,0-2,.07-1.92,0-3.85-.09-5.77,0a8,8,0,0,1-5-1.22c-1.89-1.82-2.2-4.28-2.66-6.66a40.79,40.79,0,0,1-.69-14.1,22.58,22.58,0,0,1,1.82-5.61c1.17-2.62,2.59-5.14,3.91-7.7l1.06-2.35c1.8-3.58,3.45-7.26,5.44-10.73,4-7,3.33-6.36,11-6.94s15.32-.78,23-.78c5.39,0,10.79.65,16.19,1a5.16,5.16,0,0,1,4.49,2.79c2.36,4.25,4.67,8.53,7,12.81.35.63.68,1.27,1,1.9S434.45,248.58,434.82,249.36Zm-4.3-2.12a3.2,3.2,0,0,0,0-.6q-3.18-7-6.37-13.91a2.17,2.17,0,0,0-2.15-1.34c-11.51,0-23-.12-34.52-.11-3.34,0-6.67.28-10,.36a2.55,2.55,0,0,0-2.56,1.79c-.55,1.38-1.21,2.72-1.81,4.08l-4.31,9.73Zm-17.3,34.68c1,0,1.47-.34,1.43-1.27,0-1.14,0-2.27,0-3.4,0-.81-.32-1.22-1.28-1.21l-27.3.09c-1,0-1.4.42-1.38,1.27,0,1.1,0,2.2,0,3.3s.25,1.37,1.56,1.37C390.72,282.08,408.69,281.89,413.22,281.92ZM426,267.47c1.9,0,3.8.1,5.68-.05a5.06,5.06,0,0,0,2.66-1c2.3-1.94,2.83-4.61,2.67-7.48-.1-1.71-.81-2.25-2.47-1.86-2.89.69-5.79,1.39-8.68,2.1-4.79,1.19-4.78,1.19-7.2,5.52a.75.75,0,0,1-.07.12c-.84,1.57-.22,2.64,1.59,2.66S424.08,267.47,426,267.47Zm-52.8,0c2,0,4,0,5.95,0,1.74,0,2.4-1.09,1.57-2.58-.73-1.3-1.49-2.58-2.32-3.82a2.41,2.41,0,0,0-1.23-.94c-4.28-1.1-8.58-2.14-12.88-3.16a1.46,1.46,0,0,0-1.95,1.34,9.33,9.33,0,0,0,1.76,7.29,5.33,5.33,0,0,0,4.48,1.87C370.31,267.33,371.5,267.47,373.22,267.47Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "driving-license-back-cls-12",
                      attrs: {
                        d:
                          "M434.82,249.36a53,53,0,0,0,2.59,4.89c3.22,4.85,3.82,10.24,3.33,15.83a70.72,70.72,0,0,1-1.3,8.54,22.54,22.54,0,0,1-1.73,4,7.5,7.5,0,0,1-5.36,1.65c-1.79-.08-3.58,0-5.37.05q-10.87.06-21.77.12l-30.3.12c-.68,0-1.35,0-2,.07-1.92,0-3.85-.09-5.77,0a8,8,0,0,1-5-1.22c-1.89-1.82-2.2-4.28-2.66-6.66a40.79,40.79,0,0,1-.69-14.1,22.58,22.58,0,0,1,1.82-5.61c1.17-2.62,2.59-5.14,3.91-7.7l1.06-2.35c1.8-3.58,3.45-7.26,5.44-10.73,4-7,3.33-6.36,11-6.94s15.32-.78,23-.78c5.39,0,10.79.65,16.19,1a5.16,5.16,0,0,1,4.49,2.79c2.36,4.25,4.67,8.53,7,12.81.35.63.68,1.27,1,1.9S434.45,248.58,434.82,249.36Zm-4.3-2.12a3.2,3.2,0,0,0,0-.6q-3.18-7-6.37-13.91a2.17,2.17,0,0,0-2.15-1.34c-11.51,0-23-.12-34.52-.11-3.34,0-6.67.28-10,.36a2.55,2.55,0,0,0-2.56,1.79c-.55,1.38-1.21,2.72-1.81,4.08l-4.31,9.73Zm-17.3,34.68c1,0,1.47-.34,1.43-1.27,0-1.14,0-2.27,0-3.4,0-.81-.32-1.22-1.28-1.21l-27.3.09c-1,0-1.4.42-1.38,1.27,0,1.1,0,2.2,0,3.3s.25,1.37,1.56,1.37C390.72,282.08,408.69,281.89,413.22,281.92ZM426,267.47c1.9,0,3.8.1,5.68-.05a5.06,5.06,0,0,0,2.66-1c2.3-1.94,2.83-4.61,2.67-7.48-.1-1.71-.81-2.25-2.47-1.86-2.89.69-5.79,1.39-8.68,2.1-4.79,1.19-4.78,1.19-7.2,5.52a.75.75,0,0,1-.07.12c-.84,1.57-.22,2.64,1.59,2.66S424.08,267.47,426,267.47Zm-52.8,0c2,0,4,0,5.95,0,1.74,0,2.4-1.09,1.57-2.58-.73-1.3-1.49-2.58-2.32-3.82a2.41,2.41,0,0,0-1.23-.94c-4.28-1.1-8.58-2.14-12.88-3.16a1.46,1.46,0,0,0-1.95,1.34,9.33,9.33,0,0,0,1.76,7.29,5.33,5.33,0,0,0,4.48,1.87C370.31,267.33,371.5,267.47,373.22,267.47Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "driving-license-back-cls-13",
                      attrs: {
                        d:
                          "M434.82,249.36a53,53,0,0,0,2.59,4.89c3.22,4.85,3.82,10.24,3.33,15.83a70.72,70.72,0,0,1-1.3,8.54,22.54,22.54,0,0,1-1.73,4,7.5,7.5,0,0,1-5.36,1.65c-1.79-.08-3.58,0-5.37.05q-10.87.06-21.77.12l-30.3.12c-.68,0-1.35,0-2,.07-1.92,0-3.85-.09-5.77,0a8,8,0,0,1-5-1.22c-1.89-1.82-2.2-4.28-2.66-6.66a40.79,40.79,0,0,1-.69-14.1,22.58,22.58,0,0,1,1.82-5.61c1.17-2.62,2.59-5.14,3.91-7.7l1.06-2.35c1.8-3.58,3.45-7.26,5.44-10.73,4-7,3.33-6.36,11-6.94s15.32-.78,23-.78c5.39,0,10.79.65,16.19,1a5.16,5.16,0,0,1,4.49,2.79c2.36,4.25,4.67,8.53,7,12.81.35.63.68,1.27,1,1.9S434.45,248.58,434.82,249.36Zm-4.3-2.12a3.2,3.2,0,0,0,0-.6q-3.18-7-6.37-13.91a2.17,2.17,0,0,0-2.15-1.34c-11.51,0-23-.12-34.52-.11-3.34,0-6.67.28-10,.36a2.55,2.55,0,0,0-2.56,1.79c-.55,1.38-1.21,2.72-1.81,4.08l-4.31,9.73Zm-17.3,34.68c1,0,1.47-.34,1.43-1.27,0-1.14,0-2.27,0-3.4,0-.81-.32-1.22-1.28-1.21l-27.3.09c-1,0-1.4.42-1.38,1.27,0,1.1,0,2.2,0,3.3s.25,1.37,1.56,1.37C390.72,282.08,408.69,281.89,413.22,281.92ZM426,267.47c1.9,0,3.8.1,5.68-.05a5.06,5.06,0,0,0,2.66-1c2.3-1.94,2.83-4.61,2.67-7.48-.1-1.71-.81-2.25-2.47-1.86-2.89.69-5.79,1.39-8.68,2.1-4.79,1.19-4.78,1.19-7.2,5.52a.75.75,0,0,1-.07.12c-.84,1.57-.22,2.64,1.59,2.66S424.08,267.47,426,267.47Zm-52.8,0c2,0,4,0,5.95,0,1.74,0,2.4-1.09,1.57-2.58-.73-1.3-1.49-2.58-2.32-3.82a2.41,2.41,0,0,0-1.23-.94c-4.28-1.1-8.58-2.14-12.88-3.16a1.46,1.46,0,0,0-1.95,1.34,9.33,9.33,0,0,0,1.76,7.29,5.33,5.33,0,0,0,4.48,1.87C370.31,267.33,371.5,267.47,373.22,267.47Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "driving-license-back-cls-14",
                      attrs: {
                        d:
                          "M427,284.36c1.79,0,3.58-.13,5.37-.05a7.5,7.5,0,0,0,5.36-1.65c0,2.15.1,4.31.07,6.47a2.23,2.23,0,0,1-2.46,2.42q-3,.06-5.94,0a2.16,2.16,0,0,1-2.36-2.35C427,287.6,427,286,427,284.36Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "driving-license-back-cls-15",
                      attrs: {
                        d:
                          "M362.13,283.43a8,8,0,0,0,5,1.22c1.92-.07,3.85,0,5.77,0,0,1.62,0,3.25,0,4.87a2.14,2.14,0,0,1-2.34,2.37c-2,0-4,.06-5.95,0a2.17,2.17,0,0,1-2.43-2.43C362.11,287.49,362.14,285.46,362.13,283.43Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "driving-license-back-cls-16",
                      attrs: {
                        d:
                          "M435.14,250.11c-.38-.78-1.06-2.31-1.44-3.09a2.17,2.17,0,0,0,1.77-2.08,1.21,1.21,0,0,1,.94-1.08,11,11,0,0,1,4.93.32,2.78,2.78,0,0,1,1.86,2.62,2.25,2.25,0,0,1-1.39,1.84C439.64,249.28,437.37,249.64,435.14,250.11Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "driving-license-back-cls-17",
                      attrs: {
                        d:
                          "M364.19,250.11c.37-.78,1.06-2.31,1.43-3.09a2.18,2.18,0,0,1-1.77-2.08,1.21,1.21,0,0,0-.94-1.08,11,11,0,0,0-4.93.32,2.8,2.8,0,0,0-1.86,2.62,2.25,2.25,0,0,0,1.39,1.84C359.69,249.28,362,249.64,364.19,250.11Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "driving-license-back-cls-18",
                      attrs: {
                        d:
                          "M430.52,247.24H368.79l4.31-9.73c.6-1.36,1.26-2.7,1.81-4.08a2.55,2.55,0,0,1,2.56-1.79c3.34-.08,6.67-.36,10-.36,11.51,0,23,.07,34.52.11a2.17,2.17,0,0,1,2.15,1.34q3.19,7,6.37,13.91A3.2,3.2,0,0,1,430.52,247.24Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "driving-license-back-cls-19",
                      attrs: {
                        d:
                          "M426,267.47c-1.94,0-3.88,0-5.82,0s-2.43-1.09-1.59-2.66a.75.75,0,0,0,.07-.12c2.42-4.33,2.41-4.33,7.2-5.52,2.89-.71,5.79-1.41,8.68-2.1,1.66-.39,2.37.15,2.47,1.86.16,2.87-.37,5.54-2.67,7.48a5.06,5.06,0,0,1-2.66,1C429.82,267.57,427.92,267.47,426,267.47Z",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "driving-license-back-cls-20",
                    attrs: {
                      d:
                        "M412.42,269.22a103.77,103.77,0,0,1-25.52,0,3.28,3.28,0,0,1-3-3.68c.09-.54.17-1.07.26-1.61a3.35,3.35,0,0,1,3.72-2.74,95.58,95.58,0,0,0,23.54,0,3.37,3.37,0,0,1,3.73,2.74c.08.54.16,1.07.25,1.61A3.28,3.28,0,0,1,412.42,269.22Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("line", {
                    staticClass: "driving-license-back-cls-21",
                    attrs: {
                      x1: "386.74",
                      y1: "262.01",
                      x2: "386.74",
                      y2: "269.1",
                    },
                  }),
                  _vm._v(" "),
                  _c("line", {
                    staticClass: "driving-license-back-cls-21",
                    attrs: {
                      x1: "391.84",
                      y1: "262.01",
                      x2: "391.84",
                      y2: "269.1",
                    },
                  }),
                  _vm._v(" "),
                  _c("line", {
                    staticClass: "driving-license-back-cls-21",
                    attrs: {
                      x1: "396.94",
                      y1: "262.01",
                      x2: "396.94",
                      y2: "269.1",
                    },
                  }),
                  _vm._v(" "),
                  _c("line", {
                    staticClass: "driving-license-back-cls-21",
                    attrs: {
                      x1: "402.04",
                      y1: "262.01",
                      x2: "402.04",
                      y2: "269.1",
                    },
                  }),
                  _vm._v(" "),
                  _c("line", {
                    staticClass: "driving-license-back-cls-21",
                    attrs: {
                      x1: "407.14",
                      y1: "262.01",
                      x2: "407.14",
                      y2: "269.1",
                    },
                  }),
                  _vm._v(" "),
                  _c("line", {
                    staticClass: "driving-license-back-cls-21",
                    attrs: {
                      x1: "412.23",
                      y1: "262.01",
                      x2: "412.23",
                      y2: "269.1",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("path", {
                  staticClass: "driving-license-back-cls-22",
                  attrs: {
                    d:
                      "M367.62,267.4a5,5,0,0,1-2.65-1c-2.31-1.93-2.83-4.61-2.67-7.47.09-1.72.8-2.26,2.47-1.87q4.34,1,8.67,2.11c4.79,1.18,4.79,1.18,7.21,5.52l.06.12c.85,1.57.23,2.63-1.58,2.65s-3.88,0-5.83,0S369.5,267.56,367.62,267.4Z",
                  },
                }),
                _vm._v(" "),
                _c("circle", {
                  staticClass: "driving-license-back-cls-18",
                  attrs: { cx: "431.72", cy: "262.68", r: "2.37" },
                }),
                _vm._v(" "),
                _c("circle", {
                  staticClass: "driving-license-back-cls-18",
                  attrs: { cx: "423.92", cy: "263.95", r: "1.67" },
                }),
                _vm._v(" "),
                _c("circle", {
                  staticClass: "driving-license-back-cls-18",
                  attrs: { cx: "367.95", cy: "262.64", r: "2.37" },
                }),
                _vm._v(" "),
                _c("circle", {
                  staticClass: "driving-license-back-cls-18",
                  attrs: { cx: "375.76", cy: "263.92", r: "1.67" },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("rect", {
              staticClass: "driving-license-back-cls-23",
              attrs: {
                x: "35.38",
                y: "38.41",
                width: "425.84",
                height: "281.79",
                rx: "10.87",
              },
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }