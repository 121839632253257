var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "terms-and-conditions-header" },
    _vm._l(_vm.data, function (item, index) {
      return _c("div", { key: index, staticClass: "header-item" }, [
        _c("div", {
          staticClass: "header-image",
          style: { backgroundImage: "url(" + item.image + ")" },
        }),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t(item.title)))]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }