var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "test-water-mark" }, [
      _c("span", [
        _vm._v(
          "\n    TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST\n    TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST\n  "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }