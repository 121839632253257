var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.step === "START"
    ? _c("ui-loader", { attrs: { size: 3, center: "" } })
    : _vm.step === "SEND"
    ? _c(
        "ui-frame",
        {
          staticClass: "document-sign",
          attrs: { "full-size": "", padding: "" },
        },
        [
          _c("div", { staticClass: "sign-content" }, [
            _c("h1", { staticClass: "document-header" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("DocumentSign.Header")) + "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "sign-description" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("DocumentSign.Description")) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pdf-box" }, [
              _c("iframe", {
                attrs: {
                  src: _vm.client.document,
                  width: "100%",
                  height: "100%",
                },
              }),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "client-details" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("DocumentSign.Client")) + ":")]),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.client.firstName) +
                  " " +
                  _vm._s(_vm.client.lastName) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "sign-footer" }, [
              _c(
                "div",
                { staticClass: "sign-footer-item" },
                [
                  _c("sms-confirmation", {
                    attrs: {
                      resource: "DocumentSign",
                      country: _vm.countryCode,
                      "phone-number": _vm.phoneNumber,
                      "read-only": _vm.isReadOnly,
                    },
                    on: { complete: _vm.validationCompleted },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      )
    : _vm.step === "CONFIRM"
    ? _c(
        "ui-frame",
        [
          _c(
            "ui-form",
            { staticClass: "sign-content", on: { submit: _vm.submitCode } },
            [
              _c("h1", { staticClass: "document-header" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("DocumentSign.Header")) + "\n    "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "client-details details-center" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("DocumentSign.Client")) + ":"),
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.client.firstName) +
                    " " +
                    _vm._s(_vm.client.lastName) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "code-content" },
                [
                  _c("ui-input", {
                    attrs: {
                      label: "DocumentSign.Code",
                      "max-length": "6",
                      error: _vm.codeError,
                      disabled: _vm.isConfirmed,
                    },
                    model: {
                      value: _vm.code,
                      callback: function ($$v) {
                        _vm.code = $$v
                      },
                      expression: "code",
                    },
                  }),
                  _vm._v(" "),
                  _c("ui-loader", { attrs: { size: 2 } }),
                  _vm._v("\n      " + _vm._s(_vm.time) + "\n    "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "confirmation-content" },
                [
                  _c("ui-check-box", {
                    attrs: {
                      label: "DocumentSign.Confirmation",
                      error: _vm.confirmationError,
                      disabled: _vm.isConfirmed,
                    },
                    model: {
                      value: _vm.confirmation,
                      callback: function ($$v) {
                        _vm.confirmation = $$v
                      },
                      expression: "confirmation",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "code-controls" },
                [
                  _c("ui-button", {
                    attrs: {
                      label: "DocumentSign.Change",
                      type: "button",
                      additional: "",
                      disabled: _vm.isConfirmed,
                    },
                    on: { click: _vm.changeNumber },
                  }),
                  _vm._v(" "),
                  _c("ui-button", {
                    attrs: {
                      label: "DocumentSign.CodeSend",
                      disabled: _vm.isConfirmed,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm.step === "COMPLETED"
    ? _c("ui-frame", [
        _c("div", { staticClass: "sign-content" }, [
          _c("h1", { staticClass: "document-header" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("DocumentSign.Header")) + "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "sign-description" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("DocumentSign.Completed")) + "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pdf-box" }, [
            _c("iframe", {
              attrs: {
                src: _vm.client.document,
                width: "100%",
                height: "100%",
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "sign-footer" },
            [
              _c("ui-button", {
                staticClass: "sign-complete",
                attrs: { label: "App.Continue" },
                on: { click: _vm.continueProcess },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }