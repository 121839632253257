<template>
  <div class="ui-drop-down">
    <label :class="[{ 'not-empty': value, 'has-error': error }, touchedClass]">
      <select
        :value="value"
        @input="$emit('input', $event.target.value)"
        @blur="lostFocus"
      >
        <option hidden disabled selected />
        <option v-for="item in sortedOptions" :key="item.key" :value="item.key">
          {{ item.value }}
        </option>
      </select>
      <span class="drop-down-name">{{ $t(label) }}</span>
      <i class="drop-down-caret" />
      <span class="drop-down-error">{{ $t(error) }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'ui-drop-down',

  props: {
    label: { type: String, default: undefined },
    value: { type: String, default: undefined },
    error: { type: String, default: undefined },
    options: { type: Array, default: undefined },
    sort: Boolean
  },

  data() {
    return {
      isTouched: false
    }
  },

  computed: {
    touchedClass() {
      return this.isTouched ? 'ui-touched' : 'ui-untouched'
    },

    sortedOptions() {
      if (!this.options) return []

      const mappedOptions = this.options.map((item) => ({
        key: item.key,
        value: this.$t(item.value)
      }))

      if (this.sort && mappedOptions.length > 1) {
        mappedOptions.sort(({ value: v1 }, { value: v2 }) =>
          v1?.localeCompare(v2)
        )
      }

      return mappedOptions
    }
  },

  methods: {
    lostFocus() {
      this.isTouched = true
    }
  }
}
</script>

<style scoped>
.ui-drop-down {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

label {
  position: relative;
  display: block;
}

select {
  font-family: var(--primary-font);
  font-size: 1rem;
  line-height: 1.5;
  background-color: transparent;
  border: none;
  display: block;
  width: 100%;
  padding: 0.5rem 1.5rem 0.5rem 1rem;
  color: var(--text-contrast-color);
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 200% 1px;
  background-image: linear-gradient(
    to right,
    var(--text-color) 0,
    var(--text-color) 50%,
    var(--input-color) 50%,
    var(--input-color) 100%
  );
  transition: background-position var(--input-time) ease-in-out;
}

.drop-down-name {
  color: var(--text-contrast-color);
  line-height: 1.5;
  display: block;
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  font-weight: 400;
  pointer-events: none;
  transition: all var(--input-time) ease-in-out;
}

.drop-down-caret {
  position: absolute;
  right: 0.5rem;
  top: 1rem;
  pointer-events: none;
  width: 0.5rem;
  height: 0.5rem;
  border-bottom: 1px solid var(--input-color);
  border-right: 1px solid var(--input-color);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: border-color var(--input-time) ease-in-out;
}

.drop-down-error {
  font-size: 0.75rem;
  color: var(--error);
  position: absolute;
  bottom: -1rem;
  opacity: 0;
  transition: opacity var(--input-time) ease-in-out;
}

.ui-touched .drop-down-error {
  opacity: 1;
}

select:focus {
  background-position: 0 100%;
}

select:focus ~ .drop-down-caret {
  --input-color: var(--text-color);
}

.not-empty .drop-down-name {
  font-size: 0.75rem;
  top: -1rem;
  left: 0;
}

.has-error.ui-touched select:focus ~ .drop-down-caret,
.has-error.ui-touched {
  --input-color: var(--error);
}

.has-error.ui-touched > .drop-down-name {
  color: var(--error);
}
</style>
