<template>
  <instructions-frame
    :header="$t('Instructions.Headers.Face')"
    :description="$t('Instructions.Descriptions.Face')"
    :main-item="mainItem"
    :sub-items-header="$t('Instructions.RightSidebarTitle')"
    :sub-items="subItems"
    :action="$t('Instructions.ButtonText')"
    @onaction="$emit('go-next')"
  />
</template>

<script>
import InstructionsFrame from '@src/components/partials/InstructionsFrame'
import FaceFront from '@src/components/images/FaceFront'
import FaceNotNeutral from '@src/components/images/FaceNotNeutral'
import FaceBadLighting from '@src/components/images/FaceBadLighting'
import FaceTooBlurry from '@src/components/images/FaceTooBlurry'
import { isMobileDevice } from '@src/scripts/browserSupport'

export default {
  components: {
    InstructionsFrame
  },

  computed: {
    mainItem() {
      return (!isMobileDevice() && FaceFront) || null
    },

    subItems() {
      return [
        {
          label: this.$t('Instructions.Mistakes.Blurry'),
          image: FaceTooBlurry
        },
        {
          label: this.$t('Instructions.Mistakes.BadLighting'),
          image: FaceBadLighting
        },
        {
          label: this.$t('Instructions.Mistakes.NotNeutral'),
          image: FaceNotNeutral
        }
      ]
    }
  }
}
</script>
