var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 494.84 389.59",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-back-linear-gradient",
                x1: "308.93",
                y1: "242.35",
                x2: "721.53",
                y2: "581.42",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#00ff28" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.15", "stop-color": "#0aea60" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "0.3", "stop-color": "#13d790" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.45", "stop-color": "#1bc8b8" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "0.6", "stop-color": "#21bcd7" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.74", "stop-color": "#25b4ed" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.88", "stop-color": "#27affa" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#28adff" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "id-back-linear-gradient-2",
              x1: "327.01",
              y1: "220.36",
              x2: "739.6",
              y2: "559.42",
              "xlink:href": "#id-back-linear-gradient",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-back-linear-gradient-3",
                x1: "239.53",
                y1: "350.74",
                x2: "261.49",
                y2: "396.12",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#3b3b3b" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#2b2829" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-back-linear-gradient-4",
                x1: "59.23",
                y1: "287.02",
                x2: "1087.05",
                y2: "279.76",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#a6a6a6" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-back-linear-gradient-5",
                x1: "-0.93",
                y1: "240.63",
                x2: "750.96",
                y2: "265.04",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#6f7173" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "id-back-linear-gradient-6",
                x1: "339.23",
                y1: "236.75",
                x2: "659.79",
                y2: "500.18",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#a3a3a3" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.29", "stop-color": "#b2b2b2" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.66", "stop-color": "#bfbfbf" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#c4c4c4" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "id-back-linear-gradient-7",
              x1: "353.27",
              y1: "219.66",
              x2: "673.84",
              y2: "483.09",
              "xlink:href": "#id-back-linear-gradient-6",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "id-back-cls-1" }, [
        _c("g", { attrs: { id: "ID-back" } }, [
          _c("g", [
            _c("path", {
              staticClass: "id-back-cls-2",
              attrs: { d: "M346.25,240.86h15.44v77H346.25Z" },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "id-back-cls-3",
              attrs: {
                d:
                  "M444.45,279.47c0,23.29-16,38.4-40.61,38.4H376.92v-77h26.92C428.45,240.86,444.45,256.08,444.45,279.47Zm-41.16,25.27c16.22,0,25.38-9.27,25.38-25.27s-9.16-25.59-25.38-25.59H392.37v50.86Z",
              },
            }),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("path", {
              attrs: {
                d:
                  "M216.08,32.09c-2.52-2.42-3.92-6.38-3.92-10.63s1.4-8.4,4.21-10.92h2.71v.25a14.79,14.79,0,0,0-4.33,10.67,14.94,14.94,0,0,0,4,10.36v.27Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M226.54,28.3h-5.79V14.88h5.54c2.8,0,4.36,1.46,4.36,3.46a3.09,3.09,0,0,1-2.29,3.08,3.26,3.26,0,0,1,2.6,3.25C231,26.8,229.32,28.3,226.54,28.3Zm-.64-11.24h-2.46v3.36h2.46c1.27,0,2-.58,2-1.67S227.17,17.06,225.9,17.06Zm.19,5.44h-2.65v3.61h2.71c1.31,0,2.08-.63,2.08-1.77S227.4,22.5,226.09,22.5Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M241.34,25.75H236l-.88,2.55h-2.83l4.83-13.44h3.13l4.83,13.44h-2.85ZM238.66,18l-1.94,5.61h3.89Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M253,14.71a6.34,6.34,0,0,1,6.31,4.35h-3.1A3.38,3.38,0,0,0,253,17.13c-2.34,0-4,1.71-4,4.44S250.66,26,253,26a3.37,3.37,0,0,0,3.23-1.95h3.1A6.31,6.31,0,0,1,253,28.42a6.62,6.62,0,0,1-6.8-6.85A6.63,6.63,0,0,1,253,14.71Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M264.32,22.32v6h-2.69V14.88h2.69v6l4.88-6h3.25l-5.53,6.66,5.69,6.76H269.2Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M273.68,32.09v-.27a14.94,14.94,0,0,0,4-10.36,14.79,14.79,0,0,0-4.33-10.67v-.25h2.71c2.81,2.52,4.21,6.65,4.21,10.92s-1.4,8.21-3.92,10.63Z",
              },
            }),
          ]),
          _vm._v(" "),
          _c("rect", {
            staticClass: "id-back-cls-4",
            attrs: {
              x: "34.8",
              y: "38.41",
              width: "425.84",
              height: "281.79",
              rx: "10.87",
            },
          }),
          _vm._v(" "),
          _c("g", [
            _c("g", [
              _c("polygon", {
                staticClass: "id-back-cls-5",
                attrs: {
                  points:
                    "14.55 73.91 0 73.91 0 0 73.91 0 73.91 14.55 14.55 14.55 14.55 73.91",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "id-back-cls-5",
                attrs: {
                  points:
                    "494.84 358.47 420.92 358.47 420.92 343.92 480.29 343.92 480.29 284.55 494.84 284.55 494.84 358.47",
                },
              }),
            ]),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "id-back-cls-5",
              attrs: {
                points:
                  "494.84 73.91 480.29 73.91 480.29 14.55 420.92 14.55 420.92 0 494.84 0 494.84 73.91",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "id-back-cls-5",
              attrs: {
                points:
                  "73.91 358.47 0 358.47 0 284.55 14.55 284.55 14.55 343.92 73.91 343.92 73.91 358.47",
              },
            }),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("path", {
              staticClass: "id-back-cls-5",
              attrs: {
                d:
                  "M275.78,349.69v35.46a4.44,4.44,0,0,1-4.44,4.44h-48a4.43,4.43,0,0,1-4.43-4.43V349.69a4.44,4.44,0,0,1,4.44-4.44h9.78a4.46,4.46,0,0,0,3.53-1.74l2.74-3.56a6.74,6.74,0,0,1,5.34-2.64H251a6.74,6.74,0,0,1,5.35,2.64l2.74,3.56a4.44,4.44,0,0,0,3.53,1.74h8.72A4.44,4.44,0,0,1,275.78,349.69Z",
              },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "id-back-cls-6",
              attrs: { cx: "247.06", cy: "366.3", r: "15.62" },
            }),
            _vm._v(" "),
            _c("circle", {
              staticClass: "id-back-cls-7",
              attrs: { cx: "247.06", cy: "366.3", r: "10.99" },
            }),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("path", { attrs: { d: "M60.82,69.15h2.69V82.57H60.82Z" } }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M77.93,75.87c0,4.06-2.79,6.7-7.08,6.7H66.16V69.15h4.69C75.14,69.15,77.93,71.8,77.93,75.87Zm-7.17,4.41c2.82,0,4.42-1.62,4.42-4.41s-1.6-4.46-4.42-4.46H68.85v8.87Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M87.73,71.32H82.64v3.36h4.51v2.14H82.64v3.55h5.09v2.2H79.94V69.13h7.79Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M101.65,69.13V82.57H99l-6.1-9.21v9.21H90.17V69.13h2.69L99,78.35V69.13Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M103.58,69.15h9.85v2.17h-3.58V82.57h-2.69V71.32h-3.58Z",
              },
            }),
            _vm._v(" "),
            _c("path", { attrs: { d: "M115.39,69.15h2.69V82.57h-2.69Z" } }),
            _vm._v(" "),
            _c("path", {
              attrs: { d: "M120,69.15h9.84v2.17h-3.58V82.57h-2.69V71.32H120Z" },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M130.7,69.15h3l2.88,6.13,2.87-6.13h3L138,77.89v4.68h-2.69V77.89Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M154.77,69a6.32,6.32,0,0,1,6.3,4.35H158a3.38,3.38,0,0,0-3.23-1.92c-2.35,0-4,1.71-4,4.44s1.67,4.44,4,4.44A3.38,3.38,0,0,0,158,78.34h3.09a6.3,6.3,0,0,1-6.3,4.34,6.86,6.86,0,0,1,0-13.71Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M171.61,80h-5.35l-.88,2.56h-2.83l4.83-13.44h3.13l4.83,13.44h-2.85Zm-2.68-7.77L167,77.86h3.89Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M182.18,69.15c3.23,0,4.84,1.86,4.84,4.11A3.86,3.86,0,0,1,184,77.12l3.21,5.45h-3.11l-3-5.23h-1.27v5.23h-2.69V69.15Zm-.1,2.23h-2.25v3.94h2.25c1.5,0,2.17-.79,2.17-2S183.58,71.38,182.08,71.38Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M201.22,75.87c0,4.06-2.78,6.7-7.07,6.7h-4.69V69.15h4.69C198.44,69.15,201.22,71.8,201.22,75.87Zm-7.17,4.41c2.83,0,4.43-1.62,4.43-4.41s-1.6-4.46-4.43-4.46h-1.9v8.87Z",
              },
            }),
          ]),
          _vm._v(" "),
          _c("path", {
            staticClass: "id-back-cls-8",
            attrs: {
              d:
                "M461.22,249.89l-.1,60.52a11.17,11.17,0,0,1-11.19,11.15l-402.57-.67c-6.17,0-11.16-5-12.41-9.88V249.89Z",
            },
          }),
          _vm._v(" "),
          _c("g", [
            _c("polygon", {
              staticClass: "id-back-cls-5",
              attrs: {
                points:
                  "49.55 285.63 46.72 282.81 55.02 274.51 46.6 266.1 49.43 263.27 60.67 274.51 49.55 285.63",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "id-back-cls-5",
              attrs: {
                points:
                  "68.74 285.63 65.91 282.81 74.21 274.51 65.79 266.1 68.62 263.27 79.86 274.51 68.74 285.63",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "id-back-cls-5",
              attrs: {
                points:
                  "87.93 285.63 85.11 282.81 93.4 274.51 84.98 266.1 87.81 263.27 99.06 274.51 87.93 285.63",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "id-back-cls-5",
              attrs: {
                points:
                  "107.12 285.63 104.3 282.81 112.59 274.51 104.17 266.1 107 263.27 118.25 274.51 107.12 285.63",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "id-back-cls-5",
              attrs: {
                points:
                  "126.31 285.63 123.49 282.81 131.78 274.51 123.36 266.1 126.19 263.27 137.44 274.51 126.31 285.63",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "id-back-cls-5",
              attrs: {
                points:
                  "312.36 313.25 301.12 302 312.24 290.88 315.06 293.71 306.77 302 315.19 310.42 312.36 313.25",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "id-back-cls-5",
              attrs: {
                points:
                  "293.17 313.25 281.92 302 293.05 290.88 295.87 293.71 287.58 302 296 310.42 293.17 313.25",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "id-back-cls-5",
              attrs: {
                points:
                  "273.98 313.25 262.73 302 273.85 290.88 276.68 293.71 268.39 302 276.81 310.42 273.98 313.25",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "id-back-cls-5",
              attrs: {
                points:
                  "254.78 313.25 243.54 302 254.66 290.88 257.49 293.71 249.2 302 257.61 310.42 254.78 313.25",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "id-back-cls-5",
              attrs: {
                points:
                  "235.59 313.25 224.35 302 235.47 290.88 238.3 293.71 230.01 302 238.42 310.42 235.59 313.25",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("path", {
                staticClass: "id-back-cls-5",
                attrs: {
                  d:
                    "M146,265.5h4.3l6,14,6-14h4.27v18.84h-3.78V272.09l-5.05,12.25h-2.86l-5.08-12.25v12.25H146Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "id-back-cls-5",
                attrs: {
                  d:
                    "M177.35,265.5c4.53,0,6.8,2.62,6.8,5.78a5.44,5.44,0,0,1-4.29,5.42l4.51,7.64H180L175.84,277h-1.78v7.34h-3.78V265.5Zm-.14,3.13h-3.15v5.54h3.15c2.11,0,3-1.11,3-2.81S179.32,268.63,177.21,268.63Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "id-back-cls-5",
                attrs: {
                  d:
                    "M199.92,268.42l-8.48,12.74h8.48v3.18H187.07v-2.91l8.42-12.74h-8.42V265.5h12.85Z",
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("rect", {
            staticClass: "id-back-cls-9",
            attrs: { x: "35.05", y: "247.54", width: "426.17", height: "2.35" },
          }),
          _vm._v(" "),
          _c("g", [
            _c("rect", {
              staticClass: "id-back-cls-10",
              attrs: {
                x: "352.21",
                y: "219.46",
                width: "98.67",
                height: "92.08",
                rx: "11.49",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("path", {
                staticClass: "id-back-cls-11",
                attrs: { d: "M368.22,235.58h12v59.84h-12Z" },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "id-back-cls-12",
                attrs: {
                  d:
                    "M444.52,265.59c0,18.09-12.43,29.83-31.55,29.83H392.05V235.58H413C432.09,235.58,444.52,247.41,444.52,265.59Zm-32,19.63c12.6,0,19.72-7.2,19.72-19.63s-7.12-19.89-19.72-19.89h-8.48v39.52Z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("g", { staticClass: "id-back-cls-13" }, [
              _c("path", {
                staticClass: "id-back-cls-5",
                attrs: { d: "M368.22,235.58h12v59.84h-12Z" },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "id-back-cls-5",
                attrs: {
                  d:
                    "M444.52,265.59c0,18.09-12.43,29.83-31.55,29.83H392.05V235.58H413C432.09,235.58,444.52,247.41,444.52,265.59Zm-32,19.63c12.6,0,19.72-7.2,19.72-19.63s-7.12-19.89-19.72-19.89h-8.48v39.52Z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("g", { staticClass: "id-back-cls-13" }, [
              _c("path", {
                staticClass: "id-back-cls-5",
                attrs: { d: "M368.22,235.58h12v59.84h-12Z" },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "id-back-cls-5",
                attrs: {
                  d:
                    "M444.52,265.59c0,18.09-12.43,29.83-31.55,29.83H392.05V235.58H413C432.09,235.58,444.52,247.41,444.52,265.59Zm-32,19.63c12.6,0,19.72-7.2,19.72-19.63s-7.12-19.89-19.72-19.89h-8.48v39.52Z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("g", { staticClass: "id-back-cls-14" }, [
              _c("path", {
                staticClass: "id-back-cls-5",
                attrs: { d: "M368.22,235.58h12v59.84h-12Z" },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "id-back-cls-5",
                attrs: {
                  d:
                    "M444.52,265.59c0,18.09-12.43,29.83-31.55,29.83H392.05V235.58H413C432.09,235.58,444.52,247.41,444.52,265.59Zm-32,19.63c12.6,0,19.72-7.2,19.72-19.63s-7.12-19.89-19.72-19.89h-8.48v39.52Z",
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("rect", {
              staticClass: "id-back-cls-15",
              attrs: {
                x: "141.57",
                y: "21.66",
                width: "8.93",
                height: "171.93",
                transform: "translate(38.17 253.49) rotate(-89.9)",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "id-back-cls-15",
              attrs: {
                points:
                  "168.94 221.91 60.28 221.62 60.3 212.69 168.82 213.75 168.94 221.91",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("rect", {
                staticClass: "id-back-cls-15",
                attrs: {
                  x: "119.17",
                  y: "81.91",
                  width: "8.93",
                  height: "127.28",
                  transform: "translate(-22.12 268.95) rotate(-89.9)",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                staticClass: "id-back-cls-15",
                attrs: {
                  x: "119.17",
                  y: "115.74",
                  width: "8.93",
                  height: "127.28",
                  transform: "translate(-55.95 302.72) rotate(-89.9)",
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("rect", {
            staticClass: "id-back-cls-16",
            attrs: {
              x: "34.8",
              y: "38.41",
              width: "425.84",
              height: "281.79",
              rx: "10.87",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }