var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 210.27 224.3",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-not-neutral-linear-gradient",
                x1: "107.34",
                y1: "180.02",
                x2: "107.34",
                y2: "155.61",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#e3ddd5" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#bdb3a9" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-not-neutral-linear-gradient-2",
                x1: "100.18",
                y1: "133.19",
                x2: "114.83",
                y2: "154.86",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#3b3b3b" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#545454" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-not-neutral-linear-gradient-3",
                x1: "106.13",
                y1: "49.5",
                x2: "106.35",
                y2: "49.73",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#999" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#6e6e6e" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "face-not-neutral-linear-gradient-4",
              x1: "80.37",
              y1: "90.44",
              x2: "297.98",
              y2: "189.19",
              "xlink:href": "#face-not-neutral-linear-gradient",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "face-not-neutral-linear-gradient-5",
              x1: "67.99",
              y1: "87.76",
              x2: "65.19",
              y2: "102.57",
              "xlink:href": "#face-not-neutral-linear-gradient",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-not-neutral-linear-gradient-6",
                x1: "101.05",
                y1: "99.36",
                x2: "114.44",
                y2: "136.04",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#d1cdcb" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#9c9189" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-not-neutral-linear-gradient-7",
                x1: "106.04",
                y1: "49.44",
                x2: "106.12",
                y2: "49.43",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.13", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.28", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#1f1f1f" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.54", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.78", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "face-not-neutral-linear-gradient-8",
              x1: "152.81",
              y1: "101.56",
              x2: "149.82",
              y2: "117.42",
              "xlink:href": "#face-not-neutral-linear-gradient",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-not-neutral-linear-gradient-9",
                x1: "49.17",
                y1: "65.79",
                x2: "162.36",
                y2: "65.79",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.12", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.25", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#262626" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.58", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "0.8", "stop-color": "#050505" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "face-not-neutral-linear-gradient-10",
                x1: "-461.18",
                y1: "1297.21",
                x2: "-512.53",
                y2: "1192.02",
                gradientTransform:
                  "matrix(1.18, 0.01, -0.01, 1.18, 664.49, -1289.84)",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#a3a3a3" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.29", "stop-color": "#b2b2b2" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.66", "stop-color": "#bfbfbf" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#c4c4c4" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "face-not-neutral-cls-1" }, [
        _c("g", { attrs: { id: "Face-not-neutral" } }, [
          _c("g", [
            _c("g", [
              _c("rect", {
                staticClass: "face-not-neutral-cls-2",
                attrs: { width: "210.27", height: "224.3" },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-not-neutral-cls-3",
                attrs: {
                  d:
                    "M87.75,142l-4.44,29.23a11.27,11.27,0,0,1-6,8.45l-38,19,136.11,1.09-28-15.8a19.79,19.79,0,0,1-9.62-13.6L132.56,143Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-not-neutral-cls-4",
                attrs: {
                  d:
                    "M86.06,148.87s5.58,12.54,23.65,13.52S135.1,148,135.1,148Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-not-neutral-cls-5",
                attrs: {
                  d:
                    "M106.14,50a3.39,3.39,0,0,1-.13-1.06A2.79,2.79,0,0,1,106.14,50Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-not-neutral-cls-6",
                attrs: {
                  d:
                    "M148.91,109.53a65.62,65.62,0,0,1-1.82,18.27c-1.58,6.74-5.9,14.09-11.51,19.85,0,.07-12.42,9.83-24,10.1-.47,0-.95,0-1.42,0a50.7,50.7,0,0,1-5.31-.2c-.85-.09-1.72-.16-2.54-.31h0c-11.18-1.79-16.89-8.37-22-15.39-6.46-8.84-11.44-19.55-11.42-30.49l0-22.59c.15-7.91,0-9.45.15-18.92,0,0,17.57,3.64,41.78-9.36a20,20,0,0,0,12.75,5.34c13,.48,15.84-5.37,15.9-6.7.8,3.38,1.53,27.56,7.66,30.82,4.58,2.88,1.92.85,1.9,1.45Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-not-neutral-cls-7",
                attrs: {
                  d: "M66.05,97.72s-8.1.31-7.92,6.9,11.16,12.49,11.16,12.49Z",
                },
              }),
              _vm._v(" "),
              _c("polyline", {
                staticClass: "face-not-neutral-cls-8",
                attrs: { points: "108.44 99.53 103.57 126.32 107.76 129.66" },
              }),
              _vm._v(" "),
              _c("circle", {
                staticClass: "face-not-neutral-cls-9",
                attrs: { cx: "89.11", cy: "103.22", r: "3.69" },
              }),
              _vm._v(" "),
              _c("circle", {
                staticClass: "face-not-neutral-cls-9",
                attrs: { cx: "126.82", cy: "103.22", r: "3.69" },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-not-neutral-cls-10",
                attrs: {
                  d:
                    "M92.8,138.47s26.61-2.79,39.38-8.81c0,0-3.42,18.69-21,17.58C97,146.35,92.8,138.47,92.8,138.47Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-not-neutral-cls-11",
                attrs: {
                  d:
                    "M106.14,50a3.39,3.39,0,0,1-.13-1.06A2.79,2.79,0,0,1,106.14,50Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-not-neutral-cls-12",
                attrs: { d: "M149,95.46s8.09-.36,8.46,6.22-9,14.55-9,14.55Z" },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "face-not-neutral-cls-13",
                attrs: {
                  d:
                    "M69.06,115.28q.72-3.68,1.45-7.37c-.61-17.42,3.06-23.37,6.9-25.32,2.23-1.12,5.15-1.22,8-4a15.75,15.75,0,0,0,4.4-9.53,33,33,0,0,0,12.3,10.71C119,88,137.59,76.73,142.53,84c2.87,4.22-1.89,10.28,2.86,18.54a19.7,19.7,0,0,0,3.63,4.58c3.19-3.93,14.65-19,13.22-39.64a54.82,54.82,0,0,0-7.58-24.05,54.34,54.34,0,0,0-12.91-14.24C139,27.06,118.05,11.26,99.5,17.91a55.76,55.76,0,0,0-18,10.72,34.25,34.25,0,0,1-5.75,4.72c-4.87,3.11-7.76,3-11.36,4.59C55.51,41.77,51.73,51.46,51,53.38,49.06,58.31,48.67,63.53,49.79,71,52.65,89.94,62.78,106.39,69.06,115.28Z",
                },
              }),
              _vm._v(" "),
              _c("g", [
                _c("path", {
                  staticClass: "face-not-neutral-cls-14",
                  attrs: {
                    d:
                      "M71.38,178.86s11.08,10.56,38.79,10.7,39-6.87,39-6.87l52.3,29.94,8.82,11.67H0l9.57-12.66Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "face-not-neutral-cls-15",
                  attrs: {
                    d:
                      "M71.38,178.86s11.08,10.56,38.79,10.7,39-6.87,39-6.87l52.3,29.94,8.82,11.67H0l9.57-12.66Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "face-not-neutral-cls-15",
                  attrs: {
                    d:
                      "M71.38,178.86s11.08,10.56,38.79,10.7,39-6.87,39-6.87l52.3,29.94,8.82,11.67H0l9.57-12.66Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "face-not-neutral-cls-16",
                  attrs: {
                    d:
                      "M71.38,178.86s11.08,10.56,38.79,10.7,39-6.87,39-6.87l52.3,29.94,8.82,11.67H0l9.57-12.66Z",
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("rect", {
              staticClass: "face-not-neutral-cls-17",
              attrs: { id: "Overlay", width: "210.27", height: "224.3" },
            }),
          ]),
          _vm._v(" "),
          _c("line", {
            staticClass: "face-not-neutral-cls-18",
            attrs: { x1: "80.58", y1: "100.19", x2: "92.8", y2: "92.75" },
          }),
          _vm._v(" "),
          _c("line", {
            staticClass: "face-not-neutral-cls-18",
            attrs: { x1: "123.13", y1: "85.95", x2: "135.72", y2: "92.75" },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }