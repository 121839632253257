var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.logoSource
    ? _c("div", {
        staticClass: "page-logo",
        style: { backgroundImage: "url('" + _vm.logoSource + "')" },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }