var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shell", class: { "shell-hidden": _vm.isLoading } },
    [
      _vm.shouldCloseBeVisible
        ? _c(
            "i",
            { staticClass: "icon", on: { click: _vm.handleClose } },
            [_c("close-icon")],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "shell-content",
          class: { "content-hide": _vm.isShellHiden },
        },
        [
          _c("progress-bar"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-content" },
            [
              _c(
                "transition",
                { attrs: { name: "slide", mode: "out-in" } },
                [
                  _vm.showContent
                    ? _c("router-view", { key: _vm.pageId })
                    : _vm.hasSession
                    ? _c("no-session")
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasTestFooter ? _c("test-footer") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }