var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ui-phone-code",
      class: [{ "has-error": _vm.error }, _vm.touchedClass],
    },
    [
      _c(
        "div",
        {
          staticClass: "select-container",
          class: { "select-disabled": _vm.disabled },
        },
        [
          _c("div", { staticClass: "select-tag" }, [
            _c(
              "select",
              {
                ref: "select",
                attrs: { disabled: _vm.disabled },
                domProps: { value: _vm.value },
                on: { change: _vm.valueChange, blur: _vm.lostFocus },
              },
              [
                _c("option", { attrs: { value: "", hidden: "" } }),
                _vm._v(" "),
                _vm._l(_vm.phoneCodes, function (item) {
                  return _c(
                    "option",
                    { key: item.key, domProps: { value: item.value } },
                    [_vm._v("\n          " + _vm._s(item.name) + "\n        ")]
                  )
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("span", [
            _vm._v(_vm._s(_vm.value ? "+" : "-") + _vm._s(_vm.value)),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }