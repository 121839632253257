import {
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState
} from '@microsoft/signalr'
import Store from '@src/store'

let connection

function createConnection() {
  // Not creating connection locally due to CORS problems. In order to
  // test video calls, need to fix CORS from BE side and adjust FE.
  // After fixing CORS for signalR - delete this condition block for development.
  if (IsDevelopment) {
    return null
  }

  return new HubConnectionBuilder()
    .withUrl(`${BaseUrl.realTimeApi}/sessions`, {
      accessTokenFactory: () => Store.getters.accessToken
    })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.None)
    .build()
}

function getConnection(options) {
  if (!connection) {
    connection = createConnection(options)
  }
  return connection
}

export async function start() {
  if (getConnection()?.state === HubConnectionState.Disconnected) {
    await getConnection().start()
  }
}

export const getConnectionId = () => getConnection()?.connectionId
