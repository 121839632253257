<template>
  <div class="progress-bar">
    <i class="progress-indicator" :style="progressStatus" />
  </div>
</template>

<script>
import getters from '@src/store/getters'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([getters.allowedPages, getters.isIdentityCheckFailed]),

    progressStatus() {
      const { name } = this.$route
      const { allowedPages } = this

      const width = this.isIdentityCheckFailed
        ? 100
        : (100 / allowedPages.length) * (allowedPages.indexOf(name) + 1)
      return { width: `${width || 0}%` }
    }
  }
}
</script>

<style scoped>
.progress-bar {
  background: var(--progress-bar-background);
  width: 100%;
  z-index: 2;
}

.progress-indicator {
  background: var(--progress-bar-color);
  display: block;
  width: 0;
  height: var(--progress-bar-height);
  transition: width 0.35s ease-in-out;
}
</style>
