<template>
  <div class="ui-check-box">
    <label :class="[{ 'has-error': error }, touchedClass]">
      <input
        type="checkbox"
        :value="true"
        :checked="value"
        :disabled="disabled"
        @input="$emit('input', $event.target.checked)"
        @change="onChange"
      />
      <i class="check-box-square" />
      <span class="check-box-name">{{ $t(label) }}</span>
      <span class="check-box-error">{{ $t(error) }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'ui-check-box',

  props: {
    label: { type: String, default: undefined },
    error: { type: String, default: undefined },
    value: Boolean,
    disabled: Boolean
  },

  data() {
    return {
      isTouched: false
    }
  },

  computed: {
    touchedClass() {
      return this.isTouched ? 'ui-touched' : 'ui-untouched'
    }
  },

  methods: {
    onChange() {
      this.isTouched = true
    }
  }
}
</script>

<style scoped>
.ui-check-box {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

label {
  position: relative;
  display: flex;
}

input {
  display: none;
  background-color: transparent;
}

.check-box-square {
  border: 1px solid var(--input-color);
  height: 1rem;
  min-width: 1rem;
  display: block;
  margin-right: 0.5rem;
  position: relative;
  transition: border-color var(--input-time) ease-in-out;
}

.check-box-square::after {
  content: '';
  position: absolute;
  top: -3%;
  left: 30%;
  width: 40%;
  height: 80%;
  border-right: 1px solid var(--button-primary-background);
  border-bottom: 1px solid var(--button-primary-background);
  opacity: 0;
  transform: rotateZ(35deg);
  transition: opacity var(--input-time) ease-in-out;
}

.check-box-name {
  color: var(--text-contrast-color);
  display: block;
}

.check-box-square,
.check-box-name {
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.check-box-error {
  font-size: 0.75rem;
  color: var(--error);
  position: absolute;
  bottom: -1rem;
  opacity: 0;
  transition: opacity var(--input-time) ease-in-out;
}

input:checked + .check-box-square::after {
  opacity: 1;
}

.ui-touched .check-box-error {
  opacity: 1;
}

.has-error.ui-touched {
  --input-color: var(--error);
}

.has-error.ui-touched .check-box-name {
  color: var(--error);
}
</style>
