var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 263.22 189.81",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "filter",
            { attrs: { id: "card-back-too-blurryfilter1", x: "0", y: "0" } },
            [
              _c("feGaussianBlur", {
                attrs: { in: "SourceGraphic", stdDeviation: "4" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "card-back-too-blurry-linear-gradient",
                x1: "28.35",
                y1: "162.65",
                x2: "590.33",
                y2: "158.68",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#a6a6a6" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "card-back-too-blurry-cls-1" }, [
        _c("g", { attrs: { id: "Card-too-blurry" } }, [
          _c("rect", {
            staticClass: "card-back-too-blurry-cls-2",
            attrs: { width: "263.22", height: "189.81" },
          }),
          _vm._v(" "),
          _c("g", { attrs: { filter: "url(#card-back-too-blurryfilter1)" } }, [
            _c("rect", {
              staticClass: "card-back-too-blurry-cls-3",
              attrs: {
                x: "15.12",
                y: "16.91",
                width: "232.98",
                height: "154.17",
                rx: "10.87",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              staticClass: "card-back-too-blurry-cls-4",
              attrs: {
                x: "94.61",
                y: "-11.45",
                width: "6.07",
                height: "116.85",
                transform: "translate(50.5 144.54) rotate(-89.9)",
              },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "card-back-too-blurry-cls-4",
              attrs: {
                points:
                  "113.21 124.64 39.37 124.45 39.38 118.38 113.13 119.1 113.21 124.64",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("rect", {
                staticClass: "card-back-too-blurry-cls-4",
                attrs: {
                  x: "79.39",
                  y: "29.5",
                  width: "6.07",
                  height: "86.5",
                  transform: "matrix(0, -1, 1, 0, 9.54, 155.05)",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                staticClass: "card-back-too-blurry-cls-4",
                attrs: {
                  x: "79.39",
                  y: "52.49",
                  width: "6.07",
                  height: "86.5",
                  transform: "matrix(0, -1, 1, 0, -13.45, 178.01)",
                },
              }),
            ]),
            _vm._v(" "),
            _c("path", {
              staticClass: "card-back-too-blurry-cls-5",
              attrs: {
                d:
                  "M248.22,152.79l-.06,12.2a6.1,6.1,0,0,1-6.12,6.1l-220.25-.36A7.41,7.41,0,0,1,15,165.32V152.79Z",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              staticClass: "card-back-too-blurry-cls-6",
              attrs: {
                x: "15.12",
                y: "16.82",
                width: "232.98",
                height: "154.17",
                rx: "10.87",
              },
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }