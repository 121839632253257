var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type === "SELECTION"
    ? _c("types", { on: { change: _vm.change } })
    : _vm.type === "SMART_ID"
    ? _c("smart-id", {
        attrs: { settings: _vm.settings },
        on: { wait: _vm.wait },
      })
    : _vm.type === "MOBILE_ID"
    ? _c("mobile-id", { on: { wait: _vm.wait } })
    : _vm.type === "CHECK"
    ? _c("check", {
        attrs: { type: _vm.method, code: _vm.verificationCode },
        on: { complete: _vm.complete, error: _vm.error },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }