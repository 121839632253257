<template>
  <router-view />
</template>

<script>
import { appPages } from '@src/scripts/enums'
import { mapGetters } from 'vuex'
import getters from '@src/store/getters'

export default {
  computed: mapGetters([getters.isDocumentCaptureDisabled]),

  created() {
    if (this.isDocumentCaptureDisabled) {
      return this.$router.pushNext({ name: appPages.face })
    }
  }
}
</script>
