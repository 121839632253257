<template>
  <ui-frame>
    <h1 class="document-header">
      {{ $t('Document.Header') }}
    </h1>
    <card-list>
      <document-card
        v-if="hasPassport"
        label="Document.Passport"
        document-type="passport"
      >
        <document-passport />
      </document-card>
      <document-card
        v-if="hasIdCard"
        label="Document.IdCard"
        document-type="id-card"
      >
        <document-id-card />
      </document-card>
      <document-card
        v-if="hasDrivingLicense"
        label="Document.DriverLicense"
        document-type="driver-license"
      >
        <document-driver-license />
      </document-card>
    </card-list>
    <div class="policy-certificates">
      <img
        v-for="item in certificates"
        :key="item"
        :src="`/public/images/certificates/${item}`"
      />
    </div>
  </ui-frame>
</template>

<script>
import CardList from '@src/components/partials/CardList'
import DocumentCard from '@src/components/partials/DocumentCard'
import DocumentIdCard from '@src/components/images/DocumentIdCard'
import DocumentPassport from '@src/components/images/DocumentPassport'
import DocumentDriverLicense from '@src/components/images/DocumentDriverLicense'
import { activityLogger } from '@src/scripts/activityLogger'
import getters from '@src/store/getters'
import { mapGetters } from 'vuex'

export default {
  components: {
    CardList,
    DocumentCard,
    DocumentIdCard,
    DocumentPassport,
    DocumentDriverLicense
  },

  data() {
    return {
      certificates: [
        'iso-27001-02.png',
        'gdpr.png',
        'iso-30107-3-1.png',
        'iso-30107-3-2.png'
      ]
    }
  },

  computed: {
    ...mapGetters([getters.allowedDocuments]),

    hasPassport() {
      return this.allowedDocuments.includes('Passport')
    },

    hasIdCard() {
      return this.allowedDocuments.includes('IdentityCard')
    },

    hasDrivingLicense() {
      return this.allowedDocuments.includes('DriverLicense')
    }
  },

  created() {
    activityLogger.logActivity('DOCUMENT_TYPE_SELECT_PAGE')
  }
}
</script>

<style scoped>
.document-header {
  margin-top: 2rem;
  margin-bottom: 1.25rem;
  text-align: center;
  color: var(--header-contrast-color);
}

.policy-certificates {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 3rem;
}
.policy-certificates > img {
  height: 3rem;
  margin: 0.5rem 1rem;
}

@media screen and (min-width: 38.0625rem) {
  .document-header,
  .document-info {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

@media screen and (max-width: 38rem) {
  .policy-certificates {
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    height: 2rem;
  }
  .policy-certificates > img {
    height: 100%;
    width: auto;
    margin: 0.5rem;
  }
}
</style>
