<template>
  <transition :name="transitionName" mode="out-in">
    <div v-if="popUp.length" class="pop-up" :class="{ opaque: isOpaque }">
      <i
        v-if="
          !hasReloadOption && popUp[0].type !== errorTypes.termsAndConditions
        "
        class="icon"
        @click="close"
      >
        <close-icon />
      </i>
      <ui-frame
        v-if="popUp[0].type !== errorTypes.termsAndConditions"
        max-height
      >
        <h1 v-if="errorTypes">{{ $t(`Error.Header.${popUp[0].type}`) }}</h1>
        <permission-details
          v-if="popUp[0].type === errorTypes.noPermissions"
          :button-label="buttonLabel"
          @click="onClick"
          @close="close"
        />
        <close-idv-process
          v-else-if="popUp[0].type === popUpTypes.CloseIdvProcess"
          :button-label="'App.Quit'"
          :continue-button-label="'App.ContinueProcess'"
          @click="onClick"
          @close="close"
        />
        <default-details
          v-else
          :type="popUp[0].type"
          :options="popUp[0].options"
          :button-label="buttonLabel"
          @click="onClick"
          @close="close"
        />
      </ui-frame>
      <ui-frame v-else max-height>
        <terms-and-conditions @close="close" />
      </ui-frame>
    </div>
  </transition>
</template>

<script>
import CloseIcon from '@src/components/images/CloseIcon'
import DefaultDetails from '@src/components/popUp/DefaultDetails'
import PermissionDetails from '@src/components/popUp/PermissionDetails'
import CloseIdvProcess from '@src/components/popUp/CloseIdvProcess'
import TermsAndConditions from '@src/components/partials/TermsAndConditions'
import { popUpData } from '@src/components/popUp'
import { popUpTypes, errorTypes } from '@src/scripts/enums'

export default {
  components: {
    CloseIcon,
    DefaultDetails,
    PermissionDetails,
    CloseIdvProcess,
    TermsAndConditions
  },

  data() {
    return {
      errorTypes,
      popUpTypes,
      popUp: popUpData,
      canBeReloaded: [
        errorTypes.noCamera,
        errorTypes.noPermissions,
        errorTypes.cameraStreamInactive,
        errorTypes.screenRecord,
        errorTypes.invalidIpAddress
      ]
    }
  },

  computed: {
    buttonLabel() {
      const { popUp } = this
      if (!popUp.length) return

      return this.hasReloadOption ? 'App.TryAgain' : 'App.Close'
    },

    hasReloadOption() {
      return this.canBeReloaded.includes(this.popUp[0].type)
    },

    isOpaque() {
      const { popUp } = this
      return popUp[0]?.options?.isOpaque
    },

    isImmediate() {
      const { popUp } = this
      return popUp[0]?.options?.isImmediate
    },

    transitionName() {
      return this.isImmediate ? 'immediate' : 'slide'
    }
  },

  watch: {
    popUp(value) {
      this.$emit('change', !!value.length)
    }
  },

  methods: {
    onClick() {
      const { popUp, close, reload } = this
      if (!popUp.length) return
      this.hasReloadOption ? reload() : close()
    },

    close() {
      const { popUp } = this
      if (!popUp.length) return
      popUp.shift()
    },

    reload() {
      this.$router.reload()
    }
  }
}
</script>

<style scoped>
.icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 3;
}

.icon:hover {
  cursor: pointer;
}

.pop-up {
  background-color: #fff;
  max-height: 100%;
  overflow: auto;
  text-align: center;
  display: flex;
  position: fixed;
  inset: 0;
  z-index: 5;
}

.pop-up.opaque {
  background-color: #fff;
}

h1 {
  max-width: 30rem;
  margin: 0 auto 1.25rem;
}

.slide-enter-active,
.slide-leave-active {
  transition-duration: var(--page-time);
  transition-property: opacity, transform;
  transition-timing-function: ease;
}

.slide-enter {
  transform: translateX(100%);
  opacity: 0;
}

.slide-leave-active {
  transform: translateX(-100%);
  opacity: 0;
}

.immediate-leave-active {
  transition-duration: var(--page-time);
  transition-property: opacity, transform;
  transition-timing-function: ease;
  transform: translateX(-100%);
  opacity: 0;
}
</style>
