import Router from '@src/scripts/router'
import {
  cameraStates,
  ruleTypes,
  faceCheckTypes,
  appPages
} from '@src/scripts/enums'
import { OMNICHANNEL_STATUSES } from '@src/scripts/constants'

export const methods = {
  isLoggedIn,
  isCameraActive,
  isCameraLoading,
  isCameraLoaded,
  cameraState,
  allowedDocuments,
  allowedPages,
  isBrowserSupported,
  isBrowserDefined,
  isMobileDevice,
  hasSession,
  isOmnichannelAvailable,
  isOmnichannelActive,
  isFailureUrlAvailable,
  termsStatus,
  cssSource,
  jsSource,
  logoSource,
  faviconSource,
  translationSource,
  loadingVideoSource,
  idvCustomLoaderSource,
  idvLoaderSource,
  isZoomRequired,
  accessToken,
  idvAccessToken,
  sessionExpiration,
  formType,
  claims,
  isOmnichannelStarted,
  isOmnichannelVisible,
  countryCode,
  resourceDirectory,
  isRecordRequired,
  consentHeader,
  signatureSettings,
  isLivenessEnabled,
  isPassiveLivenessAvailable,
  isIdentityCheckFailed,
  isAppStoreEnabled,
  isAgeVerificationEnabled,
  isFaceCaptureDisabled,
  isDocumentCaptureDisabled,
  rules,
  googleMapsKey,
  isOmnichannelNonCloseable,
  isOmnichannelCompleted
}

export default new Proxy(methods, {
  get(target, key) {
    if (!target[key]) {
      throw `Getter key ${key} not exist.`
    }
    return key
  }
})

function isLoggedIn({ accessData, session }) {
  return !!(accessData && accessData.token && session && session.pages)
}

function isCameraActive({ camera }) {
  return !!(camera && camera.state === cameraStates.active)
}

function isCameraLoading({ camera }) {
  return !!(camera && camera.state === cameraStates.loading)
}

function isCameraLoaded({ camera }) {
  return !!(
    camera &&
    camera.state !== cameraStates.pending &&
    camera.state !== cameraStates.failed
  )
}

function cameraState({ camera }) {
  return camera && camera.state
}

function allowedDocuments({ session }) {
  return (session && session.documents) || []
}

function allowedPages({ session }) {
  return (session && session.pages) || []
}

function isBrowserSupported({ browserData }) {
  return !!(browserData && browserData.supported)
}

function isMobileDevice({ browserData }) {
  return (browserData && browserData.isMobileDevice) || false
}

function isBrowserDefined({ browserData }) {
  return !!browserData
}

function hasSession({ sessionExists }) {
  return !!sessionExists
}

function isOmnichannelAvailable({ session, accessData }) {
  if (!session || accessData?.claims?.includes('faceauthentication')) {
    return false
  }

  if (!session.rules || !session.rules.length) return true

  const termsRule = session.rules.find(
    (rule) => rule.name === ruleTypes.omnichannelDisabled
  )
  return !termsRule
}

function isFailureUrlAvailable({ session }) {
  if (!session || !session.failureRedirectUrl) {
    return false
  }
  return session.failureRedirectUrl
}

function isOmnichannelActive({ accessData }) {
  return !!(
    accessData &&
    accessData.claims &&
    accessData.claims.includes('omnichannel')
  )
}

function isOmnichannelStarted({ accessData }) {
  return !!(
    accessData &&
    accessData.claims &&
    accessData.claims.includes('omnichannel-active')
  )
}

function isOmnichannelVisible(
  state,
  { isMobileDevice, isOmnichannelAvailable, isOmnichannelStarted }
) {
  return !isMobileDevice && isOmnichannelAvailable && !isOmnichannelStarted
}

function isOmnichannelCompleted({ omnichannel }) {
  return omnichannel.status === OMNICHANNEL_STATUSES.COMPLETED
}

function isOmnichannelNonCloseable({ omnichannel }) {
  return omnichannel.isNonCloseable
}

function termsStatus({ session }) {
  if (!session || !session.rules || !session.rules.length)
    return { required: false }

  const termsRule = session.rules.find(
    (rule) => rule.name === ruleTypes.termsAndConditions
  )
  return {
    required: !!termsRule,
    parameters: termsRule && termsRule.parameters
  }
}

function resourceDirectory({ session }) {
  if (!session?.resourceDirectory) return

  const resourceDirectory = session.resourceDirectory.toLowerCase()
  return `${BaseUrl.resources}/customs/${resourceDirectory}`
}

function cssSource({ resources: { root, styles } }) {
  if (!root || !styles) return
  return `${root}/styles.css`
}

function jsSource({ resources: { root, script } }) {
  if (!root || !script) return
  return `${root}/script.js`
}

function logoSource({ resources: { root, logo } }) {
  if (!root || !logo) return
  return `${root}/${logo}`
}

function faviconSource({ resources: { root, favicon }, session }) {
  if (!session) return

  const defaultFavicon = `${BaseUrl.resources}/customs/ondato/favicon.ico`
  if (session && !session.resourceName && !session.resourceDirectory)
    return defaultFavicon

  if (!root || !favicon) return defaultFavicon
  return `${root}/favicon.ico`
}

function translationSource({ resources: { root, translations } }) {
  if (!root || !translations) return
  return `${root}/translations`
}

function loadingVideoSource({ resources: { root, loadingVideo } }) {
  return (language) => {
    if (!root || !loadingVideo) return

    const videoSource = loadingVideo.find((item) => item.startsWith(language))
    if (!videoSource) return

    return `${root}/media/loading/${videoSource}`
  }
}

function idvCustomLoaderSource({ resources: { root, waitingAnimation } }) {
  return (language) => {
    if (!root || !waitingAnimation) {
      return null
    }

    const animation = {}

    if (waitingAnimation.progress) {
      const videoName =
        waitingAnimation.progress.find((item) => item?.startsWith(language)) ||
        waitingAnimation.progress.find((item) => item?.startsWith('en'))

      if (videoName) {
        animation.progress = `${root}/media/waiting-animation/progress/${videoName}`
      }
    }

    if (waitingAnimation.approve) {
      const videoName =
        waitingAnimation.approve.find((item) => item?.startsWith(language)) ||
        waitingAnimation.approve.find((item) => item?.startsWith('en'))

      if (videoName) {
        animation.approve = `${root}/media/waiting-animation/approve/${videoName}`
      }
    }

    if (waitingAnimation.reject) {
      const videoName =
        waitingAnimation.reject.find((item) => item?.startsWith(language)) ||
        waitingAnimation.reject.find((item) => item?.startsWith('en'))

      if (videoName) {
        animation.reject = `${root}/media/waiting-animation/reject/${videoName}`
      }
    }

    if (!Object.keys(animation).length) {
      return null
    }

    return animation
  }
}

function idvLoaderSource() {
  const videoCount = 10

  const animation = {
    approve: null,
    reject: null,
    progress: []
  }

  for (let index = 1; index <= videoCount; index++) {
    const url = `${BaseUrl.resources}/customs/ondato/media/waiting-animation/Ondato_${index}.mp4`

    if (index === videoCount) {
      animation.reject = url
    } else if (index === videoCount - 1) {
      animation.approve = url
    } else {
      animation.progress.push(url)
    }
  }

  return animation
}

function isZoomRequired({ session: { pages } = {} }) {
  return !!(
    pages &&
    (pages.includes(appPages.face) ||
      pages.includes(appPages.faceAuthentication))
  )
}

function accessToken({ accessData }) {
  return accessData?.token
}

function idvAccessToken({ accessData }) {
  return accessData?.idvToken
}

function sessionExpiration({ accessData }) {
  return accessData && accessData.expirationTime
}

function formType({ session }) {
  return session && session.formType
}

function googleMapsKey({ session }) {
  return session && session.googleMapsPlatformApiKey
}

function claims({ accessData }) {
  return accessData && accessData.claims
}

function countryCode({ session }) {
  return session && session.country
}

function isRecordRequired({ session }, { isOmnichannelActive }) {
  if (!session || !session.rules || !session.rules.length) return false
  if (isOmnichannelActive) return false
  if (Router.history.current.name === appPages.omnichannelCompleted) {
    return false
  }

  const rule = session.rules.find(
    (rule) => rule.name === ruleTypes.screenRecord
  )
  return !!rule
}

function consentHeader({ resources: { root, header } }) {
  if (!root || !header?.length) return

  for (const item of header) {
    if (!item.image) continue

    item.image = `${root}/media/header/${item.image}`
  }

  return header
}

function signatureSettings({ session }) {
  if (!session?.rules?.length) return []

  const methods = [ruleTypes.smartId, ruleTypes.mobileId]
  const settings = []

  methods.forEach((method) => {
    const rule = session.rules.find((item) => item.name === method)
    if (!rule) return

    settings.push({
      name: rule.name,
      parameters: rule.parameters && JSON.parse(rule.parameters)
    })
  })

  return settings
}

function isLivenessEnabled({ session, faceCheckMode }) {
  if (!session.rules?.length) return true

  const livenessDisabled = session.rules.some(
    (rule) => rule.name === ruleTypes.livenessCheckDisabled
  )

  return !livenessDisabled && faceCheckMode === faceCheckTypes.liveness
}

function isPassiveLivenessAvailable({ session }) {
  if (!session.rules?.length) return false

  return session.rules.some((rule) => rule.name === ruleTypes.passiveLiveness)
}

function isIdentityCheckFailed(state) {
  return state.isIdentityCheckFailed
}

function isAppStoreEnabled({ session }) {
  if (!session.rules?.length) return false

  return session.rules.some((rule) => rule.name === ruleTypes.appStoreEnabled)
}

function isAgeVerificationEnabled({ session }) {
  return !!session.rules?.some(
    (rule) => rule.name === ruleTypes.ageVerification
  )
}

function isFaceCaptureDisabled(
  { isFaceStepDisabled },
  { isAgeVerificationEnabled }
) {
  return isAgeVerificationEnabled && isFaceStepDisabled
}

function isDocumentCaptureDisabled(
  { isFaceStepDisabled },
  { isAgeVerificationEnabled }
) {
  return isAgeVerificationEnabled && !isFaceStepDisabled
}

function rules({ session }) {
  if (!session?.rules?.length) return null
  return session.rules.map((item) => item.name)
}
