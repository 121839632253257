import { IdvMediator, ControllerType } from '@ondato/iframe-mediator'
import { fileMethods } from '@src/scripts/enums'
import Api, { sendFile } from '@src/scripts/api'

export const fileHandlers = {
  data() {
    return {
      fileEventController: null
    }
  },

  beforeDestroy() {
    if (this.fileEventController) {
      this.fileEventController.unregisterHandlers()
      this.fileEventController = null
    }
  },

  methods: {
    loadFileHandlers(target) {
      if (!target) {
        return
      }

      const eventHandler = new IdvMediator({
        type: ControllerType.Parent,
        iframeElement: target,
        allowedOrigin: BaseUrl.resources
      })

      eventHandler.registerHandler(fileMethods.fileUpload, this.fileUpload)
      this.fileEventController = eventHandler
    },

    async fileUpload(data) {
      if (!data?.length) {
        throw new Error('NoFiles')
      }

      const request = new FormData()
      for (const item of data) {
        const blobBuffer = await new Response(item.value).arrayBuffer()
        const blob = new Blob([blobBuffer], { type: item.value.type })

        request.append('files', blob, item.key)
      }

      const { error } = await sendFile(Api.fileUpload, request)
      if (error) {
        this.$popUp('Failed')
        throw new Error('SaveError')
      }
    }
  }
}
