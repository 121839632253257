var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 263.22 189.81",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-small-linear-gradient",
                x1: "128.96",
                y1: "91.23",
                x2: "128.96",
                y2: "75.32",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#3b3b3b" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#545454" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-small-linear-gradient-2",
                x1: "70.48",
                y1: "169.18",
                x2: "388.21",
                y2: "166.93",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#a6a6a6" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-small-linear-gradient-3",
                x1: "51.88",
                y1: "154.84",
                x2: "284.31",
                y2: "162.38",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#6f7173" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-small-linear-gradient-4",
                x1: "88.81",
                y1: "130.19",
                x2: "88.81",
                y2: "125.89",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#e3ddd5" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#bdb3a9" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-small-linear-gradient-5",
                x1: "-321.21",
                y1: "1174.51",
                x2: "-330.31",
                y2: "1155.88",
                gradientTransform:
                  "matrix(1.15, 0.01, -0.01, 1.15, 467.19, -1214.02)",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#a3a3a3" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.29", "stop-color": "#b2b2b2" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.66", "stop-color": "#bfbfbf" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#c4c4c4" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "passport-too-small-linear-gradient-6",
              x1: "87.55",
              y1: "121.94",
              x2: "90.13",
              y2: "125.76",
              "xlink:href": "#passport-too-small-linear-gradient",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-small-linear-gradient-7",
                x1: "88.6",
                y1: "107.2",
                x2: "88.64",
                y2: "107.24",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#999" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#6e6e6e" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "passport-too-small-linear-gradient-8",
              x1: "84.07",
              y1: "114.41",
              x2: "122.36",
              y2: "131.79",
              "xlink:href": "#passport-too-small-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "passport-too-small-linear-gradient-9",
              x1: "81.89",
              y1: "113.94",
              x2: "81.4",
              y2: "116.55",
              "xlink:href": "#passport-too-small-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-small-linear-gradient-10",
                x1: "87.71",
                y1: "115.98",
                x2: "90.06",
                y2: "122.44",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.01", "stop-color": "#d1cdcb" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.99", "stop-color": "#9c9189" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-small-linear-gradient-11",
                x1: "88.58",
                y1: "107.19",
                x2: "88.6",
                y2: "107.19",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.13", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.28", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#1f1f1f" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.54", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.78", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("linearGradient", {
            attrs: {
              id: "passport-too-small-linear-gradient-12",
              x1: "96.81",
              y1: "116.37",
              x2: "96.29",
              y2: "119.16",
              "xlink:href": "#passport-too-small-linear-gradient-4",
            },
          }),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "passport-too-small-linear-gradient-13",
                x1: "78.58",
                y1: "110.07",
                x2: "98.49",
                y2: "110.07",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.12", "stop-color": "#050505" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.25", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.37", "stop-color": "#262626" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: { offset: "0.58", "stop-color": "#121212" },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "0.8", "stop-color": "#050505" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "passport-too-small-cls-1" }, [
        _c("g", { attrs: { id: "Passport-too-small" } }, [
          _c("rect", {
            staticClass: "passport-too-small-cls-2",
            attrs: { width: "263.22", height: "189.81" },
          }),
          _vm._v(" "),
          _c("g", [
            _c("g", [
              _c("path", {
                staticClass: "passport-too-small-cls-3",
                attrs: {
                  d:
                    "M66.81,179.15a3.65,3.65,0,0,1-3.34-2.62l.13-83.29,130.61.67,0,63.79,0,18.71a3,3,0,0,1-3,2.94Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "passport-too-small-cls-4",
                attrs: {
                  d:
                    "M64.1,93.74l129.61.67,0,63.29,0,18.71a2.45,2.45,0,0,1-2.45,2.44l-124.45-.2A3.18,3.18,0,0,1,64,176.46l0-18.76.1-64m-1-1-.1,65,0,18.89a4.18,4.18,0,0,0,3.84,3.06l124.44.2h0a3.45,3.45,0,0,0,3.45-3.44l0-18.71,0-64.29L63.1,92.74Z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-too-small-cls-5",
              attrs: {
                d:
                  "M63.12,93.21l0-64.76,0-18.71a3.46,3.46,0,0,1,3.46-3.45L191,6.5a4.18,4.18,0,0,1,3.84,3.06l0,18.89-.11,65Z",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              staticClass: "passport-too-small-cls-6",
              attrs: {
                x: "154.88",
                y: "79.9",
                width: "2.76",
                height: "46.94",
                transform: "translate(52.63 259.46) rotate(-89.9)",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              staticClass: "passport-too-small-cls-6",
              attrs: {
                x: "157.97",
                y: "87.16",
                width: "2.76",
                height: "53.15",
                transform: "translate(45.35 272.89) rotate(-89.9)",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              staticClass: "passport-too-small-cls-6",
              attrs: {
                x: "157.9",
                y: "114.08",
                width: "2.76",
                height: "53.15",
                transform: "translate(18.36 299.71) rotate(-89.9)",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              staticClass: "passport-too-small-cls-6",
              attrs: {
                x: "151.05",
                y: "105.78",
                width: "2.76",
                height: "39.35",
                transform: "translate(26.72 277.67) rotate(-89.9)",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-too-small-cls-7",
              attrs: {
                d:
                  "M194.74,157.7l0,18.71a3.44,3.44,0,0,1-3.46,3.44l-124.44-.2A4.18,4.18,0,0,1,63,176.59V157.7Z",
              },
            }),
            _vm._v(" "),
            _c("g", [
              _c("polygon", {
                staticClass: "passport-too-small-cls-8",
                attrs: {
                  points:
                    "67.48 168.75 66.61 167.87 69.17 165.31 66.57 162.71 67.45 161.83 70.92 165.31 67.48 168.75",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "passport-too-small-cls-8",
                attrs: {
                  points:
                    "73.42 168.75 72.54 167.87 75.11 165.31 72.5 162.71 73.38 161.83 76.86 165.31 73.42 168.75",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "passport-too-small-cls-8",
                attrs: {
                  points:
                    "79.35 168.75 78.47 167.87 81.04 165.31 78.44 162.71 79.31 161.83 82.79 165.31 79.35 168.75",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "passport-too-small-cls-8",
                attrs: {
                  points:
                    "85.28 168.75 84.41 167.87 86.97 165.31 84.37 162.71 85.25 161.83 88.72 165.31 85.28 168.75",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "passport-too-small-cls-8",
                attrs: {
                  points:
                    "91.22 168.75 90.34 167.87 92.91 165.31 90.3 162.71 91.18 161.83 94.65 165.31 91.22 168.75",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "passport-too-small-cls-8",
                attrs: {
                  points:
                    "187.23 175.82 183.75 172.34 187.19 168.91 188.07 169.78 185.5 172.34 188.1 174.94 187.23 175.82",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "passport-too-small-cls-8",
                attrs: {
                  points:
                    "181.3 175.82 177.82 172.34 181.26 168.91 182.13 169.78 179.57 172.34 182.17 174.94 181.3 175.82",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "passport-too-small-cls-8",
                attrs: {
                  points:
                    "175.36 175.82 171.89 172.34 175.33 168.91 176.2 169.78 173.64 172.34 176.24 174.94 175.36 175.82",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "passport-too-small-cls-8",
                attrs: {
                  points:
                    "169.43 175.82 165.96 172.34 169.39 168.91 170.27 169.78 167.7 172.34 170.31 174.94 169.43 175.82",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "passport-too-small-cls-8",
                attrs: {
                  points:
                    "163.5 175.82 160.02 172.34 163.46 168.91 164.34 169.78 161.77 172.34 164.37 174.94 163.5 175.82",
                },
              }),
              _vm._v(" "),
              _c("g", [
                _c("path", {
                  staticClass: "passport-too-small-cls-8",
                  attrs: {
                    d:
                      "M97.3,162.52h1.33l1.85,4.33,1.85-4.33h1.32v5.83h-1.17v-3.79l-1.56,3.79H100l-1.57-3.79v3.79H97.3Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "passport-too-small-cls-8",
                  attrs: {
                    d:
                      "M107,162.52c1.4,0,2.1.81,2.1,1.79a1.68,1.68,0,0,1-1.32,1.68l1.39,2.36h-1.35l-1.29-2.27H106v2.27H104.8v-5.83Zm0,1h-1v1.71h1c.65,0,.94-.34.94-.86S107.6,163.49,107,163.49Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "passport-too-small-cls-8",
                  attrs: {
                    d: "M114,163.43l-2.62,3.93H114v1h-4v-.9l2.6-3.94H110v-1h4Z",
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("rect", {
              staticClass: "passport-too-small-cls-9",
              attrs: { x: "63", y: "156.97", width: "131.74", height: "0.73" },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-too-small-cls-10",
              attrs: {
                d:
                  "M191.31,179.85,66.8,179.64A3.78,3.78,0,0,1,63,175.86L63.12,93l131.65.38.13,82.85A3.59,3.59,0,0,1,191.31,179.85Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "passport-too-small-cls-11",
              attrs: {
                d:
                  "M66.71,5.62l124.51.21a3.14,3.14,0,0,1,3.29,3.78l.16,83.13H63.12V9.21A3.59,3.59,0,0,1,66.71,5.62Z",
              },
            }),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("rect", {
              staticClass: "passport-too-small-cls-2",
              attrs: { x: "69.51", y: "98.71", width: "38.43", height: "39" },
            }),
            _vm._v(" "),
            _c("g", [
              _c("path", {
                staticClass: "passport-too-small-cls-12",
                attrs: {
                  d:
                    "M85.37,123.49l-.79,5.15a2,2,0,0,1-1,1.49l-6.69,3.34,24,.19-4.93-2.78a3.49,3.49,0,0,1-1.7-2.39l-.91-4.82Z",
                },
              }),
              _vm._v(" "),
              _c("g", [
                _c("path", {
                  staticClass: "passport-too-small-cls-13",
                  attrs: {
                    d:
                      "M82.76,130.08s1.91,1.83,6.7,1.85,6.74-1.19,6.74-1.19l9,5.18,1.67,2L70.6,137.7l1.47-1.95Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "passport-too-small-cls-14",
                  attrs: {
                    d:
                      "M82.76,130.08s1.91,1.83,6.7,1.85,6.74-1.19,6.74-1.19l9,5.18,1.67,2L70.6,137.7l1.47-1.95Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "passport-too-small-cls-14",
                  attrs: {
                    d:
                      "M82.76,130.08s1.91,1.83,6.7,1.85,6.74-1.19,6.74-1.19l9,5.18,1.67,2L70.6,137.7l1.47-1.95Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "passport-too-small-cls-15",
                  attrs: {
                    d:
                      "M82.76,130.08s1.91,1.83,6.7,1.85,6.74-1.19,6.74-1.19l9,5.18,1.67,2L70.6,137.7l1.47-1.95Z",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("path", {
                staticClass: "passport-too-small-cls-16",
                attrs: {
                  d:
                    "M85.07,124.7a4.68,4.68,0,0,0,4.16,2.38,4.75,4.75,0,0,0,4.47-2.53Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "passport-too-small-cls-17",
                attrs: {
                  d:
                    "M88.6,107.29a.55.55,0,0,1,0-.19A.67.67,0,0,1,88.6,107.29Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "passport-too-small-cls-18",
                attrs: {
                  d:
                    "M96.13,117.77a11.84,11.84,0,0,1-.32,3.22,7.92,7.92,0,0,1-2,3.5,8.45,8.45,0,0,1-4.21,1.77h-.25c-.3,0-.62,0-.94,0-.15,0-.3,0-.44-.06h0a5.69,5.69,0,0,1-3.88-2.71,9.42,9.42,0,0,1-2-5.37v-4c0-1.39,0-1.66,0-3.33,0,0,3.09.64,7.35-1.64a3.52,3.52,0,0,0,2.24.94c2.29.08,2.79-1,2.8-1.18.14.59.27,4.85,1.35,5.42.8.51.33.15.33.26Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "passport-too-small-cls-19",
                attrs: { d: "M81.55,115.69s-1.43.06-1.4,1.22,2,2.2,2,2.2Z" },
              }),
              _vm._v(" "),
              _c("polyline", {
                staticClass: "passport-too-small-cls-20",
                attrs: { points: "89.01 116.01 88.15 120.73 88.89 121.32" },
              }),
              _vm._v(" "),
              _c("circle", {
                staticClass: "passport-too-small-cls-21",
                attrs: { cx: "85.6", cy: "116.66", r: "0.65" },
              }),
              _vm._v(" "),
              _c("circle", {
                staticClass: "passport-too-small-cls-21",
                attrs: { cx: "92.24", cy: "116.66", r: "0.65" },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "passport-too-small-cls-3",
                attrs: {
                  d:
                    "M88.27,123.19a10,10,0,0,0,2.82-.54,1.71,1.71,0,0,1-1.41.83A1.84,1.84,0,0,1,88.27,123.19Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "passport-too-small-cls-22",
                attrs: {
                  d:
                    "M88.6,107.29a.55.55,0,0,1,0-.19A.67.67,0,0,1,88.6,107.29Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "passport-too-small-cls-23",
                attrs: {
                  d: "M96.15,115.3s1.42-.07,1.49,1.09S96.05,119,96.05,119Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "passport-too-small-cls-24",
                attrs: {
                  d:
                    "M82.08,118.79l.25-1.3c-.1-3.07.54-4.12,1.22-4.46a4.17,4.17,0,0,0,1.41-.7,2.71,2.71,0,0,0,.77-1.68,5.85,5.85,0,0,0,2.17,1.89c3,1.44,6.23-.54,7.1.74.51.74-.33,1.81.51,3.26a3.18,3.18,0,0,0,.64.81,10.29,10.29,0,0,0,2.32-7,9.81,9.81,0,0,0-1.33-4.24,9.54,9.54,0,0,0-2.27-2.5c-.49-.38-4.17-3.16-7.44-2a9.82,9.82,0,0,0-3.16,1.89,6.7,6.7,0,0,1-1,.83,9.39,9.39,0,0,1-2,.81,4.84,4.84,0,0,0-2.36,2.72,5.67,5.67,0,0,0-.21,3.09A19,19,0,0,0,82.08,118.79Z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("rect", {
              staticClass: "passport-too-small-cls-25",
              attrs: { x: "69.51", y: "98.71", width: "38.43", height: "39" },
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }