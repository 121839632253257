<template>
  <div
    id="app"
    class="app"
    :class="isMobileDevice ? 'mobile-app' : 'desktop-app'"
  >
    <link rel="stylesheet" type="text/css" :href="defaultStyles" />
    <div v-if="isBrowserSupported" class="app-content">
      <link
        v-if="cssSource"
        rel="stylesheet"
        type="text/css"
        :href="cssSource"
      />
      <script v-if="jsSource" type="application/javascript" :src="jsSource" />
      <router-view :is-shell-hidden="isShellHidden" />
    </div>
    <not-supported v-else-if="isBrowserDefined" class="app-content" />
    <pop-up @change="popUpChange" />
    <div :class="{ 'portrait-only': portraitOnly }" />
  </div>
</template>

<script>
import NotSupported from '@src/components/partials/NotSupported'
import getters from '@src/store/getters'
import { mapGetters } from 'vuex'

export default {
  components: {
    NotSupported
  },

  data() {
    return {
      supportedLanguages: [
        'en', // English
        'lt', // Lithuanian
        'ru', // Russian
        'ro', // Romanian
        'bg', // Bulgarian
        'lv', // Latvian
        'es', // Spanish
        'ca', // Catalan
        'de', // German
        'uk', // Ukrainian
        'fr', // French
        'sq', // Albanian
        'sv', // Swedish
        'et', // Estonian
        'pl', // Polish
        'ro', // Romanian
        'pb', // Portuguese (Brazil)
        'cs', // Czech
        'vi', // Vietnamese
        'nl', // Netherlands
        'fi' // Finnish
      ],
      defaultStyles: `${BaseUrl.resources}/customs/ondato/styles.css`,
      isShellHidden: false
    }
  },

  computed: {
    ...mapGetters([
      getters.isBrowserSupported,
      getters.isBrowserDefined,
      getters.cssSource,
      getters.jsSource,
      getters.faviconSource,
      getters.translationSource,
      getters.isZoomRequired,
      getters.isMobileDevice
    ]),

    session() {
      return this.$store.state && this.$store.state.session
    },

    portraitOnly() {
      const { matched } = this.$route
      if (!matched) return false

      const { portraitOnly } = matched[matched.length - 1].props.default || {}
      return portraitOnly || false
    },

    language() {
      const { supportedLanguages } = this
      let language = this.$route.params && this.$route.params.language
      if (!language) return supportedLanguages[0]

      language = language.toLowerCase()
      if (!supportedLanguages.includes(language)) return supportedLanguages[0]

      return language
    }
  },

  watch: {
    session({ pageTitle }) {
      this.setPageTitle(pageTitle)
    },

    faviconSource(value) {
      if (!value) return
      this.setFavicon(value)
    },

    translationSource(value) {
      if (!value) return
      this.$loadCustomTranslations(value, this.language)
    },

    isZoomRequired(value) {
      if (!value) return
      this.$loadZoomTranslations(this.language)
    }
  },

  created() {
    this.$changeLang(this.language)
  },

  methods: {
    setPageTitle(title) {
      document.title = title || DefaultTitle
    },

    setFavicon(source) {
      if (!source) return

      const link = document.createElement('link')
      link.type = 'image/ico'
      link.rel = 'shortcut icon'
      link.href = source
      document.head.appendChild(link)
    },

    popUpChange(type) {
      this.isShellHidden = !!type
    }
  }
}
</script>

<style scoped>
.app {
  background-color: var(--page-color);
}

.app,
.app-content {
  height: 100%;
}
</style>
