var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showInstructions
    ? _c("face-instructions", { on: { "go-next": _vm.hideInstructions } })
    : _vm.isLivenessEnabled
    ? _c("liveness-check")
    : _c("passive-liveness-check")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }