import Twilio from 'twilio-video'
import { activityLogger } from '@src/scripts/activityLogger'
import Api, { call } from '@src/scripts/api'
import { errorTypes } from '@src/scripts/enums'

export default function ({ browserData, onError }) {
  const data = {
    isActive: true,
    stream: null,
    track: null,
    room: null
  }

  const initRecord = async () => {
    const configs = await getRecordConfigs()
    if (!configs) {
      return handleError()
    }

    const isConnected = await connect(configs)
    if (!isConnected) {
      return handleError()
    }

    if (await startCamera()) {
      activityLogger.logActivity('SCREEN_RECORD_STARTED')
    }
  }

  const startCamera = async () => {
    let settings = {
      video: {
        width: { min: 640, ideal: 1280, max: 1920 },
        height: { min: 480, ideal: 720, max: 1080 }
      }
    }

    try {
      if (browserData.isMobileDevice) {
        settings.video.facingMode = { exact: 'user' }
      } else {
        // const devices = await navigator.mediaDevices.enumerateDevices()
        // if (!devices?.length) throw 'No devices'
        // const defaultCamera = devices.find((item) => item.kind === 'videoinput')
        //   ?.deviceId
      }

      const stream = await navigator.mediaDevices.getUserMedia(settings)
      handleStream(stream)
      return true
    } catch (error) {
      console.error(error)
      handleError()
      return false
    }
  }

  const handleStream = (cameraStream) => {
    if (!cameraStream) {
      if (!data.stream?.active) {
        startCamera()
        return true
      }
      return false
    }
    if (!cameraStream.active) {
      if (!data.stream?.active) {
        startCamera()
        return true
      }

      return false
    }
    if (data.stream === cameraStream) {
      return false
    }

    data.track?.stop()

    data.stream = cameraStream
    data.track = data.stream.getVideoTracks()[0]

    if (!data.isActive) {
      data.track?.stop()
      return true
    }

    publishMedia()
    checkStreamStatus()
    return true
  }

  const checkStreamStatus = () => {
    setTimeout(() => {
      const videoList = document.getElementsByTagName('video')

      for (const item of videoList) {
        if (handleStream(item.srcObject)) {
          return
        }
      }

      checkStreamStatus()
    }, 1000)
  }

  const connect = async (configs) => {
    const conectionSettings = {
      name: configs.room,
      audio: false,
      video: false
    }

    try {
      data.room = await Twilio.connect(configs.accessToken, conectionSettings)
      data.room.on('disconnected', onDisconnect)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const onDisconnect = (room) => {
    room.localParticipant.tracks.forEach((publication) => {
      const attachedElements = publication.track.detach()
      attachedElements.forEach((element) => element.remove())
    })
  }

  const publishMedia = async () => {
    const { track, room } = data
    const { localParticipant } = room

    try {
      const previousTracks = localParticipant.tracks.values()
      for (const mediaTrack of previousTracks) {
        localParticipant.unpublishTracks([mediaTrack.track])
        mediaTrack.track.stop()
      }

      const localTracks = await Twilio.createLocalTracks({
        tracks: [track]
      })

      for (const mediaTrack of localTracks) {
        localParticipant.publishTrack(mediaTrack)
      }
    } catch (error) {
      console.error(error)
      handleError()
    }
  }

  const getRecordConfigs = async () => {
    try {
      const { data } = await call(Api.recordStart)
      if (data?.accessToken) {
        return data
      }

      return undefined
    } catch (error) {
      return undefined
    }
  }

  const stopRecord = () => {
    data.isActive = false

    if (data.track) {
      data.track.stop()
      data.track = null
    }

    if (data.room) {
      data.isCanceled = true
      data.room.disconnect()
      data.room = null
    }

    data.stream = null
  }

  const stop = () => {
    activityLogger.logActivity('SCREEN_RECORD_ENDED')

    stopRecord()
  }

  const handleError = () => {
    activityLogger.logActivity('SCREEN_RECORD_ERROR')
    onError?.(errorTypes.screenRecord)

    stopRecord()
  }

  initRecord()

  return {
    stop
  }
}
