var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", staticClass: "id-check-loader container" },
    [
      !_vm.isIdentityCheckFailed && !_vm.isSuccess
        ? _c("div", { staticClass: "percent" }, [
            _c("div", { staticClass: "number", attrs: { id: "percentage" } }, [
              _vm._v(_vm._s(_vm.percent)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "sign" }, [_vm._v("%")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isIdentityCheckFailed && !_vm.isSuccess
        ? _c("div", { staticClass: "message" }, [
            _vm._v("\n    " + _vm._s(_vm.message) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "content", attrs: { id: "content" } }, [
        _c("canvas", { ref: "canvas", staticClass: "canvas" }),
        _vm._v(" "),
        _c("video", {
          ref: "video",
          staticClass: "video",
          attrs: { autoplay: "", muted: "", playsinline: "" },
          domProps: { muted: true },
        }),
        _vm._v(" "),
        _c("video", {
          ref: "success",
          staticClass: "video",
          attrs: { muted: "", playsinline: "" },
          domProps: { muted: true },
        }),
        _vm._v(" "),
        _c("video", {
          ref: "error",
          staticClass: "video",
          attrs: { muted: "", playsinline: "" },
          domProps: { muted: true },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }