var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ui-button", {
    attrs: { additional: "", label: _vm.buttonLabel },
    on: { click: _vm.handleCopy },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }