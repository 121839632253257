var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-frame",
    { attrs: { logo: "" } },
    [
      _c("i", { staticClass: "icon" }, [_c("smart-id")], 1),
      _vm._v(" "),
      _c("h1", { staticClass: "header" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("SignatureAuthentication.SmartId.Header")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "description" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("SignatureAuthentication.SmartId.Description")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "ui-form",
        { on: { submit: _vm.submit } },
        [
          _vm.options
            ? _c("ui-drop-down", {
                attrs: {
                  label: "SignatureAuthentication.SmartId.Country",
                  options: _vm.options,
                  value: _vm.country,
                },
                on: { input: _vm.countryChanged },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("ui-input", {
            attrs: {
              label: _vm.$t("SignatureAuthentication.SmartId.PersonCode"),
              value: _vm.personCode,
              error: _vm.personCodeErrorMessage,
              "max-length": 14,
              disabled: _vm.isLoading,
            },
            on: { input: _vm.personCodeChange },
          }),
          _vm._v(" "),
          _c("ui-button", {
            attrs: { label: _vm.$t("App.Continue"), disabled: _vm.isLoading },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }