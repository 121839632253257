<template>
  <ui-frame v-if="settings" logo>
    <i class="icon">
      <component :is="settings.icon" />
    </i>
    <h1 class="header">
      {{ $t('SignatureAuthentication.Wait.Header') }}
    </h1>
    <p class="description">
      {{ $t('SignatureAuthentication.Wait.Description') }}
    </p>
    <span class="verification-code">{{ code }}</span>
    <ui-loader :size="2.5" />
  </ui-frame>
</template>

<script>
import SmartId from '@src/components/images/SmartId'
import MobileId from '@src/components/images/MobileId'
import { ruleTypes } from '@src/scripts/enums'
import Api, { get } from '@src/scripts/api'

export default {
  props: {
    type: { type: String, default: null },
    code: { type: String, default: null }
  },

  computed: {
    settings() {
      const { type } = this

      if (type === ruleTypes.smartId) {
        return {
          icon: SmartId,
          method: Api.signatureAuthenticationSmartId,
          error: 'Failed'
        }
      } else if (type === ruleTypes.mobileId) {
        return {
          icon: MobileId,
          method: Api.signatureAuthenticationMobileId,
          error: 'MobileIdSignFailed'
        }
      }

      return null
    }
  },

  async created() {
    const { settings } = this
    if (!settings) return

    try {
      const { error } = await get(settings.method)
      if (!error) {
        this.$emit('complete')
        return
      }
    } catch (error) {
      //empty
    }

    this.$popUp(settings.error)
    this.$emit('error')
  }
}
</script>

<style scoped>
.icon {
  width: 6rem;
  display: block;
}

.header {
  margin-bottom: 1.25rem;
  text-align: center;
  color: var(--header-contrast-color);
}

.description {
  text-align: center;
  max-width: 30rem;
}

.verification-code {
  font-size: 3rem;
  margin-bottom: 1.5rem;
}

.description,
.verification-code {
  color: var(--text-contrast-color);
}

@media screen and (min-width: 38.0625rem) {
  .header,
  .description {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}
</style>
