// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
h1[data-v-6d6489b7] {
  color: var(--header-contrast-color);
}
p[data-v-6d6489b7] {
  color: var(--text-contrast-color);
}
h1[data-v-6d6489b7],
p[data-v-6d6489b7] {
  text-align: center;
  margin-bottom: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/OmnichannelCompleted.vue"],"names":[],"mappings":";AA6CA;EACA,mCAAA;AACA;AAEA;EACA,iCAAA;AACA;AAEA;;EAEA,kBAAA;EACA,mBAAA;AACA","sourcesContent":["<template>\n  <ui-frame>\n    <div>\n      <h1>{{ $t('Omnichannel.Status.Title') }}</h1>\n      <p>{{ $t('Omnichannel.Status.Description') }}</p>\n    </div>\n  </ui-frame>\n</template>\n\n<script>\nimport { activityLogger } from '@src/scripts/activityLogger'\nimport { mapGetters, mapMutations } from 'vuex'\nimport mutations from '@src/store/mutations'\nimport { OMNICHANNEL_STATUSES } from '@src/scripts/constants'\nimport getters from '@src/store/getters'\n\nexport default {\n  computed: {\n    ...mapGetters([getters.isOmnichannelCompleted])\n  },\n\n  created() {\n    if (this.setCompleteStatus()) {\n      activityLogger.logActivity('CONTINUED_SESSION_COMPLETED')\n    } else {\n      activityLogger.logActivity('CONTINUED_SESSION_COMPLETE_ERROR')\n    }\n  },\n\n  methods: {\n    ...mapMutations([mutations.updateOmnichannelStatus]),\n\n    setCompleteStatus() {\n      if (!this.isOmnichannelCompleted) {\n        this.updateOmnichannelStatus(OMNICHANNEL_STATUSES.COMPLETED)\n        return true\n      } else {\n        return false\n      }\n    }\n  }\n}\n</script>\n\n<style scoped>\nh1 {\n  color: var(--header-contrast-color);\n}\n\np {\n  color: var(--text-contrast-color);\n}\n\nh1,\np {\n  text-align: center;\n  margin-bottom: 2rem;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
