var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.hideControls,
          expression: "hideControls",
        },
      ],
      staticClass: "ui-controls",
    },
    [
      _vm.hasActiveControls
        ? _c(
            "div",
            {
              staticClass: "controls-group active-controls",
              class: { "controls-hidden": !_vm.activeControls },
            },
            [_vm._t("active")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasMainControls
        ? _c(
            "div",
            {
              staticClass: "controls-group main-controls",
              class: { "controls-hidden": _vm.activeControls },
            },
            [_vm._t("main")],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }