<template>
  <ui-button additional :label="buttonLabel" @click="handleCopy" />
</template>
<script>
import { copyTextToClipboard, delay } from '@src/scripts/helpers'
import { copyLinkLabelKey, linkCopiedLabelKey } from './CopyButton.constants'

export default {
  props: {
    url: { type: String, default: undefined }
  },

  data() {
    return {
      buttonLabel: this.$t(copyLinkLabelKey)
    }
  },

  methods: {
    async handleCopy() {
      this.buttonLabel = `${this.$t(linkCopiedLabelKey)} ✓`
      await copyTextToClipboard(this.url)
      await delay(1500)
      this.buttonLabel = this.$t(copyLinkLabelKey)
    }
  }
}
</script>
