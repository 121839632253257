var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", { staticClass: "pop-up-description" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.$t("Error.Description." + _vm.type, _vm.options)) +
          "\n  "
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "button-group" },
      [
        _c("ui-button", {
          attrs: { label: _vm.buttonLabel },
          on: {
            click: function ($event) {
              return _vm.$emit("click")
            },
          },
        }),
        _vm._v(" "),
        _vm.isOmnichannelAvailable
          ? _c("omnichannel-controls", {
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }