var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.source
    ? _c(
        "div",
        { staticClass: "commercial-player", class: { mute: _vm.isMuted } },
        [
          _c("video", {
            attrs: { src: _vm.source, autoplay: "", loop: _vm.loop },
            domProps: { muted: _vm.isMuted },
          }),
          _vm._v(" "),
          _c("button", {
            staticClass: "commercial-mute",
            on: { click: _vm.changeVolume },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }