// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
path[data-v-0d21f41c] {
  stroke: var(--text-contrast-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/images/CloseIcon.vue"],"names":[],"mappings":";AAsBA;EACA,kCAAA;AACA","sourcesContent":["<template>\n  <svg\n    width=\"32\"\n    height=\"32\"\n    viewBox=\"0 0 32 32\"\n    fill=\"none\"\n    xmlns=\"http://www.w3.org/2000/svg\"\n  >\n    <path\n      d=\"M5.59998 5.59998L27.2 27.2\"\n      stroke=\"#2B2B2B\"\n      stroke-linecap=\"round\"\n    />\n    <path\n      d=\"M5.59998 27.2L27.2 5.59995\"\n      stroke=\"#2B2B2B\"\n      stroke-linecap=\"round\"\n    />\n  </svg>\n</template>\n\n<style scoped>\npath {\n  stroke: var(--text-contrast-color);\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
