<template>
  <div>
    <p class="pop-up-description">
      {{ $t(`Error.Description.${type}`, options) }}
    </p>
    <div class="button-group">
      <ui-button :label="buttonLabel" @click="$emit('click')" />
      <omnichannel-controls
        v-if="isOmnichannelAvailable"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import OmnichannelControls from '@src/components/partials/OmnichannelControls'
import { errorTypes } from '@src/scripts/enums'

export default {
  components: {
    OmnichannelControls
  },

  props: {
    buttonLabel: { type: String, default: undefined, required: true },
    type: { type: String, default: undefined, required: true },
    options: { type: Object, default: undefined }
  },

  data() {
    return {
      errorTypes
    }
  },

  computed: {
    isOmnichannelAvailable() {
      const { type } = this

      return [errorTypes.noCamera, errorTypes.screenRecord].includes(type)
    }
  }
}
</script>

<style scoped>
.pop-up-description {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}

.button-group {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ui-button {
  margin: 1rem;
}
</style>
