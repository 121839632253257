// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.container[data-v-a04906ac] {
  display: flex;
  text-align: center;
  flex-direction: column;
}
h1[data-v-a04906ac] {
  margin-bottom: 2rem;
  color: var(--header-contrast-color);
}
p[data-v-a04906ac] {
  color: var(--text-contrast-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/NoSession.vue"],"names":[],"mappings":";AAYA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;AACA;AAEA;EACA,mBAAA;EACA,mCAAA;AACA;AAEA;EACA,iCAAA;AACA","sourcesContent":["<template>\n  <ui-frame>\n    <div class=\"container\">\n      <h1>{{ $t('Error.Header.Unauthorized') }}</h1>\n      <p>{{ $t('Error.Description.Unauthorized') }}</p>\n    </div>\n  </ui-frame>\n</template>\n\n<script></script>\n\n<style scoped>\n.container {\n  display: flex;\n  text-align: center;\n  flex-direction: column;\n}\n\nh1 {\n  margin-bottom: 2rem;\n  color: var(--header-contrast-color);\n}\n\np {\n  color: var(--text-contrast-color);\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
