var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 1000 1000",
        "xml:space": "preserve",
      },
    },
    [
      _c("g", [
        _c(
          "g",
          {
            attrs: {
              transform:
                "translate(0.000000,511.000000) scale(0.100000,-0.100000)",
            },
          },
          [
            _c("path", {
              attrs: {
                d:
                  "M3716.5,3832.1c-149.2-40.2-279.4-105.2-401.8-197.1c-143.5-109.1-181.8-162.6-325.3-449.7L2865,2934.6l-928-9.6c-1023.7-11.5-975.9-5.7-1234.2-139.7c-170.3-89.9-386.5-313.8-474.5-493.7C96.2,2021.9,100,2127.1,100-358.5c0-2168,1.9-2244.5,38.3-2388c109.1-428.6,424.8-744.3,853.4-853.4c145.4-38.3,227.7-38.3,4006.9-38.3c3779.2,0,3861.4,0,4006.9,38.3c440.1,111,771.1,455.4,863,889.8c42.1,199,42.1,4527.3,0,4724.4c-82.3,392.3-407.6,744.4-803.7,874.5c-57.4,19.1-315.7,28.7-1004.6,36.4l-928,9.6L7005.8,3191c-137.8,279.4-218.1,380.8-405.7,501.3c-61.2,40.2-164.6,91.8-229.6,116.7l-118.6,44l-1215.1,3.8C4009.3,3860.8,3806.5,3856.9,3716.5,3832.1z M6213.6,3204.4c53.6-24.9,111-61.2,128.2-80.4c19.1-21.1,101.4-179.9,183.7-352.1c162.6-336.8,214.3-403.8,338.7-438.2c49.7-13.4,401.8-21,1025.6-21c1050.5,0,1054.3,0,1197.9-122.5c40.2-36.4,99.5-111,130.1-166.5l57.4-103.3l5.7-2227.3c5.7-2560.3,17.2-2363.2-166.5-2548.8c-187.5-187.5,216.2-170.3-4115.9-170.3c-4332.2,0-3928.4-17.2-4115.9,170.3C698.9-2670,710.4-2867.1,716.2-306.8l5.7,2227.3l57.4,103.3c30.6,55.5,89.9,130.1,130.1,166.5c143.5,122.5,149.3,122.5,1188.3,122.5c576,0,972.1,7.7,1025.6,21c47.8,11.5,111,42.1,139.7,70.8c30.6,28.7,122.5,193.3,206.7,365.5c84.2,174.1,166.5,332.9,185.6,352.1c17.2,21,76.5,57.4,128.2,82.3l95.7,45.9h1119.4H6118L6213.6,3204.4z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M4594.8,1977.9c-304.2-57.4-633.4-200.9-872.6-380.8c-145.4-109.1-398-373.1-487.9-510.9c-112.9-170.3-220.1-399.9-273.6-581.7c-57.4-199-59.3-243-9.6-348.3C3016.2,12.7,3175-50.4,3326.2,7c109.1,40.2,168.4,120.5,208.6,275.5c139.7,545.3,585.5,966.3,1144.3,1086.9c204.8,42.1,532,24.9,731-40.2c185.6-59.3,392.3-172.2,507.1-275.5l86.1-78.5l-204.7-206.7l-206.7-206.7l851.5-227.7c468.8-126.3,855.3-223.9,859.2-220c3.8,3.8-93.8,386.5-218.1,849.6s-227.7,845.8-227.7,851.5c-1.9,3.8-95.7-84.2-208.6-197.1l-206.7-204.7l-112.9,97.6c-239.2,202.8-535.8,354-851.5,438.2C5264.5,2004.7,4811,2020,4594.8,1977.9z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M6625-427.4c-89.9-63.1-130.1-124.4-162.6-254.5c-70.8-277.5-266-587.5-484.1-767.3c-281.3-231.5-579.8-336.8-951-338.7c-266,0-442,38.3-652.5,145.4c-128.2,65.1-371.2,233.4-371.2,258.3c0,7.7,91.8,105.2,204.7,218.1l202.8,202.8l-84.2,23c-832.4,225.8-1634.1,436.3-1638,432.4c-3.8-3.8,409.5-1565.2,445.8-1683.9c9.6-28.7,47.8,0,218.1,170.3l204.8,204.8l170.3-135.9c905.1-727.1,2254.1-558.8,2962.1,367.4c290.8,382.7,461.1,903.2,344.4,1060.1c-67,91.8-156.9,139.7-256.4,139.7C6716.9-385.3,6669.1-398.7,6625-427.4z",
              },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }