// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.commercial-player[data-v-eba9fdde] {
  width: 100%;
  position: relative;
}
.commercial-player video[data-v-eba9fdde] {
  width: 100%;
}
.commercial-player + h3[data-v-eba9fdde] {
  margin-top: 1rem;
}
.commercial-mute[data-v-eba9fdde] {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url('/public/images/volume.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-color: var(--white);
  border: none;
  border-radius: 0.25rem;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}
.commercial-mute[data-v-eba9fdde]:hover {
  cursor: pointer;
}
.commercial-mute[data-v-eba9fdde]:focus {
  outline: none;
}
.mute .commercial-mute[data-v-eba9fdde] {
  background-position-x: 100%;
}
.commercial-player:hover .commercial-mute[data-v-eba9fdde] {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/CommercialPlayer.vue"],"names":[],"mappings":";AA6BA;EACA,WAAA;EACA,kBAAA;AACA;AAEA;EACA,WAAA;AACA;AAEA;EACA,gBAAA;AACA;AAEA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,cAAA;EACA,kDAAA;EACA,0BAAA;EACA,4BAAA;EACA,8BAAA;EACA,YAAA;EACA,sBAAA;EACA,UAAA;EACA,qCAAA;AACA;AAEA;EACA,eAAA;AACA;AAEA;EACA,aAAA;AACA;AAEA;EACA,2BAAA;AACA;AAEA;EACA,UAAA;AACA","sourcesContent":["<template>\n  <div v-if=\"source\" class=\"commercial-player\" :class=\"{ mute: isMuted }\">\n    <video :src=\"source\" autoplay :loop=\"loop\" :muted=\"isMuted\" />\n    <button class=\"commercial-mute\" @click=\"changeVolume\" />\n  </div>\n</template>\n\n<script>\nexport default {\n  props: {\n    source: { type: String, default: undefined },\n    loop: Boolean\n  },\n\n  data() {\n    return {\n      isMuted: false\n    }\n  },\n\n  methods: {\n    changeVolume() {\n      this.isMuted = !this.isMuted\n    }\n  }\n}\n</script>\n\n<style scoped>\n.commercial-player {\n  width: 100%;\n  position: relative;\n}\n\n.commercial-player video {\n  width: 100%;\n}\n\n.commercial-player + h3 {\n  margin-top: 1rem;\n}\n\n.commercial-mute {\n  position: absolute;\n  bottom: 1rem;\n  right: 1rem;\n  width: 1.5rem;\n  height: 1.5rem;\n  background-image: url('/public/images/volume.png');\n  background-size: auto 100%;\n  background-repeat: no-repeat;\n  background-color: var(--white);\n  border: none;\n  border-radius: 0.25rem;\n  opacity: 0;\n  transition: opacity 0.25s ease-in-out;\n}\n\n.commercial-mute:hover {\n  cursor: pointer;\n}\n\n.commercial-mute:focus {\n  outline: none;\n}\n\n.mute .commercial-mute {\n  background-position-x: 100%;\n}\n\n.commercial-player:hover .commercial-mute {\n  opacity: 1;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
