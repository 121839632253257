<template>
  <div class="instructions-frame">
    <div class="container">
      <h1 v-if="header" class="instructions-header">
        {{ header }}
      </h1>
      <p v-if="description" class="instructions-description">
        <view-generator :data="description" />
      </p>
      <div class="suggestion">
        <component :is="mainItem" v-if="mainItem" class="main-item" />
        <div v-if="subItems && subItems.length" class="sub-item-list">
          <p v-if="subItemsHeader" class="sub-item-header">
            {{ subItemsHeader }}
          </p>
          <template v-for="(item, index) in subItems">
            <div :key="index" class="sub-item-image">
              <component :is="item.image" />
            </div>
            <p :key="item.label" class="sub-item-description">
              <img src="/public/images/close-icon.png" />
              <span class="sub-item-text">{{ item.label }}</span>
            </p>
          </template>
        </div>
      </div>
      <ui-button
        slot="main"
        :label="action"
        class="button-style"
        photo-icon
        @click="$emit('onaction')"
      />
      <slot v-if="hasChildren" />
    </div>
  </div>
</template>

<script>
import ViewGenerator from '@src/components/partials/ViewGenerator'

export default {
  components: {
    ViewGenerator
  },

  props: {
    header: { type: String, default: null },
    description: { type: [String, Array], default: null },
    mainItem: { type: Object, default: null },
    subItemsHeader: { type: String, default: null },
    subItems: { type: Array, default: null },
    action: { type: String, default: null }
  },

  computed: {
    hasChildren() {
      return !!this.$slots.default
    }
  }
}
</script>

<style scoped>
.instructions-frame {
  display: flex;
  height: 100%;
  width: 100%;
  color: var(--text-contrast-color);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  padding: 2rem;
  margin: auto;
  max-width: 29rem;
}

.instructions-header {
  color: var(--header-contrast-color);
}

.instructions-header,
.instructions-description,
.sub-item-header {
  text-align: center;
}

.instructions-description,
.sub-item-header {
  margin: 0;
}

.main-item {
  width: 100%;
  display: block;
}

.sub-item-header {
  grid-column: 1 / span 3;
}

.sub-item-image {
  display: block;
}

.sub-item-image > svg {
  border: 1px solid var(--dark);
}

.sub-item-description {
  text-align: center;
  line-height: 1.25;
}

.sub-item-description > img {
  height: 0.75rem;
}

.sub-item-description > span {
  vertical-align: text-bottom;
}

@media screen and (orientation: portrait) {
  .container {
    max-height: 100%;
  }

  .suggestion {
    display: contents;
  }

  .sub-item-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-gap: 0.5rem 1rem;
    width: 100%;
  }

  .sub-item-image {
    grid-row-start: 2;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: 100%;
  }

  .sub-item-description {
    grid-row-start: 3;
    margin: 0;
  }
}

@media screen and (orientation: landscape) {
  .instructions-frame {
    overflow-y: auto;
  }

  .suggestion {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 3rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .sub-item-header {
    white-space: nowrap;
    margin-bottom: 0.5rem;
  }

  .sub-item-list {
    display: flex;
    flex-direction: column;
  }

  .sub-item-image {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 3.5rem;
  }

  .sub-item-description {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }

  .sub-item-description:last-child {
    margin-bottom: 0;
  }
}
</style>
