import { replaceAll } from '@src/scripts/helpers'

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default {
  requiredValidator,
  minLengthValidator,
  phoneNumberValidator,
  emailValidator,
  minMaxValueValidator,
  integerValidator,
  floatValidator,
  validateForm
}

function requiredValidator(value) {
  if (!value || value.trim().length === 0) {
    return 'Required'
  } else {
    return null
  }
}

function minLengthValidator(value, minLength) {
  if (!minLength) minLength = 3
  value = (value && value.trim()) || ''
  if (value.length > 0 && value.length < minLength) {
    return 'MinLength'
  }
  return null
}

function phoneNumberValidator(phoneNumber) {
  if (!phoneNumber) return 'Required'

  let error = requiredValidator(phoneNumber.code)
  if (error) return 'CountryCode'

  error = requiredValidator(phoneNumber.number)
  if (error) return error

  error = integerValidator(phoneNumber.number)
  if (error) return error

  return null
}

function emailValidator(value) {
  if (value && !emailRegex.test(value.trim())) {
    return 'Email'
  } else {
    return null
  }
}

function integerValidator(value) {
  if (value) {
    value = value.trim()
    if (!/^[0-9-]*$/.test(value)) {
      return 'Integer'
    }
    const number = parseInt(value)
    if (!Number.isInteger(number)) {
      return 'Integer'
    }
  }

  return null
}

function floatValidator(value) {
  if (value) {
    value = value.trim()
    if (!/^[0-9,.-]*$/.test(value)) {
      return 'Float'
    }
    value = replaceAll(value, ',', '.')
    const number = parseFloat(value)
    if (Number.isNaN(number) || (value.match(/\./g) || []).length > 1) {
      return 'Float'
    }
  }

  return null
}

function minMaxValueValidator(value, minValue, maxValue) {
  const number = parseFloat(replaceAll(value, ',', '.'))
  if ((minValue || minValue === 0) && number < minValue) {
    return 'MinValue'
  }
  if (maxValue && number > maxValue) {
    return 'MaxValue'
  }
  return null
}

function validateForm(errors) {
  for (const key in errors) {
    if (errors[key]) {
      return false
    }
  }
  return true
}
