// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.document-card[data-v-72dbd1fa] {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/DocumentCard.vue"],"names":[],"mappings":";AAwCA;EACA,eAAA;AACA","sourcesContent":["<template>\n  <card class=\"document-card\" :label=\"title\" @click=\"routeTo\">\n    <slot />\n  </card>\n</template>\n\n<script>\nimport Card from '@src/components/partials/Card'\nimport { appPages } from '@src/scripts/enums'\n\nexport default {\n  components: {\n    Card\n  },\n\n  props: {\n    label: { type: String, default: undefined },\n    documentType: { type: String, default: undefined }\n  },\n\n  computed: {\n    title() {\n      const { label } = this\n      return label && this.$t(label)\n    }\n  },\n\n  methods: {\n    routeTo() {\n      const { documentType } = this\n      this.$router.replace({\n        name: appPages.documentInstructionsFront,\n        params: { ...this.$route.params, type: documentType }\n      })\n    }\n  }\n}\n</script>\n\n<style scoped>\n.document-card {\n  cursor: pointer;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
