// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
path[data-v-5d58d752] {
  stroke: var(--brand);
}
`, "",{"version":3,"sources":["webpack://./src/components/images/BackArrow.vue"],"names":[],"mappings":";AAgBA;EACA,oBAAA;AACA","sourcesContent":["<template>\n  <svg\n    width=\"13\"\n    height=\"12\"\n    viewBox=\"0 0 13 12\"\n    fill=\"none\"\n    xmlns=\"http://www.w3.org/2000/svg\"\n  >\n    <path\n      d=\"M12.9839 6.46154L1.75472 6.46154L6.56722 11.3077L5.95855 12L0.000220728 6L5.95855 -1.29317e-06L6.56722 0.692307L1.75472 5.53846L12.9839 5.53846L12.9839 6.46154Z\"\n      fill=\"#00A5EE\"\n    />\n  </svg>\n</template>\n\n<style scoped>\npath {\n  stroke: var(--brand);\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
