var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return typeof _vm.data === "object"
    ? _c(
        "span",
        _vm._l(_vm.data, function (item, index) {
          return _c(
            item.type,
            {
              key: index,
              tag: "component",
              class: item.class,
              attrs: {
                href: item.type === "a" && (item.href || item.value),
                target: item.type === "a" && "_blank",
              },
            },
            [_c("view-generator", { attrs: { data: item.value } })],
            1
          )
        }),
        1
      )
    : _c("span", [_vm._v("\n  " + _vm._s(_vm.data) + "\n")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }