import Store from '@src/store'
import { activityLogger } from '@src/scripts/activityLogger'
import Api, { call } from '@src/scripts/api'
import { i18n } from '@src/scripts/translationController'
import { cachedToken } from '@src/store/cache'
import { appPages } from '@src/scripts/enums'
import api from '@src/scripts/api'

export function redirectTo(url) {
  window.onbeforeunload = null
  window.location.href = url
}

export function reload() {
  window.onbeforeunload = null
  window.location.reload()
}

export function replaceNext(options) {
  options = options || {}
  const { route, url } = createRoute(this, options)
  if (route) {
    this.replace(route)
  } else if (url) {
    completeIdentification(url)
  }
}

export function pushNext(options) {
  options = options || {}
  const { route, url } = createRoute(this, options)
  if (route) {
    this.replace(route)
  } else if (url) {
    completeIdentification(url)
  }
}

export function allowedRoute() {
  const {
    session: { pages }
  } = Store.state
  const { name } = this.currentRoute
  if (!pages) return false
  return pages.includes(name)
}

function createRoute(router, { name, indexStep, params }) {
  const { name: currentName, params: currentParams } = router.currentRoute
  const {
    session: { pages, redirectUrl }
  } = Store.state
  if (!pages) return {}

  const { routeName, redirect } = nextRouteDetails(
    pages,
    name,
    indexStep,
    currentName
  )

  if (redirect) return { url: redirectUrl }
  if (routeName) {
    return {
      route: {
        name: routeName,
        params: {
          ...currentParams,
          ...params
        }
      }
    }
  }
  return {}
}

function nextRouteDetails(pages, nextName, indexStep, currentName) {
  const name = nextName || currentName
  let pageIndex = pages.indexOf(name)

  if (!nextName) {
    pageIndex += indexStep || 1
    if (pageIndex === pages.length) {
      return { redirect: true }
    }
  } else if (pageIndex === -1 && nextName === appPages.home) {
    pageIndex = 0
  }

  return { routeName: pages[pageIndex] }
}

async function completeIdentification(url) {
  const { data } = await call(Api.identificationComplete)
  if (data?.log) {
    await activityLogger.logActivity(data.log)
  }

  const redirectUrl = getRedirectUrl_HACK()
  if (redirectUrl) {
    url = redirectUrl
  }

  sessionStorage.clear()
  redirectTo(url)
}

function getRedirectUrl_HACK() {
  let { rules, pages } = Store.state.session

  if (
    rules?.some((item) => item.name === 'KybVerification') &&
    pages?.length === 1
  ) {
    const translationKey = 'App.RedirectUrl.Kyb'
    if (i18n.te(translationKey)) {
      return i18n.t(translationKey)
    }
  } else {
    const translationKey = 'App.RedirectUrl.Kyc'
    if (i18n.te(translationKey)) {
      return i18n.t(translationKey)
    }
  }

  return null
}

export async function redirectFailure() {
  const {
    session: { failureRedirectUrl }
  } = Store.state
  await call(api.abort)
  await cachedToken(undefined)
  redirectTo(failureRedirectUrl)
}

window.onbeforeunload = (event) => {
  const { isLoggedIn } = Store.getters
  if (!isLoggedIn || IsDevelopment) return

  event.preventDefault()
  event.returnValue = ''
  return ''
}
