<template>
  <ui-controls-frame>
    <ui-button
      v-if="!completed"
      slot="main"
      label="App.Start"
      :disabled="disabled"
      @click="start"
    />
    <template v-else slot="main">
      <ui-button
        label="App.TryAgain"
        :disabled="disabled"
        additional
        @click="reset"
      />
      <ui-button
        v-if="enableContinue && !error"
        label="App.Continue"
        :disabled="disabled"
        @click="continueIdentification"
      />
    </template>
    <ui-button
      v-if="activeControls"
      slot="active"
      label="App.Capture"
      :disabled="disabled"
      @click="capture"
    />
  </ui-controls-frame>
</template>

<script>
import getters from '@src/store/getters'
import actions from '@src/store/actions'
import { cameraStates } from '@src/scripts/enums'
import { mapGetters } from 'vuex'

export default {
  props: {
    resetCallback: { type: Function, default: undefined },
    activeControls: Boolean,
    completed: Boolean,
    enableContinue: Boolean,
    error: Boolean
  },

  computed: {
    ...mapGetters([getters.cameraState]),

    disabled() {
      const { cameraState } = this
      return (
        cameraState === cameraStates.pending ||
        cameraState === cameraStates.failed
      )
    }
  },

  created() {
    this.start()
  },

  methods: {
    start() {
      this.$store.dispatch(actions.updateCameraState, cameraStates.active)
      this.$emit('start')
    },

    capture() {
      this.$store.dispatch(actions.updateCameraState, cameraStates.loading)
    },

    async reset() {
      const { resetCallback, start } = this
      if (resetCallback && (await resetCallback())) return
      start()
    },

    continueIdentification() {
      this.$emit('continue')
    }
  }
}
</script>
