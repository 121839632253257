var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "ui-loader",
    class: { "loader-center": _vm.center },
    style: _vm.loaderSize,
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }