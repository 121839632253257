import { render, staticRenderFns } from "./DefaultDetails.vue?vue&type=template&id=4dde56b4&scoped=true"
import script from "./DefaultDetails.vue?vue&type=script&lang=js"
export * from "./DefaultDetails.vue?vue&type=script&lang=js"
import style0 from "./DefaultDetails.vue?vue&type=style&index=0&id=4dde56b4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dde56b4",
  null
  
)

export default component.exports