<template>
  <div v-show="hideControls" class="ui-controls">
    <div
      v-if="hasActiveControls"
      class="controls-group active-controls"
      :class="{ 'controls-hidden': !activeControls }"
    >
      <slot name="active" />
    </div>
    <div
      v-if="hasMainControls"
      class="controls-group main-controls"
      :class="{ 'controls-hidden': activeControls }"
    >
      <slot name="main" />
    </div>
  </div>
</template>

<script>
import getters from '@src/store/getters'
import { mapGetters } from 'vuex'

export default {
  name: 'ui-controls-frame',

  computed: {
    ...mapGetters([getters.isCameraActive, getters.isCameraLoading]),

    hasMainControls() {
      return !!this.$slots.main
    },

    hasActiveControls() {
      return !!this.$slots.active
    },

    activeControls() {
      const { hasActiveControls, isCameraActive } = this
      return hasActiveControls && isCameraActive
    },

    hideControls() {
      return !this.isCameraLoading
    }
  }
}
</script>

<style scoped>
.ui-controls {
  position: relative;
  width: 100%;
}

.controls-group {
  display: flex;
  flex-wrap: wrap;
}

.controls-group > * {
  margin-top: 1rem;
}

.active-controls > * {
  margin-left: auto;
  margin-right: auto;
}

.active-controls.controls-hidden,
.main-controls.controls-hidden {
  pointer-events: none;
  display: none;
}

.mobile-app .ui-button {
  width: 100%;
}

.mobile-app .controls-group {
  flex-direction: column-reverse;
}

@media screen and (max-width: 30em) {
  .desktop-app .controls-group {
    flex-direction: column-reverse;
  }

  .desktop-app .ui-button {
    width: 100%;
  }
}
</style>
