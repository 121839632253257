// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.photo-review[data-v-ed1d9684] {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/PhotoReview.vue"],"names":[],"mappings":";AA6BA;EACA,WAAA;AACA","sourcesContent":["<template>\n  <img class=\"photo-review\" :src=\"source\" />\n</template>\n\n<script>\nimport { blobToDataUrl } from '@src/scripts/helpers'\n\nexport default {\n  props: {\n    photo: { type: Blob, default: undefined },\n    thumbnail: { type: String, default: undefined }\n  },\n\n  data() {\n    return {\n      source: null\n    }\n  },\n\n  async created() {\n    const { photo, thumbnail } = this\n    if (!photo) return (this.source = null)\n\n    this.source = thumbnail || (await blobToDataUrl(photo))\n  }\n}\n</script>\n\n<style scoped>\n.photo-review {\n  width: 100%;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
