var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.settings
    ? _c(
        "ui-frame",
        { attrs: { logo: "" } },
        [
          _c(
            "i",
            { staticClass: "icon" },
            [_c(_vm.settings.icon, { tag: "component" })],
            1
          ),
          _vm._v(" "),
          _c("h1", { staticClass: "header" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("SignatureAuthentication.Wait.Header")) +
                "\n  "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "description" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("SignatureAuthentication.Wait.Description")) +
                "\n  "
            ),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "verification-code" }, [
            _vm._v(_vm._s(_vm.code)),
          ]),
          _vm._v(" "),
          _c("ui-loader", { attrs: { size: 2.5 } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }