var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 263.22 189.81",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "card-back-too-dark-linear-gradient",
                x1: "27.2",
                y1: "163.19",
                x2: "589.18",
                y2: "159.22",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#a6a6a6" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", { staticClass: "card-back-too-dark-cls-1" }, [
        _c("g", { attrs: { id: "Layer_39", "data-name": "Layer 39" } }, [
          _c("rect", {
            staticClass: "card-back-too-dark-cls-2",
            attrs: { width: "263.22", height: "189.81" },
          }),
        ]),
        _vm._v(" "),
        _c("g", { attrs: { id: "Card-too-dark" } }, [
          _c("g", [
            _c("g", [
              _c("rect", {
                staticClass: "card-back-too-dark-cls-3",
                attrs: {
                  x: "13.97",
                  y: "17.46",
                  width: "232.98",
                  height: "154.17",
                  rx: "10.87",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                staticClass: "card-back-too-dark-cls-4",
                attrs: {
                  x: "93.46",
                  y: "-10.9",
                  width: "6.07",
                  height: "116.85",
                  transform: "translate(48.81 143.93) rotate(-89.9)",
                },
              }),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "card-back-too-dark-cls-4",
                attrs: {
                  points:
                    "112.06 125.19 38.22 124.99 38.23 118.92 111.98 119.64 112.06 125.19",
                },
              }),
              _vm._v(" "),
              _c("g", [
                _c("rect", {
                  staticClass: "card-back-too-dark-cls-4",
                  attrs: {
                    x: "78.24",
                    y: "30.04",
                    width: "6.07",
                    height: "86.5",
                    transform: "matrix(0, -1, 1, 0, 7.85, 154.45)",
                  },
                }),
                _vm._v(" "),
                _c("rect", {
                  staticClass: "card-back-too-dark-cls-4",
                  attrs: {
                    x: "78.24",
                    y: "53.03",
                    width: "6.07",
                    height: "86.5",
                    transform: "matrix(0, -1, 1, 0, -15.14, 177.4)",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("path", {
                staticClass: "card-back-too-dark-cls-5",
                attrs: {
                  d:
                    "M247.07,153.33,247,165.54a6.11,6.11,0,0,1-6.12,6.1l-220.25-.37a7.41,7.41,0,0,1-6.79-5.41V153.33Z",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                staticClass: "card-back-too-dark-cls-6",
                attrs: {
                  x: "13.97",
                  y: "17.37",
                  width: "232.98",
                  height: "154.17",
                  rx: "10.87",
                },
              }),
            ]),
            _vm._v(" "),
            _c("rect", {
              staticClass: "card-back-too-dark-cls-7",
              attrs: { y: "-0.4", width: "263.22", height: "190.21" },
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }