var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "test-footer" }, [
    _c("div", { staticClass: "footer-container" }, [
      _c(
        "a",
        {
          on: {
            click: function ($event) {
              return _vm.navigate({ name: "VideoCall" })
            },
          },
        },
        [_c("span", [_vm._v("Video")])]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          on: {
            click: function ($event) {
              return _vm.navigateFace({ name: "Face" }, "active")
            },
          },
        },
        [_c("span", [_vm._v("Face Active")])]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          on: {
            click: function ($event) {
              return _vm.navigateFace({ name: "Face" }, "passive")
            },
          },
        },
        [_c("span", [_vm._v("Face Passive")])]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          on: {
            click: function ($event) {
              return _vm.navigate({ name: "DocumentSelect" })
            },
          },
        },
        [_c("span", [_vm._v("Document")])]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          on: {
            click: function ($event) {
              return _vm.navigate({
                name: "DocumentFront",
                params: { type: "id-card" },
              })
            },
          },
        },
        [_c("span", [_vm._v("ID Front")])]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          on: {
            click: function ($event) {
              return _vm.navigate({
                name: "DocumentBack",
                params: { type: "id-card" },
              })
            },
          },
        },
        [_c("span", [_vm._v("ID Back")])]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          on: {
            click: function ($event) {
              return _vm.navigate({ name: "IdentificationCheck" })
            },
          },
        },
        [_c("span", [_vm._v("Check")])]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          on: {
            click: function ($event) {
              return _vm.navigate({ name: "CompanyRepresentation" })
            },
          },
        },
        [_c("span", [_vm._v("Company")])]
      ),
      _vm._v(" "),
      _c("a", { on: { click: _vm.zoomEdit } }, [
        _c("span", [_vm._v("Zoom test")]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }