<template>
  <div class="instructions-container">
    <div
      :style="{
        backgroundImage: `url('/public/images/instructions/${resource}-1.png')`
      }"
    />
    <div
      :style="{
        backgroundImage: `url('/public/images/instructions/${resource}-2.png')`
      }"
    />
    <div
      :style="{
        backgroundImage: `url('/public/images/instructions/${resource}-3.png')`
      }"
    />
  </div>
</template>

<script>
export default {
  props: {
    resource: { type: String, default: undefined, required: true }
  }
}
</script>

<style scoped>
.instructions-container {
  display: flex;
}

.instructions-container > div {
  width: 33%;
  padding-top: 30%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.instructions-container > div:first-child {
  position: relative;
}

.instructions-container > div:first-child::before {
  content: '';
  position: absolute;
}
</style>
