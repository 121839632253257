<template>
  <ui-camera-frame
    v-if="!isFaceAuthenticated"
    class="liveness-check"
    :resource="resource"
    :error="!!error"
    :class="{ 'hide-logo': isCameraActive }"
  >
    <ui-camera-description
      slot="description"
      :resource="resource"
      :message="message"
      instructions
    />
    <ui-camera
      slot="camera"
      :resource="resource"
      :controls="zoomLogin"
      :capture-callback="captureComplete"
      face
    />
    <camera-controls
      slot="controls"
      :completed="isCompleted"
      :reset-callback="reset"
    />
  </ui-camera-frame>
  <ui-loader v-else :size="3" center />
</template>

<script>
import CameraControls from '@src/components/partials/CameraControls'
import ZoomLogin from '@src/scripts/zoomLoginController'
import { activityLogger } from '@src/scripts/activityLogger'
import getters from '@src/store/getters'
import Api from '@src/scripts/api'
import { mapGetters } from 'vuex'
import { appPages } from '@src/scripts/enums'

export default {
  components: {
    CameraControls
  },

  data() {
    return {
      isFaceAuthenticated: false,
      isCompleted: false,
      resource: 'Face',
      zoomLogin: null,
      error: null
    }
  },

  computed: {
    ...mapGetters([getters.isCameraActive, getters.isFailureUrlAvailable]),

    message() {
      const { error } = this
      return error || 'PreLoad'
    },

    isFaceAuthenticationActive() {
      return this.$route.name === appPages.faceAuthentication
    },

    checkMethod() {
      const { isFaceAuthenticationActive } = this
      if (isFaceAuthenticationActive) {
        return Api.validateFaceAuthentication
      } else {
        return Api.validateFace
      }
    }
  },

  created() {
    const {
      checkMethod,
      isFaceAuthenticationActive,
      isFailureUrlAvailable
    } = this

    activityLogger.logActivity('IDENTIFY_FACE_PAGE')
    this.zoomLogin = new ZoomLogin({
      checkMethod,
      isFaceAuthenticationActive,
      isFailureUrlAvailable
    })
  },

  methods: {
    captureComplete({ error }) {
      if (error) {
        this.error = error
      } else {
        this.$router.pushNext()
        if (this.isFaceAuthenticationActive) {
          this.isFaceAuthenticated = true
        }
      }

      this.isCompleted = true
    },

    reset() {
      this.error = null
      this.isCompleted = false
    }
  }
}
</script>

<style>
.liveness-check .page-logo {
  transition: opacity 0.25s ease-in-out;
}

.hide-logo .page-logo {
  opacity: 0;
}
</style>
