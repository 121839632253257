import mutations from '@src/store/mutations'
import { errorTypes, ruleTypes } from '@src/scripts/enums'
import { idvApi } from '@src/scripts/idvApi'
import { IDV_API_REQUESTS, STORE_KEYS } from '@src/scripts/constants'
import JwtDecode from 'jwt-decode'

export const methods = {
  updateBrowserData,
  updateToken,
  updateIdvToken,
  updateCameraState,
  acceptTerms,
  activateOmnichannel
}

export default new Proxy(methods, {
  get(target, key) {
    if (!target[key]) {
      throw `Action key ${key} not exist.`
    }
    return key
  }
})

function activateOmnichannel({ commit }) {
  commit(mutations.setPages)
}

function updateBrowserData({ commit }, value) {
  commit(mutations.changeBrowserData, value)
}

function updateToken({ commit, state }, value) {
  value = value || null
  if (!state.accessData || value !== state.accessData.token) {
    const accessData = {
      token: value
    }
    commit(mutations.changeToken, accessData)
  }

  if (!value && !state.sessionExists) {
    commit(mutations.updateSession)
  }
}

function updateCameraState({ commit, state: { camera } }, value) {
  if (!value || camera.state === value) return
  commit(mutations.changeCameraState, value)
}

function acceptTerms({ commit, state: { session } }) {
  if (!session || !session.rules || !session.rules.length) return

  const rules = session.rules.filter(
    (rule) => rule.name !== ruleTypes.termsAndConditions
  )
  commit(mutations.updateRules, rules)
}

async function updateIdvToken({ commit }, options) {
  try {
    let payload = {}
    const {
      identityVerificationId,
      accessCode,
      faceAuthenticationId,
      token
    } = options
    if (identityVerificationId && accessCode) {
      payload = {
        identityVerificationId,
        accessCode
      }
    } else if (faceAuthenticationId) {
      payload = { faceAuthenticationId }
    } else if (token) {
      const tokenData = JwtDecode(token)
      const identificationId = tokenData.sub
      payload = { identificationId }
    }

    const accessData = {}
    const sessionTokens = sessionStorage.getItem(STORE_KEYS.TOKENS) || null
    const tokens = JSON.parse(sessionTokens)
    const idvToken = tokens?.idvToken
    if (idvToken) {
      accessData.idvToken = idvToken
    } else {
      const { data, status, success } = await idvApi.post(
        IDV_API_REQUESTS.SESSIONS,
        payload,
        null,
        5
      )

      if (success) {
        accessData.idvToken =
          (data?.accessToken && `${data.tokenType} ${data.accessToken}`) || null
      }

      if (status >= 400 && data.reason === errorTypes.invalidIpAddress) {
        return {
          error: errorTypes.invalidIpAddress
        }
      }
    }
    idvApi.updateHeaders({ authorization: accessData.idvToken })
    commit(mutations.setIdvToken, accessData)
  } catch (error) {
    console.error('catch', error)
    // error
  }
}
