<template>
  <div v-if="hasPermissionsRedirect">
    <p class="pop-up-description">
      {{ $t('Error.Description.NoPermissionsRedirect') }}
    </p>
    <div class="button-group">
      <ui-button :label="buttonLabel" :disabled="isLoading" @click="redirect" />
      <omnichannel-controls @click="$emit('close')" />
    </div>
  </div>
  <div v-else class="permission-details">
    <div>
      <img :src="imageSource" />
    </div>
    <div>
      <div v-if="isMobile">
        <p v-if="browser.isSafari">
          <span>{{
            $t('Error.Description.NoPermissions.Mobile.SafariStep1')
          }}</span>
          <span>{{
            $t('Error.Description.NoPermissions.Mobile.SafariStep2')
          }}</span>
          <span>{{
            $t('Error.Description.NoPermissions.Mobile.SafariStep3')
          }}</span>
          <span>{{
            $t('Error.Description.NoPermissions.Mobile.SafariStep4')
          }}</span>
          <span>{{
            $t('Error.Description.NoPermissions.Mobile.SafariStep5')
          }}</span>
        </p>
        <p v-else>
          <span>{{
            $t('Error.Description.NoPermissions.Mobile.AndroidStep1', {
              browser: browser.name
            })
          }}</span>
          <span>{{
            $t('Error.Description.NoPermissions.Mobile.AndroidStep2')
          }}</span>
          <span>{{
            $t('Error.Description.NoPermissions.Mobile.AndroidStep3')
          }}</span>
          <span>{{
            $t('Error.Description.NoPermissions.Mobile.AndroidStep4', {
              browser: browser.name
            })
          }}</span>
          <span>{{
            $t('Error.Description.NoPermissions.Mobile.AndroidStep5')
          }}</span>
          <span>{{
            $t('Error.Description.NoPermissions.Mobile.AndroidStep6')
          }}</span>
        </p>
      </div>
      <div v-else>
        <p v-if="browser.isChrome">
          {{ $t('Error.Description.NoPermissions.Chrome') }}
        </p>
        <p v-else-if="browser.isFirefox">
          {{ $t('Error.Description.NoPermissions.Firefox') }}
        </p>
        <p v-else-if="browser.isEdge">
          {{ $t('Error.Description.NoPermissions.Edge') }}
        </p>
        <p v-else-if="browser.isSafari">
          <span>{{ $t('Error.Description.NoPermissions.SafariStep1') }}</span>
          <span>{{ $t('Error.Description.NoPermissions.SafariStep2') }}</span>
          <span>{{ $t('Error.Description.NoPermissions.SafariStep3') }}</span>
          <span>{{ $t('Error.Description.NoPermissions.SafariStep4') }}</span>
          <span>{{ $t('Error.Description.NoPermissions.SafariStep5') }}</span>
        </p>
      </div>
      <div class="pop-up-btn">
        <ui-button :label="buttonLabel" @click="$emit('click')" />
        <omnichannel-controls @click="$emit('close')" />
      </div>
    </div>
  </div>
</template>

<script>
import OmnichannelControls from '@src/components/partials/OmnichannelControls'
import Api, { call } from '@src/scripts/api'
import { cachedToken } from '@src/store/cache'

export default {
  components: {
    OmnichannelControls
  },

  props: {
    buttonLabel: { type: String, default: undefined, required: true }
  },

  data() {
    return {
      isLoading: false
    }
  },

  computed: {
    browserData() {
      return this.$store.state && this.$store.state.browserData
    },

    isMobile() {
      const { browserData } = this
      return browserData.isMobileDevice
    },

    browser() {
      const { browserData } = this
      return browserData.DetectRTC.browser
    },

    language() {
      return this.$language()
    },

    hasPermissionsRedirect() {
      const { session } = this.$store.state
      return !!(session && session.hasPermissionsRedirect)
    },

    imageSource() {
      const { isMobile, browser } = this
      if (isMobile) {
        if (browser.isChrome || browser.isSamsung || browser.isHuaweiBrowser)
          return '/public/images/mobile/chrome-tutorial.png'
        if (browser.isFirefox)
          return '/public/images/mobile/firefox-tutorial.png'
        if (browser.isEdge) return '/public/images/mobile/edge-tutorial.png'
        if (browser.isSafari) return '/public/images/mobile/safari-tutorial.png'
      } else {
        if (browser.isChrome)
          return '/public/images/desktop/chrome-tutorial.jpg'
        if (browser.isFirefox)
          return '/public/images/desktop/firefox-tutorial.png'
        if (browser.isEdge) return '/public/images/desktop/edge-tutorial.jpg'
        if (browser.isSafari)
          return '/public/images/desktop/safari-tutorial.jpg'
      }
      return null
    }
  },

  methods: {
    async redirect() {
      this.isLoading = true

      const { language } = this

      try {
        const { data } = await call(Api.permissionsRedirect, { language })
        if (data) {
          cachedToken(undefined)
          this.$router.redirectTo(data.redirectUrl)
        }
      } catch (error) {
        //error
      }

      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.permission-details {
  display: flex;
}

.permission-details > div {
  padding-left: 1rem;
  padding-right: 1rem;
}

.ui-button {
  margin: 1rem auto 0;
}

img {
  width: 100%;
}

p > span {
  display: block;
  margin-top: 0.5rem;
}

.mobile-app .permission-details {
  flex-direction: column;
}

.mobile-app .permission-details > div {
  margin-left: auto;
  margin-right: auto;
  max-width: 25rem;
}

.pop-up-btn {
  display: flex;
  flex-wrap: wrap;
}

.pop-up-description {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}

.button-group {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.button-group .ui-button {
  margin: 1rem;
}

@media screen and (max-width: 48rem) {
  .desktop-app .permission-details {
    flex-direction: column;
  }

  .desktop-app .permission-details > div {
    margin-left: auto;
    margin-right: auto;
    max-width: 25rem;
  }
}

@media screen and (min-width: 48.0625rem) {
  .desktop-app .permission-details > div {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }
}
</style>
