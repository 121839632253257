/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuid } from 'uuid';

import { MAX_API_DELAY, MAX_API_RETRY_COUNT, MIN_API_DELAY } from './constants';
import {
  ApiError,
  ApiMethod,
  CorelationIdOption,
  ResponseStatus,
} from './enums';
import {
  ApiRequest,
  CancellationToken,
  Delete,
  Get,
  HttpClient,
  HttpClientBody,
  HttpClientHeaders,
  HttpClientProps,
  HttpClientResponse,
  HttpClientState,
  Post,
  Put,
  Setup,
  UpdateHeaders,
} from './typings';
import { sleep } from './utils';

export * from './typings';
export * from './enums';

export const httpClient = (props: HttpClientProps): HttpClient => {
  const state: HttpClientState = {
    baseUrl: props.baseUrl || null,
    defaultHeaders: props.headers || {},
    options: {
      corelationId: CorelationIdOption.None,
      ...props.options,
    },
    unauthorizedCallback: props.unauthorizedCallback || null,
  };

  if (state.options.corelationId === CorelationIdOption.Singleton) {
    state.defaultHeaders['correlation-Id'] = uuid();
  }

  const getMethod: Get = <T, E>(
    path: string,
    headers?: HttpClientHeaders,
    cancellationToken?: CancellationToken,
    retryCount?: number,
  ): Promise<HttpClientResponse<T, E>> => {
    return apiRequestInit<T, E>({
      method: ApiMethod.Get,
      requestHeaders: headers,
      path,
      retryCount,
      cancellationToken,
    });
  };

  const postMethod: Post = <T, E>(
    path: string,
    body: any,
    headers?: HttpClientHeaders,
    cancellationToken?: CancellationToken,
    retryCount?: number,
  ): Promise<HttpClientResponse<T, E>> => {
    return apiRequestInit<T, E>({
      method: ApiMethod.Post,
      body: body ?? {},
      requestHeaders: headers,
      path,
      retryCount,
      cancellationToken,
    });
  };

  const putMethod: Put = <T, E>(
    path: string,
    body: any,
    headers?: HttpClientHeaders,
    cancellationToken?: CancellationToken,
    retryCount?: number,
  ): Promise<HttpClientResponse<T, E>> => {
    return apiRequestInit<T, E>({
      method: ApiMethod.Put,
      body: body ?? {},
      requestHeaders: headers,
      path,
      retryCount,
      cancellationToken,
    });
  };

  const deleteMethod: Delete = <T, E>(
    path: string,
    body: any,
    headers?: HttpClientHeaders,
    cancellationToken?: CancellationToken,
    retryCount?: number,
  ): Promise<HttpClientResponse<T, E>> => {
    return apiRequestInit<T, E>({
      method: ApiMethod.Delete,
      body: body ?? {},
      requestHeaders: headers,
      path,
      retryCount,
      cancellationToken,
    });
  };

  function getHeaders(requestHeaders?: HttpClientHeaders, body?: any): Headers {
    if (state.options.corelationId === CorelationIdOption.Unique) {
      state.defaultHeaders['correlation-Id'] = uuid();
    }

    const headers: any = {
      ...state.defaultHeaders,
      ...requestHeaders,
    };

    if (body != null && !(body instanceof FormData)) {
      headers['Content-Type'] = 'application/json';
    }

    return headers;
  }

  function getBody(body?: any): HttpClientBody {
    if (body != null && !(body instanceof FormData)) {
      return JSON.stringify(body);
    }

    return body;
  }

  function getInitialRetryCount(count?: number): number {
    if (count && count > MAX_API_RETRY_COUNT) {
      return MAX_API_RETRY_COUNT;
    }

    return count || 0;
  }

  function isBodyValid(body?: any): boolean {
    if (body != null) {
      if (!(body instanceof Object)) {
        return false;
      }
    }

    return true;
  }

  async function apiRequestInit<T, E>({
    method,
    path,
    body,
    cancellationToken,
    requestHeaders,
    retryCount,
  }: ApiRequest): Promise<HttpClientResponse<T, E>> {
    if (!isBodyValid(body)) {
      return {
        success: false,
        status: ResponseStatus.BadRequest,
        data: {} as E,
      };
    }

    const { signal } = cancellationToken ?? {};
    const fullUrl = (state.baseUrl && `${state.baseUrl}${path}`) || path;

    const request: RequestInit = {
      method,
      cache: 'no-cache',
      mode: 'cors',
      headers: getHeaders(requestHeaders, body),
      body: getBody(body),
      signal,
    };

    const setup: Setup = {
      url: fullUrl,
      request,
    };

    const leftRetryCount = getInitialRetryCount(retryCount);
    return apiRequest<T, E>(setup, MIN_API_DELAY, leftRetryCount);
  }

  async function apiRequest<T, E>(
    setup: Setup,
    delay: number,
    count: number,
  ): Promise<HttpClientResponse<T, E>> {
    const fetchResponse = await fetchRequest<T, E>(setup);
    const { success, status } = fetchResponse;

    if (
      success ||
      (status != null && status < ResponseStatus.InternalServerError)
    ) {
      return fetchResponse;
    }

    if (count < 1) {
      return fetchResponse;
    }

    let retryDelay = delay;
    await sleep(retryDelay);

    if (retryDelay < MAX_API_DELAY) {
      retryDelay *= 2;
      retryDelay = (retryDelay < MAX_API_DELAY && retryDelay) || MAX_API_DELAY;
    }

    const apiResponse = await apiRequest<T, E>(setup, retryDelay, count - 1);
    return apiResponse;
  }

  async function fetchRequest<T, E>({
    url,
    request,
  }: Setup): Promise<HttpClientResponse<T, E>> {
    try {
      const response = await fetch(url, request);
      return await responseHandler<T, E>(response);
    } catch (error: unknown) {
      return errorResponse<T, E>(error as Error);
    }
  }

  async function responseHandler<T, E>(
    response: Response,
  ): Promise<HttpClientResponse<T, E>> {
    const isSuccess = response.ok ?? false;
    let result: HttpClientResponse<T, E> | null = null;

    try {
      const responseBody = await getResponseBody(response);

      if (isSuccess) {
        result = {
          success: true,
          status: response.status,
          data: responseBody as T,
        };
      } else {
        result = {
          success: false,
          status: response.status,
          data: responseBody as E,
        };
      }
    } catch (error) {
      result = {
        success: false,
        status: response.status,
        data: {} as E,
      };
    }

    if (
      !isSuccess &&
      (response.status === ResponseStatus.Unauthorized ||
        response.status === ResponseStatus.Forbidden)
    ) {
      state.unauthorizedCallback?.(response.status);
    }

    return result;
  }

  function errorResponse<T, E>(error?: Error): HttpClientResponse<T, E> {
    if (error?.name === ApiError.AbortError) {
      return {
        success: false,
        status: ResponseStatus.OperationCancelled,
        data: {} as E,
      };
    }

    return {
      success: false,
      status: ResponseStatus.InternalServerError,
      data: {} as E,
    };
  }

  async function getResponseBody<T, E>(response: Response): Promise<T | E> {
    const contentType = response.headers.get('content-type');

    if (contentType) {
      if (contentType.includes('application/json')) {
        return (await response.json()) as T | E;
      }

      if (response.ok) {
        if (contentType.includes('text/')) {
          return {
            text: await response.text(),
          } as unknown as T;
        }

        return {
          blob: await response.blob(),
        } as unknown as T;
      }
    }

    return {} as Promise<T | E>;
  }

  const updateHeaders: UpdateHeaders = (headers: HttpClientHeaders): void => {
    if (!headers) {
      return;
    }

    Object.entries(headers).forEach(([key, value]) => {
      if (value == null) {
        delete state.defaultHeaders[key];
      } else {
        state.defaultHeaders[key] = value;
      }
    });
  };

  return {
    get: getMethod,
    post: postMethod,
    put: putMethod,
    delete: deleteMethod,
    updateHeaders,
  };
};

export function pathCreation(
  path: string,
  options: Record<string, string>,
): string {
  if (!options) {
    return path;
  }

  let resultPath = path;

  Object.entries(options).forEach(([key, value]) => {
    const placeholder = `{${key}}`;

    if (resultPath.indexOf(placeholder) === 0) {
      resultPath = resultPath.replace(placeholder, value);
    } else {
      const encodedValue = encodeURIComponent(value);
      resultPath = resultPath.replace(placeholder, encodedValue);
    }
  });

  return resultPath;
}
