import CameraController from '@src/scripts/cameraController'
import {
  mediaSourceTypes,
  errorTypes,
  orientationTypes
} from '@src/scripts/enums'
import {
  JPEG_QUALITY,
  JPEG_SHARPEN_VALUE,
  CANVAS_MASK_Y_POSITION_OFFSET
} from '@src/scripts/constants'
import {
  dataUrlToBlob,
  getCssVarValue,
  photoCrop,
  sharpen
} from '@src/scripts/helpers'

export default class {
  get mode() {
    return mediaSourceTypes.photo
  }

  callback = {
    captureCompleted: null
  }

  state = {
    hasSeparator: false,
    parentId: null,
    maskId: null,
    videoId: null,
    browserData: null,
    orientation: null,
    cameraController: null,
    changeAvailable: false,
    maskOptions: null
  }

  // public
  constructor({ orientation, hasSeparator }) {
    ;(this.state.orientation = orientation),
      (this.state.hasSeparator = hasSeparator)
  }

  async init(params) {
    this.validateRquired(params)
    try {
      await this.initCamera()
      this.initInterface()
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async changeCamera() {
    await this.state.cameraController.changeCamera()
  }

  capture() {
    try {
      const video = document.getElementById(this.state.videoId)

      const canvas = document.createElement('canvas')
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      const context = canvas.getContext('2d')
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)

      const image = canvas.toDataURL('image/jpeg', JPEG_QUALITY)

      const blob = dataUrlToBlob(image)
      const thumbnail = this.getThumbnail(video, canvas)

      if (this.callback.captureCompleted) this.callback.captureCompleted()
      if (!blob) return { error: 'Failed' }

      return {
        blob,
        thumbnail
      }
    } catch (error) {
      return { error: 'Failed' }
    }
  }

  cancel() {
    if (this.state.cameraController) {
      this.state.cameraController.stopCamera()
      this.state.cameraController = null
    }
  }

  // private
  validateRquired({ parentId, maskId, videoId, browserData }) {
    if (!parentId || !maskId || !videoId)
      throw { type: errorTypes.noRequiredElements }

    this.state.parentId = parentId
    this.state.maskId = maskId
    this.state.videoId = videoId
    this.state.browserData = browserData
  }

  async initCamera() {
    this.state.cameraController = new CameraController({
      browserData: this.state.browserData,
      parentId: this.state.parentId,
      videoId: this.state.videoId,
      backCameraRequired: true
    })

    await this.state.cameraController.initCamera()
    this.state.changeAvailable = this.state.cameraController.state.changeAvailable
  }

  initInterface() {
    const maskElement = document.getElementById(this.state.maskId)
    const parent = document.getElementById(this.state.parentId)

    maskElement.classList.add('camera-mask')

    if (
      !this.state.browserData.isMobileDevice ||
      this.state.orientation === orientationTypes.landscape
    ) {
      this.state.maskOptions = this.getHorizontalDimensions(parent)
    } else if (this.state.orientation === orientationTypes.portrait) {
      this.state.maskOptions = this.getVerticalDimensions(parent)
    }

    if (this.state.maskOptions) {
      this.positionWaterMark(this.state.maskOptions)
      const mask = this.createMask(this.state.maskOptions)
      const frame = this.createFrame(this.state.maskOptions)
      maskElement.appendChild(mask)
      maskElement.appendChild(frame)
    }
  }

  positionWaterMark(options) {
    const el = document.getElementById('video-mask-container')
    if (!el) {
      return
    }

    const container = document.getElementById('water-mark')
    if (container) {
      container.style.width = `${options.clientWidth}px`
      container.style.height = `${options.clientHeight}px`
    }

    el.style.top = `${options.y}px`
    el.style.left = `${options.x}px`
    el.style.position = 'absolute'
    el.style.width = `${options.width}px`
    el.style.height = `${options.height}px`
  }

  createMask(options) {
    const mask = document.createElement('canvas')
    mask.classList.add('mask-background')
    mask.width = options.clientWidth
    mask.height = options.clientHeight

    const context = mask.getContext('2d')
    context.fillStyle = getCssVarValue('--page-color')
    context.fillRect(0, 0, options.clientWidth, options.clientHeight)
    context.globalCompositeOperation = 'xor'
    context.rect(options.x, options.y, options.width, options.height)
    context.fill()

    return mask
  }

  createFrame(options) {
    const frame = document.createElement('canvas')
    frame.classList.add('mask-frame')
    frame.width = options.clientWidth
    frame.height = options.clientHeight

    const context = frame.getContext('2d')
    context.strokeStyle = 'white'

    if (this.state.hasSeparator) {
      context.lineWidth = 2
      this.createSeparator(context, options)
    }

    return frame
  }

  createSeparator(context, options) {
    context.setLineDash([15, 8])

    const top = options.y + options.height * 0.6
    const left = options.x + options.width

    context.moveTo(options.x, top)
    context.lineTo(left, top)
    context.stroke()
  }

  getHorizontalDimensions(parent) {
    const options = {
      clientWidth: parent.clientWidth + 1,
      clientHeight: parent.clientHeight + 1
    }

    if (!this.state.browserData.isMobileDevice || parent.clientWidth > 768) {
      options.width = parent.clientHeight * 1.16
      options.height = options.width * 0.625
      options.x = (parent.clientWidth - options.width) / 2
      options.y = parent.clientHeight * 0.1 + CANVAS_MASK_Y_POSITION_OFFSET
    } else {
      options.width = parent.clientWidth * 0.9
      options.height = parent.clientWidth * 0.5625
      options.x = parent.clientWidth * 0.05
      options.y = (parent.clientHeight - options.height) / 2
    }

    return options
  }

  getVerticalDimensions(parent) {
    const options = {
      clientWidth: parent.clientWidth + 1,
      clientHeight: parent.clientHeight + 1
    }

    if (parent.clientWidth > 768) {
      options.width = parent.clientHeight * 0.4625
      options.height = parent.clientHeight * 0.74
      options.x = (parent.clientWidth - options.width) / 2
      if (parent.clientWidth > 900) {
        options.y = (parent.clientHeight - options.height) / 3.4
      } else {
        options.y = (parent.clientHeight - options.height) / 3.7
      }
    } else {
      options.width = parent.clientWidth * 0.7
      options.height = parent.clientWidth * 1.12
      options.x = (parent.clientWidth - options.width) / 2
      options.y = (parent.clientHeight - options.height) / 2
    }

    return options
  }

  getThumbnail(video, canvas) {
    photoCrop({
      video,
      canvas,
      dimensions: this.state.maskOptions
    })

    sharpen(canvas, JPEG_SHARPEN_VALUE)

    return canvas.toDataURL('image/jpeg', JPEG_QUALITY)
  }
}
