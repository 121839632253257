<template>
  <div class="terms-and-conditions-header">
    <div v-for="(item, index) in data" :key="index" class="header-item">
      <div
        class="header-image"
        :style="{ backgroundImage: `url(${item.image})` }"
      />
      <p>{{ $t(item.title) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Array, default: undefined }
  }
}
</script>

<style scoped>
.terms-and-conditions-header,
.header-item {
  display: flex;
}

.terms-and-conditions-header {
  justify-content: space-between;
  padding: 0 15% 1rem 3rem;
}

.header-item {
  opacity: 0.5;
}

.header-item:last-child {
  opacity: 1;
}

.header-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 2rem;
  width: 2rem;
}

p {
  font-size: 1rem;
  line-height: 1;
  margin: 0.75rem 1rem 0.5rem;
}

@media screen and (max-width: 62rem) {
  .terms-and-conditions-header {
    flex-direction: column;
  }
}
</style>
