<template>
  <ui-frame v-if="!isLoading">
    <h1 class="document-header">
      {{ $t('Company.Header') }}
    </h1>
    <p class="document-description">
      {{ $t('Company.Description') }}
    </p>
    <ui-form class="document-content" @submit="submit">
      <ui-drop-down
        label="Company.CountryLabel"
        :options="countryList"
        :value="country"
        :error="countryErrorMessage"
        sort
        @input="countryChanged"
      />
      <ui-input
        label="Company.TitleLabel"
        :value="companyName"
        :error="companyNameErrorMessage"
        max-length="50"
        @input="companyNameChanged"
      />
      <ui-input
        label="Company.InputLabel"
        :value="companyCode"
        :error="companyCodeErrorMessage"
        max-length="20"
        @input="companyCodeChanged"
      />
      <ui-button label="App.Continue" />
    </ui-form>
  </ui-frame>
  <ui-frame v-else>
    <ui-loader :size="3" center />
  </ui-frame>
</template>

<script>
import Validators from '@src/scripts/validators'
import Api, { call } from '@src/scripts/api'
import getters from '@src/store/getters'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      isLoading: false,
      country: null,
      companyCode: null,
      companyName: null,
      countryError: true,
      companyNameError: 'Required',
      companyCodeError: 'Required'
    }
  },

  computed: {
    ...mapGetters([getters.countryCode]),

    countryList() {
      const countries = this.$t('Countries')
      if (!countries) return []

      return (
        Object.entries(countries).map(([key, value]) => ({ key, value })) || []
      )
    },

    countryErrorMessage() {
      const { countryError } = this
      return (countryError && 'Company.Validators.Required') || null
    },

    companyCodeErrorMessage() {
      const { companyCodeError } = this
      return (
        (companyCodeError && `Company.Validators.${companyCodeError}`) || null
      )
    },

    companyNameErrorMessage() {
      const { companyNameError } = this
      return (
        (companyNameError && `Company.Validators.${companyNameError}`) || null
      )
    }
  },

  created() {
    const { countryCode, countryList } = this
    if (!countryCode || !countryList || !countryList.length) return

    const country = countryCode.toLowerCase()
    if (countryList.find((item) => item.key.toLowerCase() === country)) {
      this.country = countryCode
      this.countryError = false
    }
  },

  methods: {
    countryChanged(value) {
      this.country = value
      this.countryError = Validators.requiredValidator(value)
    },

    companyCodeChanged(value) {
      this.companyCode = value
      this.companyCodeError = Validators.requiredValidator(value)
      if (!this.companyCodeError)
        this.companyCodeError = Validators.minLengthValidator(value, 6)
    },

    companyNameChanged(value) {
      this.companyName = value
      this.companyNameError = Validators.requiredValidator(value)
    },

    async submit() {
      if (this.companyCodeError || this.countryError || this.companyNameError)
        return
      this.isLoading = true

      const { error } = await call(Api.companyRepresentation, {
        companyCode: this.companyCode,
        companyName: this.companyName,
        countryCode: this.country
      })

      if (error) {
        this.isLoading = false
        this.$popUp(error)
      } else {
        this.$router.pushNext()
      }
    }
  }
}
</script>

<style scoped>
.document-header {
  margin-top: 2rem;
  margin-bottom: 1.25rem;
  text-align: center;
  color: var(--header-contrast-color);
}

.document-description {
  text-align: center;
  max-width: 25rem;
  margin: auto;
  color: var(--text-contrast-color);
}

.document-content {
  max-width: 25rem;
  width: 100%;
  margin: 2rem auto 0;
}

.document-content .ui-button {
  margin: auto;
}
</style>
