import { IdvMediator, ControllerType } from '@ondato/iframe-mediator'
import { loginMethods } from '@src/scripts/enums'
import Api, { call, get } from '@src/scripts/api'

export const loginHandlers = {
  data() {
    return {
      loginEventController: null
    }
  },

  beforeDestroy() {
    if (this.loginEventController) {
      this.loginEventController.unregisterHandlers()
      this.loginEventController = null
    }
  },

  methods: {
    loadLoginHandlers(target) {
      if (!target) {
        return
      }

      const eventHandler = new IdvMediator({
        type: ControllerType.Parent,
        iframeElement: target,
        allowedOrigin: BaseUrl.resources
      })

      eventHandler.registerHandler(
        loginMethods.smartIdLoginCreationPrivate,
        this.smartIdLoginCreationPrivate
      )
      eventHandler.registerHandler(
        loginMethods.smartIdLoginVerificationPrivate,
        this.smartIdLoginVerificationPrivate
      )
      eventHandler.registerHandler(
        loginMethods.mobileIdLoginCreationPrivate,
        this.mobileIdLoginCreationPrivate
      )
      eventHandler.registerHandler(
        loginMethods.mobileIdLoginVerificationPrivate,
        this.mobileIdLoginVerificationPrivate
      )

      eventHandler.registerHandler(
        loginMethods.smartIdLoginCreationLegal,
        this.smartIdLoginCreationLegal
      )
      eventHandler.registerHandler(
        loginMethods.smartIdLoginVerificationLegal,
        this.smartIdLoginVerificationLegal
      )
      eventHandler.registerHandler(
        loginMethods.mobileIdLoginCreationLegal,
        this.mobileIdLoginCreationLegal
      )
      eventHandler.registerHandler(
        loginMethods.mobileIdLoginVerificationLegal,
        this.mobileIdLoginVerificationLegal
      )
      eventHandler.registerHandler(
        loginMethods.eIdLoginCreationLegal,
        this.eIdLoginCreationLegal
      )
      eventHandler.registerHandler(
        loginMethods.eIdLoginVerificationLegal,
        this.eIdLoginVerificationLegal
      )

      eventHandler.registerHandler(
        loginMethods.smsLoginCreation,
        this.smsLoginCreation
      )
      eventHandler.registerHandler(
        loginMethods.smsLoginVerification,
        this.smsLoginVerification
      )

      eventHandler.registerHandler(
        loginMethods.alternativeLogin,
        this.alternativeLoginVerification
      )

      this.loginEventController = eventHandler
    },

    // -- private smart, mobile ids --
    async smartIdLoginCreationPrivate(data) {
      const payload = {
        ...data,
        type: 'SMART_ID'
      }

      const response = await this.signatureCreate(payload)
      if (!response) {
        throw new Error('NotCreated')
      }

      return response
    },

    async smartIdLoginVerificationPrivate(data) {
      const payload = {
        ...data,
        type: 'SMART_ID'
      }
      const response = await this.signatureStatus(payload)
      if (!response) {
        throw new Error('NotVerified')
      }

      return {
        sessionId: payload.sessionId,
        signatureData: response
      }
    },

    async mobileIdLoginCreationPrivate(data) {
      const payload = {
        ...data,
        type: 'MOBILE_ID'
      }

      const response = await this.signatureCreate(payload)
      if (!response) {
        throw new Error('NotCreated')
      }

      return response
    },

    async mobileIdLoginVerificationPrivate(data) {
      const payload = {
        ...data,
        type: 'MOBILE_ID'
      }

      const response = await this.signatureStatus(payload)
      if (!response) {
        throw new Error('NotVerified')
      }

      return {
        sessionId: payload.sessionId,
        signatureData: response
      }
    },
    // -- private smart, mobile ids --

    // -- legal smart, mobile ids, e-card --
    async smartIdLoginCreationLegal(data) {
      const response = await call(Api.smartIdLoginLegal, data)
      if (response.error || !response.data) {
        this.$popUp('SmartIdSignFailed')
        throw new Error('NotFound')
      }

      return response.data
    },

    async smartIdLoginVerificationLegal() {
      const response = await get(Api.smartIdLoginLegal)
      if (response.error || !response.data) {
        throw new Error('NotAllowed')
      }

      return response.data
    },

    async mobileIdLoginCreationLegal(data) {
      const response = await call(Api.mobileIdLoginLegal, data)
      if (response.error || !response.data) {
        this.$popUp('MobileIdSignFailed')
        throw new Error('NotFound')
      }

      return response.data
    },

    async mobileIdLoginVerificationLegal() {
      const response = await get(Api.mobileIdLoginLegal)
      if (response.error || !response.data) {
        throw new Error('NotAllowed')
      }

      return response.data
    },

    async eIdLoginCreationLegal(data) {
      const payload = {
        ...data,
        redirectUrl: location.href
      }

      const response = await call(Api.eIdLoginCreationLegal, payload)

      if (response.error || !response.data?.url) {
        this.$popUp(response.error)
        throw new Error('NotFound')
      }

      this.$router.redirectTo(response.data.url)
    },

    async eIdLoginVerificationLegal(data) {
      const response = await call(Api.eIdLoginVerificationLegal, data)
      if (response.error || !response.data) {
        throw new Error('NotAllowed')
      }

      return response.data
    },
    // -- legal smart, mobile, e-card ids --

    // -- sms --
    async smsLoginCreation(data) {
      try {
        if (data.phoneNumber) {
          const { error } = await call(Api.loginCodeSend, data)
          if (!error) {
            return null
          }
        }
      } catch (error) {
        // error
      }

      this.$popUp('Failed')
      throw new Error('NotCreated')
    },

    async smsLoginVerification(data) {
      try {
        if (data.code) {
          const { error } = await call(Api.loginCodeVerify, data)
          if (!error) {
            return null
          }
        }
      } catch (error) {
        // error
      }

      throw new Error('NotVerified')
    },

    async alternativeLoginVerification(data) {
      const response = await call(Api.alternativeLoginVerification, data)
      if (response.error || !response.data) {
        throw new Error('NotAllowed')
      }
    }
  }
}
