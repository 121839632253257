// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
h2[data-v-c60f7ca6] {
  margin-bottom: 2rem;
}
p[data-v-c60f7ca6] {
  margin: 1rem 0;
}
a[data-v-c60f7ca6] {
  color: var(--blue);
  font-weight: 500;
  text-decoration: none;
}
h1 span[data-v-c60f7ca6],
h2 span[data-v-c60f7ca6],
p span[data-v-c60f7ca6],
p a[data-v-c60f7ca6] {
  font-size: inherit;
}
ul[data-v-c60f7ca6] {
  list-style: none;
  padding-left: 2rem;
  margin: 1rem 0;
}
ul li[data-v-c60f7ca6] {
  margin: 0.5rem 0;
  position: relative;
}
ul li[data-v-c60f7ca6]::before {
  content: '';
  border: 3px solid var(--list-bullet-color);
  border-radius: var(--list-bullet-border-radius);
  position: absolute;
  top: 0.375rem;
  left: -1.75rem;
  transform: rotate(45deg);
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/ViewGenerator.vue"],"names":[],"mappings":";AA6BA;EACA,mBAAA;AACA;AAEA;EACA,cAAA;AACA;AAEA;EACA,kBAAA;EACA,gBAAA;EACA,qBAAA;AACA;AAEA;;;;EAIA,kBAAA;AACA;AAEA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;AACA;AAEA;EACA,gBAAA;EACA,kBAAA;AACA;AAEA;EACA,WAAA;EACA,0CAAA;EACA,+CAAA;EACA,kBAAA;EACA,aAAA;EACA,cAAA;EACA,wBAAA;AACA","sourcesContent":["<template v-if=\"data\">\n  <span v-if=\"typeof data === 'object'\">\n    <component\n      :is=\"item.type\"\n      v-for=\"(item, index) of data\"\n      :key=\"index\"\n      :href=\"item.type === 'a' && (item.href || item.value)\"\n      :target=\"item.type === 'a' && '_blank'\"\n      :class=\"item.class\"\n    >\n      <view-generator :data=\"item.value\" />\n    </component>\n  </span>\n  <span v-else>\n    {{ data }}\n  </span>\n</template>\n\n<script>\nexport default {\n  name: 'view-generator',\n\n  props: {\n    data: { type: [Array, String], default: undefined }\n  }\n}\n</script>\n\n<style scoped>\nh2 {\n  margin-bottom: 2rem;\n}\n\np {\n  margin: 1rem 0;\n}\n\na {\n  color: var(--blue);\n  font-weight: 500;\n  text-decoration: none;\n}\n\nh1 span,\nh2 span,\np span,\np a {\n  font-size: inherit;\n}\n\nul {\n  list-style: none;\n  padding-left: 2rem;\n  margin: 1rem 0;\n}\n\nul li {\n  margin: 0.5rem 0;\n  position: relative;\n}\n\nul li::before {\n  content: '';\n  border: 3px solid var(--list-bullet-color);\n  border-radius: var(--list-bullet-border-radius);\n  position: absolute;\n  top: 0.375rem;\n  left: -1.75rem;\n  transform: rotate(45deg);\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
