var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ui-camera-frame",
      class: [
        _vm.controlStyle,
        _vm.controlsPosition,
        { "no-camera": !_vm.isCameraLoaded, "review-photo": !!_vm.file },
      ],
    },
    [
      _c("div", { staticClass: "content-panel" }, [
        _c(
          "div",
          { staticClass: "camera-panel" },
          [
            _vm.title && _vm.isCameraActive
              ? _c("h2", { staticClass: "camera-title" }, [
                  _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._t("camera"),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("page-logo"),
      _vm._v(" "),
      _vm.isCameraLoaded
        ? _c("div", { staticClass: "details-panel" }, [
            _c(
              "div",
              { staticClass: "details-content" },
              [
                _c(
                  "div",
                  { staticClass: "description-panel" },
                  [_vm._t("description")],
                  2
                ),
                _vm._v(" "),
                _vm.file
                  ? _c("photo-review", {
                      attrs: { photo: _vm.file, thumbnail: _vm.fileThumbnail },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm._t("controls"),
              ],
              2
            ),
          ])
        : _c(
            "div",
            { staticClass: "loader-wrapper" },
            [_c("ui-loader", { attrs: { center: "", size: 3 } })],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }