// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ui-loader[data-v-7f7eee8d] {
  position: relative;
}
.ui-loader.loader-center[data-v-7f7eee8d] {
  margin: auto;
}
.ui-loader[data-v-7f7eee8d]::before {
  content: var(--loader-content);
  height: 100%;
  width: 100%;
  border: 6px solid var(--loader-color-primary);
  border-bottom-color: var(--loader-color-additional);
  position: absolute;
  left: 0;
  border-radius: 30%;
  animation: loader-spin-data-v-7f7eee8d 1.5s infinite ease-in-out;
}
@-moz-keyframes loader-spin-data-v-7f7eee8d {
from {
    -moz-transform: rotate(0deg);
}
to {
    -moz-transform: rotate(360deg);
}
}
@-webkit-keyframes loader-spin-data-v-7f7eee8d {
from {
    -webkit-transform: rotate(0deg);
}
to {
    -webkit-transform: rotate(360deg);
}
}
@keyframes loader-spin-data-v-7f7eee8d {
from {
    transform: rotate(0deg);
}
to {
    transform: rotate(360deg);
}
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/UiLoader.vue"],"names":[],"mappings":";AA+BA;EACA,kBAAA;AACA;AAEA;EACA,YAAA;AACA;AAEA;EACA,8BAAA;EACA,YAAA;EACA,WAAA;EACA,6CAAA;EACA,mDAAA;EACA,kBAAA;EACA,OAAA;EACA,kBAAA;EACA,gEAAA;AACA;AAEA;AACA;IACA,4BAAA;AACA;AAEA;IACA,8BAAA;AACA;AACA;AAEA;AACA;IACA,+BAAA;AACA;AAEA;IACA,iCAAA;AACA;AACA;AAEA;AACA;IACA,uBAAA;AACA;AAEA;IACA,yBAAA;AACA;AACA","sourcesContent":["<template>\n  <div\n    class=\"ui-loader\"\n    :class=\"{ 'loader-center': center }\"\n    :style=\"loaderSize\"\n  />\n</template>\n\n<script>\nexport default {\n  name: 'ui-loader',\n\n  props: {\n    size: { type: Number, default: undefined },\n    center: Boolean\n  },\n\n  computed: {\n    loaderSize() {\n      const { size } = this\n      const sizeValue = `${size || 1.5}rem`\n      return {\n        width: sizeValue,\n        height: sizeValue\n      }\n    }\n  }\n}\n</script>\n\n<style scoped>\n.ui-loader {\n  position: relative;\n}\n\n.ui-loader.loader-center {\n  margin: auto;\n}\n\n.ui-loader::before {\n  content: var(--loader-content);\n  height: 100%;\n  width: 100%;\n  border: 6px solid var(--loader-color-primary);\n  border-bottom-color: var(--loader-color-additional);\n  position: absolute;\n  left: 0;\n  border-radius: 30%;\n  animation: loader-spin 1.5s infinite ease-in-out;\n}\n\n@-moz-keyframes loader-spin {\n  from {\n    -moz-transform: rotate(0deg);\n  }\n\n  to {\n    -moz-transform: rotate(360deg);\n  }\n}\n\n@-webkit-keyframes loader-spin {\n  from {\n    -webkit-transform: rotate(0deg);\n  }\n\n  to {\n    -webkit-transform: rotate(360deg);\n  }\n}\n\n@keyframes loader-spin {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
