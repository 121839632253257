<template>
  <ui-button
    v-if="isAvailable"
    additional
    label="App.Omnichannel"
    @click="click"
  />
</template>

<script>
import { stateKeys } from '@src/store'
import getters from '@src/store/getters'
import mutations from '@src/store/mutations'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { OMNICHANNEL_STATUSES, PAGE_NAMES } from '@src/scripts/constants'

export default {
  computed: {
    ...mapState([stateKeys.omnichannel]),
    ...mapGetters([getters.isMobileDevice]),

    isAvailable() {
      return (
        !this.isMobileDevice &&
        this.omnichannel?.status === OMNICHANNEL_STATUSES.AVAILABLE
      )
    }
  },

  methods: {
    ...mapMutations([mutations.initiateOmnichannel]),

    click() {
      this.$emit('click')
      this.initiateOmnichannel()
      if (this.$route.name !== PAGE_NAMES.OMNICHANNEL) {
        this.$router.pushNext({ name: PAGE_NAMES.OMNICHANNEL })
      }
    }
  }
}
</script>
