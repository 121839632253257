import { STORE_KEYS } from '@src/scripts/constants'

export function cachedToken(value) {
  if (arguments.length > 0) {
    sessionStorage.setItem(
      STORE_KEYS.TOKENS,
      (value && JSON.stringify(value)) || ''
    )
  } else {
    const item = sessionStorage.getItem(STORE_KEYS.TOKENS) || null
    value = (item && JSON.parse(item)) || null
  }

  return value
}
