<template>
  <ui-frame logo>
    <h1 class="header">
      {{ $t('SignatureAuthentication.Types.Header') }}
    </h1>
    <card-list>
      <card
        v-for="item in signatureSettings"
        :key="item.name"
        :label="label(item.name)"
        @click="$emit('change', item)"
      >
        <component :is="image(item.name)" />
      </card>
    </card-list>
  </ui-frame>
</template>

<script>
import Card from '@src/components/partials/Card'
import CardList from '@src/components/partials/CardList'
import SmartId from '@src/components/images/SmartId'
import MobileId from '@src/components/images/MobileId'
import getters from '@src/store/getters'
import { ruleTypes } from '@src/scripts/enums'
import { mapGetters } from 'vuex'

export default {
  components: {
    Card,
    CardList
  },

  computed: {
    ...mapGetters([getters.signatureSettings])
  },

  methods: {
    label(name) {
      if (name === ruleTypes.smartId) {
        return this.$t('SignatureAuthentication.Types.SmartId')
      } else if (name === ruleTypes.mobileId) {
        return this.$t('SignatureAuthentication.Types.MobileId')
      }
    },

    image(name) {
      if (name === ruleTypes.smartId) return SmartId
      else if (name === ruleTypes.mobileId) return MobileId
    }
  }
}
</script>

<style scoped>
.header {
  margin-top: 2rem;
  margin-bottom: 1.25rem;
  text-align: center;
  color: var(--header-contrast-color);
}

@media screen and (min-width: 38.0625rem) {
  .header {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}
</style>
