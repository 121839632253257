var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isFaceAuthenticated
    ? _c(
        "ui-camera-frame",
        {
          staticClass: "passive-liveness-check",
          class: { "hide-logo": _vm.isCameraActive },
          attrs: {
            resource: _vm.resource,
            error: !!_vm.error,
            "active-controls": "",
          },
        },
        [
          _c("ui-camera-description", {
            attrs: {
              slot: "description",
              resource: _vm.resource,
              message: _vm.message,
              instructions: "",
            },
            slot: "description",
          }),
          _vm._v(" "),
          _c("ui-camera", {
            attrs: {
              slot: "camera",
              resource: _vm.resource,
              controls: _vm.controller,
              "capture-callback": _vm.captureComplete,
              face: "",
            },
            slot: "camera",
          }),
          _vm._v(" "),
          _c("camera-controls", {
            attrs: {
              slot: "controls",
              completed: _vm.isCompleted,
              "reset-callback": _vm.reset,
              "active-controls": "",
            },
            on: { start: _vm.start },
            slot: "controls",
          }),
        ],
        1
      )
    : _c("ui-loader", { attrs: { size: 3, center: "" } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }