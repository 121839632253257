<template>
  <instructions-frame
    :header="$t(typeSettings.header)"
    :description="$t(typeSettings.description)"
    :main-item="mainImage"
    :sub-items-header="$t('Instructions.RightSidebarTitle')"
    :sub-items="typeSettings.subItems"
    :action="$t('Instructions.ButtonText')"
    @onaction="goNext"
  >
    <p v-if="!isBackSide" class="footer-text">
      {{ $t('Instructions.FooterText') }}
      <span class="clickable-text" @click="goHome">
        {{ $t('Instructions.FooterTextClick') }}
      </span>
    </p>
  </instructions-frame>
</template>

<script>
import PassportFront from '@src/components/images/PassportFront'
import PassportTooBlurry from '@src/components/images/PassportTooBlurry'
import PassportTooSmall from '@src/components/images/PassportTooSmall'
import PassportTooDark from '@src/components/images/PassportTooDark'
import CardTooBlurry from '@src/components/images/CardTooBlurry'
import CardBackTooBlurry from '@src/components/images/CardBackTooBlurry'
import CardTooSmall from '@src/components/images/CardTooSmall'
import CardBackTooSmall from '@src/components/images/CardBackTooSmall'
import CardTooDark from '@src/components/images/CardTooDark'
import CardBackTooDark from '@src/components/images/CardBackTooDark'
import CardFront from '@src/components/images/CardFront'
import CardBack from '@src/components/images/CardBack'
import DrivingLicenceFront from '@src/components/images/DrivingLicenceFront'
import DrivingLicenceBack from '@src/components/images/DrivingLicenceBack'
import InstructionsFrame from '@src/components/partials/InstructionsFrame'
import { appPages, pageTypes } from '@src/scripts/enums'
import { isMobileDevice } from '@src/scripts/browserSupport'

export default {
  components: {
    InstructionsFrame
  },

  props: {
    side: { type: String, default: pageTypes.front }
  },

  computed: {
    isBackSide() {
      return this.side === pageTypes.back
    },

    typeSettings() {
      const documentType = this.$route.params.type.toLowerCase()
      const translationKey = this.getTranslationKeyByType(
        documentType,
        this.side
      )
      const mainItem = this.getMainImageBytype(documentType, this.side)
      const subItems = this.getMistakesByType(documentType, this.side)

      return {
        header: `Instructions.Headers.${translationKey}`,
        description: `Instructions.Descriptions.${translationKey}`,
        mainItem,
        subItems
      }
    },

    mainImage() {
      return (!isMobileDevice() && this.typeSettings.mainItem) || null
    }
  },

  methods: {
    getTranslationKeyByType(type, side) {
      switch (type) {
        case 'passport':
          return 'Passport'
        case 'id-card':
          return side === pageTypes.front ? 'IdCardFront' : 'IdCardBack'
        case 'driver-license':
          return side === pageTypes.front
            ? 'DriverLicenceFront'
            : 'DriverLicenceBack'
        default:
          return ''
      }
    },

    getMistakesByType(type, side) {
      switch (type) {
        case 'passport':
          return [
            {
              label: this.$t('Instructions.Mistakes.Blurry'),
              image: PassportTooBlurry
            },
            {
              label: this.$t('Instructions.Mistakes.Small'),
              image: PassportTooSmall
            },
            {
              label: this.$t('Instructions.Mistakes.Dark'),
              image: PassportTooDark
            }
          ]
        case 'id-card':
        case 'driver-license':
          return [
            {
              label: this.$t('Instructions.Mistakes.Blurry'),
              image:
                side === pageTypes.front ? CardTooBlurry : CardBackTooBlurry
            },
            {
              label: this.$t('Instructions.Mistakes.Small'),
              image: side === pageTypes.front ? CardTooSmall : CardBackTooSmall
            },
            {
              label: this.$t('Instructions.Mistakes.Dark'),
              image: side === pageTypes.front ? CardTooDark : CardBackTooDark
            }
          ]
        default:
          return {}
      }
    },

    getMainImageBytype(type, side) {
      switch (type) {
        case 'passport':
          return PassportFront
        case 'id-card':
          return side === pageTypes.front ? CardFront : CardBack
        case 'driver-license':
          return side === pageTypes.front
            ? DrivingLicenceFront
            : DrivingLicenceBack
        default:
          return ''
      }
    },

    goHome() {
      this.$router.pushNext({
        name: appPages.documentSelect,
        params: { language: this.$route.params.language }
      })
    },

    goNext() {
      if (this.side === pageTypes.back) {
        return this.$router.pushNext({
          name: appPages.documentBack,
          params: this.$route.params
        })
      }
      return this.$router.pushNext({
        name: appPages.documentFront,
        params: this.$route.params
      })
    }
  }
}
</script>

<style scoped>
.footer-text {
  margin: 0;
}

.clickable-text {
  text-decoration: underline;
  cursor: pointer;
}
</style>
