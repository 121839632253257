<template>
  <ui-camera-frame :file="file" active-controls>
    <ui-camera-description
      slot="description"
      :resource="resource"
      :message="message"
    />
    <ui-camera
      slot="camera"
      :resource="resource"
      message="Info"
      :controls="photoControls"
      :capture-callback="captureComplete"
    />
    <camera-controls
      slot="controls"
      :completed="isCompleted"
      enable-continue
      active-controls
      :error="!!error"
      :reset-callback="reset"
      @continue="continueSubmition"
    />
  </ui-camera-frame>
</template>

<script>
import CameraControls from '@src/components/partials/CameraControls'
import PhotoController from '@src/scripts/photoController'
import actions from '@src/store/actions'
import Api, { sendFile } from '@src/scripts/api'
import { cameraStates } from '@src/scripts/enums'

export default {
  components: {
    CameraControls
  },

  data() {
    return {
      isCompleted: false,
      error: null,
      photoControls: null,
      file: null
    }
  },

  computed: {
    resource() {
      const { name } = this.$route
      return `External.${name}`
    },

    message() {
      const { error, file } = this
      return error || (file && 'ValidateDocument') || 'Info'
    }
  },

  created() {
    this.initDocument()
  },

  methods: {
    initDocument() {
      this.photoControls = new PhotoController({})
    },

    reset() {
      this.isCompleted = false
      this.error = null
      this.file = null
      return false
    },

    captureComplete({ error, blob }) {
      if (error) {
        this.error = error
      } else if (blob) {
        this.file = blob
      }
      this.isCompleted = true
    },

    async continueSubmition() {
      this.$store.dispatch(actions.updateCameraState, cameraStates.loading)
      const errorMessage = await this.submit(this.file)
      if (errorMessage) {
        this.error = errorMessage
        this.$store.dispatch(actions.updateCameraState, cameraStates.ok)
      } else {
        return this.$router.pushNext()
      }
      this.isCompleted = true
    },

    async submit(picture) {
      try {
        const mediaData = new FormData()
        mediaData.append('files', picture)

        const { error } = await sendFile(Api.externalFileUpload, mediaData)
        if (error) {
          return 'Failed'
        }
      } catch (error) {
        this.$popUp(error)
        return 'Failed'
      }
    }
  }
}
</script>
