<template>
  <div class="start-page" :class="{ 'show-content': showContent }">
    <h2>{{ $t('App.Loading.Start') }}</h2>
  </div>
</template>

<script>
import Api, { call, get } from '@src/scripts/api'
import actions from '@src/store/actions'
import { appPages } from '@src/scripts/enums'
import {
  API_ERRORS,
  IDV_API_REQUESTS,
  STORE_KEYS
} from '@src/scripts/constants'
import { idvApi } from '@src/scripts/idvApi'

export default {
  data() {
    return {
      showContent: false
    }
  },

  computed: {
    identificationId() {
      return this.$route.params.identificationId
    },

    faceAuthenticationIdFromParams() {
      return this.$route.params.faceAuthenticationId
    },

    faceAuthenticationIdFromStorage() {
      return sessionStorage.getItem(STORE_KEYS.FACE_AUTHENTICATION_ID)
    },

    signatureAuthenticationId() {
      return this.$route.params.signatureAuthenticationId
    },

    language() {
      return this.$route.params.language
    }
  },

  async created() {
    setTimeout(() => {
      this.showContent = true
    })

    const { name } = this.$route

    let method
    if (name === 'SignStart') {
      method = Api.signStartIdentification
    } else if (name === 'FaceAuthenticationStart') {
      method = Api.startFaceAuthentication
    } else if (name === 'SignatureAuthenticationStart') {
      method = Api.startSignatureAuthentication
    } else if (name === 'OmnichannelStart') {
      return this.handleOmnichannel()
    } else {
      method = Api.startIdentification
    }

    const {
      identificationId,
      signatureAuthenticationId,
      faceAuthenticationIdFromParams,
      language
    } = this
    const faceAuthenticationId = faceAuthenticationIdFromParams

    const { data } = await call(method, {
      identificationId,
      faceAuthenticationId,
      signatureAuthenticationId
    })

    if (data) {
      this.$store.dispatch(actions.updateToken, data.accessToken)

      await this.$store.dispatch(actions.updateIdvToken, {
        token: data.accessToken,
        faceAuthenticationId
      })

      if (identificationId) {
        window.sessionStorage.setItem('identificationId', identificationId)
      }

      if (faceAuthenticationId) {
        window.sessionStorage.setItem(
          STORE_KEYS.FACE_AUTHENTICATION_ID,
          faceAuthenticationId
        )
      }
    }
    this.$router.replace({ name: appPages.home, params: { language } })
  },

  methods: {
    async handleOmnichannel() {
      try {
        const {
          query: {
            identityVerificationId,
            kycAccessCode,
            idvAccessCode,
            faceAuthenticationId
          }
        } = this.$route
        const { language } = this

        if (!identityVerificationId || !idvAccessCode || !kycAccessCode) return

        // GET OMNICHANNEL TOKEN

        const idvTokenOptions = faceAuthenticationId
          ? { faceAuthenticationId }
          : {
              identityVerificationId,
              accessCode: idvAccessCode
            }
        const updateIdvTokenResult = await this.$store.dispatch(
          actions.updateIdvToken,
          idvTokenOptions
        )

        if (updateIdvTokenResult && updateIdvTokenResult.error) {
          this.handleInvalidSession(updateIdvTokenResult.error, language)
          return
        }
        const apiUrl = this.getAccessTokenApiUrl()

        const request = `${apiUrl}?code=${kycAccessCode}`

        // GET KYC API
        const { data, status } = await get(request)
        await this.$store.dispatch(actions.updateToken, data?.accessToken)

        if (status === 401 || status === 403) {
          throw new Error(API_ERRORS.UNAUTHORIZED)
        }

        // START OMNICHANNEL
        await idvApi.post(IDV_API_REQUESTS.OMNICHANNELS)

        this.$router.replace({ name: appPages.home, params: { language } })
      } catch (error) {
        console.error({ type: error?.message })
        this.$popUp('Failed')
      }
    },

    handleInvalidSession(errorType) {
      console.error(errorType)

      this.$popUp(errorType, { isImmediate: true, isOpaque: true })
    },

    getAccessTokenApiUrl() {
      const isFaceAuthentication =
        this.$route.query.faceAuthenticationId ||
        this.faceAuthenticationIdFromStorage

      const apiUrl = isFaceAuthentication
        ? Api.startFaceAccessTokens
        : Api.startAccessTokens

      return apiUrl
    }
  }
}
</script>

<style scoped>
.start-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity var(--page-time) ease 0.5s;
}

.start-page > h2 {
  margin: auto;
  color: var(--header-contrast-color);
}

.start-page.show-content {
  opacity: 1;
}
</style>
