var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "instructions-frame" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _vm.header
          ? _c("h1", { staticClass: "instructions-header" }, [
              _vm._v("\n      " + _vm._s(_vm.header) + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.description
          ? _c(
              "p",
              { staticClass: "instructions-description" },
              [_c("view-generator", { attrs: { data: _vm.description } })],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "suggestion" },
          [
            _vm.mainItem
              ? _c(_vm.mainItem, { tag: "component", staticClass: "main-item" })
              : _vm._e(),
            _vm._v(" "),
            _vm.subItems && _vm.subItems.length
              ? _c(
                  "div",
                  { staticClass: "sub-item-list" },
                  [
                    _vm.subItemsHeader
                      ? _c("p", { staticClass: "sub-item-header" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.subItemsHeader) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.subItems, function (item, index) {
                      return [
                        _c(
                          "div",
                          { key: index, staticClass: "sub-item-image" },
                          [_c(item.image, { tag: "component" })],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            key: item.label,
                            staticClass: "sub-item-description",
                          },
                          [
                            _c("img", {
                              attrs: { src: "/public/images/close-icon.png" },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "sub-item-text" }, [
                              _vm._v(_vm._s(item.label)),
                            ]),
                          ]
                        ),
                      ]
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("ui-button", {
          staticClass: "button-style",
          attrs: { slot: "main", label: _vm.action, "photo-icon": "" },
          on: {
            click: function ($event) {
              return _vm.$emit("onaction")
            },
          },
          slot: "main",
        }),
        _vm._v(" "),
        _vm.hasChildren ? _vm._t("default") : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }