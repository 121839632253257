<template>
  <div
    class="ui-camera-frame"
    :class="[
      controlStyle,
      controlsPosition,
      { 'no-camera': !isCameraLoaded, 'review-photo': !!file }
    ]"
  >
    <div class="content-panel">
      <div class="camera-panel">
        <h2 v-if="title && isCameraActive" class="camera-title">
          {{ title }}
        </h2>
        <slot name="camera" />
      </div>
    </div>
    <page-logo />
    <div v-if="isCameraLoaded" class="details-panel">
      <div class="details-content">
        <div class="description-panel">
          <slot name="description" />
        </div>
        <photo-review v-if="file" :photo="file" :thumbnail="fileThumbnail" />
        <slot name="controls" />
      </div>
    </div>
    <div v-else class="loader-wrapper"><ui-loader center :size="3" /></div>
  </div>
</template>

<script>
import PageLogo from '@src/components/partials/PageLogo'
import PhotoReview from '@src/components/partials/PhotoReview'
import getters from '@src/store/getters'
import { mapGetters } from 'vuex'
import { appPages } from '@src/scripts/enums'
import { activityLogger } from '@src/scripts/activityLogger'

export default {
  name: 'ui-camera-frame',

  components: {
    PageLogo,
    PhotoReview
  },

  props: {
    file: { type: Blob, default: undefined },
    fileThumbnail: { type: String, default: undefined },
    title: { type: String, default: undefined },
    resource: { type: String, default: undefined },
    activeControls: Boolean
  },

  data() {
    return {
      reviewPhoto: null
    }
  },

  computed: {
    ...mapGetters([
      getters.isCameraLoaded,
      getters.isCameraActive,
      getters.isCameraLoading
    ]),

    controlStyle() {
      const { isCameraActive, isCameraLoading, activeControls } = this

      if (isCameraLoading || (isCameraActive && !activeControls)) {
        return 'hide-active-controls'
      }
      return null
    },

    controlsPosition() {
      const { isCameraActive, isCameraLoading, activeControls } = this

      if (activeControls && (isCameraActive || isCameraLoading)) {
        return 'bottom-controls'
      }
      return null
    }
  },

  watch: {
    isCameraLoaded(newVal) {
      if (newVal && this.resource === appPages.face) {
        activityLogger.logActivity('IDENTIFY_FACE_PAGE_RENDERED')
      }
    }
  }
}
</script>

<style scoped>
.ui-camera-frame {
  width: 100%;
  margin: auto;
  height: 100%;
  position: relative;
  overflow: auto;
}

.content-panel {
  display: flex;
  width: 100%;
  padding-top: 62.5%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity var(--frame-time) ease-in-out;
}

.no-camera .content-panel {
  opacity: 0;
}

.camera-panel {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.camera-title {
  line-height: 2rem;
  z-index: 2;
  position: absolute;
  text-align: center;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
}

.details-panel {
  position: absolute;
  width: 100%;
  padding: 2rem;
  overflow: auto;
  display: flex;
}

@media screen and (min-height: 41rem) {
  .details-panel {
    height: 100%;
  }
}

@media screen and (max-width: 30rem) {
  .details-panel {
    height: 100%;
  }
}

.hide-active-controls .details-panel,
.bottom-controls .details-panel {
  pointer-events: none;
}

.details-content {
  background-color: var(--white);
  width: 100%;
  max-width: 20rem;
  max-height: max-content;
  margin: auto;
  padding: 2rem;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  pointer-events: all;
  z-index: 1;
}

.no-camera .details-content {
  max-height: 0;
  opacity: 0;
}

.loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
}

.hide-active-controls .details-content {
  max-height: 0;
  opacity: 0;
}

.bottom-controls .details-content {
  padding: 1rem 2rem;
  background-color: transparent;
  box-shadow: none;
}

.description-panel {
  max-height: 25rem;
  overflow: hidden;
}

.bottom-controls .description-panel {
  max-height: 0;
  opacity: 0;
  transform: translateY(-0.5rem);
}

.review-photo .details-content {
  max-width: 40rem;
  width: 100%;
}

.mobile-app .ui-camera-frame,
.mobile-app .content-panel {
  height: 100%;
}

.mobile-app .bottom-controls .details-content {
  margin-bottom: 0;
}

.desktop-app .ui-camera-frame {
  max-width: 60rem;
}

.desktop-app .bottom-controls .details-content {
  padding-top: 60%;
}

@media screen and (max-width: 48em) {
  .mobile-app .camera-title {
    padding: 6rem 1rem 1rem;
    width: 100%;
    position: absolute;
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
}

@media screen and (max-height: 40rem) and (min-width: 48rem) {
  .mobile-app .camera-panel {
    height: 70%;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media screen and (max-height: 50rem) and (min-width: 48rem) {
  .mobile-app .camera-title {
    width: 55%;
    top: -1rem;
  }
}
@media screen and (max-height: 50rem) {
  .desktop-app .camera-title {
    width: 50%;
  }
}

@media screen and (max-width: 48em) {
  .desktop-app .camera-title {
    transform: translate(-50%, calc(-100% - 2rem));
    padding-inline: 1rem;
  }

  .desktop-app .bottom-controls .details-content {
    transform: translateY(3rem);
  }
}
</style>

<style>
.review-photo .instructions-container {
  max-width: 18rem;
  margin: auto;
}

.desktop-app .review-photo .controls-group.main-controls {
  justify-content: space-evenly;
}
</style>
