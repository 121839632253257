<template>
  <div
    id="video-box"
    class="ui-camera"
    :class="{
      'camera-active': isCameraActive,
      'no-mask': noMask,
      'camera-loading': isCameraLoading
    }"
  >
    <div class="camera-box">
      <div id="video-mask" class="video-mask" />
      <div
        v-show="showWatermarks"
        id="water-mark"
        class="video-mask camera-mask water-mark-container"
      >
        <div id="video-mask-container">
          <water-mark />
          <test-water-mark v-if="isTest" />
        </div>
      </div>
      <video id="video-element" class="video-element" playsinline muted />
      <message-overlay v-if="message" :resource="resource" :message="message" />
    </div>
    <a
      v-if="canChange"
      v-show="isCameraActive"
      class="camera-switch"
      @click="changeCamera"
    >
      <camera-switch />
    </a>
    <div class="video-disabled" />
    <div v-if="isCameraLoading" class="video-loader">
      <commercial-player v-if="loadingVideo" :source="loadingVideo" />
      <ui-loader v-else :size="3" />
      <h3>{{ loadingMessage }}</h3>
    </div>
  </div>
</template>

<script>
import CameraSwitch from '@src/components/images/CameraSwitch'
import CommercialPlayer from '@src/components/partials/CommercialPlayer'
import MessageOverlay from '@src/components/partials/MessageOverlay'
import WaterMark from '@src/components/partials/WaterMark'
import TestWaterMark from '@src/components/partials/TestWaterMark'
import actions from '@src/store/actions'
import getters from '@src/store/getters'
import { cameraStates, mediaSourceTypes } from '@src/scripts/enums'
import { mapGetters } from 'vuex'

export default {
  name: 'ui-camera',

  components: {
    CameraSwitch,
    CommercialPlayer,
    MessageOverlay,
    WaterMark,
    TestWaterMark
  },

  props: {
    resource: { type: String, default: undefined, required: true },
    controls: { type: Object, default: undefined, required: true },
    message: { type: String, default: undefined },
    captureCallback: { type: Function, default: undefined },
    call: Boolean,
    face: Boolean
  },

  data() {
    return {
      live: true,
      isTest: !IsProduction,
      canChange: false,
      isLoading: false,
      isMuted: true
    }
  },

  computed: {
    ...mapGetters([
      getters.isCameraActive,
      getters.isCameraLoading,
      getters.cameraState,
      getters.loadingVideoSource
    ]),

    browserData() {
      return this.$store.state && this.$store.state.browserData
    },

    isPhoto() {
      const { controls } = this
      return controls && controls.mode === mediaSourceTypes.photo
    },

    isCall() {
      const { controls } = this
      return controls && controls.mode === mediaSourceTypes.call
    },

    loadingVideo() {
      const language = this.$language()
      if (!language) return

      return this.loadingVideoSource(language)
    },

    loadingMessage() {
      const { call } = this
      const key = call ? 'App.Loading.Call' : 'App.Loading.Capture'
      return this.$t(key)
    },

    showWatermarks() {
      return !this.face && !this.isCameraLoading
    },

    noMask() {
      const { matched } = this.$route
      if (!matched) return false

      const { noMask } = matched[matched.length - 1].props.default || {}
      return !!noMask
    }
  },

  watch: {
    isCameraActive(value) {
      if (value && !this.isCall && !this.isPhoto) {
        this.capture()
      }
    },

    cameraState(value, oldValue) {
      if (value !== cameraStates.loading || oldValue !== cameraStates.active) {
        return
      }

      if (!this.isCall && this.isPhoto) {
        this.capture()
      }
    }
  },

  created() {
    this.initCamera()
  },

  beforeDestroy() {
    const { controls } = this
    if (controls) {
      this.$store.dispatch(actions.updateCameraState, cameraStates.pending)
      controls.cancel()
    }
  },

  methods: {
    async initCamera() {
      const { controls } = this
      if (controls) {
        const { browserData, captureCompleted } = this
        controls.callback.captureCompleted = captureCompleted
        const isLoaded = await controls.init({
          parentId: 'video-box',
          maskId: 'video-mask',
          videoId: 'video-element',
          browserData
        })

        if (isLoaded) {
          this.$store.dispatch(actions.updateCameraState, cameraStates.ok)
          this.canChange = this.controls.state.changeAvailable
        } else {
          this.$store.dispatch(actions.updateCameraState, cameraStates.failed)
        }
      }
    },

    async changeCamera() {
      const { controls } = this
      this.isLoading = true
      await controls.changeCamera()
      this.isLoading = false
    },

    captureCompleted() {
      this.$store.dispatch(actions.updateCameraState, cameraStates.loading)
    },

    async capture() {
      try {
        const { controls, captureCallback, isLoading } = this
        if (isLoading) {
          this.$store.dispatch(actions.updateCameraState, cameraStates.active)
          return
        }

        const result = await controls.capture()
        if (captureCallback) await captureCallback(result)
      } catch (error) {
        console.error('c Error', error)
      }
      if (this.cameraState === cameraStates.pending) return
      this.$store.dispatch(actions.updateCameraState, cameraStates.ok)
    }
  }
}
</script>

<style scoped>
.ui-camera {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.camera-box {
  position: absolute;
  transition: all var(--camera-time) ease;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.video-mask {
  position: absolute;
  z-index: 1;
}

.video-element {
  display: block;
  filter: blur(20px);
  transform: rotateY(180deg);
}

.video-loader {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  height: 100%;
  z-index: 1;
}

.video-loader > * {
  margin-left: auto;
  margin-right: auto;
}

.video-loader h3 {
  color: var(--loader-text-color);
  margin-top: var(--loader-spacing);
  text-align: center;
  max-width: 80%;
}

.video-disabled {
  position: absolute;
  inset: 0;
  z-index: 1;
  opacity: 0.5;
  background-color: var(--page-color);
  transition: opacity var(--camera-time) ease;
}

.camera-active > .video-disabled {
  opacity: 0;
}

.camera-active .video-element {
  filter: none;
}

.camera-switch {
  position: absolute;
  top: 2rem;
  right: 2rem;
  height: 3rem;
  width: 3rem;
  z-index: 2;
  filter: invert();
  transition: all 0.25s ease-in-out;
}

.camera-switch:hover,
.camera-switch:active {
  cursor: pointer;
  transform: scale(1.1);
  opacity: 0.8;
}

.commercial-player {
  max-width: 90%;
}

.ui-camera::after {
  content: '';
  position: absolute;
  inset: -2px;
  border: 15px solid var(--page-color);
}

.water-mark-container {
  height: 100%;
  width: 100%;
}

@-moz-document url-prefix() {
  .camera-box video.video-element {
    margin: auto;
  }
}

@media screen and (min-width: 30.0625rem) {
  .desktop-app .commercial-player {
    max-width: 60%;
  }
}
</style>

<style>
.camera-mask {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity var(--camera-time) ease-in-out;
}

.camera-mask > .mask-background,
.camera-mask > .mask-frame {
  display: block;
}

.camera-mask > .mask-frame {
  position: absolute;
  top: 0;
}

.camera-active .camera-mask {
  opacity: 1;
}

.camera-loading .camera-box {
  opacity: 0;
}

.no-mask .camera-switch {
  filter: none;
}
</style>
