<template>
  <ui-camera-frame v-if="isCallActive">
    <ui-camera-description
      slot="description"
      :resource="resource"
      :message="message"
    />
    <ui-camera slot="camera" :resource="resource" :controls="controls" call />
    <call-controls slot="controls" :start-callback="startCall" />
  </ui-camera-frame>
  <form-page v-else sign-required @call="repeatCall" @complete="complete" />
</template>

<script>
import CallControls from '@src/components/partials/CallControls'
import FormPage from '@src/components/pages/FormPage'
import CallController from '@src/scripts/callController'
import actions from '@src/store/actions'
import { cameraStates, errorTypes } from '@src/scripts/enums'
import { activityLogger } from '@src/scripts/activityLogger'

export default {
  components: {
    CallControls,
    FormPage
  },

  data() {
    return {
      isCallActive: true,
      resource: 'Call',
      controls: null
    }
  },

  computed: {
    message() {
      const { error } = this
      return error || 'PreLoad'
    }
  },

  created() {
    activityLogger.logActivity('CALL_PAGE')
    this.registerCallService()
  },

  methods: {
    registerCallService() {
      this.controls = new CallController()
      this.controls.callback.makeCallSuccess = this.callStarted
      this.controls.callback.errorOccured = this.callFailed
      this.controls.callback.callEnded = this.callCompleted
    },

    startCall() {
      this.controls.makeCall()
      activityLogger.logActivity('CALL_START_INITIATED')
    },

    callCompleted(status) {
      if (status && status.completed) {
        activityLogger.logActivity('CALL_COMPLETED')

        if (status.dokobitRequired) {
          this.isCallActive = false
          activityLogger.logActivity('SECOND_SIGNING_REQUESTED')
        } else {
          this.$router.pushNext()
          activityLogger.logActivity('CALL_STEP_COMPLETED')
        }
      } else {
        this.$store.dispatch(actions.updateCameraState, cameraStates.ok)
        activityLogger.logActivity('CALL_CANCELED_BY_AGENT')
      }
    },

    callStarted() {
      this.$store.dispatch(actions.updateCameraState, cameraStates.active)
      activityLogger.logActivity('CALL_STARTED')
    },

    callFailed() {
      this.$store.dispatch(actions.updateCameraState, cameraStates.ok)
      this.$popUp(errorTypes.failed)
      activityLogger.logActivity('CALL_FAILED')
    },

    repeatCall() {
      this.$store.dispatch(actions.updateCameraState, cameraStates.ok)
      this.registerCallService()
      this.isCallActive = true
      activityLogger.logActivity('CALL_REPEAT_INITIATED')
    },

    complete() {
      activityLogger.logActivity('CALL_STEP_COMPLETED')
    }
  }
}
</script>

<style scoped>
.call-sign {
  height: 100%;
}

.call-sign .ui-button {
  bottom: 2rem;
  margin-right: auto;
}

.mobile-app .call-sign .ui-button {
  margin-left: 2rem;
}

@media screen and (max-width: 30rem) {
  .desktop-app .call-sign .ui-button {
    margin-left: 2rem;
  }
}

@media screen and (min-width: 30.0625rem) {
  .desktop-app .call-sign .ui-button {
    margin-left: auto;
  }
}
</style>
