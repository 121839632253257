<template>
  <div v-if="source" class="commercial-player" :class="{ mute: isMuted }">
    <video :src="source" autoplay :loop="loop" :muted="isMuted" />
    <button class="commercial-mute" @click="changeVolume" />
  </div>
</template>

<script>
export default {
  props: {
    source: { type: String, default: undefined },
    loop: Boolean
  },

  data() {
    return {
      isMuted: false
    }
  },

  methods: {
    changeVolume() {
      this.isMuted = !this.isMuted
    }
  }
}
</script>

<style scoped>
.commercial-player {
  width: 100%;
  position: relative;
}

.commercial-player video {
  width: 100%;
}

.commercial-player + h3 {
  margin-top: 1rem;
}

.commercial-mute {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url('/public/images/volume.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-color: var(--white);
  border: none;
  border-radius: 0.25rem;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}

.commercial-mute:hover {
  cursor: pointer;
}

.commercial-mute:focus {
  outline: none;
}

.mute .commercial-mute {
  background-position-x: 100%;
}

.commercial-player:hover .commercial-mute {
  opacity: 1;
}
</style>
