var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isCallActive
    ? _c(
        "ui-camera-frame",
        [
          _c("ui-camera-description", {
            attrs: {
              slot: "description",
              resource: _vm.resource,
              message: _vm.message,
            },
            slot: "description",
          }),
          _vm._v(" "),
          _c("ui-camera", {
            attrs: {
              slot: "camera",
              resource: _vm.resource,
              controls: _vm.controls,
              call: "",
            },
            slot: "camera",
          }),
          _vm._v(" "),
          _c("call-controls", {
            attrs: { slot: "controls", "start-callback": _vm.startCall },
            slot: "controls",
          }),
        ],
        1
      )
    : _c("form-page", {
        attrs: { "sign-required": "" },
        on: { call: _vm.repeatCall, complete: _vm.complete },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }