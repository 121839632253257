<template>
  <div ref="container" class="id-check-loader container">
    <div v-if="!isIdentityCheckFailed && !isSuccess" class="percent">
      <div id="percentage" class="number">{{ percent }}</div>
      <div class="sign">%</div>
    </div>
    <div v-if="!isIdentityCheckFailed && !isSuccess" class="message">
      {{ message }}
    </div>
    <div id="content" class="content">
      <canvas ref="canvas" class="canvas" />
      <video ref="video" class="video" autoplay muted playsinline />
      <video ref="success" class="video" muted playsinline />
      <video ref="error" class="video" muted playsinline />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import mutations from '@src/store/mutations'
import getters from '@src/store/getters'
import { idCheckLoaderStatuses } from '@src/scripts/enums'
import { ID_CHECK_LOADER } from '@src/scripts/constants'
import {
  getTotalCurrentTime,
  getTotalDuration,
  getPercent,
  getMessage,
  getPercentOnError
} from '@src/scripts/helpers'

const createVideoElement = () => {
  const video = document.createElement('video')
  const content = document.getElementById('content')
  content.appendChild(video)

  video.muted = true
  video.setAttribute('muted', '')
  video.setAttribute('playsinline', '')
  video.setAttribute('preload', '')

  return video
}

const getCustomCurrentTime = (currentTime, previousTime) => {
  return previousTime < currentTime ? currentTime - previousTime : currentTime
}

export default {
  props: {
    isSuccess: Boolean,
    isError: Boolean
  },

  data() {
    return {
      errorAnimationInitiated: false,
      currentVideoId: 1,
      currentTotalTime: 0,
      isCompleted: false,
      isCustomAnimationCompleted: false,
      totalCurrentTime: 0,
      previousCustomVideoTime: 0,
      percent: 0,
      message: '',
      loaderVideos: {
        video1: {
          src: null,
          duration: 14,
          messages: [
            this.$t('IdCheckLoader.Videos.Video1.Message1'),
            this.$t('IdCheckLoader.Videos.Video1.Message2')
          ]
        },
        video2: {
          src: null,
          duration: 44,
          messages: [
            this.$t('IdCheckLoader.Videos.Video2.Message1'),
            this.$t('IdCheckLoader.Videos.Video2.Message2'),
            this.$t('IdCheckLoader.Videos.Video2.Message3'),
            this.$t('IdCheckLoader.Videos.Video2.Message4'),
            this.$t('IdCheckLoader.Videos.Video2.Message5')
          ]
        },
        video3: {
          src: null,
          duration: 15,
          messages: [
            this.$t('IdCheckLoader.Videos.Video3.Message1'),
            this.$t('IdCheckLoader.Videos.Video3.Message2')
          ]
        },
        video4: {
          src: null,
          duration: 17,
          messages: [this.$t('IdCheckLoader.Videos.Video4.Message1')]
        },
        video5: {
          src: null,
          duration: 26,
          messages: [this.$t('IdCheckLoader.Videos.Video5.Message1')]
        },
        video6: {
          src: null,
          duration: 16,
          messages: [this.$t('IdCheckLoader.Videos.Video6.Message1')]
        },
        video7: {
          src: null,
          duration: 15,
          messages: [
            this.$t('IdCheckLoader.Videos.Video7.Message1'),
            this.$t('IdCheckLoader.Videos.Video7.Message2')
          ]
        },
        video8: {
          src: null,
          duration: 16,
          messages: [
            this.$t('IdCheckLoader.Videos.Video8.Message1'),
            this.$t('IdCheckLoader.Videos.Video8.Message2')
          ]
        }
      },
      isResponseVideoStarted: false,
      responseVideos: {
        success: {
          src: null,
          messages: [this.$t('IdCheckLoader.ResponseVideos.Success.Message1')]
        },
        error: {
          src: null,
          messages: [this.$t('IdCheckLoader.ResponseVideos.Error.Message1')]
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      getters.isIdentityCheckFailed,
      getters.idvCustomLoaderSource,
      getters.idvLoaderSource
    ]),

    waitingAnimation() {
      return this.idvCustomLoaderSource(this.$language())
    },

    loaderEntries() {
      return (this.loaderVideos && Object.entries(this.loaderVideos)) || []
    }
  },

  watch: {
    isSuccess: function (isSuccess) {
      if (isSuccess) {
        this.currentVideoId = 1
        this.$refs.canvas.style.opacity = 0
        this.playResponse(idCheckLoaderStatuses.success)
      }
    },
    isIdentityCheckFailed: function (isError) {
      if (isError && this.isIdentityCheckFailed) {
        this.currentVideoId = 1
        this.$refs.canvas.style.opacity = 0
        this.playResponse(idCheckLoaderStatuses.error)
      }
    },
    isCompleted: function (isCompleted) {
      if (isCompleted) {
        const interval = setInterval(() => {
          if (this.percent < 99) {
            this.percent++
          } else {
            clearInterval(interval)
          }
        }, 5000)
      }
    }
  },

  created() {
    const { idvLoaderSource, waitingAnimation } = this

    if (waitingAnimation) {
      this.setProgressAnimation(waitingAnimation.progress)
      this.setApproveAnimation(waitingAnimation.approve)
      this.setRejectAnimation(waitingAnimation.reject)
    } else {
      for (const key in idvLoaderSource.progress) {
        const element = this.loaderVideos[`video${+key + 1}`]
        if (element) {
          element.src = idvLoaderSource.progress[key]
        }
      }

      this.responseVideos.success.src = idvLoaderSource.approve
      this.responseVideos.error.src = idvLoaderSource.reject
    }
  },

  mounted() {
    this.loaderVideos.video1.video = this.getLoader({ id: 1 })
    this.responseVideos.success.video = this.getResponse(
      idCheckLoaderStatuses.success
    )
    this.responseVideos.error.video = this.getResponse(
      idCheckLoaderStatuses.error
    )
  },

  methods: {
    ...mapMutations([mutations.setIdentityCheckFailed]),

    setProgressAnimation(animation) {
      if (!animation) {
        return
      }

      let duration = 0
      let messages = []

      for (const [key] of this.loaderEntries) {
        const loader = this.loaderVideos[key]
        duration += loader.duration
        messages = [...messages, ...(loader.messages || [])]
      }

      this.loaderVideos = {
        video1: {
          messages,
          duration,
          src: animation
        }
      }
    },

    setApproveAnimation(animation) {
      if (!animation) {
        return
      }

      this.responseVideos.success.src = animation
    },

    setRejectAnimation(animation) {
      if (!animation) {
        return
      }

      this.responseVideos.error.src = animation
    },

    onVideoPlay(videoId) {
      if (this.currentVideoId !== videoId) {
        return
      }

      const playlistLen = this.loaderEntries.length
      if (videoId < playlistLen) {
        this.loaderVideos[
          `video${this.currentVideoId + 1}`
        ].video = this.getLoader({ id: this.currentVideoId + 1 })
      }
    },

    getLoader({ id = 1 }) {
      const video = (id === 1 && this.$refs.video) || createVideoElement()
      const loaderKey = `video${id}`

      video.src = this.loaderVideos[loaderKey].src
      video.addEventListener('play', () => this.onVideoPlay(id))

      if (id === 1) {
        video.play()
      }

      const onLoadedData = () => {
        video.currentTime = ID_CHECK_LOADER.VIDEO_CURRENTTIME_START_OFFSET
        const { canvas } = this.$refs
        if (canvas) {
          canvas.width =
            video.videoWidth - ID_CHECK_LOADER.CANVAS_GREEN_LINE_OFFSET
          canvas.height =
            video.videoHeight - ID_CHECK_LOADER.CANVAS_GREEN_LINE_OFFSET

          canvas.style.opacity = 1
          this.drawCanvas({ video, canvas, id })
        }
      }
      video.addEventListener('loadeddata', onLoadedData)

      const onTimeUpdate = async () => {
        if (this.currentVideoId === id) {
          const {
            isCompleted,
            currentVideoId,
            waitingAnimation,
            isCustomAnimationCompleted
          } = this

          if (
            (!isCompleted ||
              (waitingAnimation?.progress && !isCustomAnimationCompleted)) &&
            !this.isSuccess &&
            !this.isError
          ) {
            if (waitingAnimation?.progress) {
              this.totalCurrentTime += getCustomCurrentTime(
                video.currentTime,
                this.previousCustomVideoTime
              )
              this.previousCustomVideoTime = video.currentTime
            } else {
              this.totalCurrentTime = getTotalCurrentTime(
                this.loaderVideos,
                video.currentTime,
                currentVideoId
              )
            }

            this.totalDuration = getTotalDuration(this.loaderVideos)
            this.percent = getPercent(this.totalCurrentTime, this.totalDuration)
          }

          if (!this.isSuccess && !this.isError) {
            if (this.waitingAnimation?.progress) {
              if (!isCustomAnimationCompleted) {
                this.message = getMessage({
                  currentTime: this.totalCurrentTime,
                  duration: this.totalDuration,
                  loaderVideos: this.loaderVideos,
                  responseVideos: this.responseVideos,
                  currentVideoId: this.currentVideoId,
                  isResponseVideoStarted: this.isResponseVideoStarted,
                  isSuccess: this.isSuccess,
                  isError: this.isError
                })
              }
            } else if (
              video.currentTime > ID_CHECK_LOADER.VIDEO_MESSAGE_OFFSET &&
              !isCompleted
            ) {
              this.message = getMessage({
                currentTime: video.currentTime,
                duration: video.duration,
                loaderVideos: this.loaderVideos,
                responseVideos: this.responseVideos,
                currentVideoId: this.currentVideoId,
                isResponseVideoStarted: this.isResponseVideoStarted,
                isSuccess: this.isSuccess,
                isError: this.isError
              })
            }
          } else {
            if (!this.errorAnimationInitiated) {
              this.errorAnimationInitiated = true
              if (this.isError) {
                await getPercentOnError(this.percent)
                this.setIdentityCheckFailed(true)
              }
            }
            if (this.isIdentityCheckFailed) {
              getMessage({
                currentTime: video.currentTime,
                duration: video.duration,
                loaderVideos: this.loaderVideos,
                responseVideos: this.responseVideos,
                currentVideoId: this.currentVideoId,
                isResponseVideoStarted: this.isResponseVideoStarted,
                isSuccess: this.isSuccess,
                isError: this.isError
              })
            }
          }
        }
      }
      video.addEventListener('timeupdate', onTimeUpdate)

      const onEnded = () => {
        const {
          isSuccess,
          isError,
          currentVideoId,
          loaderEntries,
          totalDuration,
          totalCurrentTime,
          playNextLoader
        } = this

        this.isCompleted = currentVideoId === loaderEntries.length
        this.isCustomAnimationCompleted = totalDuration <= totalCurrentTime

        const isNextClipAvailable = !isSuccess && !isError && !this.isCompleted

        if (isNextClipAvailable) {
          video.pause()
          video.removeAttribute('src')
          video.remove()
          playNextLoader()
        } else {
          video.play()
        }
      }
      video.addEventListener('ended', onEnded)

      return video
    },

    getResponse(type) {
      const { success, error } = this.$refs
      const video = type === idCheckLoaderStatuses.success ? success : error
      video.muted = true
      video.setAttribute('muted', '')
      video.setAttribute('playsinline', '')
      video.setAttribute('preload', '')
      video.src = this.responseVideos[type].src

      const onPlay = () => {
        const { canvas, container } = this.$refs
        if (canvas) {
          canvas.width =
            video.videoWidth - ID_CHECK_LOADER.CANVAS_GREEN_LINE_OFFSET
          canvas.height =
            video.videoHeight - ID_CHECK_LOADER.CANVAS_GREEN_LINE_OFFSET

          canvas.style.opacity = 1
          this.isResponseVideoStarted = true
          container.style.opacity = 1
          this.drawCanvas({ video, canvas, id: 1 })
        }
      }
      video.addEventListener('play', onPlay)

      const onTimeUpdate = () => {
        if (video.currentTime > 10) {
          video.pause()
        }
      }
      video.addEventListener('timeupdate', onTimeUpdate)
      return video
    },

    playNextLoader() {
      this.currentVideoId++
      const video = this.loaderVideos[`video${this.currentVideoId}`].video
      video.play()
    },

    playResponse(type) {
      this.percent = 100
      const { success, error } = this.$refs
      const video = type === idCheckLoaderStatuses.success ? success : error
      if (type === idCheckLoaderStatuses.success) {
        video.playbackRate = ID_CHECK_LOADER.SUCCESS_VIDEO_PLAYBACK_RATE
        const [firstMessage] = this.responseVideos.success.messages
        this.message = firstMessage
      }
      video.play()
    },

    drawCanvas({ video, canvas, id }) {
      if (this.currentVideoId === id) {
        if (video.videoWidth && video.videoHeight && canvas) {
          const context = canvas.getContext('2d')
          context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
        }
      }
      requestAnimationFrame(() => {
        this.drawCanvas({ video, canvas, id })
      })
    }
  }
}
</script>

<style scoped>
.container {
  text-align: center;
}

.percent {
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: center;
  color: #ccc;
}

.number {
  font-size: 6rem;
}

.sign {
  font-size: 2rem;
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: fit-content;
  min-height: 5rem;
  margin: 0 auto;
  text-align: center;
  width: 300px;
}

.canvas {
  width: 100%;
  height: auto;
  max-width: 500px;
  max-height: 610px;
  margin-top: 1rem;
}

@media screen and (min-height: 601px) and (max-height: 1200px) {
  .canvas {
    max-width: 300px;
    max-height: 366px;
  }
}

@media screen and (max-height: 700px) {
  .canvas {
    max-width: 200px;
    max-height: 266px;
  }
}

@media screen and (max-height: 600px), (max-width: 480px) {
  .number {
    font-size: 3rem;
  }
  .canvas {
    max-width: 120px;
    max-height: 146px;
  }
}
</style>

<style>
.id-check-loader video {
  position: absolute;
  opacity: 0;
}
</style>
