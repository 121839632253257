import Vue from 'vue'
import Vuex from 'vuex'
import actionNames, { methods as actions } from '@src/store/actions'
import { methods as getters } from '@src/store/getters'
import { methods as mutations } from '@src/store/mutations'
import { cachedToken } from '@src/store/cache'
import { cameraStates } from '@src/scripts/enums'
import { getBrowserData } from '@src/scripts/browserSupport'
import { faceCheckTypes } from '@src/scripts/enums'
import { uuid } from '@src/scripts/helpers'
import { OMNICHANNEL_STATUSES } from '@src/scripts/constants'

Vue.use(Vuex)

const state = {
  session: {},
  resources: {},
  sessionExists: false,
  browserData: updateBrowserData(),
  accessData: cachedToken(),
  camera: {
    state: cameraStates.pending
  },
  isIdentityCheckFailed: false,
  isFaceStepDisabled: false,
  faceCheckMode: faceCheckTypes.liveness,
  correlationId: uuid(),
  omnichannel: {
    status: OMNICHANNEL_STATUSES.UNAVAILABLE,
    isNonCloseable: false
  }
}

export const stateKeys = new Proxy(state, {
  get(target, key) {
    if (!target[key]) {
      throw `State key ${key} not exist.`
    }
    return key
  }
})

const store = new Vuex.Store({
  state,
  actions,
  getters,
  mutations
})

store.watch((state) => state.accessData, cachedToken)

function updateBrowserData() {
  getBrowserData().then((browserData) => {
    store.dispatch(actionNames.updateBrowserData, browserData)
  })
}

export default store
