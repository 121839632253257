var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "identification-check-container" }, [
    _c("div", { staticClass: "identification-check-box" }, [
      _c(
        "div",
        { staticClass: "policy-content" },
        [
          _vm.isReady
            ? _c("ui-frame", { staticClass: "identification-check" }, [
                _c(
                  "div",
                  { staticClass: "frame-content" },
                  [
                    _vm.isIdentityCheckFailed || _vm.isSuccess
                      ? _c("id-check-loader", {
                          attrs: {
                            "is-success": _vm.isSuccess,
                            "is-error": _vm.isError,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("transition", { attrs: { name: "fade" } }, [
                      _c("div", { staticClass: "result-message-group" }, [
                        _vm.isIdentityCheckFailed || _vm.isSuccess
                          ? _c("h2", { staticClass: "result-message" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t(_vm.messagePrimary)) +
                                  "\n              "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isIdentityCheckFailed || _vm.isSuccess
                          ? _c(
                              "div",
                              { staticClass: "result-message-secondary" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t(_vm.messageSecondary)) +
                                    "\n              "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.isIdentityCheckFailed
                      ? _c(
                          "div",
                          { staticClass: "button-group" },
                          [
                            _vm.canRetry
                              ? _c("ui-button", {
                                  attrs: {
                                    label: "App.TryAgain",
                                    disabled: _vm.isLoading,
                                  },
                                  on: { click: _vm.action },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isFailureUrlAvailable
                              ? _c("ui-button", {
                                  attrs: { additional: "", label: "App.Close" },
                                  on: { click: _vm.close },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isOmnichannelEnabled
                              ? _c("omnichannel-controls")
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isIdentityCheckFailed && _vm.isSuccess
                      ? _c(
                          "div",
                          { staticClass: "button-group" },
                          [
                            _c("ui-button", {
                              attrs: {
                                type: "button",
                                label: "Check.Success.button",
                                timer: _vm.getRedirectTimer(_vm.redirectTimer),
                                disabled: _vm.isLoading,
                              },
                              on: { click: _vm.forceRedirect },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isIdentityCheckFailed && !_vm.isSuccess
                      ? _c("id-check-loader", {
                          attrs: {
                            "is-success": _vm.isSuccess,
                            "is-error": _vm.isError,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }